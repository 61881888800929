'use strict';


function intifySeverity(ty) {
  if (ty === "DONT_LOG") {
    return 6;
  } else if (ty === "IMPORTANT") {
    return 2;
  } else if (ty === "MOST_IMPORTANT") {
    return 1;
  } else if (ty === "INCIDENTAL") {
    return 5;
  } else if (ty === "SLIGHTLY_IMPORTANT") {
    return 3;
  } else if (ty === "REPORT") {
    return 8;
  } else if (ty === "LEAST_IMPORTANT") {
    return 4;
  } else {
    return 7;
  }
}

function stringifySeverity(ty) {
  if (ty === "DONT_LOG") {
    return "Dont Log (6)";
  } else if (ty === "IMPORTANT") {
    return "Important (2)";
  } else if (ty === "MOST_IMPORTANT") {
    return "Most Important (1)";
  } else if (ty === "INCIDENTAL") {
    return "Incidental (5)";
  } else if (ty === "SLIGHTLY_IMPORTANT") {
    return "Slightly Important (3)";
  } else if (ty === "REPORT") {
    return "Report (8)";
  } else if (ty === "LEAST_IMPORTANT") {
    return "Least Important (4)";
  } else {
    return "Channel (7)";
  }
}

function stringifyLoggingLevel(ty) {
  if (ty === "ERRORS_ONLY") {
    return "Errors Only";
  } else if (ty === "LOW") {
    return "Low";
  } else if (ty === "EVERYTHING") {
    return "Everything";
  } else if (ty === "HIGH") {
    return "High";
  } else if (ty === "MEDIUM") {
    return "Medium";
  } else if (ty === "REPORT") {
    return "Report";
  } else {
    return "Channel";
  }
}

exports.intifySeverity = intifySeverity;
exports.stringifySeverity = stringifySeverity;
exports.stringifyLoggingLevel = stringifyLoggingLevel;
/* No side effect */
