'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_MenuItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_MenuItem.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");

function stringify(ty) {
  if (ty === "NET_SUITE") {
    return "Net Suite";
  } else if (ty === "WOO_COMMERCE") {
    return "Woo Commerce";
  } else if (ty === "EPICOR") {
    return "Epicor";
  } else if (ty === "MACOLA_ES") {
    return "Macola ES";
  } else if (ty === "SALSIFY") {
    return "Salsify";
  } else if (ty === "SHOPIFY") {
    return "Shopify";
  } else if (ty === "SAGE_100") {
    return "Sage 100";
  } else if (ty === "AVALARA_CERT_CAPTURE") {
    return "Avalara Cert Capture";
  } else if (ty === "AMAZON") {
    return "Amazon";
  } else if (ty === "INFOR_CSI") {
    return "Infor CSI";
  } else if (ty === "M_1") {
    return "M 1";
  } else if (ty === "SERVICE_PRO") {
    return "Service Pro";
  } else if (ty === "QUICK_BOOKS_ONLINE") {
    return "Quick Books Online";
  } else if (ty === "MAGENTO") {
    return "Magento";
  } else if (ty === "SHIPPING_EASY") {
    return "Shipping Easy";
  } else if (ty === "ASLAN_PORTAL") {
    return "Aslan Portal";
  } else if (ty === "QUICK_BOOKS_DESKTOP") {
    return "Quick Books Desktop";
  } else if (ty === "E_AUTOMATE") {
    return "E-Automate";
  } else if (ty === "B_2_B") {
    return "B2B";
  } else {
    return "Commerce Hub";
  }
}

function ofString(value) {
  var match = value.toLowerCase();
  switch (match) {
    case "amazon" :
        return "AMAZON";
    case "aslan portal" :
        return "ASLAN_PORTAL";
    case "avalara cert capture" :
        return "AVALARA_CERT_CAPTURE";
    case "b2b" :
        return "B_2_B";
    case "commerce hub" :
        return "COMMERCE_HUB";
    case "e-automate" :
        return "E_AUTOMATE";
    case "epicor" :
        return "EPICOR";
    case "infor csi" :
        return "INFOR_CSI";
    case "m 1" :
        return "M_1";
    case "macola es" :
        return "MACOLA_ES";
    case "magento" :
        return "MAGENTO";
    case "net suite" :
        return "NET_SUITE";
    case "quick books desktop" :
        return "QUICK_BOOKS_DESKTOP";
    case "quick books online" :
        return "QUICK_BOOKS_ONLINE";
    case "sage 100" :
        return "SAGE_100";
    case "salsify" :
        return "SALSIFY";
    case "service pro" :
        return "SERVICE_PRO";
    case "shipping easy" :
        return "SHIPPING_EASY";
    case "shopify" :
        return "SHOPIFY";
    case "woo commerce" :
        return "WOO_COMMERCE";
    default:
      return ;
  }
}

var connectorTypes = [
  "QUICK_BOOKS_ONLINE",
  "M_1",
  "AVALARA_CERT_CAPTURE",
  "COMMERCE_HUB",
  "EPICOR",
  "E_AUTOMATE",
  "MAGENTO",
  "SALSIFY",
  "SHOPIFY",
  "SERVICE_PRO",
  "AMAZON",
  "QUICK_BOOKS_DESKTOP",
  "MACOLA_ES",
  "SHIPPING_EASY",
  "SAGE_100",
  "WOO_COMMERCE",
  "B_2_B",
  "NET_SUITE",
  "INFOR_CSI",
  "ASLAN_PORTAL"
];

function item(x) {
  var s = stringify(x);
  return React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(s), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                  NAME: "String",
                  VAL: s
                }, undefined, undefined, s, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

function ConnectorTypeSelect$Required(Props) {
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var value = Props.value;
  var onChange = Props.onChange;
  var value$1 = stringify(value);
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, Caml_option.some(Belt_Array.map(connectorTypes, item)), undefined, undefined, undefined, undefined, undefined, fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, label, margin, undefined, undefined, undefined, (function ($$event) {
                    return Curry._1(onChange, Belt_Option.getExn(ofString($$event.target.value)));
                  }), undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, value$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var Required = {
  make: ConnectorTypeSelect$Required
};

function ConnectorTypeSelect$Option(Props) {
  var orderTypes = Props.orderTypes;
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var value = Props.value;
  var onChange = Props.onChange;
  var value$1 = value !== undefined ? stringify(value) : "";
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, label, margin, undefined, undefined, undefined, (function ($$event) {
                    var value = $$event.target.value;
                    var tmp = value === "" ? undefined : Belt_Option.getExn(ofString(value));
                    return Curry._1(onChange, tmp);
                  }), undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, value$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(React.createElement("em", undefined, "None")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                      NAME: "String",
                      VAL: ""
                    }, undefined, undefined, "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), Belt_Array.map(orderTypes, item));
}

var $$Option = {
  make: ConnectorTypeSelect$Option
};

exports.stringify = stringify;
exports.ofString = ofString;
exports.connectorTypes = connectorTypes;
exports.item = item;
exports.Required = Required;
exports.$$Option = $$Option;
/* react Not a pure module */
