'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Stricture$BirddogsoftwareFrontend = require("../../../Components/Forms/Stricture.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../../Components/Forms/SimpleForm.bs.js");
var EnterpriseFilterGroupDetail$BirddogsoftwareFrontend = require("./EnterpriseFilterGroupDetail.bs.js");

var ppx_printed_query = "mutation q($filterGroupId: Guid!)  {\nfilterGroupDelete(filterGroupId: $filterGroupId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroupDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroupDelete: tmp
        };
}

function make(filterGroupId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "filterGroupId",
                    filterGroupId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var filterGroupId = variables.filterGroupId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "filterGroupId",
                    filterGroupId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(filterGroupId, param) {
  return Js_dict.fromArray([[
                  "filterGroupId",
                  filterGroupId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, filterGroupId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "filterGroupId",
                      filterGroupId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($filterGroupId: Guid, $data: FilterGroupInput!)  {\nfilterGroup(filterGroupId: $filterGroupId, data: $data)  {\nsuccess  {\nfilterGroupId  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroup");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "success");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "filterGroupId");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field filterGroupId on type FilterGroup is missing");
          }
          tmp$1 = {
            filterGroupId: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      tmp = {
        success: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroup: tmp
        };
}

function json_of_FilterGroupInput(value) {
  var v = value.description;
  return Js_dict.fromArray([[
                  "description",
                  v !== undefined ? v : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(filterGroupId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "filterGroupId",
                    filterGroupId !== undefined ? Caml_option.valFromOption(filterGroupId) : null
                  ],
                  [
                    "data",
                    json_of_FilterGroupInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var filterGroupId = variables.filterGroupId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "filterGroupId",
                    filterGroupId !== undefined ? Caml_option.valFromOption(filterGroupId) : null
                  ],
                  [
                    "data",
                    json_of_FilterGroupInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(filterGroupId, data, param) {
  return Js_dict.fromArray([
                [
                  "filterGroupId",
                  filterGroupId !== undefined ? Caml_option.valFromOption(filterGroupId) : null
                ],
                [
                  "data",
                  json_of_FilterGroupInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, filterGroupId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "filterGroupId",
                      filterGroupId !== undefined ? Caml_option.valFromOption(filterGroupId) : null
                    ],
                    [
                      "data",
                      json_of_FilterGroupInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Mutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_FilterGroupInput: json_of_FilterGroupInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "query Q($filterGroupId: Guid!)  {\nfilterGroup(filterGroupId: $filterGroupId)  {\nfilterGroupId  \ndescription  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroup");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "filterGroupId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field filterGroupId on type FilterGroup is missing");
      }
      var value$8 = Js_dict.get(value$4, "description");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field description on type FilterGroup is missing");
      }
      tmp = {
        filterGroupId: tmp$1,
        description: tmp$2
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroup: tmp
        };
}

function make$2(filterGroupId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "filterGroupId",
                    filterGroupId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var filterGroupId = variables.filterGroupId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "filterGroupId",
                    filterGroupId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(filterGroupId, param) {
  return Js_dict.fromArray([[
                  "filterGroupId",
                  filterGroupId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, filterGroupId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "filterGroupId",
                      filterGroupId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var Query = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function doneRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups"
        };
}

function breadcrumbs(context) {
  return [{
            href: {
              NAME: "Enterprise",
              VAL: "/filter-groups"
            },
            content: "Filter Groups"
          }];
}

function newRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/new"
        };
}

var RouteArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute
};

var newState = {
  description: ""
};

function reducer(context, state, action) {
  return {
          description: action._0
        };
}

function getName(context, state) {
  return state.description;
}

function selfRoute(context, id) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/" + id
        };
}

function makeMutation(context, filterGroupId, state) {
  return make$1(Belt_Option.map(filterGroupId, (function (prim) {
                    return prim;
                  })), {
              description: state.description
            }, undefined);
}

function mapMutation(context, result) {
  return Belt_Option.map(Belt_Option.flatMap(result.filterGroup, (function (filterGroup) {
                    return filterGroup.success;
                  })), (function (success) {
                return success.filterGroupId;
              }));
}

function makeQuery(context, filterGroupId) {
  return make$2(filterGroupId, undefined);
}

function mapQuery(context, result) {
  return Belt_Option.map(result.filterGroup, (function (filterGroup) {
                return {
                        description: filterGroup.description
                      };
              }));
}

function makeDelete(context, filterGroupId) {
  return make(filterGroupId, undefined);
}

function saveValidate(context, state) {
  return state.description.trim() !== "";
}

function mainActions(context, id, state, dispatch) {
  return [];
}

function mainContent(context, id, state, dispatch) {
  return React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Description",
                  value: state.description,
                  onChange: (function (value) {
                      return Curry._1(dispatch, /* SetDescription */{
                                  _0: value
                                });
                    })
                }));
}

var secondaryContent = (function (context, id, state, dispatch) {
    return [{
              title: "Details",
              content: id !== undefined ? React.createElement(EnterpriseFilterGroupDetail$BirddogsoftwareFrontend.List.NoContainerWithNew.make, {
                      context: {
                        filterGroupId: id
                      }
                    }) : React.createElement(React.Fragment, undefined)
            }];
  });

var FormArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  newState: newState,
  reducer: reducer,
  doneRoute: doneRoute,
  getName: getName,
  selfRoute: selfRoute,
  makeMutation: makeMutation,
  mapMutation: mapMutation,
  stayAfterSaveNew: true,
  makeQuery: makeQuery,
  mapQuery: mapQuery,
  makeDelete: makeDelete,
  saveValidate: saveValidate,
  mainActions: mainActions,
  mainContent: mainContent,
  secondaryContent: secondaryContent
};

var Form = Stricture$BirddogsoftwareFrontend.Form.Make({
      breadcrumbs: breadcrumbs,
      newRoute: newRoute,
      newState: newState,
      reducer: reducer,
      doneRoute: doneRoute,
      getName: getName,
      selfRoute: selfRoute,
      makeMutation: makeMutation,
      mapMutation: mapMutation,
      makeQuery: makeQuery,
      mapQuery: mapQuery,
      makeDelete: makeDelete,
      saveValidate: saveValidate,
      mainContent: mainContent,
      mainActions: mainActions,
      secondaryContent: secondaryContent,
      stayAfterSaveNew: true
    });

var ppx_printed_query$3 = "query Q  {\nfilterGroupList  {\nfilterGroupId  \ndescription  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroupList");
  return {
          filterGroupList: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "filterGroupId");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field filterGroupId on type FilterGroup is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "description");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field description on type FilterGroup is missing");
                  }
                  return {
                          filterGroupId: tmp,
                          description: tmp$1
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field filterGroupList on type Query is missing")
        };
}

function make$3(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeWithVariables$3(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeVariables$3(param) {
  return null;
}

function definition_2$3(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var ListQuery = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function primaryColumnTitle(context) {
  return "Description";
}

function getPrimaryColumnContent(context, x) {
  return x.description;
}

function getPrimaryColumnRoute(context, x) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/" + x.filterGroupId
        };
}

function queryArgsReducer(a, b) {
  
}

function queryArgsElement(state, dispatch) {
  return React.createElement(React.Fragment, undefined);
}

function makeQuery$1(context, queryArgs) {
  return make$3(undefined);
}

function mapQuery$1(context, result) {
  return Belt_Array.map(result.filterGroupList, (function (filterGroup) {
                return {
                        description: filterGroup.description,
                        filterGroupId: filterGroup.filterGroupId
                      };
              }));
}

var columns = [];

var ListArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  primaryColumnTitle: primaryColumnTitle,
  getPrimaryColumnContent: getPrimaryColumnContent,
  getPrimaryColumnRoute: getPrimaryColumnRoute,
  queryArgsDefault: undefined,
  queryArgsReducer: queryArgsReducer,
  queryArgsElement: queryArgsElement,
  makeQuery: makeQuery$1,
  mapQuery: mapQuery$1,
  columns: columns
};

var List = Stricture$BirddogsoftwareFrontend.List.Make(ListArg);

exports.DeleteMutation = DeleteMutation;
exports.Mutation = Mutation;
exports.Query = Query;
exports.doneRoute = doneRoute;
exports.RouteArg = RouteArg;
exports.FormArg = FormArg;
exports.Form = Form;
exports.ListQuery = ListQuery;
exports.ListArg = ListArg;
exports.List = List;
/* Form Not a pure module */
