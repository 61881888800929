'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var Date$BirddogsoftwareFrontend = require("../../../Utils/Date.bs.js");
var Link$BirddogsoftwareFrontend = require("../../../Components/Link.bs.js");
var Page$BirddogsoftwareFrontend = require("../../../Components/Page.bs.js");
var Order$BirddogsoftwareFrontend = require("../../../Types/Order.bs.js");
var Fields$BirddogsoftwareFrontend = require("../../../Components/Forms/Fields.bs.js");
var PaperPadded$BirddogsoftwareFrontend = require("../../../Components/PaperPadded.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../UrlResolver.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../../Components/Forms/ActionHeader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var MaterialTable$BirddogsoftwareFrontend = require("../../../Components/MaterialTable.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../Components/SnackbarHooks.bs.js");
var CustomerAutocomplete$BirddogsoftwareFrontend = require("../../../Components/Forms/Pickers/CustomerAutocomplete.bs.js");

var ppx_printed_query = "query Q($type: OrderListType, $userId: Guid, $customerNo: String, $shipToNo: String, $startDate: Date, $endDate: Date)  {\norderList(type: $type, userId: $userId, customerNo: $customerNo, shipToNo: $shipToNo, startDate: $startDate, endDate: $endDate)  {\n__typename\n...on OrderDraft   {\norderDraftId  \nuserId  \ncustomerNo  \n}\n\n...on OrderOpen   {\norderType  \norderNo  \nuserId  \ncustomerNo  \n}\n\n...on OrderHistory   {\norderType  \norderNo  \nuserId  \ncustomerNo  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderList");
  return {
          orderList: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var typename_obj = Js_json.decodeObject(value);
                  if (typename_obj === undefined) {
                    return Js_exn.raiseError("graphql_ppx: Expected union OrderDraftOrOpenOrHistoryGraphType to be an object, got " + JSON.stringify(value));
                  }
                  var typename = Js_dict.get(Caml_option.valFromOption(typename_obj), "__typename");
                  if (typename === undefined) {
                    return Js_exn.raiseError("graphql_ppx: Union OrderDraftOrOpenOrHistoryGraphType is missing the __typename field");
                  }
                  var typename$1 = Js_json.decodeString(Caml_option.valFromOption(typename));
                  if (typename$1 === undefined) {
                    return Js_exn.raiseError("graphql_ppx: Union OrderDraftOrOpenOrHistoryGraphType has a __typename field that is not a string");
                  }
                  switch (typename$1) {
                    case "OrderDraft" :
                        var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                        var value$2 = Js_dict.get(value$1, "orderDraftId");
                        var value$3 = Js_dict.get(value$1, "userId");
                        var tmp;
                        if (value$3 !== undefined) {
                          var value$4 = Caml_option.valFromOption(value$3);
                          var match = Js_json.decodeNull(value$4);
                          if (match !== undefined) {
                            tmp = undefined;
                          } else {
                            var value$5 = Js_json.decodeString(value$4);
                            tmp = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                          }
                        } else {
                          tmp = undefined;
                        }
                        var value$6 = Js_dict.get(value$1, "customerNo");
                        var tmp$1;
                        if (value$6 !== undefined) {
                          var value$7 = Caml_option.valFromOption(value$6);
                          var match$1 = Js_json.decodeNull(value$7);
                          if (match$1 !== undefined) {
                            tmp$1 = undefined;
                          } else {
                            var value$8 = Js_json.decodeString(value$7);
                            tmp$1 = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                          }
                        } else {
                          tmp$1 = undefined;
                        }
                        return {
                                NAME: "OrderDraft",
                                VAL: {
                                  orderDraftId: value$2 !== undefined ? Caml_option.valFromOption(value$2) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing"),
                                  userId: tmp,
                                  customerNo: tmp$1
                                }
                              };
                    case "OrderHistory" :
                        var value$9 = Js_option.getExn(Js_json.decodeObject(value));
                        var value$10 = Js_dict.get(value$9, "orderType");
                        var tmp$2;
                        if (value$10 !== undefined) {
                          var value$11 = Caml_option.valFromOption(value$10);
                          var value$12 = Js_json.decodeString(value$11);
                          if (value$12 !== undefined) {
                            switch (value$12) {
                              case "BLANKET" :
                                  tmp$2 = "BLANKET";
                                  break;
                              case "CREDIT_MEMO" :
                                  tmp$2 = "CREDIT_MEMO";
                                  break;
                              case "DEBIT_MEMO" :
                                  tmp$2 = "DEBIT_MEMO";
                                  break;
                              case "EMPTY" :
                                  tmp$2 = "EMPTY";
                                  break;
                              case "INVOICE" :
                                  tmp$2 = "INVOICE";
                                  break;
                              case "L_CLOSED" :
                                  tmp$2 = "L_CLOSED";
                                  break;
                              case "MASTER_ORDER" :
                                  tmp$2 = "MASTER_ORDER";
                                  break;
                              case "ORDER" :
                                  tmp$2 = "ORDER";
                                  break;
                              case "PRODUCTION_ORDER" :
                                  tmp$2 = "PRODUCTION_ORDER";
                                  break;
                              case "QUOTE" :
                                  tmp$2 = "QUOTE";
                                  break;
                              case "SHOP_FLOOR_ORDER" :
                                  tmp$2 = "SHOP_FLOOR_ORDER";
                                  break;
                              case "T" :
                                  tmp$2 = "T";
                                  break;
                              default:
                                tmp$2 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderType: " + value$12);
                            }
                          } else {
                            tmp$2 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderType, got " + JSON.stringify(value$11));
                          }
                        } else {
                          tmp$2 = Js_exn.raiseError("graphql_ppx: Field orderType on type OrderHistory is missing");
                        }
                        var value$13 = Js_dict.get(value$9, "orderNo");
                        var tmp$3;
                        if (value$13 !== undefined) {
                          var value$14 = Caml_option.valFromOption(value$13);
                          var value$15 = Js_json.decodeString(value$14);
                          tmp$3 = value$15 !== undefined ? value$15 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$14));
                        } else {
                          tmp$3 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderHistory is missing");
                        }
                        var value$16 = Js_dict.get(value$9, "userId");
                        var tmp$4;
                        if (value$16 !== undefined) {
                          var value$17 = Caml_option.valFromOption(value$16);
                          var match$2 = Js_json.decodeNull(value$17);
                          if (match$2 !== undefined) {
                            tmp$4 = undefined;
                          } else {
                            var value$18 = Js_json.decodeString(value$17);
                            tmp$4 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                          }
                        } else {
                          tmp$4 = undefined;
                        }
                        var value$19 = Js_dict.get(value$9, "customerNo");
                        var tmp$5;
                        if (value$19 !== undefined) {
                          var value$20 = Caml_option.valFromOption(value$19);
                          var match$3 = Js_json.decodeNull(value$20);
                          if (match$3 !== undefined) {
                            tmp$5 = undefined;
                          } else {
                            var value$21 = Js_json.decodeString(value$20);
                            tmp$5 = value$21 !== undefined ? value$21 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$20));
                          }
                        } else {
                          tmp$5 = undefined;
                        }
                        return {
                                NAME: "OrderHistory",
                                VAL: {
                                  orderType: tmp$2,
                                  orderNo: tmp$3,
                                  userId: tmp$4,
                                  customerNo: tmp$5
                                }
                              };
                    case "OrderOpen" :
                        var value$22 = Js_option.getExn(Js_json.decodeObject(value));
                        var value$23 = Js_dict.get(value$22, "orderType");
                        var tmp$6;
                        if (value$23 !== undefined) {
                          var value$24 = Caml_option.valFromOption(value$23);
                          var value$25 = Js_json.decodeString(value$24);
                          if (value$25 !== undefined) {
                            switch (value$25) {
                              case "BLANKET" :
                                  tmp$6 = "BLANKET";
                                  break;
                              case "CREDIT_MEMO" :
                                  tmp$6 = "CREDIT_MEMO";
                                  break;
                              case "DEBIT_MEMO" :
                                  tmp$6 = "DEBIT_MEMO";
                                  break;
                              case "EMPTY" :
                                  tmp$6 = "EMPTY";
                                  break;
                              case "INVOICE" :
                                  tmp$6 = "INVOICE";
                                  break;
                              case "L_CLOSED" :
                                  tmp$6 = "L_CLOSED";
                                  break;
                              case "MASTER_ORDER" :
                                  tmp$6 = "MASTER_ORDER";
                                  break;
                              case "ORDER" :
                                  tmp$6 = "ORDER";
                                  break;
                              case "PRODUCTION_ORDER" :
                                  tmp$6 = "PRODUCTION_ORDER";
                                  break;
                              case "QUOTE" :
                                  tmp$6 = "QUOTE";
                                  break;
                              case "SHOP_FLOOR_ORDER" :
                                  tmp$6 = "SHOP_FLOOR_ORDER";
                                  break;
                              case "T" :
                                  tmp$6 = "T";
                                  break;
                              default:
                                tmp$6 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderType: " + value$25);
                            }
                          } else {
                            tmp$6 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderType, got " + JSON.stringify(value$24));
                          }
                        } else {
                          tmp$6 = Js_exn.raiseError("graphql_ppx: Field orderType on type OrderOpen is missing");
                        }
                        var value$26 = Js_dict.get(value$22, "orderNo");
                        var tmp$7;
                        if (value$26 !== undefined) {
                          var value$27 = Caml_option.valFromOption(value$26);
                          var value$28 = Js_json.decodeString(value$27);
                          tmp$7 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
                        } else {
                          tmp$7 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
                        }
                        var value$29 = Js_dict.get(value$22, "userId");
                        var tmp$8;
                        if (value$29 !== undefined) {
                          var value$30 = Caml_option.valFromOption(value$29);
                          var match$4 = Js_json.decodeNull(value$30);
                          if (match$4 !== undefined) {
                            tmp$8 = undefined;
                          } else {
                            var value$31 = Js_json.decodeString(value$30);
                            tmp$8 = value$31 !== undefined ? value$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$30));
                          }
                        } else {
                          tmp$8 = undefined;
                        }
                        var value$32 = Js_dict.get(value$22, "customerNo");
                        var tmp$9;
                        if (value$32 !== undefined) {
                          var value$33 = Caml_option.valFromOption(value$32);
                          var match$5 = Js_json.decodeNull(value$33);
                          if (match$5 !== undefined) {
                            tmp$9 = undefined;
                          } else {
                            var value$34 = Js_json.decodeString(value$33);
                            tmp$9 = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$33));
                          }
                        } else {
                          tmp$9 = undefined;
                        }
                        return {
                                NAME: "OrderOpen",
                                VAL: {
                                  orderType: tmp$6,
                                  orderNo: tmp$7,
                                  userId: tmp$8,
                                  customerNo: tmp$9
                                }
                              };
                    default:
                      return Js_exn.raiseError("graphql_ppx: Union OrderDraftOrOpenOrHistoryGraphType returned unknown type " + typename$1);
                  }
                }) : Js_exn.raiseError("graphql_ppx: Field orderList on type Query is missing")
        };
}

function json_of_OrderListType(value) {
  if (value === "HISTORY") {
    return "HISTORY";
  } else if (value === "OPEN") {
    return "OPEN";
  } else {
    return "DRAFT";
  }
}

function make(type, userId, customerNo, shipToNo, startDate, endDate, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "type",
                    type !== undefined ? json_of_OrderListType(type) : null
                  ],
                  [
                    "userId",
                    userId !== undefined ? Caml_option.valFromOption(userId) : null
                  ],
                  [
                    "customerNo",
                    customerNo !== undefined ? customerNo : null
                  ],
                  [
                    "shipToNo",
                    shipToNo !== undefined ? shipToNo : null
                  ],
                  [
                    "startDate",
                    startDate !== undefined ? Caml_option.valFromOption(startDate) : null
                  ],
                  [
                    "endDate",
                    endDate !== undefined ? Caml_option.valFromOption(endDate) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var type = variables.type;
  var userId = variables.userId;
  var customerNo = variables.customerNo;
  var shipToNo = variables.shipToNo;
  var startDate = variables.startDate;
  var endDate = variables.endDate;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "type",
                    type !== undefined ? json_of_OrderListType(type) : null
                  ],
                  [
                    "userId",
                    userId !== undefined ? Caml_option.valFromOption(userId) : null
                  ],
                  [
                    "customerNo",
                    customerNo !== undefined ? customerNo : null
                  ],
                  [
                    "shipToNo",
                    shipToNo !== undefined ? shipToNo : null
                  ],
                  [
                    "startDate",
                    startDate !== undefined ? Caml_option.valFromOption(startDate) : null
                  ],
                  [
                    "endDate",
                    endDate !== undefined ? Caml_option.valFromOption(endDate) : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(type, userId, customerNo, shipToNo, startDate, endDate, param) {
  return Js_dict.fromArray([
                [
                  "type",
                  type !== undefined ? json_of_OrderListType(type) : null
                ],
                [
                  "userId",
                  userId !== undefined ? Caml_option.valFromOption(userId) : null
                ],
                [
                  "customerNo",
                  customerNo !== undefined ? customerNo : null
                ],
                [
                  "shipToNo",
                  shipToNo !== undefined ? shipToNo : null
                ],
                [
                  "startDate",
                  startDate !== undefined ? Caml_option.valFromOption(startDate) : null
                ],
                [
                  "endDate",
                  endDate !== undefined ? Caml_option.valFromOption(endDate) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, type, userId, customerNo, shipToNo, startDate, endDate, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "type",
                      type !== undefined ? json_of_OrderListType(type) : null
                    ],
                    [
                      "userId",
                      userId !== undefined ? Caml_option.valFromOption(userId) : null
                    ],
                    [
                      "customerNo",
                      customerNo !== undefined ? customerNo : null
                    ],
                    [
                      "shipToNo",
                      shipToNo !== undefined ? shipToNo : null
                    ],
                    [
                      "startDate",
                      startDate !== undefined ? Caml_option.valFromOption(startDate) : null
                    ],
                    [
                      "endDate",
                      endDate !== undefined ? Caml_option.valFromOption(endDate) : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderHistoryQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_OrderListType: json_of_OrderListType,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation Q  {\norderDraftNew(source: ORDER_ENTRY)  {\norderDraftId  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftNew");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "orderDraftId");
    tmp = {
      orderDraftId: value$4 !== undefined ? Caml_option.valFromOption(value$4) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftNew on type Mutation is missing");
  }
  return {
          orderDraftNew: tmp
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var NewDraftMutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetStartDate */0 :
        return {
                startDate: action._0,
                endDate: state.endDate,
                userId: state.userId,
                customerNo: state.customerNo,
                shipToNo: state.shipToNo
              };
    case /* SetEndDate */1 :
        return {
                startDate: state.startDate,
                endDate: action._0,
                userId: state.userId,
                customerNo: state.customerNo,
                shipToNo: state.shipToNo
              };
    case /* SetUserId */2 :
        return {
                startDate: state.startDate,
                endDate: state.endDate,
                userId: action._0,
                customerNo: state.customerNo,
                shipToNo: state.shipToNo
              };
    case /* SetCustomerNo */3 :
        return {
                startDate: state.startDate,
                endDate: state.endDate,
                userId: state.userId,
                customerNo: action._0,
                shipToNo: state.shipToNo
              };
    case /* SetShipToNo */4 :
        return {
                startDate: state.startDate,
                endDate: state.endDate,
                userId: state.userId,
                customerNo: state.customerNo,
                shipToNo: action._0
              };
    
  }
}

function getHref(row) {
  var variant = row.NAME;
  if (variant === "OrderDraft") {
    return {
            NAME: "Enterprise",
            VAL: "/orders/draft/" + (Belt_Option.getExn(Js_json.decodeString(row.VAL.orderDraftId)) + "/")
          };
  }
  if (variant === "OrderHistory") {
    var order = row.VAL;
    return {
            NAME: "Enterprise",
            VAL: "/orders/history/" + (Order$BirddogsoftwareFrontend.OrderType.stringify(order.orderType).toLowerCase() + ("/" + (order.orderNo + "/")))
          };
  }
  var order$1 = row.VAL;
  return {
          NAME: "Enterprise",
          VAL: "/orders/open/" + (Order$BirddogsoftwareFrontend.OrderType.stringify(order$1.orderType).toLowerCase() + ("/" + (order$1.orderNo + "/")))
        };
}

function EnterpriseOrderList(Props) {
  var match = React.useReducer(reducer, {
        startDate: Caml_option.some(Date$BirddogsoftwareFrontend.monthAgo(undefined)),
        endDate: Caml_option.some(new Date(Date.now())),
        userId: undefined,
        customerNo: undefined,
        shipToNo: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var query = GraphQLHooks$BirddogsoftwareFrontend.responseMap(GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(undefined, Belt_Option.map(state.userId, (function (x) {
                      return x;
                    })), state.customerNo, state.shipToNo, Belt_Option.map(state.startDate, (function (x) {
                      return x.toISOString();
                    })), Belt_Option.map(state.endDate, (function (x) {
                      return x.toISOString();
                    })), undefined), undefined, undefined).result, (function (x) {
          return x.orderList;
        }));
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var newDraft = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$1(undefined));
  var locationSet = UrlResolver$BirddogsoftwareFrontend.useLocationSet(undefined);
  return React.createElement(Page$BirddogsoftwareFrontend.make, {
              maxWidth: "Xl",
              children: null
            }, React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                  breadcrumbs: [{
                      href: {
                        NAME: "Enterprise",
                        VAL: "/orders/"
                      },
                      content: "Orders"
                    }],
                  mainActions: [{
                      key: "neworder",
                      content: "Start new order",
                      action: {
                        NAME: "OnClick",
                        VAL: (function (param) {
                            Curry._1(newDraft.runMutation, undefined).then(function (response) {
                                  if (response.TAG) {
                                    Curry._1(locationSet, {
                                          NAME: "Enterprise",
                                          VAL: "/orders/draft/" + Belt_Option.getExn(Js_json.decodeString(response._0.orderDraftNew.orderDraftId))
                                        });
                                  } else {
                                    Curry._1(error, response._0);
                                  }
                                  return Promise.resolve(undefined);
                                });
                            
                          })
                      },
                      color: "Primary"
                    }]
                }), React.createElement(PaperPadded$BirddogsoftwareFrontend.make, {
                  children: null
                }, React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, "Flex_End", Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V2 */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Fields$BirddogsoftwareFrontend.$$Date.$$Option.make, {
                                      label: "Start Date",
                                      value: state.startDate,
                                      onChangeValid: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetStartDate */0,
                                                      _0: value
                                                    });
                                        })
                                    })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Fields$BirddogsoftwareFrontend.$$Date.$$Option.make, {
                                      label: "End Date",
                                      value: state.endDate,
                                      onChangeValid: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetEndDate */1,
                                                      _0: value
                                                    });
                                        })
                                    })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(CustomerAutocomplete$BirddogsoftwareFrontend.make, {
                                      label: "Customer",
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetCustomerNo */3,
                                                      _0: value
                                                    });
                                        })
                                    })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(MaterialTable$BirddogsoftwareFrontend.GraphQL.make, {
                      columns: [
                        {
                          title: "",
                          render: (function (row) {
                              return React.createElement(Link$BirddogsoftwareFrontend.make, {
                                          href: getHref(row),
                                          children: "View Details"
                                        });
                            })
                        },
                        {
                          title: "Type",
                          render: (function (row) {
                              var variant = row.NAME;
                              if (variant === "OrderDraft") {
                                return "Draft";
                              } else if (variant === "OrderHistory") {
                                return "History";
                              } else {
                                return "Open";
                              }
                            })
                        },
                        {
                          title: "Order Type",
                          render: (function (row) {
                              if (row.NAME === "OrderDraft") {
                                return "";
                              } else {
                                return Order$BirddogsoftwareFrontend.OrderType.stringify(row.VAL.orderType);
                              }
                            })
                        },
                        {
                          title: "Order #",
                          render: (function (row) {
                              if (row.NAME === "OrderDraft") {
                                return "";
                              } else {
                                return row.VAL.orderNo;
                              }
                            })
                        }
                      ],
                      query: query,
                      options: {
                        grouping: true,
                        filtering: true,
                        toolbar: false
                      },
                      components: {
                        Container: MaterialTable$BirddogsoftwareFrontend.divContainer
                      }
                    })));
}

var Paper;

var Theme;

var Mixins;

var ZIndex;

var Breakpoints;

var PaletteColor;

var Palette;

var make$2 = EnterpriseOrderList;

exports.Paper = Paper;
exports.Theme = Theme;
exports.Mixins = Mixins;
exports.ZIndex = ZIndex;
exports.Breakpoints = Breakpoints;
exports.PaletteColor = PaletteColor;
exports.Palette = Palette;
exports.OrderHistoryQuery = OrderHistoryQuery;
exports.NewDraftMutation = NewDraftMutation;
exports.reducer = reducer;
exports.getHref = getHref;
exports.make = make$2;
/* react Not a pure module */
