'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var AlreadySignedIn$BirddogsoftwareFrontend = require("./Ecommerce/AlreadySignedIn.bs.js");

function signInHandler(signIn, store) {
  var user = store.state.user;
  if (user && store.state.enableAlreadySignedIn) {
    return React.createElement(AlreadySignedIn$BirddogsoftwareFrontend.make, {
                user: user._0
              });
  } else {
    return Curry._1(signIn, store);
  }
}

function generateModuleRoutes(signIn, signUp, forgot, reset, route, url, store) {
  var match = url.path;
  if (!match) {
    return Curry._2(route, url, store);
  }
  switch (match.hd) {
    case "forgot" :
        if (match.tl) {
          return Curry._2(route, url, store);
        } else {
          return signInHandler(forgot, store);
        }
    case "reset" :
        if (match.tl) {
          return Curry._2(route, url, store);
        }
        var parsedQueryString = new URLSearchParams(url.search);
        var tokenId = parsedQueryString.get("resetToken");
        if (tokenId == null) {
          return "resetToken is required";
        } else {
          return Curry._2(reset, store, tokenId);
        }
    case "signin" :
        if (match.tl) {
          return Curry._2(route, url, store);
        } else {
          return signInHandler(signIn, store);
        }
    case "signup" :
        if (match.tl) {
          return Curry._2(route, url, store);
        } else {
          return signInHandler(signUp, store);
        }
    default:
      return Curry._2(route, url, store);
  }
}

exports.signInHandler = signInHandler;
exports.generateModuleRoutes = generateModuleRoutes;
/* react Not a pure module */
