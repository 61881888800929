'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Divider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Divider.bs.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var Intl$BirddogsoftwareFrontend = require("../../../Utils/Intl.bs.js");
var Image$BirddogsoftwareFrontend = require("../../../Components/Image.bs.js");
var Style$BirddogsoftwareFrontend = require("../../../Utils/Style.bs.js");
var ArrayExt$BirddogsoftwareFrontend = require("../../../Utils/ArrayExt.bs.js");

function classRecordToJs(param) {
  return {
          img: param.img
        };
}

var classes = {
  TAG: /* ThemeFunc */1,
  _0: (function (theme) {
      return {
              img: {
                height: Style$BirddogsoftwareFrontend.getSpacing(theme, 10)
              }
            };
    })
};

function classRecordStringsFromJs(arg) {
  return {
          img: arg.img
        };
}

var LineStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var LineStyles = MaterialUi_WithStyles.WithStylesSafe(LineStylesDefs);

function Cart$Line(Props) {
  var line = Props.line;
  return React.createElement(LineStyles.make, {
              children: (function (classes) {
                  var item = line.item;
                  var item$1 = line.item;
                  return React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V2 */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(item !== undefined ? React.createElement(Image$BirddogsoftwareFrontend.make, {
                                                src: Caml_option.valFromOption(item).lowResImage,
                                                fitToParent: true,
                                                className: classes.img
                                              }) : React.createElement(React.Fragment, undefined)), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, /* V2 */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(item$1 !== undefined ? Caml_option.valFromOption(item$1).descriptionCalculated : React.createElement(React.Fragment, undefined)), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, /* V6 */5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(line.quantityOrdered.toString() + "x"), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, /* V1 */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, Belt_Option.getExn(Js_json.decodeNumber(line.total)))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, /* V1 */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
                })
            });
}

var Line = {
  make: Cart$Line
};

function Cart(Props) {
  var orderDraft = Props.orderDraft;
  return ArrayExt$BirddogsoftwareFrontend.interpolate($$Array.map((function (line) {
                    return React.createElement(Cart$Line, {
                                line: line,
                                key: line.orderDraftLineId
                              });
                  }), orderDraft.lines), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Grid;

var make = Cart;

exports.Grid = Grid;
exports.LineStylesDefs = LineStylesDefs;
exports.LineStyles = LineStyles;
exports.Line = Line;
exports.make = make;
/* LineStyles Not a pure module */
