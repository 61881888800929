'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var GraphqlHooks = require("graphql-hooks");
var Core = require("@material-ui/core");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var MaterialUi_CssBaseline = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_CssBaseline.bs.js");
var Modules$BirddogsoftwareFrontend = require("./Modules/Modules.bs.js");
var AppStore$BirddogsoftwareFrontend = require("./AppStore.bs.js");
var ClientOnly$BirddogsoftwareFrontend = require("./Components/ClientOnly.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("./Modules/UrlResolver.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("./Components/SnackbarHooks.bs.js");
var StylesProvider$BirddogsoftwareFrontend = require("./Components/MaterialUi/StylesProvider.bs.js");
var StoreWithPreload$BirddogsoftwareFrontend = require("./Utils/StoreWithPreload.bs.js");

function classRecordToJs(param) {
  return {
          app: param.app
        };
}

var classes = {
  TAG: /* Record */0,
  _0: {
    app: {
      fontFamily: "Roboto"
    }
  }
};

function classRecordStringsFromJs(arg) {
  return {
          app: arg.app
        };
}

var AppStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var AppStyles = MaterialUi_WithStyles.WithStylesSafe(AppStylesDefs);

function AppProvider(Props) {
  var gqlClient = Props.gqlClient;
  var hostUrl = Props.hostUrl;
  var serverUrl = Props.serverUrl;
  var defaultModule = Props.defaultModule;
  var hostMode = Props.hostMode;
  var children = Props.children;
  var defaultModule$1;
  if (defaultModule !== undefined) {
    var x = Modules$BirddogsoftwareFrontend.parse(defaultModule);
    defaultModule$1 = x !== undefined ? x : Js_exn.raiseError("Invalid default module: " + defaultModule);
  } else {
    defaultModule$1 = /* Ecommerce */1;
  }
  var basename;
  if (hostUrl === "") {
    basename = undefined;
  } else {
    var parsed = new URL(hostUrl);
    var raw = parsed.pathname;
    var raw$1 = raw.slice(1);
    var match = raw$1[raw$1.length - 1 | 0];
    var raw$2 = match === "/" ? raw$1.slice(0, -1) : raw$1;
    basename = raw$2 === "" ? /* [] */0 : $$Array.to_list(raw$2.split("/"));
  }
  var x$1 = Belt_Option.map(hostMode, (function (prim) {
          return prim.toLowerCase();
        }));
  var hostMode$1;
  if (x$1 !== undefined) {
    switch (x$1) {
      case "embed_in_classic" :
          hostMode$1 = "EmbedInClassic";
          break;
      case "host_in_classic" :
          hostMode$1 = "HostInClassic";
          break;
      case "self" :
          hostMode$1 = "SelfHost";
          break;
      default:
        hostMode$1 = Pervasives.failwith("unknown hostMode: " + x$1);
    }
  } else {
    hostMode$1 = "SelfHost";
  }
  return React.createElement(ClientOnly$BirddogsoftwareFrontend.Context.make, ClientOnly$BirddogsoftwareFrontend.Context.makeProps(serverUrl === undefined, React.createElement(GraphqlHooks.ClientContext.Provider, {
                      value: gqlClient,
                      children: React.createElement(StoreWithPreload$BirddogsoftwareFrontend.make, {
                            getInitialData: (function (param) {
                                return AppStore$BirddogsoftwareFrontend.getInitialData(hostUrl, param);
                              }),
                            reducer: AppStore$BirddogsoftwareFrontend.reducer,
                            children: (function (store) {
                                return React.createElement(StylesProvider$BirddogsoftwareFrontend.make, {
                                            children: React.createElement(AppStyles.make, {
                                                  children: (function (classes) {
                                                      return React.createElement("div", {
                                                                  className: classes.app
                                                                }, React.createElement(Core.CssBaseline, MaterialUi_CssBaseline.makeProps(undefined, undefined, undefined, undefined)), React.createElement(UrlResolver$BirddogsoftwareFrontend.Context.make, UrlResolver$BirddogsoftwareFrontend.Context.makeProps({
                                                                          defaultModule: defaultModule$1,
                                                                          hostMode: hostMode$1,
                                                                          basename: basename
                                                                        }, React.createElement(SnackbarHooks$BirddogsoftwareFrontend.Provider.make, {
                                                                              children: Curry._3(children, store, defaultModule$1, basename)
                                                                            }), undefined)));
                                                    })
                                                })
                                          });
                              })
                          })
                    }), undefined));
}

var make = AppProvider;

var $$default = AppProvider;

exports.AppStylesDefs = AppStylesDefs;
exports.AppStyles = AppStyles;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* AppStyles Not a pure module */
