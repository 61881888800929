'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../Components/Forms/SimpleForm.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../../Components/QueryResponse.bs.js");

var ppx_printed_query = "mutation q($emailTemplateId: Guid!)  {\nemailTemplateDelete(emailTemplateId: $emailTemplateId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "emailTemplateDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          emailTemplateDelete: tmp
        };
}

function make(emailTemplateId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "emailTemplateId",
                    emailTemplateId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var emailTemplateId = variables.emailTemplateId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "emailTemplateId",
                    emailTemplateId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(emailTemplateId, param) {
  return Js_dict.fromArray([[
                  "emailTemplateId",
                  emailTemplateId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, emailTemplateId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "emailTemplateId",
                      emailTemplateId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var EmailTemplateDeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($emailTemplateId: Guid, $data: EmailTemplateInput!)  {\nemailTemplate(emailTemplateId: $emailTemplateId, data: $data)  {\nemailTemplateId  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "emailTemplate");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "emailTemplateId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field emailTemplateId on type EmailTemplate is missing");
      }
      tmp = {
        emailTemplateId: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          emailTemplate: tmp
        };
}

function json_of_EmailTemplateInput(value) {
  var v = value.description;
  var v$1 = value.cc;
  var v$2 = value.bcc;
  var v$3 = value.from;
  var v$4 = value.subject;
  var v$5 = value.textBody;
  var v$6 = value.htmlBody;
  return Js_dict.fromArray([
                [
                  "description",
                  v !== undefined ? v : null
                ],
                [
                  "cc",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "bcc",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "from",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "subject",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "textBody",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "htmlBody",
                  v$6 !== undefined ? v$6 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(emailTemplateId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "emailTemplateId",
                    emailTemplateId !== undefined ? Caml_option.valFromOption(emailTemplateId) : null
                  ],
                  [
                    "data",
                    json_of_EmailTemplateInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var emailTemplateId = variables.emailTemplateId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "emailTemplateId",
                    emailTemplateId !== undefined ? Caml_option.valFromOption(emailTemplateId) : null
                  ],
                  [
                    "data",
                    json_of_EmailTemplateInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(emailTemplateId, data, param) {
  return Js_dict.fromArray([
                [
                  "emailTemplateId",
                  emailTemplateId !== undefined ? Caml_option.valFromOption(emailTemplateId) : null
                ],
                [
                  "data",
                  json_of_EmailTemplateInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, emailTemplateId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "emailTemplateId",
                      emailTemplateId !== undefined ? Caml_option.valFromOption(emailTemplateId) : null
                    ],
                    [
                      "data",
                      json_of_EmailTemplateInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var EmailTemplateMutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_EmailTemplateInput: json_of_EmailTemplateInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetDescription */0 :
        return {
                description: action._0,
                cc: state.cc,
                bcc: state.bcc,
                from: state.from,
                subject: state.subject,
                textBody: state.textBody,
                htmlBody: state.htmlBody
              };
    case /* SetCc */1 :
        return {
                description: state.description,
                cc: action._0,
                bcc: state.bcc,
                from: state.from,
                subject: state.subject,
                textBody: state.textBody,
                htmlBody: state.htmlBody
              };
    case /* SetBcc */2 :
        return {
                description: state.description,
                cc: state.cc,
                bcc: action._0,
                from: state.from,
                subject: state.subject,
                textBody: state.textBody,
                htmlBody: state.htmlBody
              };
    case /* SetFrom */3 :
        return {
                description: state.description,
                cc: state.cc,
                bcc: state.bcc,
                from: action._0,
                subject: state.subject,
                textBody: state.textBody,
                htmlBody: state.htmlBody
              };
    case /* SetSubject */4 :
        return {
                description: state.description,
                cc: state.cc,
                bcc: state.bcc,
                from: state.from,
                subject: action._0,
                textBody: state.textBody,
                htmlBody: state.htmlBody
              };
    case /* SetTextBody */5 :
        return {
                description: state.description,
                cc: state.cc,
                bcc: state.bcc,
                from: state.from,
                subject: state.subject,
                textBody: action._0,
                htmlBody: state.htmlBody
              };
    case /* SetHtmlBody */6 :
        return {
                description: state.description,
                cc: state.cc,
                bcc: state.bcc,
                from: state.from,
                subject: state.subject,
                textBody: state.textBody,
                htmlBody: action._0
              };
    
  }
}

function EnterpriseEmailTemplateEdit$Form(Props) {
  var breadcrumbLink = Props.breadcrumbLink;
  var breadcrumbText = Props.breadcrumbText;
  var emailTemplateId = Props.emailTemplateId;
  var data = Props.data;
  var runDelete = Props.runDelete;
  var useSaveMutation = function (data) {
    return GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$1(Belt_Option.map(emailTemplateId, (function (prim) {
                          return prim;
                        })), {
                    description: data.description,
                    cc: data.cc,
                    bcc: data.bcc,
                    from: data.from,
                    subject: data.subject,
                    textBody: data.textBody,
                    htmlBody: data.htmlBody
                  }, undefined));
  };
  var tmp = {
    breadcrumbs: [
      {
        href: {
          NAME: "Enterprise",
          VAL: "/email-templates"
        },
        content: "Email Templates"
      },
      {
        href: {
          NAME: "Enterprise",
          VAL: "/email-templates" + breadcrumbLink
        },
        content: breadcrumbText
      }
    ],
    reducer: reducer,
    state: data,
    doneRoute: {
      NAME: "Enterprise",
      VAL: "/email-templates"
    },
    useSaveMutation: useSaveMutation,
    saveValidate: (function (state) {
        return state.description.trim() !== "";
      }),
    mainContent: (function (state, dispatch) {
        return React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Description",
                        value: state.description,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetDescription */0,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "CC",
                        value: state.cc,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetCc */1,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "BCC",
                        value: state.bcc,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetBcc */2,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "From",
                        value: state.from,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetFrom */3,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Subject",
                        value: state.subject,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSubject */4,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Text Body",
                        value: state.textBody,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetTextBody */5,
                                        _0: value
                                      });
                          }),
                        multiline: true
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Html Body",
                        value: state.htmlBody,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetHtmlBody */6,
                                        _0: value
                                      });
                          }),
                        multiline: true
                      }));
      })
  };
  if (runDelete !== undefined) {
    tmp.runDelete = Caml_option.valFromOption(runDelete);
  }
  return React.createElement(SimpleForm$BirddogsoftwareFrontend.Form.make, tmp);
}

var Form = {
  reducer: reducer,
  make: EnterpriseEmailTemplateEdit$Form
};

function EnterpriseEmailTemplateEdit$New(Props) {
  return React.createElement(EnterpriseEmailTemplateEdit$Form, {
              breadcrumbLink: "new",
              breadcrumbText: "New",
              emailTemplateId: undefined,
              data: {
                description: "",
                cc: "",
                bcc: "",
                from: "",
                subject: "",
                textBody: "",
                htmlBody: ""
              }
            });
}

var New = {
  make: EnterpriseEmailTemplateEdit$New
};

var ppx_printed_query$2 = "query Q($emailTemplateId: Guid!)  {\nemailTemplate(emailTemplateId: $emailTemplateId)  {\nemailTemplateId  \ndescription  \ncc  \nbcc  \nfrom  \nsubject  \ntextBody  \nhtmlBody  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "emailTemplate");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "emailTemplateId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field emailTemplateId on type EmailTemplate is missing");
      }
      var value$8 = Js_dict.get(value$4, "description");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field description on type EmailTemplate is missing");
      }
      var value$11 = Js_dict.get(value$4, "cc");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field cc on type EmailTemplate is missing");
      }
      var value$14 = Js_dict.get(value$4, "bcc");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field bcc on type EmailTemplate is missing");
      }
      var value$17 = Js_dict.get(value$4, "from");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var value$19 = Js_json.decodeString(value$18);
        tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field from on type EmailTemplate is missing");
      }
      var value$20 = Js_dict.get(value$4, "subject");
      var tmp$6;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var value$22 = Js_json.decodeString(value$21);
        tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field subject on type EmailTemplate is missing");
      }
      var value$23 = Js_dict.get(value$4, "textBody");
      var tmp$7;
      if (value$23 !== undefined) {
        var value$24 = Caml_option.valFromOption(value$23);
        var value$25 = Js_json.decodeString(value$24);
        tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
      } else {
        tmp$7 = Js_exn.raiseError("graphql_ppx: Field textBody on type EmailTemplate is missing");
      }
      var value$26 = Js_dict.get(value$4, "htmlBody");
      var tmp$8;
      if (value$26 !== undefined) {
        var value$27 = Caml_option.valFromOption(value$26);
        var value$28 = Js_json.decodeString(value$27);
        tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Field htmlBody on type EmailTemplate is missing");
      }
      tmp = {
        emailTemplateId: tmp$1,
        description: tmp$2,
        cc: tmp$3,
        bcc: tmp$4,
        from: tmp$5,
        subject: tmp$6,
        textBody: tmp$7,
        htmlBody: tmp$8
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          emailTemplate: tmp
        };
}

function make$2(emailTemplateId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "emailTemplateId",
                    emailTemplateId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var emailTemplateId = variables.emailTemplateId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "emailTemplateId",
                    emailTemplateId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(emailTemplateId, param) {
  return Js_dict.fromArray([[
                  "emailTemplateId",
                  emailTemplateId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, emailTemplateId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "emailTemplateId",
                      emailTemplateId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var UserQuery = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function EnterpriseEmailTemplateEdit$Edit(Props) {
  var emailTemplateId = Props.emailTemplateId;
  var getQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$2(emailTemplateId, undefined), undefined, undefined);
  var deleteMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(emailTemplateId, undefined));
  return React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
              queryResponse: getQuery.result,
              children: (function (result) {
                  var emailTemplate = result.emailTemplate;
                  if (emailTemplate === undefined) {
                    return "Not found";
                  }
                  var emailTemplate$1 = Caml_option.valFromOption(emailTemplate);
                  return React.createElement(EnterpriseEmailTemplateEdit$Form, {
                              breadcrumbLink: emailTemplateId,
                              breadcrumbText: emailTemplate$1.description,
                              emailTemplateId: emailTemplateId,
                              data: {
                                description: emailTemplate$1.description,
                                cc: emailTemplate$1.cc,
                                bcc: emailTemplate$1.bcc,
                                from: emailTemplate$1.from,
                                subject: emailTemplate$1.subject,
                                textBody: emailTemplate$1.textBody,
                                htmlBody: emailTemplate$1.htmlBody
                              },
                              runDelete: (function (param) {
                                  return Curry._1(deleteMutation.runMutation, undefined);
                                })
                            });
                })
            });
}

var Edit = {
  make: EnterpriseEmailTemplateEdit$Edit
};

exports.EmailTemplateDeleteMutation = EmailTemplateDeleteMutation;
exports.EmailTemplateMutation = EmailTemplateMutation;
exports.Form = Form;
exports.New = New;
exports.UserQuery = UserQuery;
exports.Edit = Edit;
/* react Not a pure module */
