'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_List = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_List.bs.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Checkbox = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Checkbox.bs.js");
var MaterialUi_ListItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItem.bs.js");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var MaterialUi_ListItemText = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItemText.bs.js");
var AppStore$BirddogsoftwareFrontend = require("../../../AppStore.bs.js");
var ArrayExt$BirddogsoftwareFrontend = require("../../../Utils/ArrayExt.bs.js");
var Location$BirddogsoftwareFrontend = require("../../../Utils/Location.bs.js");
var MaterialUi_ListItemSecondaryAction = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItemSecondaryAction.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../../../Components/PaperSpacer.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../UrlResolver.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../../Components/Forms/ActionHeader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var PaperPadding$BirddogsoftwareFrontend = require("../../../Components/PaperPadding.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../../../Components/QueryResponse.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../Components/SnackbarHooks.bs.js");

var ppx_printed_query = "mutation User($userId: Guid, $data: UserInput!)  {\nuser(userId: $userId, data: $data)  {\nuserId  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "user");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "userId");
      tmp = {
        userId: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field userId on type User is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          user: tmp
        };
}

function json_of_UserInput(value) {
  var v = value.loginId;
  var v$1 = value.firstName;
  var v$2 = value.lastName;
  var v$3 = value.name;
  var v$4 = value.roles;
  return Js_dict.fromArray([
                [
                  "loginId",
                  v !== undefined ? v : null
                ],
                [
                  "firstName",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "lastName",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "name",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "roles",
                  v$4 !== undefined ? v$4.map(function (v) {
                          return v;
                        }) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(userId, data, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "userId",
                    userId !== undefined ? Caml_option.valFromOption(userId) : null
                  ],
                  [
                    "data",
                    json_of_UserInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var userId = variables.userId;
  var data = variables.data;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "userId",
                    userId !== undefined ? Caml_option.valFromOption(userId) : null
                  ],
                  [
                    "data",
                    json_of_UserInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(userId, data, param) {
  return Js_dict.fromArray([
                [
                  "userId",
                  userId !== undefined ? Caml_option.valFromOption(userId) : null
                ],
                [
                  "data",
                  json_of_UserInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, userId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "userId",
                      userId !== undefined ? Caml_option.valFromOption(userId) : null
                    ],
                    [
                      "data",
                      json_of_UserInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ContactMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_UserInput: json_of_UserInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "query RolesQuery  {\nroles  {\nroleId  \nname  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "roles");
  return {
          roles: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "roleId");
                  var value$3 = Js_dict.get(value$1, "name");
                  var tmp;
                  if (value$3 !== undefined) {
                    var value$4 = Caml_option.valFromOption(value$3);
                    var value$5 = Js_json.decodeString(value$4);
                    tmp = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field name on type Role is missing");
                  }
                  return {
                          roleId: value$2 !== undefined ? Caml_option.valFromOption(value$2) : Js_exn.raiseError("graphql_ppx: Field roleId on type Role is missing"),
                          name: tmp
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field roles on type Query is missing")
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var RolesQuery = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetLoginId */0 :
        var init = state.contactData;
        return {
                contactData: {
                  loginId: action._0,
                  firstName: init.firstName,
                  lastName: init.lastName,
                  name: init.name,
                  roles: init.roles
                },
                error: state.error
              };
    case /* SetFirstName */1 :
        var init$1 = state.contactData;
        return {
                contactData: {
                  loginId: init$1.loginId,
                  firstName: action._0,
                  lastName: init$1.lastName,
                  name: init$1.name,
                  roles: init$1.roles
                },
                error: state.error
              };
    case /* SetLastName */2 :
        var init$2 = state.contactData;
        return {
                contactData: {
                  loginId: init$2.loginId,
                  firstName: init$2.firstName,
                  lastName: action._0,
                  name: init$2.name,
                  roles: init$2.roles
                },
                error: state.error
              };
    case /* SetError */3 :
        return {
                contactData: state.contactData,
                error: action._0
              };
    case /* ToggleRole */4 :
        var init$3 = state.contactData;
        return {
                contactData: {
                  loginId: init$3.loginId,
                  firstName: init$3.firstName,
                  lastName: init$3.lastName,
                  name: init$3.name,
                  roles: ArrayExt$BirddogsoftwareFrontend.toggle(action._0, state.contactData.roles)
                },
                error: state.error
              };
    
  }
}

function EnterpriseContactEdit$Form(Props) {
  var breadcrumbLink = Props.breadcrumbLink;
  var breadcrumbText = Props.breadcrumbText;
  var userId = Props.userId;
  var contactData = Props.contactData;
  var resetPasswordButton = Props.resetPasswordButton;
  var sendWelcomeEmailButton = Props.sendWelcomeEmailButton;
  var match = React.useReducer(reducer, {
        contactData: contactData,
        error: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var saveMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(Belt_Option.map(userId, (function (prim) {
                  return prim;
                })), {
            loginId: state.contactData.loginId,
            firstName: state.contactData.firstName,
            lastName: state.contactData.lastName,
            name: undefined,
            roles: Belt_Array.map(state.contactData.roles, (function (prim) {
                    return prim;
                  }))
          }, undefined));
  var rolesQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$1(undefined), undefined, undefined);
  var saveResolved = UrlResolver$BirddogsoftwareFrontend.useResolved({
        NAME: "Enterprise",
        VAL: "/brain/contacts"
      });
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var save = function (param) {
    Curry._1(saveMutation.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            Location$BirddogsoftwareFrontend.unsafe_setUseReturnUrl(saveResolved.isExternal, saveResolved.url);
          } else {
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(null), undefined, undefined, undefined, "Md", undefined, undefined, undefined, undefined, undefined), React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                  breadcrumbs: [
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/brain/contacts"
                      },
                      content: "Contacts"
                    },
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/users" + breadcrumbLink
                      },
                      content: breadcrumbText
                    }
                  ],
                  mainActions: [
                    {
                      key: "save",
                      content: "Save",
                      action: {
                        NAME: "OnClick",
                        VAL: save
                      },
                      color: "Primary"
                    },
                    {
                      key: "cancel",
                      content: "Cancel",
                      action: {
                        NAME: "Link",
                        VAL: {
                          NAME: "Enterprise",
                          VAL: "/brain/contacts"
                        }
                      },
                      color: "Secondary"
                    }
                  ],
                  secondaryActions: ArrayExt$BirddogsoftwareFrontend.keepMapDefault([
                        resetPasswordButton,
                        sendWelcomeEmailButton
                      ])
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                    children: null
                                  }, React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.contactData.loginId, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Email", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetLoginId */0,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.contactData.firstName, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "First Name", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetFirstName */1,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.contactData.lastName, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Last Name", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetLastName */2,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
                                    queryResponse: rolesQuery.result,
                                    children: (function (result) {
                                        return React.createElement(Core.List, MaterialUi_List.makeProps(Caml_option.some(Belt_Array.map(result.roles, (function (role) {
                                                                  var roleId = Belt_Option.getExn(Js_json.decodeString(role.roleId));
                                                                  return React.createElement(React.Fragment, undefined, React.createElement(Core.ListItem, MaterialUi_ListItem.makeProps(undefined, undefined, true, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (_event) {
                                                                                        return Curry._1(dispatch, {
                                                                                                    TAG: /* ToggleRole */4,
                                                                                                    _0: roleId
                                                                                                  });
                                                                                      }), roleId, undefined, undefined, undefined, undefined), React.createElement(Core.ListItemText, MaterialUi_ListItemText.makeProps(undefined, undefined, undefined, undefined, Caml_option.some(role.name), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.ListItemSecondaryAction, MaterialUi_ListItemSecondaryAction.makeProps(Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(Belt_Option.isSome(Caml_option.undefined_to_opt(state.contactData.roles.find(function (role) {
                                                                                                                  return role === roleId;
                                                                                                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (_event, param) {
                                                                                                        return Curry._1(dispatch, {
                                                                                                                    TAG: /* ToggleRole */4,
                                                                                                                    _0: roleId
                                                                                                                  });
                                                                                                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "End", undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, undefined, undefined))));
                                                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                                      })
                                  })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }));
}

var Form = {
  reducer: reducer,
  make: EnterpriseContactEdit$Form
};

function EnterpriseContactEdit$New(Props) {
  return React.createElement(EnterpriseContactEdit$Form, {
              breadcrumbLink: "new",
              breadcrumbText: "New",
              userId: undefined,
              contactData: {
                loginId: "",
                firstName: "",
                lastName: "",
                name: "",
                roles: []
              }
            });
}

var New = {
  make: EnterpriseContactEdit$New
};

var ppx_printed_query$2 = "query UserQuery($userId: Guid!)  {\nuser(userId: $userId)  {\nloginId  \nfirstName  \nlastName  \nname  \nroles  {\nroleId  \n}\n\n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "user");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "loginId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field loginId on type User is missing");
      }
      var value$8 = Js_dict.get(value$4, "firstName");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field firstName on type User is missing");
      }
      var value$11 = Js_dict.get(value$4, "lastName");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field lastName on type User is missing");
      }
      var value$14 = Js_dict.get(value$4, "name");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
      }
      var value$17 = Js_dict.get(value$4, "roles");
      tmp = {
        loginId: tmp$1,
        firstName: tmp$2,
        lastName: tmp$3,
        name: tmp$4,
        roles: value$17 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$17))).map(function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "roleId");
                return {
                        roleId: value$2 !== undefined ? Caml_option.valFromOption(value$2) : Js_exn.raiseError("graphql_ppx: Field roleId on type UserRole is missing")
                      };
              }) : Js_exn.raiseError("graphql_ppx: Field roles on type User is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          user: tmp
        };
}

function make$2(userId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "userId",
                    userId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var userId = variables.userId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "userId",
                    userId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(userId, param) {
  return Js_dict.fromArray([[
                  "userId",
                  userId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, userId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "userId",
                      userId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var ContactQuery = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var ppx_printed_query$3 = "mutation Q($userId: Guid!, $resetUri: Uri!)  {\nuserRequestResetPasswordEmailById(userId: $userId, resetUri: $resetUri)  {\nsuccess  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "userRequestResetPasswordEmailById");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type UserRequestResetPasswordResult is missing");
    }
    tmp = {
      success: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field userRequestResetPasswordEmailById on type Mutation is missing");
  }
  return {
          userRequestResetPasswordEmailById: tmp
        };
}

function make$3(userId, resetUri, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "userId",
                    userId
                  ],
                  [
                    "resetUri",
                    resetUri
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var userId = variables.userId;
  var resetUri = variables.resetUri;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "userId",
                    userId
                  ],
                  [
                    "resetUri",
                    resetUri
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$3(userId, resetUri, param) {
  return Js_dict.fromArray([
                [
                  "userId",
                  userId
                ],
                [
                  "resetUri",
                  resetUri
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, userId, resetUri, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "userId",
                      userId
                    ],
                    [
                      "resetUri",
                      resetUri
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var ResetPasswordMutation = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

var ppx_printed_query$4 = "mutation Q($userId: Guid!, $resetUri: Uri!)  {\nuserSendNewUserEmail(userId: $userId, resetUri: $resetUri)  {\nsuccess  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "userSendNewUserEmail");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type UserRequestResetPasswordResult is missing");
    }
    tmp = {
      success: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field userSendNewUserEmail on type Mutation is missing");
  }
  return {
          userSendNewUserEmail: tmp
        };
}

function make$4(userId, resetUri, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "userId",
                    userId
                  ],
                  [
                    "resetUri",
                    resetUri
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var userId = variables.userId;
  var resetUri = variables.resetUri;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "userId",
                    userId
                  ],
                  [
                    "resetUri",
                    resetUri
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$4(userId, resetUri, param) {
  return Js_dict.fromArray([
                [
                  "userId",
                  userId
                ],
                [
                  "resetUri",
                  resetUri
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, userId, resetUri, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "userId",
                      userId
                    ],
                    [
                      "resetUri",
                      resetUri
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$4
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var NewContactEmailMutation = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

function EnterpriseContactEdit$Edit(Props) {
  var store = Props.store;
  var id = Props.id;
  var getQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$2(id, undefined), undefined, undefined);
  var resetPasswordMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$3(id, AppStore$BirddogsoftwareFrontend.resetUri(store), undefined));
  var newContactEmailMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$4(id, AppStore$BirddogsoftwareFrontend.resetUri(store), undefined));
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var success = SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  var resetPassword = function (param) {
    Curry._1(resetPasswordMutation.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            if (response._0.userRequestResetPasswordEmailById.success) {
              Curry._1(success, "Password reset email sent");
            } else {
              Curry._1(error, "Error sending email");
            }
          } else {
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  var sendWelcomeEmail = function (param) {
    Curry._1(newContactEmailMutation.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            if (response._0.userSendNewUserEmail.success) {
              Curry._1(success, "Welcome email sent");
            } else {
              Curry._1(error, "Error sending email");
            }
          } else {
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  return React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
              queryResponse: getQuery.result,
              children: (function (result) {
                  var user = result.user;
                  if (user === undefined) {
                    return "Not found";
                  }
                  var user$1 = Caml_option.valFromOption(user);
                  return React.createElement(EnterpriseContactEdit$Form, {
                              breadcrumbLink: id,
                              breadcrumbText: user$1.name,
                              userId: id,
                              contactData: {
                                loginId: user$1.loginId,
                                firstName: user$1.firstName,
                                lastName: user$1.lastName,
                                name: user$1.name,
                                roles: Belt_Array.map(user$1.roles, (function (role) {
                                        return Belt_Option.getExn(Js_json.decodeString(role.roleId));
                                      }))
                              },
                              resetPasswordButton: {
                                key: "reset-password",
                                content: "Reset Password",
                                action: {
                                  NAME: "OnClick",
                                  VAL: resetPassword
                                },
                                color: "Primary"
                              },
                              sendWelcomeEmailButton: {
                                key: "send-welcome-email",
                                content: "Send welcome email",
                                action: {
                                  NAME: "OnClick",
                                  VAL: sendWelcomeEmail
                                },
                                color: "Primary"
                              }
                            });
                })
            });
}

var Edit = {
  make: EnterpriseContactEdit$Edit
};

exports.ContactMutation = ContactMutation;
exports.RolesQuery = RolesQuery;
exports.Form = Form;
exports.New = New;
exports.ContactQuery = ContactQuery;
exports.ResetPasswordMutation = ResetPasswordMutation;
exports.NewContactEmailMutation = NewContactEmailMutation;
exports.Edit = Edit;
/* react Not a pure module */
