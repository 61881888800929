'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_MapInt = require("bs-platform/lib/js/belt_MapInt.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Snackbar = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Snackbar.bs.js");
var SnackbarContent$BirddogsoftwareFrontend = require("./SnackbarContent.bs.js");

var context = React.createContext([
      (function (x) {
          
        }),
      (function (param) {
          return 0;
        })
    ]);

var provider = context.Provider;

function SnackbarHooks$Context(Props) {
  var dispatch = Props.dispatch;
  var getId = Props.getId;
  var children = Props.children;
  return React.createElement(provider, {
              value: [
                dispatch,
                getId
              ],
              children: children
            });
}

var Context = {
  context: context,
  provider: provider,
  make: SnackbarHooks$Context
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            snackbars: state.snackbars,
            nextId: state.nextId + 1 | 0
          };
  } else if (action.TAG) {
    return {
            snackbars: Belt_MapInt.remove(state.snackbars, action._0),
            nextId: state.nextId
          };
  } else {
    return {
            snackbars: Belt_MapInt.set(state.snackbars, action._0, action._1),
            nextId: state.nextId
          };
  }
}

function SnackbarHooks$Provider(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, {
        snackbars: undefined,
        nextId: 0
      });
  var dispatch = match[1];
  var state = match[0];
  var getId = function (param) {
    Curry._1(dispatch, /* IncrimentId */0);
    return state.nextId;
  };
  return React.createElement(SnackbarHooks$Context, {
              dispatch: dispatch,
              getId: getId,
              children: null
            }, children, Belt_MapInt.valuesToArray(state.snackbars));
}

var Provider = {
  make: SnackbarHooks$Provider
};

function useSnackbar(variant) {
  var match = React.useContext(context);
  var getId = match[1];
  var dispatch = match[0];
  return function (element) {
    var id = Curry._1(getId, undefined);
    var onClose = function (param) {
      return Curry._1(dispatch, {
                  TAG: /* Close */1,
                  _0: id
                });
    };
    return Curry._1(dispatch, {
                TAG: /* Open */0,
                _0: id,
                _1: React.createElement(Core.Snackbar, MaterialUi_Snackbar.makeProps(undefined, Caml_option.some(MaterialUi_Snackbar.AnchorOrigin.make("Center", "Bottom")), {
                          NAME: "Int",
                          VAL: 6000
                        }, Caml_option.some(React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
                                  onClose: onClose,
                                  variant: variant,
                                  children: element
                                })), undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                            return Curry._1(dispatch, {
                                        TAG: /* Close */1,
                                        _0: id
                                      });
                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
              });
  };
}

function useError(param) {
  return useSnackbar("Error");
}

function useInfo(param) {
  return useSnackbar("Info");
}

function useSuccess(param) {
  return useSnackbar("Success");
}

function useWarning(param) {
  return useSnackbar("Warning");
}

var $$Map;

exports.Context = Context;
exports.$$Map = $$Map;
exports.reducer = reducer;
exports.Provider = Provider;
exports.useSnackbar = useSnackbar;
exports.useError = useError;
exports.useInfo = useInfo;
exports.useSuccess = useSuccess;
exports.useWarning = useWarning;
/* context Not a pure module */
