
const stock = require("F:\\WebProjects\\9.6\\src\\BirdDogSoftware.Web.Frontend\\lib\\js\\src\\client\\Modules\\Enterprise\\EnterpriseModule.bs.js?customizationLoader=skip");
let custom;
try {
  custom = require("F:/WebProjects/9.6/src/BirdDogSoftware.Web.Frontend/lib/js/custom/client/Modules/Enterprise/C_EnterpriseModule.bs");
} catch (e) {
  if (e.code !== 'MODULE_NOT_FOUND') {
    throw (e);
  }
};

if (custom) {
  Object.keys(custom).forEach(function (key) {
    stock[key] = custom[key];
  });
}

module.exports = stock;