'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_List = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_List.bs.js");
var Caml_splice_call = require("bs-platform/lib/js/caml_splice_call.js");
var Core = require("@material-ui/core");
var MaterialUi_Divider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Divider.bs.js");
var MaterialUi_ListItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItem.bs.js");
var MaterialUi_ListItemIcon = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItemIcon.bs.js");
var MaterialUi_ListItemText = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItemText.bs.js");
var MaterialUi_ListSubheader = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListSubheader.bs.js");
var Link$BirddogsoftwareFrontend = require("./Link.bs.js");
var ArrayExt$BirddogsoftwareFrontend = require("../Utils/ArrayExt.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../Modules/UrlResolver.bs.js");

function make(icon, text, href) {
  return {
          text: text,
          icon: icon,
          href: href
        };
}

function Sidebar$SidebarItem$Component(Props) {
  var item = Props.item;
  var resolved = UrlResolver$BirddogsoftwareFrontend.useResolved(item.href);
  return React.createElement(Core.ListItem, MaterialUi_ListItem.makeProps(undefined, undefined, true, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function ($$event) {
                    return Link$BirddogsoftwareFrontend.handleClick($$event, resolved.isExternal, resolved.url, undefined, undefined);
                  }), item.text, undefined, undefined, undefined, undefined), React.createElement(Core.ListItemIcon, MaterialUi_ListItemIcon.makeProps(Caml_option.some(item.icon), undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.ListItemText, MaterialUi_ListItemText.makeProps(undefined, undefined, undefined, undefined, Caml_option.some(item.text), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Component = {
  make: Sidebar$SidebarItem$Component
};

function toReactElement(item) {
  return React.createElement(Sidebar$SidebarItem$Component, {
              item: item,
              key: item.text
            });
}

var SidebarItem = {
  make: make,
  Component: Component,
  toReactElement: toReactElement
};

function make$1(icon, text, onClick) {
  return {
          text: text,
          icon: icon,
          onClick: onClick
        };
}

function Sidebar$SidebarButtonItem$Component(Props) {
  var item = Props.item;
  var onClick = Curry._1(item.onClick, undefined);
  return React.createElement(Core.ListItem, MaterialUi_ListItem.makeProps(undefined, undefined, true, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function ($$event) {
                    return Curry._1(onClick, undefined);
                  }), item.text, undefined, undefined, undefined, undefined), React.createElement(Core.ListItemIcon, MaterialUi_ListItemIcon.makeProps(Caml_option.some(item.icon), undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.ListItemText, MaterialUi_ListItemText.makeProps(undefined, undefined, undefined, undefined, Caml_option.some(item.text), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Component$1 = {
  make: Sidebar$SidebarButtonItem$Component
};

function toReactElement$1(item) {
  return React.createElement(Sidebar$SidebarButtonItem$Component, {
              item: item,
              key: item.text
            });
}

var SidebarButtonItem = {
  make: make$1,
  Component: Component$1,
  toReactElement: toReactElement$1
};

function make$2(title, children) {
  return {
          title: title,
          children: children
        };
}

var SidebarSection = {
  make: make$2
};

function make$3(children) {
  return children;
}

function toReactElement$2(sidebar) {
  var sections = $$Array.map((function (section) {
          var title = section.title;
          var header = title !== undefined ? [React.createElement(Core.ListSubheader, MaterialUi_ListSubheader.makeProps(Caml_option.some(title), undefined, undefined, undefined, undefined, undefined, true, title, undefined, undefined, undefined, undefined))] : [];
          var sectionChildren = $$Array.map((function (x) {
                  if (x.TAG) {
                    return toReactElement$1(x._0);
                  } else {
                    return toReactElement(x._0);
                  }
                }), section.children);
          var listChildren = $$Array.append(header, sectionChildren);
          return React.createElement(Core.List, MaterialUi_List.makeProps(listChildren, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }), sidebar);
  var results = ArrayExt$BirddogsoftwareFrontend.interpolate(sections, React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  return Caml_splice_call.spliceApply(React.createElement, [
              React.Fragment,
              undefined,
              results
            ]);
}

var Sidebar = {
  make: make$3,
  toReactElement: toReactElement$2
};

function item(icon, text, href) {
  return {
          TAG: /* Item */0,
          _0: {
            text: text,
            icon: icon,
            href: href
          }
        };
}

function buttonItem(icon, text, onClick) {
  return {
          TAG: /* ButtonItem */1,
          _0: {
            text: text,
            icon: icon,
            onClick: onClick
          }
        };
}

var section = make$2;

var sidebar = make$3;

exports.SidebarItem = SidebarItem;
exports.SidebarButtonItem = SidebarButtonItem;
exports.SidebarSection = SidebarSection;
exports.Sidebar = Sidebar;
exports.item = item;
exports.buttonItem = buttonItem;
exports.section = section;
exports.sidebar = sidebar;
exports.toReactElement = toReactElement$2;
/* react Not a pure module */
