'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../../../Components/QueryResponse.bs.js");
var EnterpriseOrder$BirddogsoftwareFrontend = require("./EnterpriseOrder.bs.js");
var EnterpriseOrderDraftFooter$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderDraftFooter.bs.js");
var EnterpriseOrderDraftSettings$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderDraftSettings.bs.js");

var ppx_printed_query = "mutation Q($orderDraftId: Guid!, $itemNo: String!, $data: OrderDraftLineInput!)  {\norderDraftAddLine(orderDraftId: $orderDraftId, itemNo: $itemNo, data: $data)  {\nsuccess  {\norderDraftId  \n}\n\nfailure  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftAddLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "INVALID_FEATURE_OPTION_CONFIG" :
                      tmp$1 = "INVALID_FEATURE_OPTION_CONFIG";
                      break;
                  case "INVALID_ITEM" :
                      tmp$1 = "INVALID_ITEM";
                      break;
                  case "INVALID_ORDER" :
                      tmp$1 = "INVALID_ORDER";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftAddLineFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftAddLineFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderDraftId");
          tmp = {
            NAME: "Success",
            VAL: {
              orderDraftId: value$7 !== undefined ? Caml_option.valFromOption(value$7) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftAddLine on type Mutation is missing");
  }
  return {
          orderDraftAddLine: tmp
        };
}

function json_of_OrderDraftLineInput(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(orderDraftId, itemNo, data, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var orderDraftId = variables.orderDraftId;
  var itemNo = variables.itemNo;
  var data = variables.data;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(orderDraftId, itemNo, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "itemNo",
                  itemNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, orderDraftId, itemNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "itemNo",
                      itemNo
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftAddLine = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation Q($orderDraftId: Guid!, $orderDraftLineId: Guid!, $data: OrderDraftLineInput!)  {\norderDraftUpdateLine(orderDraftId: $orderDraftId, orderDraftLineId: $orderDraftLineId, data: $data)  {\norderDraftId  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftUpdateLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderDraftId");
      tmp = {
        orderDraftId: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderDraftUpdateLine: tmp
        };
}

function json_of_OrderDraftLineInput$1(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(orderDraftId, orderDraftLineId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "orderDraftLineId",
                    orderDraftLineId
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput$1(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var orderDraftId = variables.orderDraftId;
  var orderDraftLineId = variables.orderDraftLineId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "orderDraftLineId",
                    orderDraftLineId
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput$1(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(orderDraftId, orderDraftLineId, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "orderDraftLineId",
                  orderDraftLineId
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput$1(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, orderDraftId, orderDraftLineId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "orderDraftLineId",
                      orderDraftLineId
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput$1(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var OrderDraftUpdateLine = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "mutation Q($orderDraftId: Guid!, $orderDraftLineId: Guid!)  {\norderDraftDeleteLine(orderDraftId: $orderDraftId, orderDraftLineId: $orderDraftLineId)  {\nsuccess  {\norderDraftId  \n}\n\nfailure  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftDeleteLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftLineDeleteFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "DRAFT_IS_FINALIZED" :
                      tmp$1 = "DRAFT_IS_FINALIZED";
                      break;
                  case "LINE_NOT_FOUND" :
                      tmp$1 = "LINE_NOT_FOUND";
                      break;
                  case "ORDER_NOT_FOUND" :
                      tmp$1 = "ORDER_NOT_FOUND";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftLineDeleteFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftLineDeleteFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftLineDeleteFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderDraftId");
          tmp = {
            NAME: "Success",
            VAL: {
              orderDraftId: value$7 !== undefined ? Caml_option.valFromOption(value$7) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftLineDeleteFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftLineDeleteFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftDeleteLine on type Mutation is missing");
  }
  return {
          orderDraftDeleteLine: tmp
        };
}

function make$2(orderDraftId, orderDraftLineId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "orderDraftLineId",
                    orderDraftLineId
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var orderDraftId = variables.orderDraftId;
  var orderDraftLineId = variables.orderDraftLineId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "orderDraftLineId",
                    orderDraftLineId
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(orderDraftId, orderDraftLineId, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "orderDraftLineId",
                  orderDraftLineId
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, orderDraftId, orderDraftLineId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "orderDraftLineId",
                      orderDraftLineId
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var OrderDraftDeleteLine = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function useLineAdd(orderDraftId, itemNo, data) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(orderDraftId, itemNo, data, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderDraftAddLine;
                }), param);
  };
}

var ppx_printed_query$3 = "mutation Q($orderDraftId: Guid!, $data: OrderDraftInput!)  {\norderDraft(orderDraftId: $orderDraftId, data: $data)  {\norderDraftId  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraft");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderDraftId");
      tmp = {
        orderDraftId: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderDraft: tmp
        };
}

function json_of_NameAndAddressInput(value) {
  var v = value.name;
  var v$1 = value.line1;
  var v$2 = value.line2;
  var v$3 = value.city;
  var v$4 = value.region;
  var v$5 = value.postalCode;
  var v$6 = value.country;
  return Js_dict.fromArray([
                [
                  "name",
                  v !== undefined ? v : null
                ],
                [
                  "line1",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "line2",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "city",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "region",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "postalCode",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "country",
                  v$6 !== undefined ? v$6 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_OrderType(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function json_of_OrderDraftInput(value) {
  var v = value.shipTo;
  var v$1 = value.billTo;
  var v$2 = value.discountPercent;
  var v$3 = value.freight;
  var v$4 = value.miscellaneous;
  var v$5 = value.calculateFreight;
  var v$6 = value.orderDate;
  var v$7 = value.customerNo;
  var v$8 = value.userId;
  var v$9 = value.orderType;
  return Js_dict.fromArray([
                [
                  "shipTo",
                  v !== undefined ? json_of_NameAndAddressInput(Caml_option.valFromOption(v)) : null
                ],
                [
                  "billTo",
                  v$1 !== undefined ? json_of_NameAndAddressInput(Caml_option.valFromOption(v$1)) : null
                ],
                [
                  "discountPercent",
                  v$2 !== undefined ? Caml_option.valFromOption(v$2) : null
                ],
                [
                  "freight",
                  v$3 !== undefined ? Caml_option.valFromOption(v$3) : null
                ],
                [
                  "miscellaneous",
                  v$4 !== undefined ? Caml_option.valFromOption(v$4) : null
                ],
                [
                  "calculateFreight",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "orderDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "customerNo",
                  v$7 !== undefined ? v$7 : null
                ],
                [
                  "userId",
                  v$8 !== undefined ? Caml_option.valFromOption(v$8) : null
                ],
                [
                  "orderType",
                  v$9 !== undefined ? json_of_OrderType(v$9) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$3(orderDraftId, data, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "data",
                    json_of_OrderDraftInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var orderDraftId = variables.orderDraftId;
  var data = variables.data;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "data",
                    json_of_OrderDraftInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$3(orderDraftId, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "data",
                  json_of_OrderDraftInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, orderDraftId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "data",
                      json_of_OrderDraftInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var OrderDraftUpdate = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  json_of_NameAndAddressInput: json_of_NameAndAddressInput,
  json_of_OrderType: json_of_OrderType,
  json_of_OrderDraftInput: json_of_OrderDraftInput,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function useUpdate(orderDraftId, data) {
  return GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$3(orderDraftId, {
                  billTo: data.billTo,
                  shipTo: data.shipTo,
                  calculateFreight: data.calculateFreight,
                  discountPercent: Belt_Option.map(data.discountPercent, (function (x) {
                          return x;
                        })),
                  freight: Belt_Option.map(data.freight, (function (x) {
                          return x;
                        })),
                  miscellaneous: Belt_Option.map(data.miscellaneous, (function (x) {
                          return x;
                        })),
                  userId: Belt_Option.map(data.userId, (function (x) {
                          return x;
                        })),
                  orderDate: Belt_Option.map(data.orderDate, (function (x) {
                          return x.toISOString();
                        })),
                  orderType: undefined,
                  customerNo: undefined
                }, undefined));
}

function useLineEdit(orderDraftId, orderLine, data) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$1(orderDraftId, orderLine.orderDraftLineId, {
            quantityOrdered: data.quantityOrdered,
            quantityToShip: data.quantityToShip,
            comments: data.comments,
            discountPercent: data.discountPercent,
            locationCode: data.locationCode,
            promiseDate: Belt_Option.map(data.promiseDate, (function (x) {
                    return x.toISOString();
                  })),
            requestDate: Belt_Option.map(data.requestDate, (function (x) {
                    return x.toISOString();
                  })),
            price: Belt_Option.map(data.price, (function (x) {
                    return x;
                  }))
          }, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderDraftUpdateLine;
                }), param);
  };
}

function useLineDelete(orderDraftId, orderLine) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$2(orderDraftId, orderLine.orderDraftLineId, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderDraftDeleteLine;
                }), param);
  };
}

var ppx_printed_query$4 = "mutation Q($orderDraftId: Guid!, $customerNo: String)  {\norderDraftSelectCustomer(orderDraftId: $orderDraftId, customerNo: $customerNo)  {\norderDraftId  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftSelectCustomer");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderDraftId");
      tmp = {
        orderDraftId: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderDraftSelectCustomer: tmp
        };
}

function make$4(orderDraftId, customerNo, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "customerNo",
                    customerNo !== undefined ? customerNo : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var orderDraftId = variables.orderDraftId;
  var customerNo = variables.customerNo;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "customerNo",
                    customerNo !== undefined ? customerNo : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$4(orderDraftId, customerNo, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "customerNo",
                  customerNo !== undefined ? customerNo : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, orderDraftId, customerNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "customerNo",
                      customerNo !== undefined ? customerNo : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$4
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var SelectCustomerMutation = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

function useSelectCustomer(orderDraftId, customerNo) {
  return GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$4(orderDraftId, customerNo, undefined));
}

var ppx_printed_query$5 = "query Q($orderDraftId: Guid!)  {\norderDraft(orderDraftId: $orderDraftId)  {\norderType  \ncustomer  {\ncustomerNo  \nname  \n}\n\nbillTo  {\nname  \nline1  \nline2  \ncity  \nregion  \npostalCode  \ncountry  \n}\n\nshipTo  {\nname  \nline1  \nline2  \ncity  \nregion  \npostalCode  \ncountry  \n}\n\nlines  {\norderDraftLineId  \nitemNo  \nitem  {\ndescriptionCalculated  \n}\n\nquantityOrdered  \nquantityToShip  \nlocationCode  \ndiscountPercent  \ncomments  \nprice  \ntotal  \npromiseDate  \nrequestDate  \n}\n\ntax  \nfreight  \nmiscellaneous  \nsubTotalByOrdered  \ntotalByOrdered  \ndiscountPercent  \n}\n\n}\n";

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraft");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderType");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        if (value$7 !== undefined) {
          switch (value$7) {
            case "BLANKET" :
                tmp$1 = "BLANKET";
                break;
            case "CREDIT_MEMO" :
                tmp$1 = "CREDIT_MEMO";
                break;
            case "DEBIT_MEMO" :
                tmp$1 = "DEBIT_MEMO";
                break;
            case "EMPTY" :
                tmp$1 = "EMPTY";
                break;
            case "INVOICE" :
                tmp$1 = "INVOICE";
                break;
            case "L_CLOSED" :
                tmp$1 = "L_CLOSED";
                break;
            case "MASTER_ORDER" :
                tmp$1 = "MASTER_ORDER";
                break;
            case "ORDER" :
                tmp$1 = "ORDER";
                break;
            case "PRODUCTION_ORDER" :
                tmp$1 = "PRODUCTION_ORDER";
                break;
            case "QUOTE" :
                tmp$1 = "QUOTE";
                break;
            case "SHOP_FLOOR_ORDER" :
                tmp$1 = "SHOP_FLOOR_ORDER";
                break;
            case "T" :
                tmp$1 = "T";
                break;
            default:
              tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderType: " + value$7);
          }
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderType, got " + JSON.stringify(value$6));
        }
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field orderType on type OrderDraft is missing");
      }
      var value$8 = Js_dict.get(value$4, "customer");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var match$1 = Js_json.decodeNull(value$9);
        if (match$1 !== undefined) {
          tmp$2 = undefined;
        } else {
          var value$10 = Js_option.getExn(Js_json.decodeObject(value$9));
          var value$11 = Js_dict.get(value$10, "customerNo");
          var tmp$3;
          if (value$11 !== undefined) {
            var value$12 = Caml_option.valFromOption(value$11);
            var value$13 = Js_json.decodeString(value$12);
            tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
          } else {
            tmp$3 = Js_exn.raiseError("graphql_ppx: Field customerNo on type Customer is missing");
          }
          var value$14 = Js_dict.get(value$10, "name");
          var tmp$4;
          if (value$14 !== undefined) {
            var value$15 = Caml_option.valFromOption(value$14);
            var value$16 = Js_json.decodeString(value$15);
            tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
          } else {
            tmp$4 = Js_exn.raiseError("graphql_ppx: Field name on type Customer is missing");
          }
          tmp$2 = {
            customerNo: tmp$3,
            name: tmp$4
          };
        }
      } else {
        tmp$2 = undefined;
      }
      var value$17 = Js_dict.get(value$4, "billTo");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$17)));
        var value$19 = Js_dict.get(value$18, "name");
        var tmp$6;
        if (value$19 !== undefined) {
          var value$20 = Caml_option.valFromOption(value$19);
          var value$21 = Js_json.decodeString(value$20);
          tmp$6 = value$21 !== undefined ? value$21 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$20));
        } else {
          tmp$6 = Js_exn.raiseError("graphql_ppx: Field name on type NameAndAddress is missing");
        }
        var value$22 = Js_dict.get(value$18, "line1");
        var tmp$7;
        if (value$22 !== undefined) {
          var value$23 = Caml_option.valFromOption(value$22);
          var value$24 = Js_json.decodeString(value$23);
          tmp$7 = value$24 !== undefined ? value$24 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$23));
        } else {
          tmp$7 = Js_exn.raiseError("graphql_ppx: Field line1 on type NameAndAddress is missing");
        }
        var value$25 = Js_dict.get(value$18, "line2");
        var tmp$8;
        if (value$25 !== undefined) {
          var value$26 = Caml_option.valFromOption(value$25);
          var value$27 = Js_json.decodeString(value$26);
          tmp$8 = value$27 !== undefined ? value$27 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$26));
        } else {
          tmp$8 = Js_exn.raiseError("graphql_ppx: Field line2 on type NameAndAddress is missing");
        }
        var value$28 = Js_dict.get(value$18, "city");
        var tmp$9;
        if (value$28 !== undefined) {
          var value$29 = Caml_option.valFromOption(value$28);
          var value$30 = Js_json.decodeString(value$29);
          tmp$9 = value$30 !== undefined ? value$30 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$29));
        } else {
          tmp$9 = Js_exn.raiseError("graphql_ppx: Field city on type NameAndAddress is missing");
        }
        var value$31 = Js_dict.get(value$18, "region");
        var tmp$10;
        if (value$31 !== undefined) {
          var value$32 = Caml_option.valFromOption(value$31);
          var value$33 = Js_json.decodeString(value$32);
          tmp$10 = value$33 !== undefined ? value$33 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$32));
        } else {
          tmp$10 = Js_exn.raiseError("graphql_ppx: Field region on type NameAndAddress is missing");
        }
        var value$34 = Js_dict.get(value$18, "postalCode");
        var tmp$11;
        if (value$34 !== undefined) {
          var value$35 = Caml_option.valFromOption(value$34);
          var value$36 = Js_json.decodeString(value$35);
          tmp$11 = value$36 !== undefined ? value$36 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$35));
        } else {
          tmp$11 = Js_exn.raiseError("graphql_ppx: Field postalCode on type NameAndAddress is missing");
        }
        var value$37 = Js_dict.get(value$18, "country");
        var tmp$12;
        if (value$37 !== undefined) {
          var value$38 = Caml_option.valFromOption(value$37);
          var value$39 = Js_json.decodeString(value$38);
          tmp$12 = value$39 !== undefined ? value$39 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$38));
        } else {
          tmp$12 = Js_exn.raiseError("graphql_ppx: Field country on type NameAndAddress is missing");
        }
        tmp$5 = {
          name: tmp$6,
          line1: tmp$7,
          line2: tmp$8,
          city: tmp$9,
          region: tmp$10,
          postalCode: tmp$11,
          country: tmp$12
        };
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field billTo on type OrderDraft is missing");
      }
      var value$40 = Js_dict.get(value$4, "shipTo");
      var tmp$13;
      if (value$40 !== undefined) {
        var value$41 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$40)));
        var value$42 = Js_dict.get(value$41, "name");
        var tmp$14;
        if (value$42 !== undefined) {
          var value$43 = Caml_option.valFromOption(value$42);
          var value$44 = Js_json.decodeString(value$43);
          tmp$14 = value$44 !== undefined ? value$44 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$43));
        } else {
          tmp$14 = Js_exn.raiseError("graphql_ppx: Field name on type NameAndAddress is missing");
        }
        var value$45 = Js_dict.get(value$41, "line1");
        var tmp$15;
        if (value$45 !== undefined) {
          var value$46 = Caml_option.valFromOption(value$45);
          var value$47 = Js_json.decodeString(value$46);
          tmp$15 = value$47 !== undefined ? value$47 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$46));
        } else {
          tmp$15 = Js_exn.raiseError("graphql_ppx: Field line1 on type NameAndAddress is missing");
        }
        var value$48 = Js_dict.get(value$41, "line2");
        var tmp$16;
        if (value$48 !== undefined) {
          var value$49 = Caml_option.valFromOption(value$48);
          var value$50 = Js_json.decodeString(value$49);
          tmp$16 = value$50 !== undefined ? value$50 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$49));
        } else {
          tmp$16 = Js_exn.raiseError("graphql_ppx: Field line2 on type NameAndAddress is missing");
        }
        var value$51 = Js_dict.get(value$41, "city");
        var tmp$17;
        if (value$51 !== undefined) {
          var value$52 = Caml_option.valFromOption(value$51);
          var value$53 = Js_json.decodeString(value$52);
          tmp$17 = value$53 !== undefined ? value$53 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$52));
        } else {
          tmp$17 = Js_exn.raiseError("graphql_ppx: Field city on type NameAndAddress is missing");
        }
        var value$54 = Js_dict.get(value$41, "region");
        var tmp$18;
        if (value$54 !== undefined) {
          var value$55 = Caml_option.valFromOption(value$54);
          var value$56 = Js_json.decodeString(value$55);
          tmp$18 = value$56 !== undefined ? value$56 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$55));
        } else {
          tmp$18 = Js_exn.raiseError("graphql_ppx: Field region on type NameAndAddress is missing");
        }
        var value$57 = Js_dict.get(value$41, "postalCode");
        var tmp$19;
        if (value$57 !== undefined) {
          var value$58 = Caml_option.valFromOption(value$57);
          var value$59 = Js_json.decodeString(value$58);
          tmp$19 = value$59 !== undefined ? value$59 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$58));
        } else {
          tmp$19 = Js_exn.raiseError("graphql_ppx: Field postalCode on type NameAndAddress is missing");
        }
        var value$60 = Js_dict.get(value$41, "country");
        var tmp$20;
        if (value$60 !== undefined) {
          var value$61 = Caml_option.valFromOption(value$60);
          var value$62 = Js_json.decodeString(value$61);
          tmp$20 = value$62 !== undefined ? value$62 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$61));
        } else {
          tmp$20 = Js_exn.raiseError("graphql_ppx: Field country on type NameAndAddress is missing");
        }
        tmp$13 = {
          name: tmp$14,
          line1: tmp$15,
          line2: tmp$16,
          city: tmp$17,
          region: tmp$18,
          postalCode: tmp$19,
          country: tmp$20
        };
      } else {
        tmp$13 = Js_exn.raiseError("graphql_ppx: Field shipTo on type OrderDraft is missing");
      }
      var value$63 = Js_dict.get(value$4, "lines");
      var value$64 = Js_dict.get(value$4, "tax");
      var value$65 = Js_dict.get(value$4, "freight");
      var value$66 = Js_dict.get(value$4, "miscellaneous");
      var value$67 = Js_dict.get(value$4, "subTotalByOrdered");
      var value$68 = Js_dict.get(value$4, "totalByOrdered");
      var value$69 = Js_dict.get(value$4, "discountPercent");
      tmp = {
        orderType: tmp$1,
        customer: tmp$2,
        billTo: tmp$5,
        shipTo: tmp$13,
        lines: value$63 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$63))).map(function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "orderDraftLineId");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftLineId on type OrderDraftLine is missing");
                }
                var value$5 = Js_dict.get(value$1, "itemNo");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field itemNo on type OrderDraftLine is missing");
                }
                var value$8 = Js_dict.get(value$1, "item");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var match = Js_json.decodeNull(value$9);
                  if (match !== undefined) {
                    tmp$2 = undefined;
                  } else {
                    var value$10 = Js_option.getExn(Js_json.decodeObject(value$9));
                    var value$11 = Js_dict.get(value$10, "descriptionCalculated");
                    var tmp$3;
                    if (value$11 !== undefined) {
                      var value$12 = Caml_option.valFromOption(value$11);
                      var value$13 = Js_json.decodeString(value$12);
                      tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                    } else {
                      tmp$3 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Item is missing");
                    }
                    tmp$2 = {
                      descriptionCalculated: tmp$3
                    };
                  }
                } else {
                  tmp$2 = undefined;
                }
                var value$14 = Js_dict.get(value$1, "quantityOrdered");
                var tmp$4;
                if (value$14 !== undefined) {
                  var value$15 = Caml_option.valFromOption(value$14);
                  var value$16 = Js_json.decodeNumber(value$15);
                  tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$15));
                } else {
                  tmp$4 = Js_exn.raiseError("graphql_ppx: Field quantityOrdered on type OrderDraftLine is missing");
                }
                var value$17 = Js_dict.get(value$1, "quantityToShip");
                var tmp$5;
                if (value$17 !== undefined) {
                  var value$18 = Caml_option.valFromOption(value$17);
                  var value$19 = Js_json.decodeNumber(value$18);
                  tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$18));
                } else {
                  tmp$5 = Js_exn.raiseError("graphql_ppx: Field quantityToShip on type OrderDraftLine is missing");
                }
                var value$20 = Js_dict.get(value$1, "locationCode");
                var tmp$6;
                if (value$20 !== undefined) {
                  var value$21 = Caml_option.valFromOption(value$20);
                  var value$22 = Js_json.decodeString(value$21);
                  tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
                } else {
                  tmp$6 = Js_exn.raiseError("graphql_ppx: Field locationCode on type OrderDraftLine is missing");
                }
                var value$23 = Js_dict.get(value$1, "discountPercent");
                var tmp$7;
                if (value$23 !== undefined) {
                  var value$24 = Caml_option.valFromOption(value$23);
                  var value$25 = Js_json.decodeNumber(value$24);
                  tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$24));
                } else {
                  tmp$7 = Js_exn.raiseError("graphql_ppx: Field discountPercent on type OrderDraftLine is missing");
                }
                var value$26 = Js_dict.get(value$1, "comments");
                var tmp$8;
                if (value$26 !== undefined) {
                  var value$27 = Caml_option.valFromOption(value$26);
                  var value$28 = Js_json.decodeString(value$27);
                  tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
                } else {
                  tmp$8 = Js_exn.raiseError("graphql_ppx: Field comments on type OrderDraftLine is missing");
                }
                var value$29 = Js_dict.get(value$1, "price");
                var value$30 = Js_dict.get(value$1, "total");
                var value$31 = Js_dict.get(value$1, "promiseDate");
                var value$32 = Js_dict.get(value$1, "requestDate");
                return {
                        orderDraftLineId: tmp,
                        itemNo: tmp$1,
                        item: tmp$2,
                        quantityOrdered: tmp$4,
                        quantityToShip: tmp$5,
                        locationCode: tmp$6,
                        discountPercent: tmp$7,
                        comments: tmp$8,
                        price: value$29 !== undefined ? Caml_option.valFromOption(value$29) : Js_exn.raiseError("graphql_ppx: Field price on type OrderDraftLine is missing"),
                        total: value$30 !== undefined ? Caml_option.valFromOption(value$30) : Js_exn.raiseError("graphql_ppx: Field total on type OrderDraftLine is missing"),
                        promiseDate: value$31 !== undefined ? Caml_option.valFromOption(value$31) : Js_exn.raiseError("graphql_ppx: Field promiseDate on type OrderDraftLine is missing"),
                        requestDate: value$32 !== undefined ? Caml_option.valFromOption(value$32) : Js_exn.raiseError("graphql_ppx: Field requestDate on type OrderDraftLine is missing")
                      };
              }) : Js_exn.raiseError("graphql_ppx: Field lines on type OrderDraft is missing"),
        tax: value$64 !== undefined ? Caml_option.valFromOption(value$64) : Js_exn.raiseError("graphql_ppx: Field tax on type OrderDraft is missing"),
        freight: value$65 !== undefined ? Caml_option.valFromOption(value$65) : Js_exn.raiseError("graphql_ppx: Field freight on type OrderDraft is missing"),
        miscellaneous: value$66 !== undefined ? Caml_option.valFromOption(value$66) : Js_exn.raiseError("graphql_ppx: Field miscellaneous on type OrderDraft is missing"),
        subTotalByOrdered: value$67 !== undefined ? Caml_option.valFromOption(value$67) : Js_exn.raiseError("graphql_ppx: Field subTotalByOrdered on type OrderDraft is missing"),
        totalByOrdered: value$68 !== undefined ? Caml_option.valFromOption(value$68) : Js_exn.raiseError("graphql_ppx: Field totalByOrdered on type OrderDraft is missing"),
        discountPercent: value$69 !== undefined ? Caml_option.valFromOption(value$69) : Js_exn.raiseError("graphql_ppx: Field discountPercent on type OrderDraft is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderDraft: tmp
        };
}

function make$5(orderDraftId, param) {
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "orderDraftId",
                    orderDraftId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeWithVariables$5(variables) {
  var orderDraftId = variables.orderDraftId;
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([[
                    "orderDraftId",
                    orderDraftId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeVariables$5(orderDraftId, param) {
  return Js_dict.fromArray([[
                  "orderDraftId",
                  orderDraftId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$5(graphql_ppx_use_json_variables_fn, orderDraftId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "orderDraftId",
                      orderDraftId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$5 = [
  parse$5,
  ppx_printed_query$5,
  definition_2$5
];

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

var OrderDraftQuery = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  make: make$5,
  makeWithVariables: makeWithVariables$5,
  makeVariables: makeVariables$5,
  definition: definition$5,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5
};

function EnterpriseOrderDraft$Draft(Props) {
  var orderDraftId = Props.orderDraftId;
  Props.onReset;
  var orderQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$5(orderDraftId, undefined), undefined, undefined);
  var orderQueryResult = GraphQLHooks$BirddogsoftwareFrontend.responseMap(orderQuery.result, (function (x) {
          var o = x.orderDraft;
          if (o === undefined) {
            return ;
          }
          var o$1 = Caml_option.valFromOption(o);
          var nameAndAddress = function (x) {
            return {
                    name: x.name,
                    line1: x.line1,
                    line2: x.line2,
                    city: x.city,
                    region: x.region,
                    postalCode: x.postalCode,
                    country: x.country
                  };
          };
          return {
                  orderType: o$1.orderType,
                  customer: Belt_Option.map(o$1.customer, (function (c) {
                          return {
                                  name: c.name,
                                  customerNo: c.customerNo
                                };
                        })),
                  billTo: nameAndAddress(o$1.billTo),
                  shipTo: nameAndAddress(o$1.shipTo),
                  lines: Belt_Array.map(o$1.lines, (function (l) {
                          return {
                                  orderDraftLineId: l.orderDraftLineId,
                                  itemNo: l.itemNo,
                                  item: l.item,
                                  quantityOrdered: l.quantityOrdered,
                                  quantityToShip: l.quantityToShip,
                                  locationCode: l.locationCode,
                                  discountPercent: l.discountPercent,
                                  comments: l.comments,
                                  price: Belt_Option.getExn(Js_json.decodeNumber(l.price)),
                                  total: Belt_Option.getExn(Js_json.decodeNumber(l.total)),
                                  promiseDate: new Date(Belt_Option.getExn(Js_json.decodeString(l.promiseDate))),
                                  requestDate: new Date(Belt_Option.getExn(Js_json.decodeString(l.requestDate)))
                                };
                        })),
                  tax: Belt_Option.getExn(Js_json.decodeNumber(o$1.tax)),
                  freight: Belt_Option.getExn(Js_json.decodeNumber(o$1.freight)),
                  miscellaneous: Belt_Option.getExn(Js_json.decodeNumber(o$1.miscellaneous)),
                  subTotalByOrdered: Belt_Option.getExn(Js_json.decodeNumber(o$1.subTotalByOrdered)),
                  totalByOrdered: Belt_Option.getExn(Js_json.decodeNumber(o$1.totalByOrdered)),
                  discountPercent: Belt_Option.getExn(Js_json.decodeNumber(o$1.discountPercent))
                };
        }));
  return React.createElement(EnterpriseOrder$BirddogsoftwareFrontend.make, {
              breadcrumbHref: "/orders/draft/" + orderDraftId,
              breadcrumbContent: "Draft",
              orderQueryResult: orderQueryResult,
              refetch: (function (param) {
                  return Curry._1(orderQuery.refetch, undefined);
                }),
              footer: React.createElement(EnterpriseOrderDraftFooter$BirddogsoftwareFrontend.make, {
                    orderDraftId: orderDraftId
                  }),
              sideContent: React.createElement(EnterpriseOrderDraftSettings$BirddogsoftwareFrontend.make, {
                    orderDraftId: orderDraftId,
                    orderQueryResult: orderQueryResult
                  }),
              useUpdate: (function (param) {
                  return useUpdate(orderDraftId, param);
                }),
              useLineAdd: (function (param, param$1) {
                  return useLineAdd(orderDraftId, param, param$1);
                }),
              useLineEdit: (function (param, param$1) {
                  return useLineEdit(orderDraftId, param, param$1);
                }),
              useLineDelete: (function (param) {
                  return useLineDelete(orderDraftId, param);
                }),
              useSelectCustomer: (function (param) {
                  return useSelectCustomer(orderDraftId, param);
                })
            });
}

var Draft = {
  make: EnterpriseOrderDraft$Draft
};

var ppx_printed_query$6 = "query Q  {\norderDraftInProgressOrNew(source: ORDER_ENTRY)  {\norderDraftId  \n}\n\n}\n";

function parse$6(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftInProgressOrNew");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "orderDraftId");
    tmp = {
      orderDraftId: value$4 !== undefined ? Caml_option.valFromOption(value$4) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftInProgressOrNew on type Query is missing");
  }
  return {
          orderDraftInProgressOrNew: tmp
        };
}

function make$6(param) {
  return {
          query: ppx_printed_query$6,
          variables: null,
          parse: parse$6
        };
}

function makeWithVariables$6(param) {
  return {
          query: ppx_printed_query$6,
          variables: null,
          parse: parse$6
        };
}

function makeVariables$6(param) {
  return null;
}

function definition_2$6(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$6 = [
  parse$6,
  ppx_printed_query$6,
  definition_2$6
];

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

var OrderDraftInProgressOrNewQuery = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  make: make$6,
  makeWithVariables: makeWithVariables$6,
  makeVariables: makeVariables$6,
  definition: definition$6,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6
};

function reducer(state, action) {
  if (action) {
    return /* Loaded */{
            _0: action._0
          };
  } else {
    return /* Loading */1;
  }
}

function EnterpriseOrderDraft$Current(Props) {
  React.useReducer(reducer, /* NotLoaded */0);
  var orderDraftInProgressOrNewQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$6(undefined), undefined, undefined);
  return React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
              queryResponse: orderDraftInProgressOrNewQuery.result,
              children: (function (result) {
                  return React.createElement(EnterpriseOrderDraft$Draft, {
                              orderDraftId: Belt_Option.getExn(Js_json.decodeString(result.orderDraftInProgressOrNew.orderDraftId)),
                              onReset: (function (param) {
                                  return Curry._1(orderDraftInProgressOrNewQuery.refetch, undefined);
                                })
                            });
                })
            });
}

var Current = {
  make: EnterpriseOrderDraft$Current
};

exports.OrderDraftAddLine = OrderDraftAddLine;
exports.OrderDraftUpdateLine = OrderDraftUpdateLine;
exports.OrderDraftDeleteLine = OrderDraftDeleteLine;
exports.useLineAdd = useLineAdd;
exports.OrderDraftUpdate = OrderDraftUpdate;
exports.useUpdate = useUpdate;
exports.useLineEdit = useLineEdit;
exports.useLineDelete = useLineDelete;
exports.SelectCustomerMutation = SelectCustomerMutation;
exports.useSelectCustomer = useSelectCustomer;
exports.OrderDraftQuery = OrderDraftQuery;
exports.Draft = Draft;
exports.OrderDraftInProgressOrNewQuery = OrderDraftInProgressOrNewQuery;
exports.reducer = reducer;
exports.Current = Current;
/* react Not a pure module */
