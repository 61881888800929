'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var MaterialUi_Dialog = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Dialog.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var MaterialUi_IconButton = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_IconButton.bs.js");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var MaterialUi_DialogTitle = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_DialogTitle.bs.js");
var Edit = require("@material-ui/icons/Edit");
var MaterialUi_DialogActions = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_DialogActions.bs.js");
var MaterialUi_DialogContent = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_DialogContent.bs.js");
var RegionSelect$BirddogsoftwareFrontend = require("../../../../Components/Forms/Pickers/RegionSelect.bs.js");
var CountrySelect$BirddogsoftwareFrontend = require("../../../../Components/Forms/Pickers/CountrySelect.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var NameAndAddress$BirddogsoftwareFrontend = require("../../../../Components/NameAndAddress.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* SendSaving */0) {
      return {
              name: state.name,
              line1: state.line1,
              line2: state.line2,
              city: state.city,
              region: state.region,
              postalCode: state.postalCode,
              country: state.country,
              saveState: /* Saving */1
            };
    } else {
      return {
              name: state.name,
              line1: state.line1,
              line2: state.line2,
              city: state.city,
              region: state.region,
              postalCode: state.postalCode,
              country: state.country,
              saveState: /* Saved */2
            };
    }
  }
  switch (action.TAG | 0) {
    case /* SetName */0 :
        return {
                name: action._0,
                line1: state.line1,
                line2: state.line2,
                city: state.city,
                region: state.region,
                postalCode: state.postalCode,
                country: state.country,
                saveState: state.saveState
              };
    case /* SetLine1 */1 :
        return {
                name: state.name,
                line1: action._0,
                line2: state.line2,
                city: state.city,
                region: state.region,
                postalCode: state.postalCode,
                country: state.country,
                saveState: state.saveState
              };
    case /* SetLine2 */2 :
        return {
                name: state.name,
                line1: state.line1,
                line2: action._0,
                city: state.city,
                region: state.region,
                postalCode: state.postalCode,
                country: state.country,
                saveState: state.saveState
              };
    case /* SetCity */3 :
        return {
                name: state.name,
                line1: state.line1,
                line2: state.line2,
                city: action._0,
                region: state.region,
                postalCode: state.postalCode,
                country: state.country,
                saveState: state.saveState
              };
    case /* SetRegion */4 :
        return {
                name: state.name,
                line1: state.line1,
                line2: state.line2,
                city: state.city,
                region: action._0,
                postalCode: state.postalCode,
                country: state.country,
                saveState: state.saveState
              };
    case /* SetPostalCode */5 :
        return {
                name: state.name,
                line1: state.line1,
                line2: state.line2,
                city: state.city,
                region: state.region,
                postalCode: action._0,
                country: state.country,
                saveState: state.saveState
              };
    case /* SetCountry */6 :
        return {
                name: state.name,
                line1: state.line1,
                line2: state.line2,
                city: state.city,
                region: state.region,
                postalCode: state.postalCode,
                country: action._0,
                saveState: state.saveState
              };
    
  }
}

function EnterpriseOrderNameAndAddress$EditDialog(Props) {
  var title = Props.title;
  var onClose = Props.onClose;
  var onSave = Props.onSave;
  var nameAndAddress = Props.nameAndAddress;
  var useUpdate = Props.useUpdate;
  var buildInput = Props.buildInput;
  var match = React.useReducer(reducer, {
        name: nameAndAddress.name,
        line1: nameAndAddress.line1,
        line2: nameAndAddress.line2,
        city: nameAndAddress.city,
        region: nameAndAddress.region,
        postalCode: nameAndAddress.postalCode,
        country: nameAndAddress.country,
        saveState: /* NotSaved */0
      });
  var dispatch = match[1];
  var state = match[0];
  var save = Curry._1(useUpdate, Curry._1(buildInput, {
            name: state.name,
            line1: state.line1,
            line2: state.line2,
            city: state.city,
            region: state.region,
            postalCode: state.postalCode,
            country: state.country
          }));
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var success = SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  var match$1 = state.saveState;
  var disabled = match$1 !== 0;
  var field = function (label, defaultValue, onChange) {
    return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, Caml_option.some(defaultValue), disabled, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(label), "Dense", undefined, undefined, undefined, (function ($$event) {
                      return Curry._1(dispatch, Curry._1(onChange, $$event.target.value));
                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  };
  return React.createElement(Core.Dialog, MaterialUi_Dialog.makeProps(undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                    return Curry._1(onClose, undefined);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.DialogTitle, MaterialUi_DialogTitle.makeProps(Caml_option.some(React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some("Edit " + title), undefined, "Inherit", undefined, undefined, undefined, true, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined))), undefined, true, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.DialogContent, MaterialUi_DialogContent.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined), field("Name", state.name, (function (x) {
                        return {
                                TAG: /* SetName */0,
                                _0: x
                              };
                      })), field("Line 1", state.line1, (function (x) {
                        return {
                                TAG: /* SetLine1 */1,
                                _0: x
                              };
                      })), field("Line 2", state.line2, (function (x) {
                        return {
                                TAG: /* SetLine2 */2,
                                _0: x
                              };
                      })), field("City", state.city, (function (x) {
                        return {
                                TAG: /* SetCity */3,
                                _0: x
                              };
                      })), React.createElement(CountrySelect$BirddogsoftwareFrontend.make, {
                      label: "Country",
                      fullWidth: true,
                      disabled: disabled,
                      margin: "Dense",
                      value: state.country,
                      onChange: (function (value) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetCountry */6,
                                      _0: value
                                    });
                        })
                    }), React.createElement(RegionSelect$BirddogsoftwareFrontend.make, {
                      countryIsoCode: state.country,
                      label: "State / Province / Region",
                      fullWidth: true,
                      disabled: disabled,
                      margin: "Dense",
                      value: state.region,
                      onChange: (function (value) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetRegion */4,
                                      _0: value
                                    });
                        })
                    }), field("Zip", state.postalCode, (function (x) {
                        return {
                                TAG: /* SetPostalCode */5,
                                _0: x
                              };
                      }))), React.createElement(Core.DialogActions, MaterialUi_DialogActions.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Button, MaterialUi_Button.makeProps("Cancel", undefined, "Primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "submit", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                            return Curry._1(onClose, undefined);
                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Button, MaterialUi_Button.makeProps("Save", undefined, "Primary", undefined, false || disabled, undefined, undefined, undefined, undefined, undefined, undefined, "submit", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                            Curry._1(dispatch, /* SendSaving */0);
                            Curry._1(save.runMutation, undefined).then(function (response) {
                                  if (response.TAG) {
                                    Curry._1(onSave, undefined);
                                    Curry._1(onClose, undefined);
                                    Curry._1(success, "Saved " + (title + "."));
                                    Curry._1(dispatch, /* SendSaved */1);
                                  } else {
                                    Curry._1(dispatch, /* SendSaved */1);
                                    Curry._1(error, response._0);
                                  }
                                  return Promise.resolve(undefined);
                                });
                            
                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var EditDialog = {
  reducer: reducer,
  make: EnterpriseOrderNameAndAddress$EditDialog
};

function EnterpriseOrderNameAndAddress(Props) {
  var title = Props.title;
  var nameAndAddress = Props.nameAndAddress;
  var useUpdate = Props.useUpdate;
  var buildInput = Props.buildInput;
  var refetch = Props.refetch;
  var match = React.useReducer((function (state, action) {
          return action;
        }), false);
  var setEditing = match[1];
  var tmp;
  if (nameAndAddress !== undefined) {
    var nameAndAddress$1 = Caml_option.valFromOption(nameAndAddress);
    tmp = React.createElement(React.Fragment, undefined, match[0] ? React.createElement(EnterpriseOrderNameAndAddress$EditDialog, {
                title: title,
                onClose: (function (param) {
                    return Curry._1(setEditing, false);
                  }),
                onSave: (function (param) {
                    Curry._1(setEditing, false);
                    return Curry._1(refetch, undefined);
                  }),
                nameAndAddress: nameAndAddress$1,
                useUpdate: useUpdate,
                buildInput: buildInput
              }) : React.createElement(React.Fragment, undefined), React.createElement(NameAndAddress$BirddogsoftwareFrontend.make, {
              nameAndAddress: nameAndAddress$1
            }));
  } else {
    tmp = React.createElement(NameAndAddress$BirddogsoftwareFrontend.Placeholder.make, {});
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, "Row", undefined, "Space_Between", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(title), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(Edit.default, {})), undefined, undefined, undefined, undefined, undefined, undefined, "Small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        return Curry._1(setEditing, true);
                                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), tmp);
}

var make = EnterpriseOrderNameAndAddress;

exports.EditDialog = EditDialog;
exports.make = make;
/* react Not a pure module */
