'use strict';


function convert(gqlRole) {
  if (gqlRole === "CUSTOMER_SERVICE") {
    return /* CustomerService */2;
  } else if (gqlRole === "EMPLOYEE") {
    return /* Employee */3;
  } else if (gqlRole === "ADMIN") {
    return /* Admin */4;
  } else if (gqlRole === "CONTENT_MANAGEMENT") {
    return /* ContentManagement */1;
  } else if (gqlRole === "BRAIN") {
    return /* Brain */0;
  } else {
    return /* WarehouseManagement */5;
  }
}

function stringify(role) {
  switch (role) {
    case /* Brain */0 :
        return "Brain";
    case /* ContentManagement */1 :
        return "Content Management";
    case /* CustomerService */2 :
        return "Customer Service";
    case /* Employee */3 :
        return "Employee";
    case /* Admin */4 :
        return "Admin";
    case /* WarehouseManagement */5 :
        return "Warehouse Management";
    
  }
}

exports.convert = convert;
exports.stringify = stringify;
/* No side effect */
