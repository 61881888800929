'use strict';

var React = require("react");
var Lock = require("@material-ui/icons/Lock");
var PagePaper$BirddogsoftwareFrontend = require("./PagePaper.bs.js");
var AvatarContainer$BirddogsoftwareFrontend = require("./AvatarContainer.bs.js");

function AvatarLockout(Props) {
  var title = Props.title;
  var children = Props.children;
  return React.createElement(PagePaper$BirddogsoftwareFrontend.make, {
              maxWidth: "Sm",
              children: React.createElement(AvatarContainer$BirddogsoftwareFrontend.make, {
                    avatar: React.createElement(Lock.default, {}),
                    title: title,
                    children: children
                  })
            });
}

var make = AvatarLockout;

exports.make = make;
/* react Not a pure module */
