'use strict';

var React = require("react");
var Forgot$BirddogsoftwareFrontend = require("../../../Components/SignIn/Forgot.bs.js");
var PagePaper$BirddogsoftwareFrontend = require("../../../Components/PagePaper.bs.js");

function EcommerceForgot(Props) {
  var signInLink = Props.signInLink;
  var store = Props.store;
  return React.createElement(PagePaper$BirddogsoftwareFrontend.make, {
              maxWidth: "Sm",
              children: React.createElement(Forgot$BirddogsoftwareFrontend.make, {
                    signInLink: signInLink,
                    store: store
                  })
            });
}

var make = EcommerceForgot;

exports.make = make;
/* react Not a pure module */
