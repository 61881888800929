'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");

function Make(Args) {
  var StrongField$Make$Option = function (Props) {
    var label = Props.label;
    var className = Props.className;
    var fullWidth = Props.fullWidth;
    var disabled = Props.disabled;
    var margin = Props.margin;
    var defaultValue = Props.defaultValue;
    var value = Props.value;
    var showEnterErrorOpt = Props.showEnterError;
    var onChange = Props.onChange;
    var onChangeValid = Props.onChangeValid;
    var showEnterError = showEnterErrorOpt !== undefined ? showEnterErrorOpt : false;
    var match = React.useReducer((function (state, action) {
            return action;
          }), false);
    var setShowError = match[1];
    var helperText = match[0] ? Caml_option.some("Please enter a " + Args.name) : (
        showEnterError ? Caml_option.some("Please enter a " + Args.name) : undefined
      );
    var onChange$1 = Belt_Option.getWithDefault(onChange, (function (param) {
            
          }));
    var onChangeValid$1 = Belt_Option.getWithDefault(onChangeValid, (function (param) {
            
          }));
    var tmp;
    if (defaultValue !== undefined) {
      var x = Caml_option.valFromOption(defaultValue);
      tmp = x !== undefined ? Curry._1(Args.stringify, Caml_option.valFromOption(x)) : "";
    } else {
      tmp = undefined;
    }
    var tmp$1;
    if (value !== undefined) {
      var x$1 = Caml_option.valFromOption(value);
      tmp$1 = x$1 !== undefined ? Curry._1(Args.stringify, Caml_option.valFromOption(x$1)) : "";
    } else {
      tmp$1 = undefined;
    }
    return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, className, tmp, disabled, helperText !== undefined, undefined, fullWidth, helperText, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(label), margin, undefined, undefined, undefined, (function ($$event) {
                      var x = $$event.target.value;
                      if (x === "") {
                        Curry._1(setShowError, false);
                        Curry._1(onChange$1, Caml_option.some(undefined));
                        return Curry._1(onChangeValid$1, undefined);
                      }
                      var x$1 = Curry._1(Args.parse, x);
                      if (x$1 !== undefined) {
                        var x$2 = Caml_option.valFromOption(x$1);
                        Curry._1(setShowError, false);
                        Curry._1(onChange$1, Caml_option.some(Caml_option.some(x$2)));
                        return Curry._1(onChangeValid$1, Caml_option.some(x$2));
                      }
                      Curry._1(setShowError, true);
                      return Curry._1(onChange$1, undefined);
                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Args.fieldType, tmp$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  };
  var $$Option = {
    make: StrongField$Make$Option
  };
  var StrongField$Make$Required = function (Props) {
    var label = Props.label;
    var className = Props.className;
    var fullWidth = Props.fullWidth;
    var disabled = Props.disabled;
    var margin = Props.margin;
    var defaultValue = Props.defaultValue;
    var value = Props.value;
    var onChange = Props.onChange;
    var onChangeValid = Props.onChangeValid;
    React.useReducer((function (state, action) {
            return action;
          }), false);
    var onChange$1 = onChange !== undefined ? (function (value) {
          if (value === undefined) {
            return Curry._1(onChange, undefined);
          }
          var x = Caml_option.valFromOption(value);
          if (x !== undefined) {
            return Curry._1(onChange, Caml_option.some(Caml_option.valFromOption(x)));
          } else {
            return Curry._1(onChange, undefined);
          }
        }) : (function (param) {
          
        });
    var onChangeValid$1 = onChangeValid !== undefined ? (function (value) {
          if (value !== undefined) {
            return Curry._1(onChangeValid, Caml_option.valFromOption(value));
          }
          
        }) : (function (param) {
          
        });
    var tmp = {
      label: label,
      onChange: onChange$1,
      onChangeValid: onChangeValid$1
    };
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    if (fullWidth !== undefined) {
      tmp.fullWidth = Caml_option.valFromOption(fullWidth);
    }
    if (disabled !== undefined) {
      tmp.disabled = Caml_option.valFromOption(disabled);
    }
    if (margin !== undefined) {
      tmp.margin = Caml_option.valFromOption(margin);
    }
    if (defaultValue !== undefined) {
      tmp.defaultValue = Caml_option.valFromOption(defaultValue);
    }
    if (value !== undefined) {
      tmp.value = Caml_option.valFromOption(value);
    }
    return React.createElement(StrongField$Make$Option, tmp);
  };
  var Required = {
    make: StrongField$Make$Required
  };
  return {
          $$Option: $$Option,
          Required: Required
        };
}

exports.Make = Make;
/* react Not a pure module */
