'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_IconButton = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_IconButton.bs.js");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var Clear = require("@material-ui/icons/Clear");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var LoadingPlaceholder$BirddogsoftwareFrontend = require("../../../../Components/LoadingPlaceholder.bs.js");
var CustomerAutocomplete$BirddogsoftwareFrontend = require("../../../../Components/Forms/Pickers/CustomerAutocomplete.bs.js");

function EnterpriseOrderCustomer$SelectCustomer(Props) {
  var useSelectCustomer = Props.useSelectCustomer;
  var refetch = Props.refetch;
  var match = React.useReducer((function (state, action) {
          return action;
        }), /* NotStarted */0);
  var dispatch = match[1];
  var state = match[0];
  var selectCustomer = Curry._1(useSelectCustomer, typeof state === "number" ? "" : state._0);
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  if (typeof state === "number") {
    
  } else {
    Curry._1(dispatch, /* StartedSelect */1);
    Curry._1(selectCustomer.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            Curry._1(refetch, undefined);
          } else {
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
  }
  return React.createElement(CustomerAutocomplete$BirddogsoftwareFrontend.make, {
              onChange: (function (cusNo) {
                  if (cusNo !== undefined) {
                    return Curry._1(dispatch, /* StartSelect */{
                                _0: cusNo
                              });
                  }
                  
                })
            });
}

var SelectCustomer = {
  make: EnterpriseOrderCustomer$SelectCustomer
};

function EnterpriseOrderCustomer(Props) {
  var order = Props.order;
  var refetch = Props.refetch;
  var useSelectCustomer = Props.useSelectCustomer;
  var selectCustomer = Curry._1(useSelectCustomer, undefined);
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var tmp;
  if (order !== undefined) {
    var customer = Caml_option.valFromOption(order).customer;
    if (customer !== undefined) {
      var customer$1 = Caml_option.valFromOption(customer);
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, "Row", undefined, "Space_Between", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Customer", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(Clear.default, {})), undefined, undefined, undefined, undefined, undefined, undefined, "Small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                      Curry._1(selectCustomer.runMutation, undefined).then(function (response) {
                                            if (response.TAG) {
                                              Curry._1(refetch, undefined);
                                            } else {
                                              Curry._1(error, response._0);
                                            }
                                            return Promise.resolve(undefined);
                                          });
                                      
                                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), "#" + (customer$1.customerNo + (" - " + customer$1.name)));
    } else {
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Find or create a customer", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(EnterpriseOrderCustomer$SelectCustomer, {
                useSelectCustomer: useSelectCustomer,
                refetch: refetch
              }));
    }
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Customer", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {
              lines: 2
            }));
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = EnterpriseOrderCustomer;

exports.SelectCustomer = SelectCustomer;
exports.make = make;
/* react Not a pure module */
