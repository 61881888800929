'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Map = require("bs-platform/lib/js/belt_Map.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var SnackbarContent$BirddogsoftwareFrontend = require("../../../Components/SnackbarContent.bs.js");
var EcommerceSearchTypes$BirddogsoftwareFrontend = require("./EcommerceSearchTypes.bs.js");
var EcommerceSearchFiltersSimple$BirddogsoftwareFrontend = require("./EcommerceSearchFiltersSimple.bs.js");
var EcommerceSearchResultsTwoLevel$BirddogsoftwareFrontend = require("./EcommerceSearchResultsTwoLevel.bs.js");

var ppx_printed_query = "query Q($webCategoryId: Guid!)  {\nwebCategory(webCategoryId: $webCategoryId)  {\nfilterGroups  {\ndescription  \ndetails  {\nname  \nuserDefinedField  {\nvalues  {\nvalue  \nlowResImage  \n}\n\n}\n\nlabel  \ndescription  \nsortOrder  \nautoPopulateWithAvailableValues  \n}\n\n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "webCategory");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "filterGroups");
      tmp = {
        filterGroups: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map(function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "description");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field description on type FilterGroup is missing");
                }
                var value$5 = Js_dict.get(value$1, "details");
                return {
                        description: tmp,
                        details: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map(function (value) {
                                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                                var value$2 = Js_dict.get(value$1, "name");
                                var tmp;
                                if (value$2 !== undefined) {
                                  var value$3 = Caml_option.valFromOption(value$2);
                                  var value$4 = Js_json.decodeString(value$3);
                                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                                } else {
                                  tmp = Js_exn.raiseError("graphql_ppx: Field name on type FilterGroupDetail is missing");
                                }
                                var value$5 = Js_dict.get(value$1, "userDefinedField");
                                var tmp$1;
                                if (value$5 !== undefined) {
                                  var value$6 = Caml_option.valFromOption(value$5);
                                  var match = Js_json.decodeNull(value$6);
                                  if (match !== undefined) {
                                    tmp$1 = undefined;
                                  } else {
                                    var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
                                    var value$8 = Js_dict.get(value$7, "values");
                                    tmp$1 = {
                                      values: value$8 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$8))).map(function (value) {
                                              var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                                              var value$2 = Js_dict.get(value$1, "value");
                                              var tmp;
                                              if (value$2 !== undefined) {
                                                var value$3 = Caml_option.valFromOption(value$2);
                                                var value$4 = Js_json.decodeString(value$3);
                                                tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                                              } else {
                                                tmp = Js_exn.raiseError("graphql_ppx: Field value on type UserDefinedFieldValue is missing");
                                              }
                                              var value$5 = Js_dict.get(value$1, "lowResImage");
                                              var tmp$1;
                                              if (value$5 !== undefined) {
                                                var value$6 = Caml_option.valFromOption(value$5);
                                                var value$7 = Js_json.decodeString(value$6);
                                                tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                                              } else {
                                                tmp$1 = Js_exn.raiseError("graphql_ppx: Field lowResImage on type UserDefinedFieldValue is missing");
                                              }
                                              return {
                                                      value: tmp,
                                                      lowResImage: tmp$1
                                                    };
                                            }) : Js_exn.raiseError("graphql_ppx: Field values on type UserDefinedField is missing")
                                    };
                                  }
                                } else {
                                  tmp$1 = undefined;
                                }
                                var value$9 = Js_dict.get(value$1, "label");
                                var tmp$2;
                                if (value$9 !== undefined) {
                                  var value$10 = Caml_option.valFromOption(value$9);
                                  var value$11 = Js_json.decodeString(value$10);
                                  tmp$2 = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
                                } else {
                                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field label on type FilterGroupDetail is missing");
                                }
                                var value$12 = Js_dict.get(value$1, "description");
                                var tmp$3;
                                if (value$12 !== undefined) {
                                  var value$13 = Caml_option.valFromOption(value$12);
                                  var value$14 = Js_json.decodeString(value$13);
                                  tmp$3 = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
                                } else {
                                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field description on type FilterGroupDetail is missing");
                                }
                                var value$15 = Js_dict.get(value$1, "sortOrder");
                                var tmp$4;
                                if (value$15 !== undefined) {
                                  var value$16 = Caml_option.valFromOption(value$15);
                                  var value$17 = Js_json.decodeNumber(value$16);
                                  tmp$4 = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$16));
                                } else {
                                  tmp$4 = Js_exn.raiseError("graphql_ppx: Field sortOrder on type FilterGroupDetail is missing");
                                }
                                var value$18 = Js_dict.get(value$1, "autoPopulateWithAvailableValues");
                                var tmp$5;
                                if (value$18 !== undefined) {
                                  var value$19 = Caml_option.valFromOption(value$18);
                                  var value$20 = Js_json.decodeBoolean(value$19);
                                  tmp$5 = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$19));
                                } else {
                                  tmp$5 = Js_exn.raiseError("graphql_ppx: Field autoPopulateWithAvailableValues on type FilterGroupDetail is missing");
                                }
                                return {
                                        name: tmp,
                                        userDefinedField: tmp$1,
                                        label: tmp$2,
                                        description: tmp$3,
                                        sortOrder: tmp$4,
                                        autoPopulateWithAvailableValues: tmp$5
                                      };
                              }) : Js_exn.raiseError("graphql_ppx: Field details on type FilterGroup is missing")
                      };
              }) : Js_exn.raiseError("graphql_ppx: Field filterGroups on type CachedWebCategory is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          webCategory: tmp
        };
}

function make(webCategoryId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "webCategoryId",
                    webCategoryId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var webCategoryId = variables.webCategoryId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "webCategoryId",
                    webCategoryId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(webCategoryId, param) {
  return Js_dict.fromArray([[
                  "webCategoryId",
                  webCategoryId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, webCategoryId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "webCategoryId",
                      webCategoryId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var FilterGroupsQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function reducer(state, action) {
  return {
          query: action._0
        };
}

function EcommerceSearch$Search$SearchBody(Props) {
  Props.dispatch;
  var filterGroups = Props.filterGroups;
  var unfilteredQuery = Props.unfilteredQuery;
  var currentQuery = Props.currentQuery;
  var results = Props.results;
  var resultsPlaceholder = Props.resultsPlaceholder;
  var filterState = Props.filterState;
  var filterDispatch = Props.filterDispatch;
  var match = unfilteredQuery.result;
  var match$1 = currentQuery.result;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (filterGroups !== undefined) {
    if (typeof match === "number") {
      exit$1 = 3;
    } else if (match.TAG) {
      if (typeof match$1 === "number" || !match$1.TAG) {
        exit$1 = 3;
      } else {
        var buildItemFieldValues = function (result) {
          return Belt_Array.map(result.siteSearch.itemFieldValues, (function (x) {
                        return {
                                fieldName: x.fieldName,
                                itemFieldSource: x.itemFieldSource,
                                values: x.values
                              };
                      }));
        };
        tmp = React.createElement(EcommerceSearchFiltersSimple$BirddogsoftwareFrontend.make, {
              filterGroups: filterGroups,
              unfilteredItemFieldValues: buildItemFieldValues(match._0),
              filteredItemFieldValues: buildItemFieldValues(match$1._0),
              state: filterState,
              dispatch: filterDispatch
            });
      }
    } else {
      exit$2 = 4;
    }
  } else {
    exit$2 = 4;
  }
  if (exit$2 === 4) {
    if (typeof match === "number" || match.TAG) {
      exit$1 = 3;
    } else {
      exit = 1;
    }
  }
  if (exit$1 === 3) {
    exit = typeof match$1 === "number" || match$1.TAG ? 2 : 1;
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
              variant: "Error",
              children: "There was an error fetching the search results."
            });
        break;
    case 2 :
        tmp = React.createElement(EcommerceSearchFiltersSimple$BirddogsoftwareFrontend.Placeholder.make, {});
        break;
    
  }
  var message = currentQuery.result;
  var tmp$1;
  tmp$1 = typeof message === "number" ? resultsPlaceholder : (
      message.TAG ? Curry._1(results, message._0) : React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
              variant: "Error",
              children: "There was an error fetching the search results."
            })
    );
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V2 */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(tmp), undefined, undefined, undefined, undefined, true, undefined, /* V3 */2, /* V3 */2, undefined, undefined, undefined, /* V3 */2, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(tmp$1), undefined, undefined, undefined, undefined, true, undefined, /* V9 */8, /* V9 */8, undefined, undefined, undefined, /* V9 */8, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var SearchBody = {
  make: EcommerceSearch$Search$SearchBody
};

function EcommerceSearch$Search$SearchWithFilter(Props) {
  var siteSearchInput = Props.siteSearchInput;
  var makeQuery = Props.makeQuery;
  var rootWebCategoryId = Props.rootWebCategoryId;
  var itemFieldsToCollateValues = Props.itemFieldsToCollateValues;
  var render = Props.render;
  return Curry._1(render, GraphQLHooks$BirddogsoftwareFrontend.useQuery(Curry._4(makeQuery, Caml_option.some(rootWebCategoryId), Caml_option.some(siteSearchInput), itemFieldsToCollateValues, undefined), undefined, undefined));
}

var SearchWithFilter = {
  make: EcommerceSearch$Search$SearchWithFilter
};

function EcommerceSearch$Search(Props) {
  var rootWebCategoryId = Props.rootWebCategoryId;
  var filterGroups = Props.filterGroups;
  var makeQuery = Props.makeQuery;
  var results = Props.results;
  var resultsPlaceholder = Props.resultsPlaceholder;
  var match = React.useReducer(reducer, {
        query: undefined
      });
  var dispatch = match[1];
  var filterReducer = function (state, action) {
    var state$1 = EcommerceSearchFiltersSimple$BirddogsoftwareFrontend.reducer(state, action);
    var query = EcommerceSearchTypes$BirddogsoftwareFrontend.FilterGroupQuery.compileSimple(Belt_List.map(Belt_Map.toList(state$1.filters), (function (param) {
                var match = param[0];
                var filterGroupDetailName = match[1];
                var filterGroupDescription = match[0];
                var filterGroup = Belt_Option.getExn(Belt_Array.getBy(Belt_Option.getWithDefault(filterGroups, []), (function (filterGroup) {
                            return filterGroup.description === filterGroupDescription;
                          })));
                var filterGroupDetail = Belt_Option.getExn(Belt_Array.getBy(filterGroup.details, (function (filterGroupDetail) {
                            return filterGroupDetail.name === filterGroupDetailName;
                          })));
                return [
                        filterGroup,
                        filterGroupDetail,
                        Belt_List.fromArray(param[1].selectedValues)
                      ];
              })));
    Curry._1(dispatch, /* SetQuery */{
          _0: query
        });
    return state$1;
  };
  var match$1 = React.useReducer(filterReducer, {
        filters: Belt_Map.make(EcommerceSearchFiltersSimple$BirddogsoftwareFrontend.IdStringString)
      });
  var filterDispatch = match$1[1];
  var filterState = match$1[0];
  var siteSearchInput = Belt_Option.map(match[0].query, (function (query) {
          return EcommerceSearchTypes$BirddogsoftwareFrontend.SiteSearchInput.compile(query);
        }));
  var itemFieldsToCollateValues = Belt_Array.concatMany(Belt_Array.map(Belt_Option.getWithDefault(filterGroups, []), (function (filterGroup) {
              return Belt_Array.map(filterGroup.details, (function (detail) {
                            return {
                                    itemFieldSource: "USER_DEFINED_FIELD",
                                    fieldName: detail.name
                                  };
                          }));
            })));
  var rootWebCategoryId$1 = rootWebCategoryId !== undefined ? rootWebCategoryId : null;
  var unfilteredQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(Curry._4(makeQuery, Caml_option.some(rootWebCategoryId$1), undefined, itemFieldsToCollateValues, undefined), undefined, undefined);
  if (siteSearchInput !== undefined) {
    return React.createElement(EcommerceSearch$Search$SearchWithFilter, {
                siteSearchInput: Caml_option.valFromOption(siteSearchInput),
                makeQuery: makeQuery,
                rootWebCategoryId: rootWebCategoryId$1,
                itemFieldsToCollateValues: itemFieldsToCollateValues,
                render: (function (query) {
                    return React.createElement(EcommerceSearch$Search$SearchBody, {
                                dispatch: dispatch,
                                filterGroups: filterGroups,
                                unfilteredQuery: unfilteredQuery,
                                currentQuery: query,
                                results: results,
                                resultsPlaceholder: resultsPlaceholder,
                                filterState: filterState,
                                filterDispatch: filterDispatch
                              });
                  })
              });
  } else {
    return React.createElement(EcommerceSearch$Search$SearchBody, {
                dispatch: dispatch,
                filterGroups: filterGroups,
                unfilteredQuery: unfilteredQuery,
                currentQuery: unfilteredQuery,
                results: results,
                resultsPlaceholder: resultsPlaceholder,
                filterState: filterState,
                filterDispatch: filterDispatch
              });
  }
}

var Search = {
  reducer: reducer,
  SearchBody: SearchBody,
  SearchWithFilter: SearchWithFilter,
  make: EcommerceSearch$Search
};

var ppx_printed_query$1 = "query Q  {\norderDraftInProgressOrNew  {\norderDraftId  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftInProgressOrNew");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "orderDraftId");
    tmp = {
      orderDraftId: value$4 !== undefined ? Caml_option.valFromOption(value$4) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftInProgressOrNew on type Query is missing");
  }
  return {
          orderDraftInProgressOrNew: tmp
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var OrderDraftInProgressOrNewQuery = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function EcommerceSearch$Id(Props) {
  var rootWebCategoryId = Props.rootWebCategoryId;
  var filterGroupsQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(rootWebCategoryId, undefined), undefined, undefined);
  var orderQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$1(undefined), undefined, undefined);
  var makeQuery = EcommerceSearchResultsTwoLevel$BirddogsoftwareFrontend.Query.make;
  var resultsPlaceholder = React.createElement(EcommerceSearchResultsTwoLevel$BirddogsoftwareFrontend.Placeholder.make, {});
  var message = orderQuery.result;
  if (typeof message === "number") {
    return "Better placeholder";
  }
  if (!message.TAG) {
    return React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
                variant: "Error",
                children: "There was an error rendering the search."
              });
  }
  var orderResult = message._0;
  var results = function (result) {
    return React.createElement(EcommerceSearchResultsTwoLevel$BirddogsoftwareFrontend.make, {
                result: result,
                orderDraftId: Belt_Option.getExn(Js_json.decodeString(orderResult.orderDraftInProgressOrNew.orderDraftId))
              });
  };
  var message$1 = filterGroupsQuery.result;
  var tmp;
  if (typeof message$1 === "number") {
    tmp = React.createElement(EcommerceSearch$Search, {
          rootWebCategoryId: rootWebCategoryId,
          makeQuery: makeQuery,
          results: results,
          resultsPlaceholder: resultsPlaceholder
        });
  } else if (message$1.TAG) {
    var webCategory = message$1._0.webCategory;
    if (webCategory !== undefined) {
      var filterGroups = Belt_Array.map(Caml_option.valFromOption(webCategory).filterGroups, (function (filterGroup) {
              return {
                      description: filterGroup.description,
                      details: Belt_Array.map(filterGroup.details, (function (detail) {
                              var values = Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.map(detail.userDefinedField, (function (x) {
                                              return x.values;
                                            })), []), (function (x) {
                                      return {
                                              value: x.value,
                                              lowResImage: x.lowResImage
                                            };
                                    }));
                              return {
                                      name: detail.name,
                                      label: detail.label,
                                      description: detail.description,
                                      sortOrder: detail.sortOrder,
                                      values: values
                                    };
                            }))
                    };
            }));
      tmp = React.createElement(EcommerceSearch$Search, {
            rootWebCategoryId: rootWebCategoryId,
            filterGroups: filterGroups,
            makeQuery: makeQuery,
            results: results,
            resultsPlaceholder: resultsPlaceholder
          });
    } else {
      tmp = React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
            variant: "Error",
            children: "The rootWebCategory could not be loaded"
          });
    }
  } else {
    tmp = React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
          variant: "Error",
          children: "There was an error rendering the search."
        });
  }
  return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(tmp), undefined, undefined, undefined, "Lg", undefined, undefined, undefined, undefined, undefined));
}

var Id = {
  make: EcommerceSearch$Id
};

var ppx_printed_query$2 = "query Settings  {\ncompiledSettings  {\nsiteSearchRootWebCategoryId  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "compiledSettings");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "siteSearchRootWebCategoryId");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var match = Js_json.decodeNull(value$5);
      tmp$1 = match !== undefined ? undefined : Caml_option.some(value$5);
    } else {
      tmp$1 = undefined;
    }
    tmp = {
      siteSearchRootWebCategoryId: tmp$1
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field compiledSettings on type Query is missing");
  }
  return {
          compiledSettings: tmp
        };
}

function make$2(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeWithVariables$2(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeVariables$2(param) {
  return null;
}

function definition_2$2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var SettingsQuery = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function EcommerceSearch$NoId(Props) {
  GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$2(undefined), undefined, undefined);
  return "foos";
}

var NoId = {
  SettingsQuery: SettingsQuery,
  make: EcommerceSearch$NoId
};

exports.FilterGroupsQuery = FilterGroupsQuery;
exports.Search = Search;
exports.OrderDraftInProgressOrNewQuery = OrderDraftInProgressOrNewQuery;
exports.Id = Id;
exports.NoId = NoId;
/* react Not a pure module */
