'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Divider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Divider.bs.js");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var Logging$BirddogsoftwareFrontend = require("../../Types/Logging.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../../Components/PaperSpacer.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../Components/Forms/ActionHeader.bs.js");
var FormDataView$BirddogsoftwareFrontend = require("../../Components/Forms/FormDataView.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");
var PaperPadding$BirddogsoftwareFrontend = require("../../Components/PaperPadding.bs.js");

var ppx_printed_query = "query LopEntryQuery($id: Guid!)  {\nappLog(id: $id)  {\neventTime  \ncompany  \nuserName  \nversionNo  \neventMessage  \ncategory  \nseverity  \nloggingLevel  \nerrorCode  \nsiteID  \nreportID  \nstackTrace  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "appLog");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "eventTime");
      var value$6 = Js_dict.get(value$4, "company");
      var tmp$1;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        tmp$1 = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field company on type AppLog is missing");
      }
      var value$9 = Js_dict.get(value$4, "userName");
      var tmp$2;
      if (value$9 !== undefined) {
        var value$10 = Caml_option.valFromOption(value$9);
        var value$11 = Js_json.decodeString(value$10);
        tmp$2 = value$11 !== undefined ? value$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$10));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field userName on type AppLog is missing");
      }
      var value$12 = Js_dict.get(value$4, "versionNo");
      var tmp$3;
      if (value$12 !== undefined) {
        var value$13 = Caml_option.valFromOption(value$12);
        var value$14 = Js_json.decodeString(value$13);
        tmp$3 = value$14 !== undefined ? value$14 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$13));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field versionNo on type AppLog is missing");
      }
      var value$15 = Js_dict.get(value$4, "eventMessage");
      var tmp$4;
      if (value$15 !== undefined) {
        var value$16 = Caml_option.valFromOption(value$15);
        var value$17 = Js_json.decodeString(value$16);
        tmp$4 = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field eventMessage on type AppLog is missing");
      }
      var value$18 = Js_dict.get(value$4, "category");
      var tmp$5;
      if (value$18 !== undefined) {
        var value$19 = Caml_option.valFromOption(value$18);
        var value$20 = Js_json.decodeString(value$19);
        tmp$5 = value$20 !== undefined ? value$20 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$19));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field category on type AppLog is missing");
      }
      var value$21 = Js_dict.get(value$4, "severity");
      var tmp$6;
      if (value$21 !== undefined) {
        var value$22 = Caml_option.valFromOption(value$21);
        var value$23 = Js_json.decodeString(value$22);
        if (value$23 !== undefined) {
          switch (value$23) {
            case "CHANNEL" :
                tmp$6 = "CHANNEL";
                break;
            case "DONT_LOG" :
                tmp$6 = "DONT_LOG";
                break;
            case "IMPORTANT" :
                tmp$6 = "IMPORTANT";
                break;
            case "INCIDENTAL" :
                tmp$6 = "INCIDENTAL";
                break;
            case "LEAST_IMPORTANT" :
                tmp$6 = "LEAST_IMPORTANT";
                break;
            case "MOST_IMPORTANT" :
                tmp$6 = "MOST_IMPORTANT";
                break;
            case "REPORT" :
                tmp$6 = "REPORT";
                break;
            case "SLIGHTLY_IMPORTANT" :
                tmp$6 = "SLIGHTLY_IMPORTANT";
                break;
            default:
              tmp$6 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for LoggingSeverity: " + value$23);
          }
        } else {
          tmp$6 = Js_exn.raiseError("graphql_ppx: Expected enum value for LoggingSeverity, got " + JSON.stringify(value$22));
        }
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field severity on type AppLog is missing");
      }
      var value$24 = Js_dict.get(value$4, "loggingLevel");
      var tmp$7;
      if (value$24 !== undefined) {
        var value$25 = Caml_option.valFromOption(value$24);
        var value$26 = Js_json.decodeString(value$25);
        if (value$26 !== undefined) {
          switch (value$26) {
            case "CHANNEL" :
                tmp$7 = "CHANNEL";
                break;
            case "ERRORS_ONLY" :
                tmp$7 = "ERRORS_ONLY";
                break;
            case "EVERYTHING" :
                tmp$7 = "EVERYTHING";
                break;
            case "HIGH" :
                tmp$7 = "HIGH";
                break;
            case "LOW" :
                tmp$7 = "LOW";
                break;
            case "MEDIUM" :
                tmp$7 = "MEDIUM";
                break;
            case "REPORT" :
                tmp$7 = "REPORT";
                break;
            default:
              tmp$7 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for LoggingLevel: " + value$26);
          }
        } else {
          tmp$7 = Js_exn.raiseError("graphql_ppx: Expected enum value for LoggingLevel, got " + JSON.stringify(value$25));
        }
      } else {
        tmp$7 = Js_exn.raiseError("graphql_ppx: Field loggingLevel on type AppLog is missing");
      }
      var value$27 = Js_dict.get(value$4, "errorCode");
      var tmp$8;
      if (value$27 !== undefined) {
        var value$28 = Caml_option.valFromOption(value$27);
        var value$29 = Js_json.decodeNumber(value$28);
        tmp$8 = value$29 !== undefined ? value$29 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$28));
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Field errorCode on type AppLog is missing");
      }
      var value$30 = Js_dict.get(value$4, "siteID");
      var tmp$9;
      if (value$30 !== undefined) {
        var value$31 = Caml_option.valFromOption(value$30);
        var value$32 = Js_json.decodeString(value$31);
        tmp$9 = value$32 !== undefined ? value$32 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$31));
      } else {
        tmp$9 = Js_exn.raiseError("graphql_ppx: Field siteID on type AppLog is missing");
      }
      var value$33 = Js_dict.get(value$4, "reportID");
      var tmp$10;
      if (value$33 !== undefined) {
        var value$34 = Caml_option.valFromOption(value$33);
        var value$35 = Js_json.decodeString(value$34);
        tmp$10 = value$35 !== undefined ? value$35 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$34));
      } else {
        tmp$10 = Js_exn.raiseError("graphql_ppx: Field reportID on type AppLog is missing");
      }
      var value$36 = Js_dict.get(value$4, "stackTrace");
      var tmp$11;
      if (value$36 !== undefined) {
        var value$37 = Caml_option.valFromOption(value$36);
        var value$38 = Js_json.decodeString(value$37);
        tmp$11 = value$38 !== undefined ? value$38 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$37));
      } else {
        tmp$11 = Js_exn.raiseError("graphql_ppx: Field stackTrace on type AppLog is missing");
      }
      tmp = {
        eventTime: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field eventTime on type AppLog is missing"),
        company: tmp$1,
        userName: tmp$2,
        versionNo: tmp$3,
        eventMessage: tmp$4,
        category: tmp$5,
        severity: tmp$6,
        loggingLevel: tmp$7,
        errorCode: tmp$8,
        siteID: tmp$9,
        reportID: tmp$10,
        stackTrace: tmp$11
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          appLog: tmp
        };
}

function make(id, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "id",
                    id
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var id = variables.id;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "id",
                    id
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(id, param) {
  return Js_dict.fromArray([[
                  "id",
                  id
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, id, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "id",
                      id
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var LogEntryQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function copyToClipboard(data) {
  var clipboard = navigator.clipboard;
  var lines = [
    [
      "eventTime",
      Belt_Option.getWithDefault(Js_json.decodeString(data.eventTime), "invalid")
    ],
    [
      "company",
      data.company
    ],
    [
      "userName",
      data.userName
    ],
    [
      "versionNo",
      data.versionNo
    ],
    [
      "eventMessage",
      data.eventMessage
    ],
    [
      "category",
      data.category
    ],
    [
      "severity",
      Logging$BirddogsoftwareFrontend.stringifySeverity(data.severity)
    ],
    [
      "loggingLevel",
      Logging$BirddogsoftwareFrontend.stringifyLoggingLevel(data.loggingLevel)
    ],
    [
      "errorCode",
      String(data.errorCode)
    ],
    [
      "siteID",
      data.siteID
    ],
    [
      "reportID",
      data.reportID
    ],
    [
      "stackTrace",
      data.stackTrace
    ]
  ];
  var json = {};
  Belt_Array.forEach(lines, (function (param) {
          json[param[0]] = param[1];
          
        }));
  var text = JSON.stringify(json, null, 4);
  clipboard.writeText(text);
  
}

function eventMessage(data) {
  return React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
              title: "Event Message",
              value: Belt_Option.map(data, (function (data) {
                      return React.createElement("code", undefined, data.eventMessage);
                    }))
            });
}

function form(data) {
  return React.createElement(React.Fragment, undefined, React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Event Time",
                  value: Belt_Option.map(data, (function (data) {
                          return Belt_Option.getWithDefault(Js_json.decodeString(data.eventTime), "invalid");
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Company",
                  value: Belt_Option.map(data, (function (data) {
                          return data.company;
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "User Name",
                  value: Belt_Option.map(data, (function (data) {
                          return data.userName;
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Version No",
                  value: Belt_Option.map(data, (function (data) {
                          return data.versionNo;
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Category",
                  value: Belt_Option.map(data, (function (data) {
                          return data.category;
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Severity",
                  value: Belt_Option.map(data, (function (data) {
                          return Logging$BirddogsoftwareFrontend.stringifySeverity(data.severity);
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Logging Level",
                  value: Belt_Option.map(data, (function (data) {
                          return Logging$BirddogsoftwareFrontend.stringifyLoggingLevel(data.loggingLevel);
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Error Code",
                  value: Belt_Option.map(data, (function (data) {
                          return String(data.errorCode);
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Site ID",
                  value: Belt_Option.map(data, (function (data) {
                          return data.siteID;
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Report ID",
                  value: Belt_Option.map(data, (function (data) {
                          return data.reportID;
                        }))
                }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(FormDataView$BirddogsoftwareFrontend.make, {
                  title: "Stack Trace",
                  value: Belt_Option.map(data, (function (data) {
                          return React.createElement("code", undefined, data.stackTrace);
                        })),
                  collapseMode: "StartCollapsed"
                }));
}

function EnterpriseLogEntry(Props) {
  var id = Props.id;
  var logEntryQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(id, undefined), undefined, undefined);
  var message = logEntryQuery.result;
  var match;
  match = typeof message === "number" ? [
      true,
      undefined,
      undefined
    ] : (
      message.TAG ? [
          false,
          undefined,
          Caml_option.some(message._0.appLog)
        ] : [
          false,
          message._0,
          undefined
        ]
    );
  var data = match[2];
  var tmp;
  if (data !== undefined) {
    var data$1 = Caml_option.valFromOption(data);
    if (data$1 !== undefined) {
      var data$2 = Caml_option.valFromOption(data$1);
      tmp = {
        NAME: "OnClick",
        VAL: (function (param) {
            return copyToClipboard(data$2);
          })
      };
    } else {
      tmp = "Disabled";
    }
  } else {
    tmp = "Disabled";
  }
  var tmp$1;
  if (data !== undefined) {
    var data$3 = Caml_option.valFromOption(data);
    tmp$1 = data$3 !== undefined ? eventMessage(Caml_option.some(Caml_option.valFromOption(data$3))) : "Not found";
  } else {
    tmp$1 = eventMessage(undefined);
  }
  var tmp$2;
  if (data !== undefined) {
    var data$4 = Caml_option.valFromOption(data);
    tmp$2 = data$4 !== undefined ? form(Caml_option.some(Caml_option.valFromOption(data$4))) : "Not found";
  } else {
    tmp$2 = form(undefined);
  }
  return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(null), undefined, undefined, undefined, "Md", undefined, undefined, undefined, undefined, undefined), React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                  breadcrumbs: [
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/log"
                      },
                      content: "Log"
                    },
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/log/" + id
                      },
                      content: "Event"
                    }
                  ],
                  mainActions: [{
                      key: "copy",
                      content: "Copy",
                      action: tmp,
                      color: "Primary"
                    }]
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                    children: tmp$1
                                  })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                    children: tmp$2
                                  })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }));
}

var make$1 = EnterpriseLogEntry;

exports.LogEntryQuery = LogEntryQuery;
exports.copyToClipboard = copyToClipboard;
exports.eventMessage = eventMessage;
exports.form = form;
exports.make = make$1;
/* react Not a pure module */
