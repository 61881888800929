'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Checkbox = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Checkbox.bs.js");
var MaterialUi_FormControlLabel = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_FormControlLabel.bs.js");
var IntField$BirddogsoftwareFrontend = require("../../Components/Forms/IntField.bs.js");
var Stricture$BirddogsoftwareFrontend = require("../../Components/Forms/Stricture.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../Components/Forms/SimpleForm.bs.js");
var TermSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/TermSelect.bs.js");
var ShipViaSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/ShipViaSelect.bs.js");
var TaxCodeSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/TaxCodeSelect.bs.js");
var LocationSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/LocationSelect.bs.js");
var GLAccountSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/GLAccountSelect.bs.js");
var SalesPersonSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/SalesPersonSelect.bs.js");
var TaxScheduleSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/TaxScheduleSelect.bs.js");
var CustomerTypeSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/CustomerTypeSelect.bs.js");
var ConnectorTypeSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/ConnectorTypeSelect.bs.js");
var ItemsToUploadSelect$BirddogsoftwareFrontend = require("./ItemsToUploadSelect.bs.js");
var CustomersToUploadSelect$BirddogsoftwareFrontend = require("./CustomersToUploadSelect.bs.js");
var ProcessCompletedOrderSelect$BirddogsoftwareFrontend = require("./ProcessCompletedOrderSelect.bs.js");
var ConnectorAccountVerbosityLevelSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/ConnectorAccountVerbosityLevelSelect.bs.js");

var ppx_printed_query = "mutation q($connectorAccountId: Guid!)  {\nconnectorAccountDelete(connectorAccountId: $connectorAccountId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "connectorAccountDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          connectorAccountDelete: tmp
        };
}

function make(connectorAccountId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "connectorAccountId",
                    connectorAccountId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var connectorAccountId = variables.connectorAccountId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "connectorAccountId",
                    connectorAccountId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(connectorAccountId, param) {
  return Js_dict.fromArray([[
                  "connectorAccountId",
                  connectorAccountId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, connectorAccountId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "connectorAccountId",
                      connectorAccountId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($connectorAccountId: Guid, $data: ConnectorAccountInput!)  {\nconnectorAccount(connectorAccountId: $connectorAccountId, data: $data)  {\nsuccess  {\nconnectorAccountId  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "connectorAccount");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "success");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "connectorAccountId");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field connectorAccountId on type ConnectorAccount is missing");
          }
          tmp$1 = {
            connectorAccountId: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      tmp = {
        success: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          connectorAccount: tmp
        };
}

function json_of_ConnectorType(value) {
  if (value === "NET_SUITE") {
    return "NET_SUITE";
  } else if (value === "WOO_COMMERCE") {
    return "WOO_COMMERCE";
  } else if (value === "EPICOR") {
    return "EPICOR";
  } else if (value === "MACOLA_ES") {
    return "MACOLA_ES";
  } else if (value === "SALSIFY") {
    return "SALSIFY";
  } else if (value === "SHOPIFY") {
    return "SHOPIFY";
  } else if (value === "SAGE_100") {
    return "SAGE_100";
  } else if (value === "AVALARA_CERT_CAPTURE") {
    return "AVALARA_CERT_CAPTURE";
  } else if (value === "AMAZON") {
    return "AMAZON";
  } else if (value === "INFOR_CSI") {
    return "INFOR_CSI";
  } else if (value === "M_1") {
    return "M_1";
  } else if (value === "SERVICE_PRO") {
    return "SERVICE_PRO";
  } else if (value === "QUICK_BOOKS_ONLINE") {
    return "QUICK_BOOKS_ONLINE";
  } else if (value === "MAGENTO") {
    return "MAGENTO";
  } else if (value === "SHIPPING_EASY") {
    return "SHIPPING_EASY";
  } else if (value === "ASLAN_PORTAL") {
    return "ASLAN_PORTAL";
  } else if (value === "QUICK_BOOKS_DESKTOP") {
    return "QUICK_BOOKS_DESKTOP";
  } else if (value === "E_AUTOMATE") {
    return "E_AUTOMATE";
  } else if (value === "B_2_B") {
    return "B_2_B";
  } else {
    return "COMMERCE_HUB";
  }
}

function json_of_ItemsToUploadSettings(value) {
  if (value === "ALL") {
    return "ALL";
  } else if (value === "WEBCATEGORY") {
    return "WEBCATEGORY";
  } else if (value === "NONE") {
    return "NONE";
  } else {
    return "QUERY";
  }
}

function json_of_CustomersToUploadSettings(value) {
  if (value === "ALL") {
    return "ALL";
  } else if (value === "TAX_EXEMPT") {
    return "TAX_EXEMPT";
  } else if (value === "NONE") {
    return "NONE";
  } else {
    return "QUERY";
  }
}

function json_of_CustomerNumberForNewCustomersSettings(value) {
  if (value === "GUEST_CUSTOMER_NUMBER_WITH_SHIP_TO") {
    return "GUEST_CUSTOMER_NUMBER_WITH_SHIP_TO";
  } else if (value === "NEXT_CUSTOMER_NUMBER") {
    return "NEXT_CUSTOMER_NUMBER";
  } else {
    return "GUEST_CUSTOMER_NUMBER";
  }
}

function json_of_VerbosityLevels(value) {
  if (value === "ERRORS") {
    return "ERRORS";
  } else if (value === "NONE") {
    return "NONE";
  } else if (value === "ACTIVITY") {
    return "ACTIVITY";
  } else {
    return "ALL";
  }
}

function json_of_NotSetInputType$lessIntGraphType$great(value) {
  var v = value.value;
  return Js_dict.fromArray([[
                  "value",
                  v !== undefined ? v : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_ProcessCompletedOrders(value) {
  if (value === "POST") {
    return "POST";
  } else {
    return "NO";
  }
}

function json_of_ConnectorAccountInput(value) {
  var v = value.connectorType;
  var v$1 = value.enabled;
  var v$2 = value.description;
  var v$3 = value.stargateInstance;
  var v$4 = value.stargateUserName;
  var v$5 = value.stargatePassword;
  var v$6 = value.path;
  var v$7 = value.defaultCustomerTypeCode;
  var v$8 = value.defaultShipViaCode;
  var v$9 = value.defaultTaxSchedule;
  var v$10 = value.defaultTaxCode;
  var v$11 = value.defaultTaxLiabilityAccount;
  var v$12 = value.defaultTermsCode;
  var v$13 = value.defaultSalesPersonNo;
  var v$14 = value.location;
  var v$15 = value.apiKey;
  var v$16 = value.apiSecret;
  var v$17 = value.url;
  var v$18 = value.userName;
  var v$19 = value.password;
  var v$20 = value.itemsToUpload;
  var v$21 = value.customersToUpload;
  var v$22 = value.customerNumberForNewCustomers;
  var v$23 = value.notificationEmail;
  var v$24 = value.verbosityLevel;
  var v$25 = value.startingOrderNo;
  var v$26 = value.syncOrderLineUnitPrice;
  var v$27 = value.processCompletedOrder;
  var v$28 = value.epicorCompany;
  var v$29 = value.epicorFOBCode;
  return Js_dict.fromArray([
                [
                  "connectorType",
                  v !== undefined ? json_of_ConnectorType(v) : null
                ],
                [
                  "enabled",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "description",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "stargateInstance",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "stargateUserName",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "stargatePassword",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "path",
                  v$6 !== undefined ? v$6 : null
                ],
                [
                  "defaultCustomerTypeCode",
                  v$7 !== undefined ? v$7 : null
                ],
                [
                  "defaultShipViaCode",
                  v$8 !== undefined ? v$8 : null
                ],
                [
                  "defaultTaxSchedule",
                  v$9 !== undefined ? v$9 : null
                ],
                [
                  "defaultTaxCode",
                  v$10 !== undefined ? v$10 : null
                ],
                [
                  "defaultTaxLiabilityAccount",
                  v$11 !== undefined ? v$11 : null
                ],
                [
                  "defaultTermsCode",
                  v$12 !== undefined ? v$12 : null
                ],
                [
                  "defaultSalesPersonNo",
                  v$13 !== undefined ? v$13 : null
                ],
                [
                  "location",
                  v$14 !== undefined ? v$14 : null
                ],
                [
                  "apiKey",
                  v$15 !== undefined ? v$15 : null
                ],
                [
                  "apiSecret",
                  v$16 !== undefined ? v$16 : null
                ],
                [
                  "url",
                  v$17 !== undefined ? v$17 : null
                ],
                [
                  "userName",
                  v$18 !== undefined ? v$18 : null
                ],
                [
                  "password",
                  v$19 !== undefined ? v$19 : null
                ],
                [
                  "itemsToUpload",
                  v$20 !== undefined ? json_of_ItemsToUploadSettings(v$20) : null
                ],
                [
                  "customersToUpload",
                  v$21 !== undefined ? json_of_CustomersToUploadSettings(v$21) : null
                ],
                [
                  "customerNumberForNewCustomers",
                  v$22 !== undefined ? json_of_CustomerNumberForNewCustomersSettings(v$22) : null
                ],
                [
                  "notificationEmail",
                  v$23 !== undefined ? v$23 : null
                ],
                [
                  "verbosityLevel",
                  v$24 !== undefined ? json_of_VerbosityLevels(v$24) : null
                ],
                [
                  "startingOrderNo",
                  v$25 !== undefined ? json_of_NotSetInputType$lessIntGraphType$great(Caml_option.valFromOption(v$25)) : null
                ],
                [
                  "syncOrderLineUnitPrice",
                  v$26 !== undefined ? v$26 : null
                ],
                [
                  "processCompletedOrder",
                  v$27 !== undefined ? json_of_ProcessCompletedOrders(v$27) : null
                ],
                [
                  "epicorCompany",
                  v$28 !== undefined ? v$28 : null
                ],
                [
                  "epicorFOBCode",
                  v$29 !== undefined ? v$29 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(connectorAccountId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "connectorAccountId",
                    connectorAccountId !== undefined ? Caml_option.valFromOption(connectorAccountId) : null
                  ],
                  [
                    "data",
                    json_of_ConnectorAccountInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var connectorAccountId = variables.connectorAccountId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "connectorAccountId",
                    connectorAccountId !== undefined ? Caml_option.valFromOption(connectorAccountId) : null
                  ],
                  [
                    "data",
                    json_of_ConnectorAccountInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(connectorAccountId, data, param) {
  return Js_dict.fromArray([
                [
                  "connectorAccountId",
                  connectorAccountId !== undefined ? Caml_option.valFromOption(connectorAccountId) : null
                ],
                [
                  "data",
                  json_of_ConnectorAccountInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, connectorAccountId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "connectorAccountId",
                      connectorAccountId !== undefined ? Caml_option.valFromOption(connectorAccountId) : null
                    ],
                    [
                      "data",
                      json_of_ConnectorAccountInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Mutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_ConnectorType: json_of_ConnectorType,
  json_of_ItemsToUploadSettings: json_of_ItemsToUploadSettings,
  json_of_CustomersToUploadSettings: json_of_CustomersToUploadSettings,
  json_of_CustomerNumberForNewCustomersSettings: json_of_CustomerNumberForNewCustomersSettings,
  json_of_VerbosityLevels: json_of_VerbosityLevels,
  json_of_NotSetInputType$lessIntGraphType$great: json_of_NotSetInputType$lessIntGraphType$great,
  json_of_ProcessCompletedOrders: json_of_ProcessCompletedOrders,
  json_of_ConnectorAccountInput: json_of_ConnectorAccountInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "query Q($connectorAccountId: Guid!)  {\nconnectorAccount(connectorAccountId: $connectorAccountId)  {\nconnectorAccountId  \ndescription  \nenabled  \nconnectorType  \nstargateInstance  \nstargatePassword  \nstargateUserName  \npath  \ndefaultCustomerTypeCode  \ndefaultSalesPersonNo  \ndefaultShipViaCode  \ndefaultTaxCode  \ndefaultTaxLiabilityAccount  \ndefaultTaxSchedule  \ndefaultTermsCode  \nlocation  \napiKey  \napiSecret  \nurl  \nuserName  \npassword  \nitemsToUpload  \ncustomersToUpload  \ncustomerNumberForNewCustomers  \nnotificationEmail  \nverbosityLevel  \nstartingOrderNo  \nsyncOrderLineUnitPrice  \nprocessCompletedOrder  \nepicorCompany  \nepicorFOBCode  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "connectorAccount");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "connectorAccountId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field connectorAccountId on type ConnectorAccount is missing");
      }
      var value$8 = Js_dict.get(value$4, "description");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field description on type ConnectorAccount is missing");
      }
      var value$11 = Js_dict.get(value$4, "enabled");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeBoolean(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field enabled on type ConnectorAccount is missing");
      }
      var value$14 = Js_dict.get(value$4, "connectorType");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        if (value$16 !== undefined) {
          switch (value$16) {
            case "AMAZON" :
                tmp$4 = "AMAZON";
                break;
            case "ASLAN_PORTAL" :
                tmp$4 = "ASLAN_PORTAL";
                break;
            case "AVALARA_CERT_CAPTURE" :
                tmp$4 = "AVALARA_CERT_CAPTURE";
                break;
            case "B_2_B" :
                tmp$4 = "B_2_B";
                break;
            case "COMMERCE_HUB" :
                tmp$4 = "COMMERCE_HUB";
                break;
            case "EPICOR" :
                tmp$4 = "EPICOR";
                break;
            case "E_AUTOMATE" :
                tmp$4 = "E_AUTOMATE";
                break;
            case "INFOR_CSI" :
                tmp$4 = "INFOR_CSI";
                break;
            case "MACOLA_ES" :
                tmp$4 = "MACOLA_ES";
                break;
            case "MAGENTO" :
                tmp$4 = "MAGENTO";
                break;
            case "M_1" :
                tmp$4 = "M_1";
                break;
            case "NET_SUITE" :
                tmp$4 = "NET_SUITE";
                break;
            case "QUICK_BOOKS_DESKTOP" :
                tmp$4 = "QUICK_BOOKS_DESKTOP";
                break;
            case "QUICK_BOOKS_ONLINE" :
                tmp$4 = "QUICK_BOOKS_ONLINE";
                break;
            case "SAGE_100" :
                tmp$4 = "SAGE_100";
                break;
            case "SALSIFY" :
                tmp$4 = "SALSIFY";
                break;
            case "SERVICE_PRO" :
                tmp$4 = "SERVICE_PRO";
                break;
            case "SHIPPING_EASY" :
                tmp$4 = "SHIPPING_EASY";
                break;
            case "SHOPIFY" :
                tmp$4 = "SHOPIFY";
                break;
            case "WOO_COMMERCE" :
                tmp$4 = "WOO_COMMERCE";
                break;
            default:
              tmp$4 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for ConnectorType: " + value$16);
          }
        } else {
          tmp$4 = Js_exn.raiseError("graphql_ppx: Expected enum value for ConnectorType, got " + JSON.stringify(value$15));
        }
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field connectorType on type ConnectorAccount is missing");
      }
      var value$17 = Js_dict.get(value$4, "stargateInstance");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var value$19 = Js_json.decodeString(value$18);
        tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field stargateInstance on type ConnectorAccount is missing");
      }
      var value$20 = Js_dict.get(value$4, "stargatePassword");
      var tmp$6;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var value$22 = Js_json.decodeString(value$21);
        tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field stargatePassword on type ConnectorAccount is missing");
      }
      var value$23 = Js_dict.get(value$4, "stargateUserName");
      var tmp$7;
      if (value$23 !== undefined) {
        var value$24 = Caml_option.valFromOption(value$23);
        var value$25 = Js_json.decodeString(value$24);
        tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
      } else {
        tmp$7 = Js_exn.raiseError("graphql_ppx: Field stargateUserName on type ConnectorAccount is missing");
      }
      var value$26 = Js_dict.get(value$4, "path");
      var tmp$8;
      if (value$26 !== undefined) {
        var value$27 = Caml_option.valFromOption(value$26);
        var value$28 = Js_json.decodeString(value$27);
        tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Field path on type ConnectorAccount is missing");
      }
      var value$29 = Js_dict.get(value$4, "defaultCustomerTypeCode");
      var tmp$9;
      if (value$29 !== undefined) {
        var value$30 = Caml_option.valFromOption(value$29);
        var value$31 = Js_json.decodeString(value$30);
        tmp$9 = value$31 !== undefined ? value$31 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$30));
      } else {
        tmp$9 = Js_exn.raiseError("graphql_ppx: Field defaultCustomerTypeCode on type ConnectorAccount is missing");
      }
      var value$32 = Js_dict.get(value$4, "defaultSalesPersonNo");
      var tmp$10;
      if (value$32 !== undefined) {
        var value$33 = Caml_option.valFromOption(value$32);
        var value$34 = Js_json.decodeString(value$33);
        tmp$10 = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$33));
      } else {
        tmp$10 = Js_exn.raiseError("graphql_ppx: Field defaultSalesPersonNo on type ConnectorAccount is missing");
      }
      var value$35 = Js_dict.get(value$4, "defaultShipViaCode");
      var tmp$11;
      if (value$35 !== undefined) {
        var value$36 = Caml_option.valFromOption(value$35);
        var value$37 = Js_json.decodeString(value$36);
        tmp$11 = value$37 !== undefined ? value$37 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$36));
      } else {
        tmp$11 = Js_exn.raiseError("graphql_ppx: Field defaultShipViaCode on type ConnectorAccount is missing");
      }
      var value$38 = Js_dict.get(value$4, "defaultTaxCode");
      var tmp$12;
      if (value$38 !== undefined) {
        var value$39 = Caml_option.valFromOption(value$38);
        var value$40 = Js_json.decodeString(value$39);
        tmp$12 = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$39));
      } else {
        tmp$12 = Js_exn.raiseError("graphql_ppx: Field defaultTaxCode on type ConnectorAccount is missing");
      }
      var value$41 = Js_dict.get(value$4, "defaultTaxLiabilityAccount");
      var tmp$13;
      if (value$41 !== undefined) {
        var value$42 = Caml_option.valFromOption(value$41);
        var value$43 = Js_json.decodeString(value$42);
        tmp$13 = value$43 !== undefined ? value$43 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$42));
      } else {
        tmp$13 = Js_exn.raiseError("graphql_ppx: Field defaultTaxLiabilityAccount on type ConnectorAccount is missing");
      }
      var value$44 = Js_dict.get(value$4, "defaultTaxSchedule");
      var tmp$14;
      if (value$44 !== undefined) {
        var value$45 = Caml_option.valFromOption(value$44);
        var value$46 = Js_json.decodeString(value$45);
        tmp$14 = value$46 !== undefined ? value$46 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$45));
      } else {
        tmp$14 = Js_exn.raiseError("graphql_ppx: Field defaultTaxSchedule on type ConnectorAccount is missing");
      }
      var value$47 = Js_dict.get(value$4, "defaultTermsCode");
      var tmp$15;
      if (value$47 !== undefined) {
        var value$48 = Caml_option.valFromOption(value$47);
        var value$49 = Js_json.decodeString(value$48);
        tmp$15 = value$49 !== undefined ? value$49 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$48));
      } else {
        tmp$15 = Js_exn.raiseError("graphql_ppx: Field defaultTermsCode on type ConnectorAccount is missing");
      }
      var value$50 = Js_dict.get(value$4, "location");
      var tmp$16;
      if (value$50 !== undefined) {
        var value$51 = Caml_option.valFromOption(value$50);
        var value$52 = Js_json.decodeString(value$51);
        tmp$16 = value$52 !== undefined ? value$52 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$51));
      } else {
        tmp$16 = Js_exn.raiseError("graphql_ppx: Field location on type ConnectorAccount is missing");
      }
      var value$53 = Js_dict.get(value$4, "apiKey");
      var tmp$17;
      if (value$53 !== undefined) {
        var value$54 = Caml_option.valFromOption(value$53);
        var value$55 = Js_json.decodeString(value$54);
        tmp$17 = value$55 !== undefined ? value$55 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$54));
      } else {
        tmp$17 = Js_exn.raiseError("graphql_ppx: Field apiKey on type ConnectorAccount is missing");
      }
      var value$56 = Js_dict.get(value$4, "apiSecret");
      var tmp$18;
      if (value$56 !== undefined) {
        var value$57 = Caml_option.valFromOption(value$56);
        var value$58 = Js_json.decodeString(value$57);
        tmp$18 = value$58 !== undefined ? value$58 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$57));
      } else {
        tmp$18 = Js_exn.raiseError("graphql_ppx: Field apiSecret on type ConnectorAccount is missing");
      }
      var value$59 = Js_dict.get(value$4, "url");
      var tmp$19;
      if (value$59 !== undefined) {
        var value$60 = Caml_option.valFromOption(value$59);
        var value$61 = Js_json.decodeString(value$60);
        tmp$19 = value$61 !== undefined ? value$61 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$60));
      } else {
        tmp$19 = Js_exn.raiseError("graphql_ppx: Field url on type ConnectorAccount is missing");
      }
      var value$62 = Js_dict.get(value$4, "userName");
      var tmp$20;
      if (value$62 !== undefined) {
        var value$63 = Caml_option.valFromOption(value$62);
        var value$64 = Js_json.decodeString(value$63);
        tmp$20 = value$64 !== undefined ? value$64 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$63));
      } else {
        tmp$20 = Js_exn.raiseError("graphql_ppx: Field userName on type ConnectorAccount is missing");
      }
      var value$65 = Js_dict.get(value$4, "password");
      var tmp$21;
      if (value$65 !== undefined) {
        var value$66 = Caml_option.valFromOption(value$65);
        var value$67 = Js_json.decodeString(value$66);
        tmp$21 = value$67 !== undefined ? value$67 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$66));
      } else {
        tmp$21 = Js_exn.raiseError("graphql_ppx: Field password on type ConnectorAccount is missing");
      }
      var value$68 = Js_dict.get(value$4, "itemsToUpload");
      var tmp$22;
      if (value$68 !== undefined) {
        var value$69 = Caml_option.valFromOption(value$68);
        var value$70 = Js_json.decodeString(value$69);
        if (value$70 !== undefined) {
          switch (value$70) {
            case "ALL" :
                tmp$22 = "ALL";
                break;
            case "NONE" :
                tmp$22 = "NONE";
                break;
            case "QUERY" :
                tmp$22 = "QUERY";
                break;
            case "WEBCATEGORY" :
                tmp$22 = "WEBCATEGORY";
                break;
            default:
              tmp$22 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for ItemsToUploadSettings: " + value$70);
          }
        } else {
          tmp$22 = Js_exn.raiseError("graphql_ppx: Expected enum value for ItemsToUploadSettings, got " + JSON.stringify(value$69));
        }
      } else {
        tmp$22 = Js_exn.raiseError("graphql_ppx: Field itemsToUpload on type ConnectorAccount is missing");
      }
      var value$71 = Js_dict.get(value$4, "customersToUpload");
      var tmp$23;
      if (value$71 !== undefined) {
        var value$72 = Caml_option.valFromOption(value$71);
        var value$73 = Js_json.decodeString(value$72);
        if (value$73 !== undefined) {
          switch (value$73) {
            case "ALL" :
                tmp$23 = "ALL";
                break;
            case "NONE" :
                tmp$23 = "NONE";
                break;
            case "QUERY" :
                tmp$23 = "QUERY";
                break;
            case "TAX_EXEMPT" :
                tmp$23 = "TAX_EXEMPT";
                break;
            default:
              tmp$23 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for CustomersToUploadSettings: " + value$73);
          }
        } else {
          tmp$23 = Js_exn.raiseError("graphql_ppx: Expected enum value for CustomersToUploadSettings, got " + JSON.stringify(value$72));
        }
      } else {
        tmp$23 = Js_exn.raiseError("graphql_ppx: Field customersToUpload on type ConnectorAccount is missing");
      }
      var value$74 = Js_dict.get(value$4, "customerNumberForNewCustomers");
      var tmp$24;
      if (value$74 !== undefined) {
        var value$75 = Caml_option.valFromOption(value$74);
        var value$76 = Js_json.decodeString(value$75);
        if (value$76 !== undefined) {
          switch (value$76) {
            case "GUEST_CUSTOMER_NUMBER" :
                tmp$24 = "GUEST_CUSTOMER_NUMBER";
                break;
            case "GUEST_CUSTOMER_NUMBER_WITH_SHIP_TO" :
                tmp$24 = "GUEST_CUSTOMER_NUMBER_WITH_SHIP_TO";
                break;
            case "NEXT_CUSTOMER_NUMBER" :
                tmp$24 = "NEXT_CUSTOMER_NUMBER";
                break;
            default:
              tmp$24 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for CustomerNumberForNewCustomersSettings: " + value$76);
          }
        } else {
          tmp$24 = Js_exn.raiseError("graphql_ppx: Expected enum value for CustomerNumberForNewCustomersSettings, got " + JSON.stringify(value$75));
        }
      } else {
        tmp$24 = Js_exn.raiseError("graphql_ppx: Field customerNumberForNewCustomers on type ConnectorAccount is missing");
      }
      var value$77 = Js_dict.get(value$4, "notificationEmail");
      var tmp$25;
      if (value$77 !== undefined) {
        var value$78 = Caml_option.valFromOption(value$77);
        var value$79 = Js_json.decodeString(value$78);
        tmp$25 = value$79 !== undefined ? value$79 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$78));
      } else {
        tmp$25 = Js_exn.raiseError("graphql_ppx: Field notificationEmail on type ConnectorAccount is missing");
      }
      var value$80 = Js_dict.get(value$4, "verbosityLevel");
      var tmp$26;
      if (value$80 !== undefined) {
        var value$81 = Caml_option.valFromOption(value$80);
        var value$82 = Js_json.decodeString(value$81);
        if (value$82 !== undefined) {
          switch (value$82) {
            case "ACTIVITY" :
                tmp$26 = "ACTIVITY";
                break;
            case "ALL" :
                tmp$26 = "ALL";
                break;
            case "ERRORS" :
                tmp$26 = "ERRORS";
                break;
            case "NONE" :
                tmp$26 = "NONE";
                break;
            default:
              tmp$26 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for VerbosityLevels: " + value$82);
          }
        } else {
          tmp$26 = Js_exn.raiseError("graphql_ppx: Expected enum value for VerbosityLevels, got " + JSON.stringify(value$81));
        }
      } else {
        tmp$26 = Js_exn.raiseError("graphql_ppx: Field verbosityLevel on type ConnectorAccount is missing");
      }
      var value$83 = Js_dict.get(value$4, "startingOrderNo");
      var tmp$27;
      if (value$83 !== undefined) {
        var value$84 = Caml_option.valFromOption(value$83);
        var match$1 = Js_json.decodeNull(value$84);
        if (match$1 !== undefined) {
          tmp$27 = undefined;
        } else {
          var value$85 = Js_json.decodeNumber(value$84);
          tmp$27 = value$85 !== undefined ? value$85 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$84));
        }
      } else {
        tmp$27 = undefined;
      }
      var value$86 = Js_dict.get(value$4, "syncOrderLineUnitPrice");
      var tmp$28;
      if (value$86 !== undefined) {
        var value$87 = Caml_option.valFromOption(value$86);
        var value$88 = Js_json.decodeBoolean(value$87);
        tmp$28 = value$88 !== undefined ? value$88 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$87));
      } else {
        tmp$28 = Js_exn.raiseError("graphql_ppx: Field syncOrderLineUnitPrice on type ConnectorAccount is missing");
      }
      var value$89 = Js_dict.get(value$4, "processCompletedOrder");
      var tmp$29;
      if (value$89 !== undefined) {
        var value$90 = Caml_option.valFromOption(value$89);
        var value$91 = Js_json.decodeString(value$90);
        if (value$91 !== undefined) {
          switch (value$91) {
            case "NO" :
                tmp$29 = "NO";
                break;
            case "POST" :
                tmp$29 = "POST";
                break;
            default:
              tmp$29 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for ProcessCompletedOrders: " + value$91);
          }
        } else {
          tmp$29 = Js_exn.raiseError("graphql_ppx: Expected enum value for ProcessCompletedOrders, got " + JSON.stringify(value$90));
        }
      } else {
        tmp$29 = Js_exn.raiseError("graphql_ppx: Field processCompletedOrder on type ConnectorAccount is missing");
      }
      var value$92 = Js_dict.get(value$4, "epicorCompany");
      var tmp$30;
      if (value$92 !== undefined) {
        var value$93 = Caml_option.valFromOption(value$92);
        var value$94 = Js_json.decodeString(value$93);
        tmp$30 = value$94 !== undefined ? value$94 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$93));
      } else {
        tmp$30 = Js_exn.raiseError("graphql_ppx: Field epicorCompany on type ConnectorAccount is missing");
      }
      var value$95 = Js_dict.get(value$4, "epicorFOBCode");
      var tmp$31;
      if (value$95 !== undefined) {
        var value$96 = Caml_option.valFromOption(value$95);
        var value$97 = Js_json.decodeString(value$96);
        tmp$31 = value$97 !== undefined ? value$97 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$96));
      } else {
        tmp$31 = Js_exn.raiseError("graphql_ppx: Field epicorFOBCode on type ConnectorAccount is missing");
      }
      tmp = {
        connectorAccountId: tmp$1,
        description: tmp$2,
        enabled: tmp$3,
        connectorType: tmp$4,
        stargateInstance: tmp$5,
        stargatePassword: tmp$6,
        stargateUserName: tmp$7,
        path: tmp$8,
        defaultCustomerTypeCode: tmp$9,
        defaultSalesPersonNo: tmp$10,
        defaultShipViaCode: tmp$11,
        defaultTaxCode: tmp$12,
        defaultTaxLiabilityAccount: tmp$13,
        defaultTaxSchedule: tmp$14,
        defaultTermsCode: tmp$15,
        location: tmp$16,
        apiKey: tmp$17,
        apiSecret: tmp$18,
        url: tmp$19,
        userName: tmp$20,
        password: tmp$21,
        itemsToUpload: tmp$22,
        customersToUpload: tmp$23,
        customerNumberForNewCustomers: tmp$24,
        notificationEmail: tmp$25,
        verbosityLevel: tmp$26,
        startingOrderNo: tmp$27,
        syncOrderLineUnitPrice: tmp$28,
        processCompletedOrder: tmp$29,
        epicorCompany: tmp$30,
        epicorFOBCode: tmp$31
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          connectorAccount: tmp
        };
}

function make$2(connectorAccountId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "connectorAccountId",
                    connectorAccountId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var connectorAccountId = variables.connectorAccountId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "connectorAccountId",
                    connectorAccountId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(connectorAccountId, param) {
  return Js_dict.fromArray([[
                  "connectorAccountId",
                  connectorAccountId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, connectorAccountId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "connectorAccountId",
                      connectorAccountId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var Query = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function doneRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/connector-accounts"
        };
}

function breadcrumbs(context) {
  return [{
            href: {
              NAME: "Enterprise",
              VAL: "/connector-accounts"
            },
            content: "Connector Accounts"
          }];
}

function newRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/connector-accounts/new"
        };
}

var RouteArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute
};

var newState = {
  description: "",
  enabled: false,
  connectorType: "SAGE_100",
  stargateInstance: "",
  stargatePassword: "",
  stargateUserName: "",
  path: "",
  defaultCustomerTypeCode: "",
  defaultSalesPersonNo: "",
  defaultShipViaCode: "",
  defaultTaxCode: "",
  defaultTaxLiabilityAccount: "",
  defaultTaxSchedule: "",
  defaultTermsCode: "",
  location: "",
  apiKey: "",
  apiSecret: "",
  url: "",
  userName: "",
  password: "",
  itemsToUpload: "NONE",
  customersToUpload: "NONE",
  customerNumberForNewCustomers: "NEXT_CUSTOMER_NUMBER",
  notificationEmail: "",
  verbosityLevel: "ALL",
  startingOrderNo: undefined,
  startingOrderNoValid: true,
  syncOrderLineUnitPrice: false,
  processCompletedOrder: "NO",
  epicorCompany: "",
  epicorFOBCode: ""
};

function reducer(context, state, action) {
  switch (action.TAG | 0) {
    case /* SetShortDescription */0 :
        var newrecord = Caml_obj.caml_obj_dup(state);
        newrecord.description = action._0;
        return newrecord;
    case /* SetConnectorType */1 :
        var newrecord$1 = Caml_obj.caml_obj_dup(state);
        newrecord$1.connectorType = action._0;
        return newrecord$1;
    case /* SetEnabled */2 :
        var newrecord$2 = Caml_obj.caml_obj_dup(state);
        newrecord$2.enabled = action._0;
        return newrecord$2;
    case /* SetStargateInstance */3 :
        var newrecord$3 = Caml_obj.caml_obj_dup(state);
        newrecord$3.stargateInstance = action._0;
        return newrecord$3;
    case /* SetStargatePassword */4 :
        var newrecord$4 = Caml_obj.caml_obj_dup(state);
        newrecord$4.stargatePassword = action._0;
        return newrecord$4;
    case /* SetStargateUserName */5 :
        var newrecord$5 = Caml_obj.caml_obj_dup(state);
        newrecord$5.stargateUserName = action._0;
        return newrecord$5;
    case /* SetPath */6 :
        var newrecord$6 = Caml_obj.caml_obj_dup(state);
        newrecord$6.path = action._0;
        return newrecord$6;
    case /* SetDefaultCustomerTypeCode */7 :
        var newrecord$7 = Caml_obj.caml_obj_dup(state);
        newrecord$7.defaultCustomerTypeCode = action._0;
        return newrecord$7;
    case /* SetDefaultSalesPersonNo */8 :
        var newrecord$8 = Caml_obj.caml_obj_dup(state);
        newrecord$8.defaultSalesPersonNo = action._0;
        return newrecord$8;
    case /* SetDefaultShipViaCode */9 :
        var newrecord$9 = Caml_obj.caml_obj_dup(state);
        newrecord$9.defaultShipViaCode = action._0;
        return newrecord$9;
    case /* SetDefaultTaxCode */10 :
        var newrecord$10 = Caml_obj.caml_obj_dup(state);
        newrecord$10.defaultTaxCode = action._0;
        return newrecord$10;
    case /* SetDefaultTaxLiabilityAccount */11 :
        var newrecord$11 = Caml_obj.caml_obj_dup(state);
        newrecord$11.defaultTaxLiabilityAccount = action._0;
        return newrecord$11;
    case /* SetDefaultTaxSchedule */12 :
        var newrecord$12 = Caml_obj.caml_obj_dup(state);
        newrecord$12.defaultTaxSchedule = action._0;
        return newrecord$12;
    case /* SetDefaultTermsCode */13 :
        var newrecord$13 = Caml_obj.caml_obj_dup(state);
        newrecord$13.defaultTermsCode = action._0;
        return newrecord$13;
    case /* SetLocation */14 :
        var newrecord$14 = Caml_obj.caml_obj_dup(state);
        newrecord$14.location = action._0;
        return newrecord$14;
    case /* SetApiKey */15 :
        var newrecord$15 = Caml_obj.caml_obj_dup(state);
        newrecord$15.apiKey = action._0;
        return newrecord$15;
    case /* SetApiSecret */16 :
        var newrecord$16 = Caml_obj.caml_obj_dup(state);
        newrecord$16.apiSecret = action._0;
        return newrecord$16;
    case /* SetUrl */17 :
        var newrecord$17 = Caml_obj.caml_obj_dup(state);
        newrecord$17.url = action._0;
        return newrecord$17;
    case /* SetUserName */18 :
        var newrecord$18 = Caml_obj.caml_obj_dup(state);
        newrecord$18.userName = action._0;
        return newrecord$18;
    case /* SetPassword */19 :
        var newrecord$19 = Caml_obj.caml_obj_dup(state);
        newrecord$19.password = action._0;
        return newrecord$19;
    case /* SetItemsToUpload */20 :
        var newrecord$20 = Caml_obj.caml_obj_dup(state);
        newrecord$20.itemsToUpload = action._0;
        return newrecord$20;
    case /* SetCustomersToUpload */21 :
        var newrecord$21 = Caml_obj.caml_obj_dup(state);
        newrecord$21.customersToUpload = action._0;
        return newrecord$21;
    case /* SetCustomerNumberForNewCustomers */22 :
        var newrecord$22 = Caml_obj.caml_obj_dup(state);
        newrecord$22.customerNumberForNewCustomers = action._0;
        return newrecord$22;
    case /* SetNotificationEmail */23 :
        var newrecord$23 = Caml_obj.caml_obj_dup(state);
        newrecord$23.notificationEmail = action._0;
        return newrecord$23;
    case /* SetVerbosityLevel */24 :
        var newrecord$24 = Caml_obj.caml_obj_dup(state);
        newrecord$24.verbosityLevel = action._0;
        return newrecord$24;
    case /* SetStartingOrderNo */25 :
        var startingOrderNo = action._0;
        if (startingOrderNo !== undefined) {
          if (startingOrderNo.NAME === "Int") {
            var newrecord$25 = Caml_obj.caml_obj_dup(state);
            newrecord$25.startingOrderNoValid = true;
            newrecord$25.startingOrderNo = startingOrderNo.VAL;
            return newrecord$25;
          }
          var newrecord$26 = Caml_obj.caml_obj_dup(state);
          newrecord$26.startingOrderNoValid = false;
          return newrecord$26;
        }
        var newrecord$27 = Caml_obj.caml_obj_dup(state);
        newrecord$27.startingOrderNoValid = true;
        newrecord$27.startingOrderNo = undefined;
        return newrecord$27;
    case /* SetSyncOrderLineUnitPrice */26 :
        var newrecord$28 = Caml_obj.caml_obj_dup(state);
        newrecord$28.syncOrderLineUnitPrice = action._0;
        return newrecord$28;
    case /* SetProcessCompletedOrder */27 :
        var newrecord$29 = Caml_obj.caml_obj_dup(state);
        newrecord$29.processCompletedOrder = action._0;
        return newrecord$29;
    case /* SetEpicorCompany */28 :
        var newrecord$30 = Caml_obj.caml_obj_dup(state);
        newrecord$30.epicorCompany = action._0;
        return newrecord$30;
    case /* SetEpicorFOBCode */29 :
        var newrecord$31 = Caml_obj.caml_obj_dup(state);
        newrecord$31.epicorFOBCode = action._0;
        return newrecord$31;
    
  }
}

function getName(context, state) {
  return state.description;
}

function selfRoute(context, id) {
  return {
          NAME: "Enterprise",
          VAL: "/connector-accounts/" + id
        };
}

function makeMutation(context, connectorAccountId, state) {
  var value = function (x) {
    return {
            value: x
          };
  };
  return make$1(Belt_Option.map(connectorAccountId, (function (prim) {
                    return prim;
                  })), {
              description: state.description,
              connectorType: state.connectorType,
              defaultCustomerTypeCode: state.defaultCustomerTypeCode,
              defaultSalesPersonNo: state.defaultSalesPersonNo,
              defaultShipViaCode: state.defaultShipViaCode,
              defaultTaxCode: state.defaultTaxCode,
              defaultTaxLiabilityAccount: state.defaultTaxLiabilityAccount,
              defaultTaxSchedule: state.defaultTaxSchedule,
              defaultTermsCode: state.defaultTermsCode,
              enabled: state.enabled,
              stargateInstance: state.stargateInstance,
              stargatePassword: state.stargatePassword,
              stargateUserName: state.stargateUserName,
              path: state.path,
              location: state.location,
              apiKey: state.apiKey,
              apiSecret: state.apiSecret,
              url: state.url,
              userName: state.userName,
              password: state.password,
              itemsToUpload: state.itemsToUpload,
              customersToUpload: state.customersToUpload,
              customerNumberForNewCustomers: state.customerNumberForNewCustomers,
              notificationEmail: state.notificationEmail,
              verbosityLevel: state.verbosityLevel,
              processCompletedOrder: state.processCompletedOrder,
              startingOrderNo: value(state.startingOrderNo),
              syncOrderLineUnitPrice: state.syncOrderLineUnitPrice,
              epicorCompany: state.epicorCompany,
              epicorFOBCode: state.epicorFOBCode
            }, undefined);
}

function mapMutation(context, result) {
  return Belt_Option.map(Belt_Option.flatMap(result.connectorAccount, (function (connectorAccount) {
                    return connectorAccount.success;
                  })), (function (success) {
                return success.connectorAccountId;
              }));
}

function makeQuery(context, connectorAccountId) {
  return make$2(connectorAccountId, undefined);
}

function mapQuery(context, result) {
  return Belt_Option.map(result.connectorAccount, (function (connectorAccount) {
                return {
                        description: connectorAccount.description,
                        enabled: connectorAccount.enabled,
                        connectorType: connectorAccount.connectorType,
                        stargateInstance: connectorAccount.stargateInstance,
                        stargatePassword: connectorAccount.stargatePassword,
                        stargateUserName: connectorAccount.stargateUserName,
                        path: connectorAccount.path,
                        defaultCustomerTypeCode: connectorAccount.defaultCustomerTypeCode,
                        defaultSalesPersonNo: connectorAccount.defaultSalesPersonNo,
                        defaultShipViaCode: connectorAccount.defaultShipViaCode,
                        defaultTaxCode: connectorAccount.defaultTaxCode,
                        defaultTaxLiabilityAccount: connectorAccount.defaultTaxLiabilityAccount,
                        defaultTaxSchedule: connectorAccount.defaultTaxSchedule,
                        defaultTermsCode: connectorAccount.defaultTermsCode,
                        location: connectorAccount.location,
                        apiKey: connectorAccount.apiKey,
                        apiSecret: connectorAccount.apiSecret,
                        url: connectorAccount.url,
                        userName: connectorAccount.userName,
                        password: connectorAccount.password,
                        itemsToUpload: connectorAccount.itemsToUpload,
                        customersToUpload: connectorAccount.customersToUpload,
                        customerNumberForNewCustomers: connectorAccount.customerNumberForNewCustomers,
                        notificationEmail: connectorAccount.notificationEmail,
                        verbosityLevel: connectorAccount.verbosityLevel,
                        startingOrderNo: connectorAccount.startingOrderNo,
                        startingOrderNoValid: true,
                        syncOrderLineUnitPrice: connectorAccount.syncOrderLineUnitPrice,
                        processCompletedOrder: connectorAccount.processCompletedOrder,
                        epicorCompany: connectorAccount.epicorCompany,
                        epicorFOBCode: connectorAccount.epicorFOBCode
                      };
              }));
}

function makeDelete(context, connectorAccountId) {
  return make(connectorAccountId, undefined);
}

function saveValidate(context, state) {
  return state.description.trim() !== "";
}

function mainActions(context, id, state, dispatch) {
  return [];
}

function mainContent(context, id, state, dispatch) {
  var terminus = React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
            label: "Terminus Instance",
            value: state.stargateInstance,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetStargateInstance */3,
                            _0: value
                          });
              })
          }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
            label: "Terminus Password",
            value: state.stargatePassword,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetStargatePassword */4,
                            _0: value
                          });
              })
          }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
            label: "Terminus User Name",
            value: state.stargateUserName,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetStargateUserName */5,
                            _0: value
                          });
              })
          }));
  var $$location = React.createElement(LocationSelect$BirddogsoftwareFrontend.make, {
        label: "Location",
        fullWidth: true,
        value: state.location,
        onChange: (function (value) {
            return Curry._1(dispatch, {
                        TAG: /* SetLocation */14,
                        _0: value
                      });
          })
      });
  var processCompletedOrder = React.createElement(ProcessCompletedOrderSelect$BirddogsoftwareFrontend.Required.make, {
        label: "Process Completed Orders",
        fullWidth: true,
        value: state.processCompletedOrder,
        onChange: (function (value) {
            return Curry._1(dispatch, {
                        TAG: /* SetProcessCompletedOrder */27,
                        _0: value
                      });
          })
      });
  var defaults = React.createElement(React.Fragment, undefined, React.createElement(CustomerTypeSelect$BirddogsoftwareFrontend.make, {
            label: "Default Customer Type",
            fullWidth: true,
            value: state.defaultCustomerTypeCode,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultCustomerTypeCode */7,
                            _0: value
                          });
              })
          }), React.createElement(SalesPersonSelect$BirddogsoftwareFrontend.make, {
            label: "Default Sales Person",
            fullWidth: true,
            value: state.defaultSalesPersonNo,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultSalesPersonNo */8,
                            _0: value
                          });
              }),
            blankText: "Select a sales person..."
          }), React.createElement(ShipViaSelect$BirddogsoftwareFrontend.make, {
            label: "Default Ship Via",
            fullWidth: true,
            value: state.defaultShipViaCode,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultShipViaCode */9,
                            _0: value
                          });
              }),
            blankText: "Select a ship via..."
          }), React.createElement(TaxCodeSelect$BirddogsoftwareFrontend.make, {
            label: "Default Tax Code",
            fullWidth: true,
            value: state.defaultTaxCode,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultTaxCode */10,
                            _0: value
                          });
              }),
            blankText: "Select a tax code..."
          }), React.createElement(GLAccountSelect$BirddogsoftwareFrontend.make, {
            label: "Default Tax Liability Account",
            fullWidth: true,
            value: state.defaultTaxLiabilityAccount,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultTaxLiabilityAccount */11,
                            _0: value
                          });
              }),
            blankText: "Select a tax liability account..."
          }), React.createElement(TaxScheduleSelect$BirddogsoftwareFrontend.make, {
            label: "Default Tax Schedule",
            fullWidth: true,
            value: state.defaultTaxSchedule,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultTaxSchedule */12,
                            _0: value
                          });
              }),
            blankText: "Select a tax schedule..."
          }), React.createElement(TermSelect$BirddogsoftwareFrontend.make, {
            label: "Default Terms Code",
            fullWidth: true,
            value: state.defaultTermsCode,
            onChange: (function (value) {
                return Curry._1(dispatch, {
                            TAG: /* SetDefaultTermsCode */13,
                            _0: value
                          });
              }),
            blankText: "Select a terms..."
          }));
  var match = state.connectorType;
  return React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Short Description",
                  value: state.description,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetShortDescription */0,
                                  _0: value
                                });
                    })
                }), React.createElement(ConnectorTypeSelect$BirddogsoftwareFrontend.Required.make, {
                  label: "Connector Type",
                  fullWidth: true,
                  value: state.connectorType,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetConnectorType */1,
                                  _0: value
                                });
                    })
                }), React.createElement(Core.FormControlLabel, MaterialUi_FormControlLabel.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(state.enabled, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetEnabled */2,
                                                _0: !state.enabled
                                              });
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, "Enabled", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Notification Email",
                  value: state.notificationEmail,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetNotificationEmail */23,
                                  _0: value
                                });
                    })
                }), React.createElement(ConnectorAccountVerbosityLevelSelect$BirddogsoftwareFrontend.Required.make, {
                  label: "Verbosity Level",
                  fullWidth: true,
                  value: state.verbosityLevel,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetVerbosityLevel */24,
                                  _0: value
                                });
                    })
                }), match === "EPICOR" ? React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Epicor Url",
                        value: state.url,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetUrl */17,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Epicor User",
                        value: state.userName,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetUserName */18,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Epicor Password",
                        value: state.password,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetPassword */19,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Epicor API Key",
                        value: state.apiKey,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetApiKey */15,
                                        _0: value
                                      });
                          })
                      }), defaults, $$location, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Epicor Company",
                        value: state.epicorCompany,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetEpicorCompany */28,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                        label: "Epicor FOB Code",
                        value: state.epicorFOBCode,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetEpicorFOBCode */29,
                                        _0: value
                                      });
                          })
                      })) : (
                match === "SERVICE_PRO" ? React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                            label: "API Key",
                            value: state.apiKey,
                            onChange: (function (value) {
                                return Curry._1(dispatch, {
                                            TAG: /* SetApiKey */15,
                                            _0: value
                                          });
                              })
                          }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                            label: "Api Secret",
                            value: state.apiSecret,
                            onChange: (function (value) {
                                return Curry._1(dispatch, {
                                            TAG: /* SetApiSecret */16,
                                            _0: value
                                          });
                              })
                          }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                            label: "URL",
                            value: state.url,
                            onChange: (function (value) {
                                return Curry._1(dispatch, {
                                            TAG: /* SetUrl */17,
                                            _0: value
                                          });
                              })
                          }), defaults, $$location, React.createElement(ItemsToUploadSelect$BirddogsoftwareFrontend.Required.make, {
                            label: "Items To Upload",
                            fullWidth: true,
                            value: state.itemsToUpload,
                            onChange: (function (value) {
                                return Curry._1(dispatch, {
                                            TAG: /* SetItemsToUpload */20,
                                            _0: value
                                          });
                              })
                          }), React.createElement(CustomersToUploadSelect$BirddogsoftwareFrontend.Required.make, {
                            label: "Customers To Upload",
                            fullWidth: true,
                            value: state.customersToUpload,
                            onChange: (function (value) {
                                return Curry._1(dispatch, {
                                            TAG: /* SetCustomersToUpload */21,
                                            _0: value
                                          });
                              })
                          }), React.createElement(IntField$BirddogsoftwareFrontend.make, {
                            label: "Starting Order Number",
                            fullWidth: true,
                            value: Belt_Option.map(state.startingOrderNo, (function (x) {
                                    return {
                                            NAME: "Int",
                                            VAL: x
                                          };
                                  })),
                            onChange: (function (value) {
                                return Curry._1(dispatch, {
                                            TAG: /* SetStartingOrderNo */25,
                                            _0: value
                                          });
                              })
                          }), processCompletedOrder, React.createElement("div", undefined, React.createElement(Core.FormControlLabel, MaterialUi_FormControlLabel.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(state.syncOrderLineUnitPrice, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                                                  return Curry._1(dispatch, {
                                                              TAG: /* SetSyncOrderLineUnitPrice */26,
                                                              _0: !state.syncOrderLineUnitPrice
                                                            });
                                                }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, "Sync Order Line Unit Price", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))) : (
                    match === "QUICK_BOOKS_DESKTOP" ? React.createElement(React.Fragment, undefined, terminus, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                                label: "Path",
                                value: state.path,
                                onChange: (function (value) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetPath */6,
                                                _0: value
                                              });
                                  })
                              }), defaults, $$location) : React.createElement("div", undefined, "Edit is currently unsupported")
                  )
              ));
}

var FormArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  newState: newState,
  reducer: reducer,
  doneRoute: doneRoute,
  getName: getName,
  selfRoute: selfRoute,
  makeMutation: makeMutation,
  mapMutation: mapMutation,
  stayAfterSaveNew: true,
  makeQuery: makeQuery,
  mapQuery: mapQuery,
  makeDelete: makeDelete,
  saveValidate: saveValidate,
  mainActions: mainActions,
  mainContent: mainContent,
  secondaryContent: undefined
};

var Form = Stricture$BirddogsoftwareFrontend.Form.Make({
      breadcrumbs: breadcrumbs,
      newRoute: newRoute,
      newState: newState,
      reducer: reducer,
      doneRoute: doneRoute,
      getName: getName,
      selfRoute: selfRoute,
      makeMutation: makeMutation,
      mapMutation: mapMutation,
      makeQuery: makeQuery,
      mapQuery: mapQuery,
      makeDelete: makeDelete,
      saveValidate: saveValidate,
      mainContent: mainContent,
      mainActions: mainActions,
      secondaryContent: undefined,
      stayAfterSaveNew: true
    });

var ppx_printed_query$3 = "query Q  {\nconnectorAccountList  {\nconnectorAccountId  \ndescription  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "connectorAccountList");
  return {
          connectorAccountList: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "connectorAccountId");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field connectorAccountId on type ConnectorAccount is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "description");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field description on type ConnectorAccount is missing");
                  }
                  return {
                          connectorAccountId: tmp,
                          description: tmp$1
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field connectorAccountList on type Query is missing")
        };
}

function make$3(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeWithVariables$3(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeVariables$3(param) {
  return null;
}

function definition_2$3(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var ListQuery = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function primaryColumnTitle(context) {
  return "Description";
}

function getPrimaryColumnContent(context, x) {
  return x.description;
}

function getPrimaryColumnRoute(context, x) {
  return {
          NAME: "Enterprise",
          VAL: "/connector-accounts/" + x.connectorAccountId
        };
}

function queryArgsReducer(a, b) {
  
}

function queryArgsElement(state, dispatch) {
  return React.createElement(React.Fragment, undefined);
}

function makeQuery$1(context, queryArgs) {
  return make$3(undefined);
}

function mapQuery$1(context, result) {
  return Belt_Array.map(result.connectorAccountList, (function (connectorAccount) {
                return {
                        description: connectorAccount.description,
                        connectorAccountId: connectorAccount.connectorAccountId
                      };
              }));
}

var columns = [];

var ListArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  primaryColumnTitle: primaryColumnTitle,
  getPrimaryColumnContent: getPrimaryColumnContent,
  getPrimaryColumnRoute: getPrimaryColumnRoute,
  queryArgsDefault: undefined,
  queryArgsReducer: queryArgsReducer,
  queryArgsElement: queryArgsElement,
  makeQuery: makeQuery$1,
  mapQuery: mapQuery$1,
  columns: columns
};

var List = Stricture$BirddogsoftwareFrontend.List.Make(ListArg);

exports.DeleteMutation = DeleteMutation;
exports.Mutation = Mutation;
exports.Query = Query;
exports.doneRoute = doneRoute;
exports.RouteArg = RouteArg;
exports.FormArg = FormArg;
exports.Form = Form;
exports.ListQuery = ListQuery;
exports.ListArg = ListArg;
exports.List = List;
/* Form Not a pure module */
