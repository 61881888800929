'use strict';


var numberFormatUSD = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });

var formatCurrencyUSD = numberFormatUSD.format;

exports.numberFormatUSD = numberFormatUSD;
exports.formatCurrencyUSD = formatCurrencyUSD;
/* numberFormatUSD Not a pure module */
