import React from 'react';
import GraphiQL from 'graphiql';
const css = require("graphiql/graphiql.min.css");

export default function BDGraphiQL(props) {
  return <>
    <style dangerouslySetInnerHTML={{ __html: css.toString() }}>
    </style>
    <style dangerouslySetInnerHTML={{
      __html: `
    body {
      padding: 0;
      margin: 0;
      min-height: 100vh;
    }
    #root {
      height: 100vh;
    }` }}>
    </style>

    <div id="root">
      <GraphiQL
        style={{ height: '100vh' }}
        fetcher={async graphQLParams => {
          const data = await fetch(
            '/api/graphql',
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(graphQLParams),
              credentials: 'same-origin',
            },
          );
          return data.json().catch(() => data.text());
        }}
      />
    </div>
  </>
}
