'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Role$BirddogsoftwareFrontend = require("./Types/Role.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("./Utils/GraphQLHooks.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    if (action !== 0) {
      return {
              user: state.user,
              enableAlreadySignedIn: true,
              hostUrl: state.hostUrl
            };
    } else {
      return {
              user: state.user,
              enableAlreadySignedIn: false,
              hostUrl: state.hostUrl
            };
    }
  } else {
    return {
            user: action._0,
            enableAlreadySignedIn: state.enableAlreadySignedIn,
            hostUrl: state.hostUrl
          };
  }
}

function resetUri(store) {
  return store.state.hostUrl + "reset";
}

var ppx_printed_query = "query   {\ncurrentUser  {\nuserId  \nname  \nbuiltInRoles  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "currentUser");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "userId");
      var value$6 = Js_dict.get(value$4, "name");
      var tmp$1;
      if (value$6 !== undefined) {
        var value$7 = Caml_option.valFromOption(value$6);
        var value$8 = Js_json.decodeString(value$7);
        tmp$1 = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
      }
      var value$9 = Js_dict.get(value$4, "builtInRoles");
      tmp = {
        userId: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field userId on type User is missing"),
        name: tmp$1,
        builtInRoles: value$9 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$9))).map(function (value) {
                var value$1 = Js_json.decodeString(value);
                if (value$1 === undefined) {
                  return Js_exn.raiseError("graphql_ppx: Expected enum value for BuiltInRoles, got " + JSON.stringify(value));
                }
                switch (value$1) {
                  case "ADMIN" :
                      return "ADMIN";
                  case "BRAIN" :
                      return "BRAIN";
                  case "CONTENT_MANAGEMENT" :
                      return "CONTENT_MANAGEMENT";
                  case "CUSTOMER_SERVICE" :
                      return "CUSTOMER_SERVICE";
                  case "EMPLOYEE" :
                      return "EMPLOYEE";
                  case "WAREHOUSE_MANAGEMENT" :
                      return "WAREHOUSE_MANAGEMENT";
                  default:
                    return Js_exn.raiseError("graphql_ppx: Unknown enum variant for BuiltInRoles: " + value$1);
                }
              }) : Js_exn.raiseError("graphql_ppx: Field builtInRoles on type User is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          currentUser: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var InitialDataQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var initialDataQuery = make(undefined);

function getInitialData(hostUrl, setState) {
  var q = GraphQLHooks$BirddogsoftwareFrontend.useQuery(initialDataQuery, undefined, undefined);
  var message = q.result;
  if (typeof message === "number") {
    return ;
  }
  if (!message.TAG) {
    return Curry._1(setState, {
                TAG: /* Error */1,
                _0: message._0
              });
  }
  var currentUser = message._0.currentUser;
  var tmp;
  if (currentUser !== undefined) {
    var currentUser$1 = Caml_option.valFromOption(currentUser);
    tmp = /* User */{
      _0: {
        userId: Belt_Option.getExn(Js_json.decodeString(currentUser$1.userId)),
        name: currentUser$1.name,
        builtInRoles: $$Array.map(Role$BirddogsoftwareFrontend.convert, currentUser$1.builtInRoles)
      }
    };
  } else {
    tmp = /* Anon */0;
  }
  return Curry._1(setState, {
              TAG: /* Ok */0,
              _0: {
                user: tmp,
                enableAlreadySignedIn: true,
                hostUrl: hostUrl
              }
            });
}

exports.reducer = reducer;
exports.resetUri = resetUri;
exports.InitialDataQuery = InitialDataQuery;
exports.initialDataQuery = initialDataQuery;
exports.getInitialData = getInitialData;
/* initialDataQuery Not a pure module */
