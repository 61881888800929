'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var MaterialUi_CircularProgress = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_CircularProgress.bs.js");

function classRecordToJs(param) {
  return {
          wrapper: param.wrapper,
          buttonProgress: param.buttonProgress
        };
}

var classes = {
  TAG: /* Record */0,
  _0: {
    wrapper: {
      position: "relative"
    },
    buttonProgress: {
      left: "50%",
      marginTop: "-12px",
      marginLeft: "-12px",
      position: "absolute",
      top: "50%"
    }
  }
};

function classRecordStringsFromJs(arg) {
  return {
          wrapper: arg.wrapper,
          buttonProgress: arg.buttonProgress
        };
}

var ButtonProgressStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var ButtonProgressStyles = MaterialUi_WithStyles.WithStylesSafe(ButtonProgressStylesDefs);

function ButtonProgress(Props) {
  var showProgress = Props.showProgress;
  var children = Props.children;
  return React.createElement(ButtonProgressStyles.make, {
              children: (function (classes) {
                  return React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement("div", {
                                                      className: classes.wrapper
                                                    }, children, showProgress ? React.createElement(Core.CircularProgress, MaterialUi_CircularProgress.makeProps(classes.buttonProgress, undefined, {
                                                                NAME: "Int",
                                                                VAL: 24
                                                              }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : React.createElement(React.Fragment, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, true, "Row", undefined, "Flex_End", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                })
            });
}

var make = ButtonProgress;

exports.ButtonProgressStylesDefs = ButtonProgressStylesDefs;
exports.ButtonProgressStyles = ButtonProgressStyles;
exports.make = make;
/* ButtonProgressStyles Not a pure module */
