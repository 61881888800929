'use strict';

var React = require("react");
var SignUp$BirddogsoftwareFrontend = require("../../../Components/SignIn/SignUp.bs.js");
var PagePaper$BirddogsoftwareFrontend = require("../../../Components/PagePaper.bs.js");

function EcommerceSignUp(Props) {
  var signInLink = Props.signInLink;
  var forgotLink = Props.forgotLink;
  var store = Props.store;
  return React.createElement(PagePaper$BirddogsoftwareFrontend.make, {
              maxWidth: "Sm",
              children: React.createElement(SignUp$BirddogsoftwareFrontend.make, {
                    signInLink: signInLink,
                    forgotLink: forgotLink,
                    store: store
                  })
            });
}

var make = EcommerceSignUp;

exports.make = make;
/* react Not a pure module */
