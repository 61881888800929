'use strict';

var React = require("react");
var Core = require("@material-ui/core");
var MaterialUi_LinearProgress = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_LinearProgress.bs.js");
var Location$BirddogsoftwareFrontend = require("../../Utils/Location.bs.js");
var PagePaper$BirddogsoftwareFrontend = require("../../Components/PagePaper.bs.js");
var UserAvatar$BirddogsoftwareFrontend = require("../../Components/UserAvatar.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../UrlResolver.bs.js");
var AvatarContainer$BirddogsoftwareFrontend = require("../../Components/AvatarContainer.bs.js");

function AlreadySignedIn(Props) {
  var user = Props.user;
  var rootResolved = UrlResolver$BirddogsoftwareFrontend.useResolved({
        NAME: "Raw",
        VAL: "/"
      });
  setTimeout((function (param) {
          return Location$BirddogsoftwareFrontend.unsafe_setUseReturnUrl(rootResolved.isExternal, rootResolved.url);
        }), 5000);
  return React.createElement(PagePaper$BirddogsoftwareFrontend.make, {
              maxWidth: "Sm",
              children: React.createElement(AvatarContainer$BirddogsoftwareFrontend.make, {
                    avatar: React.createElement(UserAvatar$BirddogsoftwareFrontend.make, {
                          userId: user.userId
                        }),
                    title: "Howdy " + (user.name + "."),
                    footer: React.createElement(Core.LinearProgress, MaterialUi_LinearProgress.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                    children: React.createElement("p", undefined, "You're already signed in, so we're going to send you on your way.")
                  })
            });
}

var Theme;

var Mixins;

var ZIndex;

var Breakpoints;

var PaletteColor;

var Palette;

var make = AlreadySignedIn;

exports.Theme = Theme;
exports.Mixins = Mixins;
exports.ZIndex = ZIndex;
exports.Breakpoints = Breakpoints;
exports.PaletteColor = PaletteColor;
exports.Palette = Palette;
exports.make = make;
/* react Not a pure module */
