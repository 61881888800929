// config.js
export const runtimeConfig =
  typeof window !== 'undefined'
    ? {
      // client
      defaultModule: window.env.defaultModule,
      hostUrl: window.env.hostUrl,
      instance: window.env.instance,
      hostMode: window.env.hostMode,
      enterpriseDashboardUrl: window.env.enterpriseDashboardUrl,
    }
    : {
      // server
      defaultModule: process.env.BIRDDOG_DEFAULT_MODULE,
      hostUrl: process.env.HOST_URL,
      instance: process.env.BIRDDOG_INSTANCE,
      hostMode: process.env.HOST_MODE,
      enterpriseDashboardUrl: undefined
    };