// brazenly copied from https://raw.githubusercontent.com/mui-org/material-ui/master/docs/src/pages/components/autocomplete/IntegrationDownshift.js

import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

function renderInput(inputProps) {
  const { InputProps, classes, ref, toggleMenu, ...other } = inputProps;

  const handleAdornmentOnClick = event => {
    event.preventDefault();
    toggleMenu();
  };

  const handleAdornmentMouseDown = event => {
    event.preventDefault();
  };

  return (
    <TextField
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              onClick={handleAdornmentOnClick}
              onMouseDown={handleAdornmentMouseDown}
              tabIndex={-1}
            >
              <ArrowDropDown />
            </IconButton>
          </InputAdornment>,
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
  toggleMenu: PropTypes.object.isRequired
};

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem ? (selectedItem.key === suggestion.key) : false;

  /* ; */

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.key}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'inline-flex'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    // flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));


function HooksWrapper(props) {
  const { suggestions, isLoading } =
    props.inputValue ?
      props.useGetSuggestions(props.inputValue) :
      (props.useGetEmptySuggestions ? props.useGetEmptySuggestions() : { suggestions: [], isLoading: false });

  return props.children({ suggestions, isLoading });
}

export default function MaterialUi_Downshift(props) {
  const classes = useStyles();

  const classNames = [classes.root];
  if (props.className) {
    classNames.push(props.className);
  }
  const className = classNames.join(' ');
  return (
    <div className={className}>
      <Downshift id="downshift-simple"
        onChange={(suggestion) => props.onChange(suggestion ? suggestion.data : null)}
        itemToString={(suggestion) => props.itemToString(suggestion ? suggestion.data : null) || ""}
        stateReducer={(state, changes) => {
          if (
            props.isTypeahead &&
            (changes.type === Downshift.stateChangeTypes.blurInput ||
              changes.type === Downshift.stateChangeTypes.mouseUp)
            && state.inputValue) {
            const x = {
              ...changes,
              isOpen: false,
              highlightedIndex: null,
              selectedItem: {
                label: state.inputValue,
                data: state.inputValue,
                key: state.inputValue
              },
              inputValue: state.inputValue,
            }
            return x
          }
          return changes;
        }}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          toggleMenu,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: props.placeholder,
          });

          var { innerRef, ...rootProps } = getRootProps({ refKey: 'innerRef' });

          return <HooksWrapper innerRef={innerRef} useGetSuggestions={props.useGetSuggestions} inputValue={inputValue}>
            {({ suggestions, isLoading }) => {
              return (
                <div className={classes.container} ref={innerRef} {...rootProps}>
                  {renderInput({
                    fullWidth: props.fullWidth,
                    classes,
                    label: props.label,
                    InputLabelProps: getLabelProps(),
                    InputProps: { onBlur, onFocus },
                    inputProps,
                    helperText: props.helperText,
                    error: props.error,
                    toggleMenu,
                  })}

                  {isLoading && (
                    <LinearProgress
                      style={{
                        position: 'relative',
                        bottom: 2,
                        height: 2,
                        marginBottom: -2,
                      }}
                    />
                  )}

                  <div {...getMenuProps()}>
                    {isOpen ? (<>
                      <Paper className={classes.paper} square key="list" >
                        {suggestions.map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion }),
                            highlightedIndex,
                            selectedItem,
                          }),
                        )}
                      </Paper>
                    </>
                    ) : null}
                  </div>
                </div>
              );
            }}
          </HooksWrapper>

        }}
      </Downshift>
    </div>
  );
}