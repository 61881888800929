'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");

function PaperSpacer(Props) {
  var children = Props.children;
  return React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(children), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V1 */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = PaperSpacer;

exports.make = make;
/* react Not a pure module */
