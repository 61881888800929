'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");

function joinWithOxford(ar, sep) {
  var len = ar.length;
  if (len >= 3) {
    var len$1 = ar.length;
    var start = Belt_Array.slice(ar, 0, len$1 - 1 | 0);
    var last = Belt_Array.getExn(ar, len$1 - 1 | 0);
    return start.join(", ") + (", " + (sep + (" " + last)));
  }
  switch (len) {
    case 0 :
        return "";
    case 1 :
        return ar[0];
    case 2 :
        var x = ar[0];
        var y = ar[1];
        return x + (" " + (sep + (" " + y)));
    
  }
}

function joinWithOxfordAnd(ar) {
  return joinWithOxford(ar, "and");
}

function joinWithOxfordOr(ar) {
  return joinWithOxford(ar, "or");
}

exports.joinWithOxford = joinWithOxford;
exports.joinWithOxfordAnd = joinWithOxfordAnd;
exports.joinWithOxfordOr = joinWithOxfordOr;
/* No side effect */
