'use strict';

var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_MenuItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_MenuItem.bs.js");
var QuerySelect$BirddogsoftwareFrontend = require("../QuerySelect.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");

var ppx_printed_query = "query Q  {\ntaxScheduleList  {\ntaxScheduleCode  \ndescription  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "taxScheduleList");
  return {
          taxScheduleList: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "taxScheduleCode");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field taxScheduleCode on type TaxSchedule is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "description");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field description on type TaxSchedule is missing");
                  }
                  return {
                          taxScheduleCode: tmp,
                          description: tmp$1
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field taxScheduleList on type Query is missing")
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Q = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function TaxScheduleSelect(Props) {
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var value = Props.value;
  var onChange = Props.onChange;
  var blankText = Props.blankText;
  var queryResponse = GraphQLHooks$BirddogsoftwareFrontend.responseMap(GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(undefined), undefined, undefined).result, (function (x) {
          return x.taxScheduleList;
        }));
  var tmp = {
    queryResponse: queryResponse,
    value: value,
    onChange: onChange,
    children: (function (items) {
        return Belt_Array.map(items, (function (taxSchedule) {
                      return React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(taxSchedule.description), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      NAME: "String",
                                      VAL: taxSchedule.taxScheduleCode
                                    }, undefined, undefined, taxSchedule.taxScheduleCode, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                    }));
      })
  };
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (fullWidth !== undefined) {
    tmp.fullWidth = Caml_option.valFromOption(fullWidth);
  }
  if (margin !== undefined) {
    tmp.margin = Caml_option.valFromOption(margin);
  }
  if (blankText !== undefined) {
    tmp.blankText = Caml_option.valFromOption(blankText);
  }
  return React.createElement(QuerySelect$BirddogsoftwareFrontend.make, tmp);
}

var make$1 = TaxScheduleSelect;

exports.Q = Q;
exports.make = make$1;
/* react Not a pure module */
