'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Stricture$BirddogsoftwareFrontend = require("../../../Components/Forms/Stricture.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../../Components/Forms/SimpleForm.bs.js");
var LocationSelect$BirddogsoftwareFrontend = require("../../../Components/Forms/Pickers/LocationSelect.bs.js");

var ppx_printed_query = "mutation q($warehouseStationId: Guid!)  {\nwarehouseStationDelete(warehouseStationId: $warehouseStationId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "warehouseStationDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          warehouseStationDelete: tmp
        };
}

function make(warehouseStationId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "warehouseStationId",
                    warehouseStationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var warehouseStationId = variables.warehouseStationId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "warehouseStationId",
                    warehouseStationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(warehouseStationId, param) {
  return Js_dict.fromArray([[
                  "warehouseStationId",
                  warehouseStationId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, warehouseStationId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "warehouseStationId",
                      warehouseStationId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($warehouseStationId: Guid, $data: WarehouseStationInput!)  {\nwarehouseStation(warehouseStationId: $warehouseStationId, data: $data)  {\nsuccess  {\nwarehouseStationId  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "warehouseStation");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "success");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "warehouseStationId");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field warehouseStationId on type WarehouseStation is missing");
          }
          tmp$1 = {
            warehouseStationId: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      tmp = {
        success: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          warehouseStation: tmp
        };
}

function json_of_WarehouseStationPrinterType(value) {
  if (value === "PRINTER") {
    return "PRINTER";
  } else {
    return "QUEUE";
  }
}

function json_of_WarehouseStationInput(value) {
  var v = value.loc;
  var v$1 = value.name;
  var v$2 = value.labelPrinterType;
  var v$3 = value.labelPrinterName;
  var v$4 = value.otherPrinterType;
  var v$5 = value.otherPrinterName;
  return Js_dict.fromArray([
                [
                  "loc",
                  v !== undefined ? v : null
                ],
                [
                  "name",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "labelPrinterType",
                  v$2 !== undefined ? json_of_WarehouseStationPrinterType(v$2) : null
                ],
                [
                  "labelPrinterName",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "otherPrinterType",
                  v$4 !== undefined ? json_of_WarehouseStationPrinterType(v$4) : null
                ],
                [
                  "otherPrinterName",
                  v$5 !== undefined ? v$5 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(warehouseStationId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "warehouseStationId",
                    warehouseStationId !== undefined ? Caml_option.valFromOption(warehouseStationId) : null
                  ],
                  [
                    "data",
                    json_of_WarehouseStationInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var warehouseStationId = variables.warehouseStationId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "warehouseStationId",
                    warehouseStationId !== undefined ? Caml_option.valFromOption(warehouseStationId) : null
                  ],
                  [
                    "data",
                    json_of_WarehouseStationInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(warehouseStationId, data, param) {
  return Js_dict.fromArray([
                [
                  "warehouseStationId",
                  warehouseStationId !== undefined ? Caml_option.valFromOption(warehouseStationId) : null
                ],
                [
                  "data",
                  json_of_WarehouseStationInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, warehouseStationId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "warehouseStationId",
                      warehouseStationId !== undefined ? Caml_option.valFromOption(warehouseStationId) : null
                    ],
                    [
                      "data",
                      json_of_WarehouseStationInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Mutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_WarehouseStationPrinterType: json_of_WarehouseStationPrinterType,
  json_of_WarehouseStationInput: json_of_WarehouseStationInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "query Q($warehouseStationId: Guid!)  {\nwarehouseStation(warehouseStationId: $warehouseStationId)  {\nwarehouseStationId  \nloc  \nname  \nlabelPrinterType  \nlabelPrinterName  \notherPrinterType  \notherPrinterName  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "warehouseStation");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "warehouseStationId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field warehouseStationId on type WarehouseStation is missing");
      }
      var value$8 = Js_dict.get(value$4, "loc");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field loc on type WarehouseStation is missing");
      }
      var value$11 = Js_dict.get(value$4, "name");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field name on type WarehouseStation is missing");
      }
      var value$14 = Js_dict.get(value$4, "labelPrinterType");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        if (value$16 !== undefined) {
          switch (value$16) {
            case "PRINTER" :
                tmp$4 = "PRINTER";
                break;
            case "QUEUE" :
                tmp$4 = "QUEUE";
                break;
            default:
              tmp$4 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for WarehouseStationPrinterType: " + value$16);
          }
        } else {
          tmp$4 = Js_exn.raiseError("graphql_ppx: Expected enum value for WarehouseStationPrinterType, got " + JSON.stringify(value$15));
        }
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field labelPrinterType on type WarehouseStation is missing");
      }
      var value$17 = Js_dict.get(value$4, "labelPrinterName");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var value$19 = Js_json.decodeString(value$18);
        tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field labelPrinterName on type WarehouseStation is missing");
      }
      var value$20 = Js_dict.get(value$4, "otherPrinterType");
      var tmp$6;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var value$22 = Js_json.decodeString(value$21);
        if (value$22 !== undefined) {
          switch (value$22) {
            case "PRINTER" :
                tmp$6 = "PRINTER";
                break;
            case "QUEUE" :
                tmp$6 = "QUEUE";
                break;
            default:
              tmp$6 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for WarehouseStationPrinterType: " + value$22);
          }
        } else {
          tmp$6 = Js_exn.raiseError("graphql_ppx: Expected enum value for WarehouseStationPrinterType, got " + JSON.stringify(value$21));
        }
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field otherPrinterType on type WarehouseStation is missing");
      }
      var value$23 = Js_dict.get(value$4, "otherPrinterName");
      var tmp$7;
      if (value$23 !== undefined) {
        var value$24 = Caml_option.valFromOption(value$23);
        var value$25 = Js_json.decodeString(value$24);
        tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
      } else {
        tmp$7 = Js_exn.raiseError("graphql_ppx: Field otherPrinterName on type WarehouseStation is missing");
      }
      tmp = {
        warehouseStationId: tmp$1,
        loc: tmp$2,
        name: tmp$3,
        labelPrinterType: tmp$4,
        labelPrinterName: tmp$5,
        otherPrinterType: tmp$6,
        otherPrinterName: tmp$7
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          warehouseStation: tmp
        };
}

function make$2(warehouseStationId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "warehouseStationId",
                    warehouseStationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var warehouseStationId = variables.warehouseStationId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "warehouseStationId",
                    warehouseStationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(warehouseStationId, param) {
  return Js_dict.fromArray([[
                  "warehouseStationId",
                  warehouseStationId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, warehouseStationId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "warehouseStationId",
                      warehouseStationId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var Query = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function doneRoute(text) {
  return {
          NAME: "Enterprise",
          VAL: "/warehouse-stations"
        };
}

function breadcrumbs(context) {
  return [{
            href: {
              NAME: "Enterprise",
              VAL: "/warehouse-stations"
            },
            content: "Warehouse Stations"
          }];
}

function newRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/warehouse-stations/new"
        };
}

var RouteArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute
};

var newState = {
  loc: "",
  name: "",
  labelPrinterType: "PRINTER",
  labelPrinterName: "",
  otherPrinterType: "PRINTER",
  otherPrinterName: ""
};

function reducer(context, state, action) {
  switch (action.TAG | 0) {
    case /* SetLoc */0 :
        return {
                loc: action._0,
                name: state.name,
                labelPrinterType: state.labelPrinterType,
                labelPrinterName: state.labelPrinterName,
                otherPrinterType: state.otherPrinterType,
                otherPrinterName: state.otherPrinterName
              };
    case /* SetName */1 :
        return {
                loc: state.loc,
                name: action._0,
                labelPrinterType: state.labelPrinterType,
                labelPrinterName: state.labelPrinterName,
                otherPrinterType: state.otherPrinterType,
                otherPrinterName: state.otherPrinterName
              };
    case /* SetLabelPrinterType */2 :
        return {
                loc: state.loc,
                name: state.name,
                labelPrinterType: action._0,
                labelPrinterName: state.labelPrinterName,
                otherPrinterType: state.otherPrinterType,
                otherPrinterName: state.otherPrinterName
              };
    case /* SetLabelPrinterName */3 :
        return {
                loc: state.loc,
                name: state.name,
                labelPrinterType: state.labelPrinterType,
                labelPrinterName: action._0,
                otherPrinterType: state.otherPrinterType,
                otherPrinterName: state.otherPrinterName
              };
    case /* SetOtherPrinterType */4 :
        return {
                loc: state.loc,
                name: state.name,
                labelPrinterType: state.labelPrinterType,
                labelPrinterName: state.labelPrinterName,
                otherPrinterType: action._0,
                otherPrinterName: state.otherPrinterName
              };
    case /* SetOtherPrinterName */5 :
        return {
                loc: state.loc,
                name: state.name,
                labelPrinterType: state.labelPrinterType,
                labelPrinterName: state.labelPrinterName,
                otherPrinterType: state.otherPrinterType,
                otherPrinterName: action._0
              };
    
  }
}

function getName(context, state) {
  return state.name;
}

function selfRoute(context, id) {
  return {
          NAME: "Enterprise",
          VAL: "/warehouse-stations/" + id
        };
}

function makeMutation(context, warehouseStationId, state) {
  return make$1(Belt_Option.map(warehouseStationId, (function (prim) {
                    return prim;
                  })), {
              loc: state.loc,
              name: state.name,
              labelPrinterType: state.labelPrinterType,
              labelPrinterName: state.labelPrinterName,
              otherPrinterType: state.otherPrinterType,
              otherPrinterName: state.otherPrinterName
            }, undefined);
}

function mapMutation(context, result) {
  return Belt_Option.map(Belt_Option.flatMap(result.warehouseStation, (function (warehouseStation) {
                    return warehouseStation.success;
                  })), (function (success) {
                return success.warehouseStationId;
              }));
}

function makeQuery(context, warehouseStationId) {
  return make$2(warehouseStationId, undefined);
}

function mapQuery(context, result) {
  return Belt_Option.map(result.warehouseStation, (function (warehouseStation) {
                return {
                        loc: warehouseStation.loc,
                        name: warehouseStation.name,
                        labelPrinterType: warehouseStation.labelPrinterType,
                        labelPrinterName: warehouseStation.labelPrinterName,
                        otherPrinterType: warehouseStation.otherPrinterType,
                        otherPrinterName: warehouseStation.otherPrinterName
                      };
              }));
}

function makeDelete(context, warehouseStationId) {
  return make(warehouseStationId, undefined);
}

function saveValidate(context, state) {
  return state.name.trim() !== "";
}

function mainActions(context, id, state, dispatch) {
  return [];
}

function mainContent(context, id, state, dispatch) {
  return React.createElement(React.Fragment, undefined, React.createElement(LocationSelect$BirddogsoftwareFrontend.make, {
                  label: "Location",
                  fullWidth: true,
                  value: state.loc,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetLoc */0,
                                  _0: value
                                });
                    })
                }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Name",
                  value: state.name,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetName */1,
                                  _0: value
                                });
                    })
                }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Label Printer",
                  value: state.labelPrinterName,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetLabelPrinterName */3,
                                  _0: value
                                });
                    })
                }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Other Printer",
                  value: state.otherPrinterName,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetOtherPrinterName */5,
                                  _0: value
                                });
                    })
                }));
}

var FormArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  newState: newState,
  reducer: reducer,
  doneRoute: doneRoute,
  getName: getName,
  selfRoute: selfRoute,
  makeMutation: makeMutation,
  mapMutation: mapMutation,
  stayAfterSaveNew: false,
  makeQuery: makeQuery,
  mapQuery: mapQuery,
  makeDelete: makeDelete,
  saveValidate: saveValidate,
  mainActions: mainActions,
  mainContent: mainContent,
  secondaryContent: undefined
};

var Form = Stricture$BirddogsoftwareFrontend.Form.Make({
      breadcrumbs: breadcrumbs,
      newRoute: newRoute,
      newState: newState,
      reducer: reducer,
      doneRoute: doneRoute,
      getName: getName,
      selfRoute: selfRoute,
      makeMutation: makeMutation,
      mapMutation: mapMutation,
      makeQuery: makeQuery,
      mapQuery: mapQuery,
      makeDelete: makeDelete,
      saveValidate: saveValidate,
      mainContent: mainContent,
      mainActions: mainActions,
      secondaryContent: undefined,
      stayAfterSaveNew: false
    });

var ppx_printed_query$3 = "query Q  {\nwarehouseStationList  {\nwarehouseStationId  \nloc  \nname  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "warehouseStationList");
  return {
          warehouseStationList: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "warehouseStationId");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field warehouseStationId on type WarehouseStation is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "loc");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field loc on type WarehouseStation is missing");
                  }
                  var value$8 = Js_dict.get(value$1, "name");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field name on type WarehouseStation is missing");
                  }
                  return {
                          warehouseStationId: tmp,
                          loc: tmp$1,
                          name: tmp$2
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field warehouseStationList on type Query is missing")
        };
}

function make$3(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeWithVariables$3(param) {
  return {
          query: ppx_printed_query$3,
          variables: null,
          parse: parse$3
        };
}

function makeVariables$3(param) {
  return null;
}

function definition_2$3(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var ListQuery = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function primaryColumnTitle(context) {
  return "Name";
}

function getPrimaryColumnContent(context, x) {
  return x.name;
}

function getPrimaryColumnRoute(context, x) {
  return {
          NAME: "Enterprise",
          VAL: "/warehouse-stations/" + x.warehouseStationId
        };
}

function queryArgsReducer(a, b) {
  
}

function queryArgsElement(state, dispatch) {
  return React.createElement(React.Fragment, undefined);
}

function makeQuery$1(context, queryArgs) {
  return make$3(undefined);
}

function mapQuery$1(context, result) {
  return Belt_Array.map(result.warehouseStationList, (function (warehouseStation) {
                return {
                        loc: warehouseStation.loc,
                        name: warehouseStation.name,
                        warehouseStationId: warehouseStation.warehouseStationId
                      };
              }));
}

var columns = [{
    title: "Loc",
    render: (function (row) {
        return row.loc;
      })
  }];

var ListArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  primaryColumnTitle: primaryColumnTitle,
  getPrimaryColumnContent: getPrimaryColumnContent,
  getPrimaryColumnRoute: getPrimaryColumnRoute,
  queryArgsDefault: undefined,
  queryArgsReducer: queryArgsReducer,
  queryArgsElement: queryArgsElement,
  makeQuery: makeQuery$1,
  mapQuery: mapQuery$1,
  columns: columns
};

var List = Stricture$BirddogsoftwareFrontend.List.Make(ListArg);

exports.DeleteMutation = DeleteMutation;
exports.Mutation = Mutation;
exports.Query = Query;
exports.doneRoute = doneRoute;
exports.RouteArg = RouteArg;
exports.FormArg = FormArg;
exports.Form = Form;
exports.ListQuery = ListQuery;
exports.ListArg = ListArg;
exports.List = List;
/* Form Not a pure module */
