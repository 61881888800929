'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_MenuItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_MenuItem.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");

function QuerySelect(Props) {
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var queryResponse = Props.queryResponse;
  var value = Props.value;
  var onChange = Props.onChange;
  var children = Props.children;
  var blankText = Props.blankText;
  var tmp;
  tmp = typeof queryResponse === "number" ? React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps("Loading...", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
              NAME: "String",
              VAL: "loading"
            }, undefined, undefined, "loading", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : (
      queryResponse.TAG ? (
            blankText !== undefined ? [React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(blankText), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                          NAME: "String",
                          VAL: ""
                        }, undefined, undefined, "_empty", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))] : []
          ).concat(Curry._1(children, queryResponse._0)) : queryResponse._0
    );
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, Caml_option.some(tmp), undefined, undefined, undefined, undefined, undefined, fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, label, margin, undefined, undefined, undefined, (function ($$event) {
                    return Curry._1(onChange, $$event.target.value);
                  }), undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = QuerySelect;

exports.make = make;
/* react Not a pure module */
