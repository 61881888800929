'use strict';

var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var List = require("@material-ui/icons/List");
var Email = require("@material-ui/icons/Email");
var Widgets = require("@material-ui/icons/Widgets");
var Settings = require("@material-ui/icons/Settings");
var Dashboard = require("@material-ui/icons/Dashboard");
var ShoppingCart = require("@material-ui/icons/ShoppingCart");
var Sidebar$BirddogsoftwareFrontend = require("../../Components/Sidebar.bs.js");
var AccountCircle = require("@material-ui/icons/AccountCircle");
var CalendarToday = require("@material-ui/icons/CalendarToday");
var Notifications = require("@material-ui/icons/Notifications");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");

var ppx_printed_query = "query Q1  {\nlicenses  {\nbrain  \ncustomerService  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "licenses");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "brain");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field brain on type Licenses is missing");
    }
    var value$7 = Js_dict.get(value$3, "customerService");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeBoolean(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field customerService on type Licenses is missing");
    }
    tmp = {
      brain: tmp$1,
      customerService: tmp$2
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field licenses on type Query is missing");
  }
  return {
          licenses: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var InitialDataQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var initialDataQuery = make(undefined);

function EnterpriseSidebar(Props) {
  var enterpriseDashboardUrl = Props.enterpriseDashboardUrl;
  var q = GraphQLHooks$BirddogsoftwareFrontend.useQuery(initialDataQuery, undefined, undefined);
  var message = q.result;
  if (typeof message === "number") {
    return React.createElement(React.Fragment, undefined);
  }
  if (!message.TAG) {
    return React.createElement(React.Fragment, undefined, message._0);
  }
  var result = message._0;
  return Sidebar$BirddogsoftwareFrontend.toReactElement(Sidebar$BirddogsoftwareFrontend.sidebar(Belt_Array.keepMap([
                      Sidebar$BirddogsoftwareFrontend.section(undefined, [Sidebar$BirddogsoftwareFrontend.item(React.createElement(Dashboard.default, {}), "Dashboard", enterpriseDashboardUrl)]),
                      result.licenses.brain ? Sidebar$BirddogsoftwareFrontend.section("BRAIN", [
                              Sidebar$BirddogsoftwareFrontend.item(React.createElement(Dashboard.default, {}), "Menu", {
                                    NAME: "Enterprise",
                                    VAL: "/brain"
                                  }),
                              Sidebar$BirddogsoftwareFrontend.item(React.createElement(ShoppingCart.default, {}), "Inbox", {
                                    NAME: "Enterprise",
                                    VAL: "/brain/inbox"
                                  }),
                              Sidebar$BirddogsoftwareFrontend.item(React.createElement(CalendarToday.default, {}), "Calendar", {
                                    NAME: "Enterprise",
                                    VAL: "/brain/calendar/"
                                  }),
                              Sidebar$BirddogsoftwareFrontend.item(React.createElement(AccountCircle.default, {}), "Contacts", {
                                    NAME: "Enterprise",
                                    VAL: "/brain/contacts/"
                                  })
                            ]) : undefined,
                      result.licenses.customerService ? Sidebar$BirddogsoftwareFrontend.section("Customer Service", [
                              Sidebar$BirddogsoftwareFrontend.item(React.createElement(ShoppingCart.default, {}), "Order Entry", {
                                    NAME: "Enterprise",
                                    VAL: "/orders/draft"
                                  }),
                              Sidebar$BirddogsoftwareFrontend.item(React.createElement(List.default, {}), "Order History", {
                                    NAME: "Enterprise",
                                    VAL: "/orders/"
                                  })
                            ]) : undefined,
                      Sidebar$BirddogsoftwareFrontend.section("Admin", [
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(AccountCircle.default, {}), "Users", {
                                  NAME: "Enterprise",
                                  VAL: "/users"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Widgets.default, {}), "Items", {
                                  NAME: "Enterprise",
                                  VAL: "/items"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Settings.default, {}), "Connector Accounts", {
                                  NAME: "Enterprise",
                                  VAL: "/connector-accounts"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Settings.default, {}), "Warehouse Stations", {
                                  NAME: "Enterprise",
                                  VAL: "/warehouse-stations"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Settings.default, {}), "Settings", {
                                  NAME: "Enterprise",
                                  VAL: "/settings"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Email.default, {}), "Email Templates", {
                                  NAME: "Enterprise",
                                  VAL: "/email-templates"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Email.default, {}), "Filter Groups", {
                                  NAME: "Enterprise",
                                  VAL: "/filter-groups"
                                }),
                            Sidebar$BirddogsoftwareFrontend.item(React.createElement(Notifications.default, {}), "Log", {
                                  NAME: "Enterprise",
                                  VAL: "/log"
                                })
                          ])
                    ], (function (x) {
                        return x;
                      }))));
}

var make$1 = EnterpriseSidebar;

exports.InitialDataQuery = InitialDataQuery;
exports.initialDataQuery = initialDataQuery;
exports.make = make$1;
/* initialDataQuery Not a pure module */
