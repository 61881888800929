'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var PaperPadding$BirddogsoftwareFrontend = require("./PaperPadding.bs.js");

function PaperPadded(Props) {
  var children = Props.children;
  return React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                          children: children
                        })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = PaperPadded;

exports.make = make;
/* react Not a pure module */
