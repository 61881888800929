'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var IntField$BirddogsoftwareFrontend = require("../../Components/Forms/IntField.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../Components/Forms/SimpleForm.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../../Components/QueryResponse.bs.js");
var OrderTypeSelect$BirddogsoftwareFrontend = require("../../Components/Forms/Pickers/OrderTypeSelect.bs.js");
var TrueFalseUnsetSelect$BirddogsoftwareFrontend = require("../../Components/TrueFalseUnsetSelect.bs.js");

var ppx_printed_query = "mutation q($instanceSettingsId: Guid!)  {\ninstanceSettingsDelete(instanceSettingsId: $instanceSettingsId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "instanceSettingsDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          instanceSettingsDelete: tmp
        };
}

function make(instanceSettingsId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "instanceSettingsId",
                    instanceSettingsId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var instanceSettingsId = variables.instanceSettingsId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "instanceSettingsId",
                    instanceSettingsId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(instanceSettingsId, param) {
  return Js_dict.fromArray([[
                  "instanceSettingsId",
                  instanceSettingsId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, instanceSettingsId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "instanceSettingsId",
                      instanceSettingsId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var InstanceSettingsDeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($instanceSettingsId: Guid, $data: InstanceSettingsInput!)  {\ninstanceSettings(instanceSettingsId: $instanceSettingsId, data: $data)  {\nsuccess  {\ninstanceSettingsId  \n}\n\nfailure  {\nnotFound  \nrowVersionConflict  \ninvalidProperties  {\npropertyName  \nisEmpty  \nreason  \n}\n\n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "instanceSettings");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeObject(value$3);
      var tmp$1;
      if (value$4 !== undefined) {
        var value$5 = Caml_option.valFromOption(value$4);
        var temp = Js_dict.get(value$5, "success");
        if (temp !== undefined) {
          var temp$1 = Caml_option.valFromOption(temp);
          var match$1 = Js_json.decodeNull(temp$1);
          if (match$1 !== undefined) {
            var temp$2 = Js_dict.get(value$5, "failure");
            if (temp$2 !== undefined) {
              var temp$3 = Caml_option.valFromOption(temp$2);
              var match$2 = Js_json.decodeNull(temp$3);
              if (match$2 !== undefined) {
                tmp$1 = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<InstanceSettings, InsertOrUpdateError> were null");
              } else {
                var value$6 = Js_option.getExn(Js_json.decodeObject(temp$3));
                var value$7 = Js_dict.get(value$6, "notFound");
                var tmp$2;
                if (value$7 !== undefined) {
                  var value$8 = Caml_option.valFromOption(value$7);
                  var value$9 = Js_json.decodeBoolean(value$8);
                  tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$8));
                } else {
                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field notFound on type InsertOrUpdateError is missing");
                }
                var value$10 = Js_dict.get(value$6, "rowVersionConflict");
                var tmp$3;
                if (value$10 !== undefined) {
                  var value$11 = Caml_option.valFromOption(value$10);
                  var value$12 = Js_json.decodeBoolean(value$11);
                  tmp$3 = value$12 !== undefined ? value$12 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$11));
                } else {
                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field rowVersionConflict on type InsertOrUpdateError is missing");
                }
                var value$13 = Js_dict.get(value$6, "invalidProperties");
                tmp$1 = {
                  NAME: "Failure",
                  VAL: {
                    notFound: tmp$2,
                    rowVersionConflict: tmp$3,
                    invalidProperties: value$13 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$13))).map(function (value) {
                            var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                            var value$2 = Js_dict.get(value$1, "propertyName");
                            var tmp;
                            if (value$2 !== undefined) {
                              var value$3 = Caml_option.valFromOption(value$2);
                              var value$4 = Js_json.decodeString(value$3);
                              tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                            } else {
                              tmp = Js_exn.raiseError("graphql_ppx: Field propertyName on type InvalidProperty is missing");
                            }
                            var value$5 = Js_dict.get(value$1, "isEmpty");
                            var tmp$1;
                            if (value$5 !== undefined) {
                              var value$6 = Caml_option.valFromOption(value$5);
                              var value$7 = Js_json.decodeBoolean(value$6);
                              tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$6));
                            } else {
                              tmp$1 = Js_exn.raiseError("graphql_ppx: Field isEmpty on type InvalidProperty is missing");
                            }
                            var value$8 = Js_dict.get(value$1, "reason");
                            var tmp$2;
                            if (value$8 !== undefined) {
                              var value$9 = Caml_option.valFromOption(value$8);
                              var value$10 = Js_json.decodeString(value$9);
                              tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                            } else {
                              tmp$2 = Js_exn.raiseError("graphql_ppx: Field reason on type InvalidProperty is missing");
                            }
                            return {
                                    propertyName: tmp,
                                    isEmpty: tmp$1,
                                    reason: tmp$2
                                  };
                          }) : Js_exn.raiseError("graphql_ppx: Field invalidProperties on type InsertOrUpdateError is missing")
                  }
                };
              }
            } else {
              tmp$1 = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<InstanceSettings, InsertOrUpdateError> is missing");
            }
          } else {
            var value$14 = Js_option.getExn(Js_json.decodeObject(temp$1));
            var value$15 = Js_dict.get(value$14, "instanceSettingsId");
            var tmp$4;
            if (value$15 !== undefined) {
              var value$16 = Caml_option.valFromOption(value$15);
              var value$17 = Js_json.decodeString(value$16);
              tmp$4 = value$17 !== undefined ? value$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$16));
            } else {
              tmp$4 = Js_exn.raiseError("graphql_ppx: Field instanceSettingsId on type InstanceSettings is missing");
            }
            tmp$1 = {
              NAME: "Success",
              VAL: {
                instanceSettingsId: tmp$4
              }
            };
          }
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<InstanceSettings, InsertOrUpdateError> is missing");
        }
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<InstanceSettings, InsertOrUpdateError> to be an object");
      }
      tmp = tmp$1;
    }
  } else {
    tmp = undefined;
  }
  return {
          instanceSettings: tmp
        };
}

function json_of_NotSetInputType$lessStringGraphType$great(value) {
  var v = value.value;
  return Js_dict.fromArray([[
                  "value",
                  v !== undefined ? v : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_NotSetInputType$lessGuidGraphType$great(value) {
  var v = value.value;
  return Js_dict.fromArray([[
                  "value",
                  v !== undefined ? Caml_option.valFromOption(v) : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_NotSetInputType$lessBooleanGraphType$great(value) {
  var v = value.value;
  return Js_dict.fromArray([[
                  "value",
                  v !== undefined ? v : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_NotSetInputType$lessIntGraphType$great(value) {
  var v = value.value;
  return Js_dict.fromArray([[
                  "value",
                  v !== undefined ? v : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_OrderType(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function json_of_NotSetInputType$lessOrderTypeEnum$great(value) {
  var v = value.value;
  return Js_dict.fromArray([[
                  "value",
                  v !== undefined ? json_of_OrderType(v) : null
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_InstanceSettingsInput(value) {
  var v = value.name;
  var v$1 = value.parentId;
  var v$2 = value.allowUserSignUp;
  var v$3 = value.resetPasswordEmailTemplateId;
  var v$4 = value.newUserEmailTemplateId;
  var v$5 = value.companyName;
  var v$6 = value.supportEmailAddress;
  var v$7 = value.smtpServer;
  var v$8 = value.smtpPort;
  var v$9 = value.smtpUser;
  var v$10 = value.smtpPassword;
  var v$11 = value.allowOrderEntryOrderType_Order;
  var v$12 = value.allowOrderEntryOrderType_Blanket;
  var v$13 = value.allowOrderEntryOrderType_CreditMemo;
  var v$14 = value.allowOrderEntryOrderType_DebitMemo;
  var v$15 = value.allowOrderEntryOrderType_Invoice;
  var v$16 = value.allowOrderEntryOrderType_Quote;
  var v$17 = value.orderEntryDefaultOrderType;
  var v$18 = value.defaultOrderType;
  var v$19 = value.siteRootWebCategoryId;
  var v$20 = value.siteSearchRootWebCategoryId;
  var v$21 = value.defaultCurrencyCode;
  return Js_dict.fromArray([
                [
                  "name",
                  v !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v)) : null
                ],
                [
                  "parentId",
                  v$1 !== undefined ? json_of_NotSetInputType$lessGuidGraphType$great(Caml_option.valFromOption(v$1)) : null
                ],
                [
                  "allowUserSignUp",
                  v$2 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$2)) : null
                ],
                [
                  "resetPasswordEmailTemplateId",
                  v$3 !== undefined ? json_of_NotSetInputType$lessGuidGraphType$great(Caml_option.valFromOption(v$3)) : null
                ],
                [
                  "newUserEmailTemplateId",
                  v$4 !== undefined ? json_of_NotSetInputType$lessGuidGraphType$great(Caml_option.valFromOption(v$4)) : null
                ],
                [
                  "companyName",
                  v$5 !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v$5)) : null
                ],
                [
                  "supportEmailAddress",
                  v$6 !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v$6)) : null
                ],
                [
                  "smtpServer",
                  v$7 !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v$7)) : null
                ],
                [
                  "smtpPort",
                  v$8 !== undefined ? json_of_NotSetInputType$lessIntGraphType$great(Caml_option.valFromOption(v$8)) : null
                ],
                [
                  "smtpUser",
                  v$9 !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v$9)) : null
                ],
                [
                  "smtpPassword",
                  v$10 !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v$10)) : null
                ],
                [
                  "allowOrderEntryOrderType_Order",
                  v$11 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$11)) : null
                ],
                [
                  "allowOrderEntryOrderType_Blanket",
                  v$12 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$12)) : null
                ],
                [
                  "allowOrderEntryOrderType_CreditMemo",
                  v$13 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$13)) : null
                ],
                [
                  "allowOrderEntryOrderType_DebitMemo",
                  v$14 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$14)) : null
                ],
                [
                  "allowOrderEntryOrderType_Invoice",
                  v$15 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$15)) : null
                ],
                [
                  "allowOrderEntryOrderType_Quote",
                  v$16 !== undefined ? json_of_NotSetInputType$lessBooleanGraphType$great(Caml_option.valFromOption(v$16)) : null
                ],
                [
                  "orderEntryDefaultOrderType",
                  v$17 !== undefined ? json_of_NotSetInputType$lessOrderTypeEnum$great(Caml_option.valFromOption(v$17)) : null
                ],
                [
                  "defaultOrderType",
                  v$18 !== undefined ? json_of_NotSetInputType$lessOrderTypeEnum$great(Caml_option.valFromOption(v$18)) : null
                ],
                [
                  "siteRootWebCategoryId",
                  v$19 !== undefined ? json_of_NotSetInputType$lessGuidGraphType$great(Caml_option.valFromOption(v$19)) : null
                ],
                [
                  "siteSearchRootWebCategoryId",
                  v$20 !== undefined ? json_of_NotSetInputType$lessGuidGraphType$great(Caml_option.valFromOption(v$20)) : null
                ],
                [
                  "defaultCurrencyCode",
                  v$21 !== undefined ? json_of_NotSetInputType$lessStringGraphType$great(Caml_option.valFromOption(v$21)) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(instanceSettingsId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "instanceSettingsId",
                    instanceSettingsId !== undefined ? Caml_option.valFromOption(instanceSettingsId) : null
                  ],
                  [
                    "data",
                    json_of_InstanceSettingsInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var instanceSettingsId = variables.instanceSettingsId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "instanceSettingsId",
                    instanceSettingsId !== undefined ? Caml_option.valFromOption(instanceSettingsId) : null
                  ],
                  [
                    "data",
                    json_of_InstanceSettingsInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(instanceSettingsId, data, param) {
  return Js_dict.fromArray([
                [
                  "instanceSettingsId",
                  instanceSettingsId !== undefined ? Caml_option.valFromOption(instanceSettingsId) : null
                ],
                [
                  "data",
                  json_of_InstanceSettingsInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, instanceSettingsId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "instanceSettingsId",
                      instanceSettingsId !== undefined ? Caml_option.valFromOption(instanceSettingsId) : null
                    ],
                    [
                      "data",
                      json_of_InstanceSettingsInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var InstanceSettingsMutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_NotSetInputType$lessStringGraphType$great: json_of_NotSetInputType$lessStringGraphType$great,
  json_of_NotSetInputType$lessGuidGraphType$great: json_of_NotSetInputType$lessGuidGraphType$great,
  json_of_NotSetInputType$lessBooleanGraphType$great: json_of_NotSetInputType$lessBooleanGraphType$great,
  json_of_NotSetInputType$lessIntGraphType$great: json_of_NotSetInputType$lessIntGraphType$great,
  json_of_OrderType: json_of_OrderType,
  json_of_NotSetInputType$lessOrderTypeEnum$great: json_of_NotSetInputType$lessOrderTypeEnum$great,
  json_of_InstanceSettingsInput: json_of_InstanceSettingsInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetName */0 :
        var newrecord = Caml_obj.caml_obj_dup(state);
        newrecord.name = action._0;
        return newrecord;
    case /* SetParentId */1 :
        var newrecord$1 = Caml_obj.caml_obj_dup(state);
        newrecord$1.parentId = action._0;
        return newrecord$1;
    case /* SetAllowUserSignUp */2 :
        var newrecord$2 = Caml_obj.caml_obj_dup(state);
        newrecord$2.allowUserSignUp = action._0;
        return newrecord$2;
    case /* SetResetPasswordEmailTemplateId */3 :
        var newrecord$3 = Caml_obj.caml_obj_dup(state);
        newrecord$3.resetPasswordEmailTemplateId = action._0;
        return newrecord$3;
    case /* SetNewUserEmailTemplateId */4 :
        var newrecord$4 = Caml_obj.caml_obj_dup(state);
        newrecord$4.newUserEmailTemplateId = action._0;
        return newrecord$4;
    case /* SetSupportEmailAddress */5 :
        var newrecord$5 = Caml_obj.caml_obj_dup(state);
        newrecord$5.supportEmailAddress = action._0;
        return newrecord$5;
    case /* SetCompanyName */6 :
        var newrecord$6 = Caml_obj.caml_obj_dup(state);
        newrecord$6.companyName = action._0;
        return newrecord$6;
    case /* SetSmtpServer */7 :
        var newrecord$7 = Caml_obj.caml_obj_dup(state);
        newrecord$7.smtpServer = action._0;
        return newrecord$7;
    case /* SetSmtpPort */8 :
        var smtpPort = action._0;
        if (smtpPort !== undefined) {
          if (smtpPort.NAME === "Int") {
            var newrecord$8 = Caml_obj.caml_obj_dup(state);
            newrecord$8.smtpPort = smtpPort.VAL;
            newrecord$8.smtpPortValid = true;
            return newrecord$8;
          }
          var newrecord$9 = Caml_obj.caml_obj_dup(state);
          newrecord$9.smtpPortValid = false;
          return newrecord$9;
        }
        var newrecord$10 = Caml_obj.caml_obj_dup(state);
        newrecord$10.smtpPort = undefined;
        newrecord$10.smtpPortValid = true;
        return newrecord$10;
    case /* SetSmtpUser */9 :
        var newrecord$11 = Caml_obj.caml_obj_dup(state);
        newrecord$11.smtpUser = action._0;
        return newrecord$11;
    case /* SetSmtpPassword */10 :
        var newrecord$12 = Caml_obj.caml_obj_dup(state);
        newrecord$12.smtpPassword = action._0;
        return newrecord$12;
    case /* SetAllowOrderEntryOrderType_Order */11 :
        var newrecord$13 = Caml_obj.caml_obj_dup(state);
        newrecord$13.allowOrderEntryOrderType_Order = action._0;
        return newrecord$13;
    case /* SetAllowOrderEntryOrderType_Blanket */12 :
        var newrecord$14 = Caml_obj.caml_obj_dup(state);
        newrecord$14.allowOrderEntryOrderType_Blanket = action._0;
        return newrecord$14;
    case /* SetAllowOrderEntryOrderType_CreditMemo */13 :
        var newrecord$15 = Caml_obj.caml_obj_dup(state);
        newrecord$15.allowOrderEntryOrderType_CreditMemo = action._0;
        return newrecord$15;
    case /* SetAllowOrderEntryOrderType_DebitMemo */14 :
        var newrecord$16 = Caml_obj.caml_obj_dup(state);
        newrecord$16.allowOrderEntryOrderType_DebitMemo = action._0;
        return newrecord$16;
    case /* SetAllowOrderEntryOrderType_Invoice */15 :
        var newrecord$17 = Caml_obj.caml_obj_dup(state);
        newrecord$17.allowOrderEntryOrderType_Invoice = action._0;
        return newrecord$17;
    case /* SetAllowOrderEntryOrderType_Quote */16 :
        var newrecord$18 = Caml_obj.caml_obj_dup(state);
        newrecord$18.allowOrderEntryOrderType_Quote = action._0;
        return newrecord$18;
    case /* SetOrderEntryDefaultOrderType */17 :
        var newrecord$19 = Caml_obj.caml_obj_dup(state);
        newrecord$19.orderEntryDefaultOrderType = action._0;
        return newrecord$19;
    case /* SetDefaultOrderType */18 :
        var newrecord$20 = Caml_obj.caml_obj_dup(state);
        newrecord$20.defaultOrderType = action._0;
        return newrecord$20;
    case /* SetSiteRootWebCategoryId */19 :
        var newrecord$21 = Caml_obj.caml_obj_dup(state);
        newrecord$21.siteRootWebCategoryId = action._0;
        return newrecord$21;
    case /* SetSiteSearchRootWebCategoryId */20 :
        var newrecord$22 = Caml_obj.caml_obj_dup(state);
        newrecord$22.siteSearchRootWebCategoryId = action._0;
        return newrecord$22;
    case /* SetDefaultCurrencyCode */21 :
        var newrecord$23 = Caml_obj.caml_obj_dup(state);
        newrecord$23.defaultCurrencyCode = action._0;
        return newrecord$23;
    
  }
}

function EnterpriseSettingsEdit$Form(Props) {
  var breadcrumbLink = Props.breadcrumbLink;
  var breadcrumbText = Props.breadcrumbText;
  var instanceSettingsId = Props.instanceSettingsId;
  var state = Props.state;
  var runDelete = Props.runDelete;
  var value = function (x) {
    return {
            value: x
          };
  };
  var valueGuid = function (x) {
    return value(x !== undefined ? Caml_option.some(x) : undefined);
  };
  var useSaveMutation = function (state) {
    return GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$1(Belt_Option.map(instanceSettingsId, (function (prim) {
                          return prim;
                        })), {
                    name: value(state.name),
                    parentId: valueGuid(state.parentId),
                    allowUserSignUp: value(state.allowUserSignUp),
                    resetPasswordEmailTemplateId: valueGuid(state.resetPasswordEmailTemplateId),
                    newUserEmailTemplateId: valueGuid(state.newUserEmailTemplateId),
                    supportEmailAddress: value(state.supportEmailAddress),
                    companyName: value(state.companyName),
                    smtpServer: value(state.smtpServer),
                    smtpPort: value(state.smtpPort),
                    smtpUser: value(state.smtpUser),
                    smtpPassword: value(state.smtpPassword),
                    allowOrderEntryOrderType_Order: value(state.allowOrderEntryOrderType_Order),
                    allowOrderEntryOrderType_Blanket: value(state.allowOrderEntryOrderType_Blanket),
                    allowOrderEntryOrderType_CreditMemo: value(state.allowOrderEntryOrderType_CreditMemo),
                    allowOrderEntryOrderType_DebitMemo: value(state.allowOrderEntryOrderType_DebitMemo),
                    allowOrderEntryOrderType_Invoice: value(state.allowOrderEntryOrderType_Invoice),
                    allowOrderEntryOrderType_Quote: value(state.allowOrderEntryOrderType_Quote),
                    orderEntryDefaultOrderType: value(state.orderEntryDefaultOrderType),
                    defaultOrderType: value(state.defaultOrderType),
                    siteRootWebCategoryId: valueGuid(state.siteRootWebCategoryId),
                    siteSearchRootWebCategoryId: valueGuid(state.siteSearchRootWebCategoryId),
                    defaultCurrencyCode: value(state.defaultCurrencyCode)
                  }, undefined));
  };
  var tmp = {
    breadcrumbs: [
      {
        href: {
          NAME: "Enterprise",
          VAL: "/settings"
        },
        content: "Settings"
      },
      {
        href: {
          NAME: "Enterprise",
          VAL: "/settings" + breadcrumbLink
        },
        content: breadcrumbText
      }
    ],
    reducer: reducer,
    state: state,
    doneRoute: {
      NAME: "Enterprise",
      VAL: "/settings"
    },
    useSaveMutation: useSaveMutation,
    saveValidate: (function (state) {
        return state.smtpPortValid;
      }),
    mainContent: (function (state, dispatch) {
        var tmp = {
          orderTypes: [
            "ORDER",
            "BLANKET",
            "CREDIT_MEMO",
            "DEBIT_MEMO",
            "INVOICE",
            "QUOTE"
          ],
          label: "Order Entry Default Order Type",
          fullWidth: true,
          onChange: (function (value) {
              return Curry._1(dispatch, {
                          TAG: /* SetOrderEntryDefaultOrderType */17,
                          _0: value
                        });
            })
        };
        if (state.orderEntryDefaultOrderType !== undefined) {
          tmp.value = Caml_option.valFromOption(state.orderEntryDefaultOrderType);
        }
        var tmp$1 = {
          orderTypes: [
            "ORDER",
            "BLANKET",
            "CREDIT_MEMO",
            "DEBIT_MEMO",
            "INVOICE",
            "QUOTE"
          ],
          label: "Default Order Type",
          fullWidth: true,
          onChange: (function (value) {
              return Curry._1(dispatch, {
                          TAG: /* SetDefaultOrderType */18,
                          _0: value
                        });
            })
        };
        if (state.defaultOrderType !== undefined) {
          tmp$1.value = Caml_option.valFromOption(state.defaultOrderType);
        }
        return React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "Name",
                        value: state.name,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetName */0,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "ResetPasswordEmailTemplateId",
                        value: state.resetPasswordEmailTemplateId,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetResetPasswordEmailTemplateId */3,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "New User Email Template",
                        value: state.newUserEmailTemplateId,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetNewUserEmailTemplateId */4,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "Support Email Address",
                        value: state.supportEmailAddress,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSupportEmailAddress */5,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "Company Name",
                        value: state.companyName,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetCompanyName */6,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "SMTP Server",
                        value: state.smtpServer,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSmtpServer */7,
                                        _0: value
                                      });
                          })
                      }), React.createElement(IntField$BirddogsoftwareFrontend.make, {
                        label: "SMTP Port",
                        value: Belt_Option.map(state.smtpPort, (function (x) {
                                return {
                                        NAME: "Int",
                                        VAL: x
                                      };
                              })),
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSmtpPort */8,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "SMTP User",
                        value: state.smtpUser,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSmtpUser */9,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "SMTP Password",
                        value: state.smtpPassword,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSmtpPassword */10,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "AllowUserSignUp",
                        fullWidth: true,
                        value: state.allowUserSignUp,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowUserSignUp */2,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "Allow Order Entry Order Type Order",
                        fullWidth: true,
                        value: state.allowOrderEntryOrderType_Order,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowOrderEntryOrderType_Order */11,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "Allow Order Entry Order Type Blanket",
                        fullWidth: true,
                        value: state.allowOrderEntryOrderType_Blanket,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowOrderEntryOrderType_Blanket */12,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "Allow Order Entry Order Type CreditMemo",
                        fullWidth: true,
                        value: state.allowOrderEntryOrderType_CreditMemo,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowOrderEntryOrderType_CreditMemo */13,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "Allow Order Entry Order Type DebitMemo",
                        fullWidth: true,
                        value: state.allowOrderEntryOrderType_DebitMemo,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowOrderEntryOrderType_DebitMemo */14,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "Allow Order Entry Order Type Invoice",
                        fullWidth: true,
                        value: state.allowOrderEntryOrderType_Invoice,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowOrderEntryOrderType_Invoice */15,
                                        _0: value
                                      });
                          })
                      }), React.createElement(TrueFalseUnsetSelect$BirddogsoftwareFrontend.make, {
                        label: "Allow Order Entry Order Type Quote",
                        fullWidth: true,
                        value: state.allowOrderEntryOrderType_Quote,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetAllowOrderEntryOrderType_Quote */16,
                                        _0: value
                                      });
                          })
                      }), React.createElement(OrderTypeSelect$BirddogsoftwareFrontend.$$Option.make, tmp), React.createElement(OrderTypeSelect$BirddogsoftwareFrontend.$$Option.make, tmp$1), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "Site Root WebCategory Id",
                        value: state.resetPasswordEmailTemplateId,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSiteRootWebCategoryId */19,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "Site Search Root WebCategory Id",
                        value: state.siteSearchRootWebCategoryId,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetSiteSearchRootWebCategoryId */20,
                                        _0: value
                                      });
                          })
                      }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextFieldOption.make, {
                        label: "Default Currency Code",
                        value: state.defaultCurrencyCode,
                        onChange: (function (value) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetDefaultCurrencyCode */21,
                                        _0: value
                                      });
                          })
                      }));
      })
  };
  if (runDelete !== undefined) {
    tmp.runDelete = Caml_option.valFromOption(runDelete);
  }
  return React.createElement(SimpleForm$BirddogsoftwareFrontend.Form.make, tmp);
}

var Form = {
  reducer: reducer,
  make: EnterpriseSettingsEdit$Form
};

function EnterpriseSettingsEdit$New(Props) {
  return React.createElement(EnterpriseSettingsEdit$Form, {
              breadcrumbLink: "new",
              breadcrumbText: "New",
              instanceSettingsId: undefined,
              state: {
                smtpPortValid: true,
                name: undefined,
                parentId: undefined,
                allowUserSignUp: undefined,
                resetPasswordEmailTemplateId: undefined,
                newUserEmailTemplateId: undefined,
                supportEmailAddress: undefined,
                companyName: undefined,
                smtpServer: undefined,
                smtpPort: undefined,
                smtpUser: undefined,
                smtpPassword: undefined,
                allowOrderEntryOrderType_Order: undefined,
                allowOrderEntryOrderType_Blanket: undefined,
                allowOrderEntryOrderType_CreditMemo: undefined,
                allowOrderEntryOrderType_DebitMemo: undefined,
                allowOrderEntryOrderType_Invoice: undefined,
                allowOrderEntryOrderType_Quote: undefined,
                orderEntryDefaultOrderType: undefined,
                defaultOrderType: undefined,
                siteRootWebCategoryId: undefined,
                siteSearchRootWebCategoryId: undefined,
                defaultCurrencyCode: undefined
              }
            });
}

var New = {
  make: EnterpriseSettingsEdit$New
};

var ppx_printed_query$2 = "query Q($instanceSettingsId: Guid!)  {\ninstanceSettings(instanceSettingsId: $instanceSettingsId)  {\ninstanceSettingsId  \nname  \nparentId  \nallowUserSignUp  \nresetPasswordEmailTemplateId  \nnewUserEmailTemplateId  \nsupportEmailAddress  \ncompanyName  \nsmtpServer  \nsmtpPort  \nsmtpUser  \nsmtpPassword  \nallowOrderEntryOrderType_Order  \nallowOrderEntryOrderType_Blanket  \nallowOrderEntryOrderType_CreditMemo  \nallowOrderEntryOrderType_DebitMemo  \nallowOrderEntryOrderType_Invoice  \nallowOrderEntryOrderType_Quote  \norderEntryDefaultOrderType  \ndefaultOrderType  \nsiteRootWebCategoryId  \nsiteSearchRootWebCategoryId  \ndefaultCurrencyCode  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "instanceSettings");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "instanceSettingsId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field instanceSettingsId on type InstanceSettings is missing");
      }
      var value$8 = Js_dict.get(value$4, "name");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var match$1 = Js_json.decodeNull(value$9);
        if (match$1 !== undefined) {
          tmp$2 = undefined;
        } else {
          var value$10 = Js_json.decodeString(value$9);
          tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
        }
      } else {
        tmp$2 = undefined;
      }
      var value$11 = Js_dict.get(value$4, "parentId");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var match$2 = Js_json.decodeNull(value$12);
        tmp$3 = match$2 !== undefined ? undefined : Caml_option.some(value$12);
      } else {
        tmp$3 = undefined;
      }
      var value$13 = Js_dict.get(value$4, "allowUserSignUp");
      var tmp$4;
      if (value$13 !== undefined) {
        var value$14 = Caml_option.valFromOption(value$13);
        var match$3 = Js_json.decodeNull(value$14);
        if (match$3 !== undefined) {
          tmp$4 = undefined;
        } else {
          var value$15 = Js_json.decodeBoolean(value$14);
          tmp$4 = value$15 !== undefined ? value$15 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$14));
        }
      } else {
        tmp$4 = undefined;
      }
      var value$16 = Js_dict.get(value$4, "resetPasswordEmailTemplateId");
      var tmp$5;
      if (value$16 !== undefined) {
        var value$17 = Caml_option.valFromOption(value$16);
        var match$4 = Js_json.decodeNull(value$17);
        tmp$5 = match$4 !== undefined ? undefined : Caml_option.some(value$17);
      } else {
        tmp$5 = undefined;
      }
      var value$18 = Js_dict.get(value$4, "newUserEmailTemplateId");
      var tmp$6;
      if (value$18 !== undefined) {
        var value$19 = Caml_option.valFromOption(value$18);
        var match$5 = Js_json.decodeNull(value$19);
        tmp$6 = match$5 !== undefined ? undefined : Caml_option.some(value$19);
      } else {
        tmp$6 = undefined;
      }
      var value$20 = Js_dict.get(value$4, "supportEmailAddress");
      var tmp$7;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var match$6 = Js_json.decodeNull(value$21);
        if (match$6 !== undefined) {
          tmp$7 = undefined;
        } else {
          var value$22 = Js_json.decodeString(value$21);
          tmp$7 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
        }
      } else {
        tmp$7 = undefined;
      }
      var value$23 = Js_dict.get(value$4, "companyName");
      var tmp$8;
      if (value$23 !== undefined) {
        var value$24 = Caml_option.valFromOption(value$23);
        var match$7 = Js_json.decodeNull(value$24);
        if (match$7 !== undefined) {
          tmp$8 = undefined;
        } else {
          var value$25 = Js_json.decodeString(value$24);
          tmp$8 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
        }
      } else {
        tmp$8 = undefined;
      }
      var value$26 = Js_dict.get(value$4, "smtpServer");
      var tmp$9;
      if (value$26 !== undefined) {
        var value$27 = Caml_option.valFromOption(value$26);
        var match$8 = Js_json.decodeNull(value$27);
        if (match$8 !== undefined) {
          tmp$9 = undefined;
        } else {
          var value$28 = Js_json.decodeString(value$27);
          tmp$9 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
        }
      } else {
        tmp$9 = undefined;
      }
      var value$29 = Js_dict.get(value$4, "smtpPort");
      var tmp$10;
      if (value$29 !== undefined) {
        var value$30 = Caml_option.valFromOption(value$29);
        var match$9 = Js_json.decodeNull(value$30);
        if (match$9 !== undefined) {
          tmp$10 = undefined;
        } else {
          var value$31 = Js_json.decodeNumber(value$30);
          tmp$10 = value$31 !== undefined ? value$31 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$30));
        }
      } else {
        tmp$10 = undefined;
      }
      var value$32 = Js_dict.get(value$4, "smtpUser");
      var tmp$11;
      if (value$32 !== undefined) {
        var value$33 = Caml_option.valFromOption(value$32);
        var match$10 = Js_json.decodeNull(value$33);
        if (match$10 !== undefined) {
          tmp$11 = undefined;
        } else {
          var value$34 = Js_json.decodeString(value$33);
          tmp$11 = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$33));
        }
      } else {
        tmp$11 = undefined;
      }
      var value$35 = Js_dict.get(value$4, "smtpPassword");
      var tmp$12;
      if (value$35 !== undefined) {
        var value$36 = Caml_option.valFromOption(value$35);
        var match$11 = Js_json.decodeNull(value$36);
        if (match$11 !== undefined) {
          tmp$12 = undefined;
        } else {
          var value$37 = Js_json.decodeString(value$36);
          tmp$12 = value$37 !== undefined ? value$37 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$36));
        }
      } else {
        tmp$12 = undefined;
      }
      var value$38 = Js_dict.get(value$4, "allowOrderEntryOrderType_Order");
      var tmp$13;
      if (value$38 !== undefined) {
        var value$39 = Caml_option.valFromOption(value$38);
        var match$12 = Js_json.decodeNull(value$39);
        if (match$12 !== undefined) {
          tmp$13 = undefined;
        } else {
          var value$40 = Js_json.decodeBoolean(value$39);
          tmp$13 = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$39));
        }
      } else {
        tmp$13 = undefined;
      }
      var value$41 = Js_dict.get(value$4, "allowOrderEntryOrderType_Blanket");
      var tmp$14;
      if (value$41 !== undefined) {
        var value$42 = Caml_option.valFromOption(value$41);
        var match$13 = Js_json.decodeNull(value$42);
        if (match$13 !== undefined) {
          tmp$14 = undefined;
        } else {
          var value$43 = Js_json.decodeBoolean(value$42);
          tmp$14 = value$43 !== undefined ? value$43 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$42));
        }
      } else {
        tmp$14 = undefined;
      }
      var value$44 = Js_dict.get(value$4, "allowOrderEntryOrderType_CreditMemo");
      var tmp$15;
      if (value$44 !== undefined) {
        var value$45 = Caml_option.valFromOption(value$44);
        var match$14 = Js_json.decodeNull(value$45);
        if (match$14 !== undefined) {
          tmp$15 = undefined;
        } else {
          var value$46 = Js_json.decodeBoolean(value$45);
          tmp$15 = value$46 !== undefined ? value$46 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$45));
        }
      } else {
        tmp$15 = undefined;
      }
      var value$47 = Js_dict.get(value$4, "allowOrderEntryOrderType_DebitMemo");
      var tmp$16;
      if (value$47 !== undefined) {
        var value$48 = Caml_option.valFromOption(value$47);
        var match$15 = Js_json.decodeNull(value$48);
        if (match$15 !== undefined) {
          tmp$16 = undefined;
        } else {
          var value$49 = Js_json.decodeBoolean(value$48);
          tmp$16 = value$49 !== undefined ? value$49 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$48));
        }
      } else {
        tmp$16 = undefined;
      }
      var value$50 = Js_dict.get(value$4, "allowOrderEntryOrderType_Invoice");
      var tmp$17;
      if (value$50 !== undefined) {
        var value$51 = Caml_option.valFromOption(value$50);
        var match$16 = Js_json.decodeNull(value$51);
        if (match$16 !== undefined) {
          tmp$17 = undefined;
        } else {
          var value$52 = Js_json.decodeBoolean(value$51);
          tmp$17 = value$52 !== undefined ? value$52 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$51));
        }
      } else {
        tmp$17 = undefined;
      }
      var value$53 = Js_dict.get(value$4, "allowOrderEntryOrderType_Quote");
      var tmp$18;
      if (value$53 !== undefined) {
        var value$54 = Caml_option.valFromOption(value$53);
        var match$17 = Js_json.decodeNull(value$54);
        if (match$17 !== undefined) {
          tmp$18 = undefined;
        } else {
          var value$55 = Js_json.decodeBoolean(value$54);
          tmp$18 = value$55 !== undefined ? value$55 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$54));
        }
      } else {
        tmp$18 = undefined;
      }
      var value$56 = Js_dict.get(value$4, "orderEntryDefaultOrderType");
      var tmp$19;
      if (value$56 !== undefined) {
        var value$57 = Caml_option.valFromOption(value$56);
        var match$18 = Js_json.decodeNull(value$57);
        if (match$18 !== undefined) {
          tmp$19 = undefined;
        } else {
          var value$58 = Js_json.decodeString(value$57);
          var tmp$20;
          if (value$58 !== undefined) {
            switch (value$58) {
              case "BLANKET" :
                  tmp$20 = "BLANKET";
                  break;
              case "CREDIT_MEMO" :
                  tmp$20 = "CREDIT_MEMO";
                  break;
              case "DEBIT_MEMO" :
                  tmp$20 = "DEBIT_MEMO";
                  break;
              case "EMPTY" :
                  tmp$20 = "EMPTY";
                  break;
              case "INVOICE" :
                  tmp$20 = "INVOICE";
                  break;
              case "L_CLOSED" :
                  tmp$20 = "L_CLOSED";
                  break;
              case "MASTER_ORDER" :
                  tmp$20 = "MASTER_ORDER";
                  break;
              case "ORDER" :
                  tmp$20 = "ORDER";
                  break;
              case "PRODUCTION_ORDER" :
                  tmp$20 = "PRODUCTION_ORDER";
                  break;
              case "QUOTE" :
                  tmp$20 = "QUOTE";
                  break;
              case "SHOP_FLOOR_ORDER" :
                  tmp$20 = "SHOP_FLOOR_ORDER";
                  break;
              case "T" :
                  tmp$20 = "T";
                  break;
              default:
                tmp$20 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderType: " + value$58);
            }
          } else {
            tmp$20 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderType, got " + JSON.stringify(value$57));
          }
          tmp$19 = tmp$20;
        }
      } else {
        tmp$19 = undefined;
      }
      var value$59 = Js_dict.get(value$4, "defaultOrderType");
      var tmp$21;
      if (value$59 !== undefined) {
        var value$60 = Caml_option.valFromOption(value$59);
        var match$19 = Js_json.decodeNull(value$60);
        if (match$19 !== undefined) {
          tmp$21 = undefined;
        } else {
          var value$61 = Js_json.decodeString(value$60);
          var tmp$22;
          if (value$61 !== undefined) {
            switch (value$61) {
              case "BLANKET" :
                  tmp$22 = "BLANKET";
                  break;
              case "CREDIT_MEMO" :
                  tmp$22 = "CREDIT_MEMO";
                  break;
              case "DEBIT_MEMO" :
                  tmp$22 = "DEBIT_MEMO";
                  break;
              case "EMPTY" :
                  tmp$22 = "EMPTY";
                  break;
              case "INVOICE" :
                  tmp$22 = "INVOICE";
                  break;
              case "L_CLOSED" :
                  tmp$22 = "L_CLOSED";
                  break;
              case "MASTER_ORDER" :
                  tmp$22 = "MASTER_ORDER";
                  break;
              case "ORDER" :
                  tmp$22 = "ORDER";
                  break;
              case "PRODUCTION_ORDER" :
                  tmp$22 = "PRODUCTION_ORDER";
                  break;
              case "QUOTE" :
                  tmp$22 = "QUOTE";
                  break;
              case "SHOP_FLOOR_ORDER" :
                  tmp$22 = "SHOP_FLOOR_ORDER";
                  break;
              case "T" :
                  tmp$22 = "T";
                  break;
              default:
                tmp$22 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderType: " + value$61);
            }
          } else {
            tmp$22 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderType, got " + JSON.stringify(value$60));
          }
          tmp$21 = tmp$22;
        }
      } else {
        tmp$21 = undefined;
      }
      var value$62 = Js_dict.get(value$4, "siteRootWebCategoryId");
      var tmp$23;
      if (value$62 !== undefined) {
        var value$63 = Caml_option.valFromOption(value$62);
        var match$20 = Js_json.decodeNull(value$63);
        tmp$23 = match$20 !== undefined ? undefined : Caml_option.some(value$63);
      } else {
        tmp$23 = undefined;
      }
      var value$64 = Js_dict.get(value$4, "siteSearchRootWebCategoryId");
      var tmp$24;
      if (value$64 !== undefined) {
        var value$65 = Caml_option.valFromOption(value$64);
        var match$21 = Js_json.decodeNull(value$65);
        tmp$24 = match$21 !== undefined ? undefined : Caml_option.some(value$65);
      } else {
        tmp$24 = undefined;
      }
      var value$66 = Js_dict.get(value$4, "defaultCurrencyCode");
      var tmp$25;
      if (value$66 !== undefined) {
        var value$67 = Caml_option.valFromOption(value$66);
        var match$22 = Js_json.decodeNull(value$67);
        if (match$22 !== undefined) {
          tmp$25 = undefined;
        } else {
          var value$68 = Js_json.decodeString(value$67);
          tmp$25 = value$68 !== undefined ? value$68 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$67));
        }
      } else {
        tmp$25 = undefined;
      }
      tmp = {
        instanceSettingsId: tmp$1,
        name: tmp$2,
        parentId: tmp$3,
        allowUserSignUp: tmp$4,
        resetPasswordEmailTemplateId: tmp$5,
        newUserEmailTemplateId: tmp$6,
        supportEmailAddress: tmp$7,
        companyName: tmp$8,
        smtpServer: tmp$9,
        smtpPort: tmp$10,
        smtpUser: tmp$11,
        smtpPassword: tmp$12,
        allowOrderEntryOrderType_Order: tmp$13,
        allowOrderEntryOrderType_Blanket: tmp$14,
        allowOrderEntryOrderType_CreditMemo: tmp$15,
        allowOrderEntryOrderType_DebitMemo: tmp$16,
        allowOrderEntryOrderType_Invoice: tmp$17,
        allowOrderEntryOrderType_Quote: tmp$18,
        orderEntryDefaultOrderType: tmp$19,
        defaultOrderType: tmp$21,
        siteRootWebCategoryId: tmp$23,
        siteSearchRootWebCategoryId: tmp$24,
        defaultCurrencyCode: tmp$25
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          instanceSettings: tmp
        };
}

function make$2(instanceSettingsId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "instanceSettingsId",
                    instanceSettingsId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var instanceSettingsId = variables.instanceSettingsId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "instanceSettingsId",
                    instanceSettingsId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(instanceSettingsId, param) {
  return Js_dict.fromArray([[
                  "instanceSettingsId",
                  instanceSettingsId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, instanceSettingsId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "instanceSettingsId",
                      instanceSettingsId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var UserQuery = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function EnterpriseSettingsEdit$Edit(Props) {
  var instanceSettingsId = Props.instanceSettingsId;
  var getQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$2(instanceSettingsId, undefined), undefined, undefined);
  var deleteMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(instanceSettingsId, undefined));
  var decodeStringUnsafe = function (x) {
    return Belt_Option.map(x, (function (x) {
                  return Belt_Option.getExn(Js_json.decodeString(x));
                }));
  };
  return React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
              queryResponse: getQuery.result,
              children: (function (result) {
                  var instanceSettings = result.instanceSettings;
                  if (instanceSettings === undefined) {
                    return "Not found";
                  }
                  var instanceSettings$1 = Caml_option.valFromOption(instanceSettings);
                  var x = instanceSettings$1.name;
                  return React.createElement(EnterpriseSettingsEdit$Form, {
                              breadcrumbLink: instanceSettingsId,
                              breadcrumbText: x !== undefined ? x : "Global",
                              instanceSettingsId: instanceSettingsId,
                              state: {
                                smtpPortValid: true,
                                name: instanceSettings$1.name,
                                parentId: decodeStringUnsafe(instanceSettings$1.parentId),
                                allowUserSignUp: instanceSettings$1.allowUserSignUp,
                                resetPasswordEmailTemplateId: decodeStringUnsafe(instanceSettings$1.resetPasswordEmailTemplateId),
                                newUserEmailTemplateId: decodeStringUnsafe(instanceSettings$1.newUserEmailTemplateId),
                                supportEmailAddress: instanceSettings$1.supportEmailAddress,
                                companyName: instanceSettings$1.companyName,
                                smtpServer: instanceSettings$1.smtpServer,
                                smtpPort: instanceSettings$1.smtpPort,
                                smtpUser: instanceSettings$1.smtpUser,
                                smtpPassword: instanceSettings$1.smtpPassword,
                                allowOrderEntryOrderType_Order: instanceSettings$1.allowOrderEntryOrderType_Order,
                                allowOrderEntryOrderType_Blanket: instanceSettings$1.allowOrderEntryOrderType_Blanket,
                                allowOrderEntryOrderType_CreditMemo: instanceSettings$1.allowOrderEntryOrderType_CreditMemo,
                                allowOrderEntryOrderType_DebitMemo: instanceSettings$1.allowOrderEntryOrderType_DebitMemo,
                                allowOrderEntryOrderType_Invoice: instanceSettings$1.allowOrderEntryOrderType_Invoice,
                                allowOrderEntryOrderType_Quote: instanceSettings$1.allowOrderEntryOrderType_Quote,
                                orderEntryDefaultOrderType: instanceSettings$1.orderEntryDefaultOrderType,
                                defaultOrderType: instanceSettings$1.defaultOrderType,
                                siteRootWebCategoryId: decodeStringUnsafe(instanceSettings$1.siteRootWebCategoryId),
                                siteSearchRootWebCategoryId: decodeStringUnsafe(instanceSettings$1.siteSearchRootWebCategoryId),
                                defaultCurrencyCode: instanceSettings$1.defaultCurrencyCode
                              },
                              runDelete: (function (param) {
                                  return Curry._1(deleteMutation.runMutation, undefined);
                                })
                            });
                })
            });
}

var Edit = {
  make: EnterpriseSettingsEdit$Edit
};

exports.InstanceSettingsDeleteMutation = InstanceSettingsDeleteMutation;
exports.InstanceSettingsMutation = InstanceSettingsMutation;
exports.Form = Form;
exports.New = New;
exports.UserQuery = UserQuery;
exports.Edit = Edit;
/* react Not a pure module */
