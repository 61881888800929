'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var MaterialUi_Dialog = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Dialog.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var MaterialUi_DialogTitle = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_DialogTitle.bs.js");
var MaterialUi_DialogActions = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_DialogActions.bs.js");
var MaterialUi_DialogContent = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_DialogContent.bs.js");
var Fields$BirddogsoftwareFrontend = require("../../../../Components/Forms/Fields.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var ItemLocationSelect$BirddogsoftwareFrontend = require("../../../../Components/Forms/Pickers/ItemLocationSelect.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* SendStartSave */0) {
      return {
              quantityOrdered: state.quantityOrdered,
              quantityToShip: state.quantityToShip,
              comments: state.comments,
              discountPercent: state.discountPercent,
              locationCode: state.locationCode,
              promiseDate: state.promiseDate,
              requestDate: state.requestDate,
              price: state.price,
              saveState: /* Saving */1
            };
    } else {
      return {
              quantityOrdered: state.quantityOrdered,
              quantityToShip: state.quantityToShip,
              comments: state.comments,
              discountPercent: state.discountPercent,
              locationCode: state.locationCode,
              promiseDate: state.promiseDate,
              requestDate: state.requestDate,
              price: state.price,
              saveState: /* Saved */2
            };
    }
  }
  switch (action.TAG | 0) {
    case /* SetQuantityOrdered */0 :
        return {
                quantityOrdered: action._0,
                quantityToShip: state.quantityToShip,
                comments: state.comments,
                discountPercent: state.discountPercent,
                locationCode: state.locationCode,
                promiseDate: state.promiseDate,
                requestDate: state.requestDate,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetQuantityToShip */1 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: action._0,
                comments: state.comments,
                discountPercent: state.discountPercent,
                locationCode: state.locationCode,
                promiseDate: state.promiseDate,
                requestDate: state.requestDate,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetComments */2 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: state.quantityToShip,
                comments: action._0,
                discountPercent: state.discountPercent,
                locationCode: state.locationCode,
                promiseDate: state.promiseDate,
                requestDate: state.requestDate,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetDiscountPercent */3 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: state.quantityToShip,
                comments: state.comments,
                discountPercent: action._0,
                locationCode: state.locationCode,
                promiseDate: state.promiseDate,
                requestDate: state.requestDate,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetLocationCode */4 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: state.quantityToShip,
                comments: state.comments,
                discountPercent: state.discountPercent,
                locationCode: action._0,
                promiseDate: state.promiseDate,
                requestDate: state.requestDate,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetPromiseDate */5 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: state.quantityToShip,
                comments: state.comments,
                discountPercent: state.discountPercent,
                locationCode: state.locationCode,
                promiseDate: action._0,
                requestDate: state.requestDate,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetRequestDate */6 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: state.quantityToShip,
                comments: state.comments,
                discountPercent: state.discountPercent,
                locationCode: state.locationCode,
                promiseDate: state.promiseDate,
                requestDate: action._0,
                price: state.price,
                saveState: state.saveState
              };
    case /* SetPrice */7 :
        return {
                quantityOrdered: state.quantityOrdered,
                quantityToShip: state.quantityToShip,
                comments: state.comments,
                discountPercent: state.discountPercent,
                locationCode: state.locationCode,
                promiseDate: state.promiseDate,
                requestDate: state.requestDate,
                price: action._0,
                saveState: state.saveState
              };
    
  }
}

function classRecordToJs(param) {
  return {
          root: param.root,
          item: param.item
        };
}

var classes = {
  TAG: /* ThemeFunc */1,
  _0: (function (theme) {
      return {
              root: {},
              item: {}
            };
    })
};

function classRecordStringsFromJs(arg) {
  return {
          root: arg.root,
          item: arg.item
        };
}

var EnterpriseOrderLineAddStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var EnterpriseOrderLineAddStyles = MaterialUi_WithStyles.WithStylesSafe(EnterpriseOrderLineAddStylesDefs);

function EnterpriseOrderLineEdit(Props) {
  var orderLine = Props.orderLine;
  var useLineEdit = Props.useLineEdit;
  var onSave = Props.onSave;
  var onClose = Props.onClose;
  var match = React.useReducer(reducer, {
        quantityOrdered: orderLine.quantityOrdered,
        quantityToShip: orderLine.quantityToShip,
        comments: orderLine.comments,
        discountPercent: orderLine.discountPercent,
        locationCode: orderLine.locationCode,
        promiseDate: Caml_option.some(orderLine.promiseDate),
        requestDate: Caml_option.some(orderLine.requestDate),
        price: orderLine.price,
        saveState: /* NotSaved */0
      });
  var dispatch = match[1];
  var state = match[0];
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var success = SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  var lineEdit = Curry._2(useLineEdit, orderLine, {
        quantityOrdered: state.quantityOrdered,
        quantityToShip: state.quantityToShip,
        comments: state.comments,
        discountPercent: state.discountPercent,
        locationCode: state.locationCode,
        promiseDate: state.promiseDate,
        requestDate: state.requestDate,
        price: state.price
      });
  var isValid = Belt_List.has({
        hd: Belt_Option.isNone(state.quantityOrdered),
        tl: {
          hd: Belt_Option.isNone(state.quantityToShip),
          tl: {
            hd: Belt_Option.isNone(state.discountPercent),
            tl: {
              hd: Belt_Option.isNone(state.promiseDate),
              tl: {
                hd: Belt_Option.isNone(state.requestDate),
                tl: {
                  hd: Belt_Option.isNone(state.price),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }, true, (function (prim, prim$1) {
          return prim === prim$1;
        }));
  var match$1 = state.saveState;
  var disabled = match$1 !== 0;
  return React.createElement(EnterpriseOrderLineAddStyles.make, {
              children: (function (classes) {
                  return React.createElement(Core.Dialog, MaterialUi_Dialog.makeProps(undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                                    return Curry._1(onClose, undefined);
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.DialogTitle, MaterialUi_DialogTitle.makeProps(Caml_option.some(React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Edit line", undefined, "Inherit", undefined, undefined, undefined, true, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.DialogContent, MaterialUi_DialogContent.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                                      label: "Quantity Ordered",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      defaultValue: state.quantityOrdered,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetQuantityOrdered */0,
                                                      _0: value
                                                    });
                                        })
                                    }), React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                                      label: "Quantity To Ship",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      defaultValue: state.quantityToShip,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetQuantityToShip */1,
                                                      _0: value
                                                    });
                                        })
                                    }), React.createElement(ItemLocationSelect$BirddogsoftwareFrontend.make, {
                                      itemNo: orderLine.itemNo,
                                      label: "Location",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      value: state.locationCode,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetLocationCode */4,
                                                      _0: value
                                                    });
                                        })
                                    }), React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                                      label: "Unit Price",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      defaultValue: state.price,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetPrice */7,
                                                      _0: value
                                                    });
                                        })
                                    }), React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                                      label: "Discount %",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      defaultValue: state.discountPercent,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetDiscountPercent */3,
                                                      _0: value
                                                    });
                                        })
                                    }), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.comments, disabled, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Comments", "Dense", true, undefined, undefined, (function ($$event) {
                                            return Curry._1(dispatch, {
                                                        TAG: /* SetComments */2,
                                                        _0: $$event.target.value
                                                      });
                                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Fields$BirddogsoftwareFrontend.$$Date.Required.make, {
                                      label: "Request Date",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      defaultValue: state.requestDate,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetRequestDate */6,
                                                      _0: value
                                                    });
                                        })
                                    }), React.createElement(Fields$BirddogsoftwareFrontend.$$Date.Required.make, {
                                      label: "Promise Date",
                                      fullWidth: true,
                                      disabled: disabled,
                                      margin: "Dense",
                                      defaultValue: state.promiseDate,
                                      onChange: (function (value) {
                                          return Curry._1(dispatch, {
                                                      TAG: /* SetPromiseDate */5,
                                                      _0: value
                                                    });
                                        })
                                    })), React.createElement(Core.DialogActions, MaterialUi_DialogActions.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Button, MaterialUi_Button.makeProps("Cancel", undefined, "Primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "submit", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                            return Curry._1(onClose, undefined);
                                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Button, MaterialUi_Button.makeProps("Save", undefined, "Primary", undefined, isValid, undefined, undefined, undefined, undefined, undefined, undefined, "submit", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                            if (isValid) {
                                              return ;
                                            } else {
                                              Curry._1(dispatch, /* SendStartSave */0);
                                              Curry._1(lineEdit, undefined).then(function (response) {
                                                    if (response.TAG) {
                                                      Curry._1(onSave, undefined);
                                                      Curry._1(onClose, undefined);
                                                      Curry._1(success, "Saved line.");
                                                      Curry._1(dispatch, /* SendFinishedSave */1);
                                                    } else {
                                                      Curry._1(dispatch, /* SendFinishedSave */1);
                                                      Curry._1(error, response._0);
                                                    }
                                                    return Promise.resolve(undefined);
                                                  });
                                              return ;
                                            }
                                          }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
                })
            });
}

var make = EnterpriseOrderLineEdit;

exports.reducer = reducer;
exports.EnterpriseOrderLineAddStylesDefs = EnterpriseOrderLineAddStylesDefs;
exports.EnterpriseOrderLineAddStyles = EnterpriseOrderLineAddStyles;
exports.make = make;
/* EnterpriseOrderLineAddStyles Not a pure module */
