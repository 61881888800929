'use strict';

var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Role$BirddogsoftwareFrontend = require("../Types/Role.bs.js");
var Grammer$BirddogsoftwareFrontend = require("../Utils/Grammer.bs.js");
var AvatarLockout$BirddogsoftwareFrontend = require("./AvatarLockout.bs.js");

function RequireRole$MissingRoles(Props) {
  var roles = Props.roles;
  var allOrAny = Props.allOrAny;
  var rolesString = (
      allOrAny ? Grammer$BirddogsoftwareFrontend.joinWithOxfordOr : Grammer$BirddogsoftwareFrontend.joinWithOxfordAnd
    )(roles.map(Role$BirddogsoftwareFrontend.stringify));
  return React.createElement(AvatarLockout$BirddogsoftwareFrontend.make, {
              title: "You dont have the role" + ((
                  roles.length !== 1 ? "s" : ""
                ) + (" " + (rolesString + "."))),
              children: React.createElement("p", undefined, "The " + (rolesString + (" role" + ((
                          allOrAny || roles.length === 1 ? " is" : "s are"
                        ) + " required to access this page. Contact an admin for assistance."))))
            });
}

var MissingRoles = {
  make: RequireRole$MissingRoles
};

function RequireRole(Props) {
  var store = Props.store;
  var roles = Props.roles;
  var allOrAny = Props.allOrAny;
  var ifAnon = Props.ifAnon;
  var children = Props.children;
  var user = store.state.user;
  if (!user) {
    return ifAnon;
  }
  var user$1 = user._0;
  var missingRoles = Belt_Array.keep(roles, (function (role) {
          return !Belt_Array.some(user$1.builtInRoles, (function (userRole) {
                        return userRole === role;
                      }));
        }));
  var missingRoles$1;
  if (allOrAny) {
    var missingRolesLen = missingRoles.length;
    var rolesLen = roles.length;
    missingRoles$1 = missingRolesLen === rolesLen ? missingRoles : [];
  } else {
    missingRoles$1 = missingRoles;
  }
  if (missingRoles$1.length !== 0) {
    return React.createElement(RequireRole$MissingRoles, {
                roles: missingRoles$1,
                allOrAny: allOrAny
              });
  } else {
    return children;
  }
}

var make = RequireRole;

exports.MissingRoles = MissingRoles;
exports.make = make;
/* react Not a pure module */
