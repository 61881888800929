'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialTable = require("material-table");
var Edit = require("@material-ui/icons/Edit");
var Check = require("@material-ui/icons/Check");
var Clear = require("@material-ui/icons/Clear");
var AddBox = require("@material-ui/icons/AddBox");
var Remove = require("@material-ui/icons/Remove");
var Search = require("@material-ui/icons/Search");
var SaveAlt = require("@material-ui/icons/SaveAlt");
var LastPage = require("@material-ui/icons/LastPage");
var FirstPage = require("@material-ui/icons/FirstPage");
var FilterList = require("@material-ui/icons/FilterList");
var ViewColumn = require("@material-ui/icons/ViewColumn");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ChevronLeft = require("@material-ui/icons/ChevronLeft");
var ChevronRight = require("@material-ui/icons/ChevronRight");
var DeleteOutline = require("@material-ui/icons/DeleteOutline");
var SnackbarHooks$BirddogsoftwareFrontend = require("./SnackbarHooks.bs.js");

var _map = {"Boolean":"boolean","Numeric":"numeric","Date":"date","Datetime":"datetime","Time":"time","Currency":"currency"};

var _revMap = {"boolean":"Boolean","numeric":"Numeric","date":"Date","datetime":"Datetime","time":"Time","currency":"Currency"};

function columnTypeToJs(param) {
  return _map[param];
}

function columnTypeFromJs(param) {
  return _revMap[param];
}

function MaterialTable$TableNoIcons(Props) {
  var columns = Props.columns;
  var data = Props.data;
  var isLoading = Props.isLoading;
  var title = Props.title;
  var options = Props.options;
  var components = Props.components;
  var icons = Props.icons;
  var tmp = {
    columns: Belt_Array.map(columns, (function (col) {
            return {
                    title: Caml_option.undefined_to_opt(col.title),
                    field: Caml_option.undefined_to_opt(col.field),
                    render: Caml_option.undefined_to_opt(col.render),
                    currencySetting: Caml_option.undefined_to_opt(col.currencySetting),
                    customFilterAndSearch: Caml_option.undefined_to_opt(col.customFilterAndSearch),
                    type: Belt_Option.map(Caml_option.undefined_to_opt(col.type), columnTypeToJs)
                  };
          })),
    data: data,
    title: title,
    options: options
  };
  if (isLoading !== undefined) {
    tmp.isLoading = Caml_option.valFromOption(isLoading);
  }
  if (components !== undefined) {
    tmp.components = Caml_option.valFromOption(components);
  }
  if (icons !== undefined) {
    tmp.icons = Caml_option.valFromOption(icons);
  }
  return React.createElement(MaterialTable.default, tmp);
}

var TableNoIcons = {
  make: MaterialTable$TableNoIcons
};

function MaterialTable$Table(Props) {
  var columns = Props.columns;
  var data = Props.data;
  var isLoading = Props.isLoading;
  var title = Props.title;
  var options = Props.options;
  var components = Props.components;
  var tmp = {
    columns: columns,
    data: data,
    icons: {
      Add: AddBox.default,
      Check: Check.default,
      Clear: Clear.default,
      Delete: DeleteOutline.default,
      DetailPanel: ChevronRight.default,
      Edit: Edit.default,
      Export: SaveAlt.default,
      Filter: FilterList.default,
      FirstPage: FirstPage.default,
      LastPage: LastPage.default,
      NextPage: ChevronRight.default,
      PreviousPage: ChevronLeft.default,
      ResetSearch: Clear.default,
      Search: Search.default,
      SortArrow: ArrowUpward.default,
      ThirdStateCheck: Remove.default,
      ViewColumn: ViewColumn.default
    }
  };
  if (isLoading !== undefined) {
    tmp.isLoading = Caml_option.valFromOption(isLoading);
  }
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (options !== undefined) {
    tmp.options = Caml_option.valFromOption(options);
  }
  if (components !== undefined) {
    tmp.components = Caml_option.valFromOption(components);
  }
  return React.createElement(MaterialTable$TableNoIcons, tmp);
}

var Table = {
  make: MaterialTable$Table
};

function divContainer(props) {
  return React.createElement("div", undefined, props.children);
}

function MaterialTable$GraphQL(Props) {
  var columns = Props.columns;
  var query = Props.query;
  var title = Props.title;
  var options = Props.options;
  var components = Props.components;
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var match;
  if (typeof query === "number") {
    match = [
      true,
      []
    ];
  } else if (query.TAG) {
    match = [
      false,
      query._0
    ];
  } else {
    Curry._1(error, query._0);
    match = [
      false,
      []
    ];
  }
  var tmp = {
    columns: columns,
    data: match[1],
    isLoading: match[0]
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (options !== undefined) {
    tmp.options = Caml_option.valFromOption(options);
  }
  if (components !== undefined) {
    tmp.components = Caml_option.valFromOption(components);
  }
  return React.createElement(MaterialTable$Table, tmp);
}

var GraphQL = {
  make: MaterialTable$GraphQL
};

exports.columnTypeToJs = columnTypeToJs;
exports.columnTypeFromJs = columnTypeFromJs;
exports.TableNoIcons = TableNoIcons;
exports.Table = Table;
exports.divContainer = divContainer;
exports.GraphQL = GraphQL;
/* react Not a pure module */
