'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_ListItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItem.bs.js");
var MaterialUi_ListItemIcon = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItemIcon.bs.js");
var MaterialUi_ListItemText = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ListItemText.bs.js");
var Link$BirddogsoftwareFrontend = require("./Link.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../Modules/UrlResolver.bs.js");

function ListItemLink(Props) {
  var icon = Props.icon;
  var text = Props.text;
  var href = Props.href;
  var resolved = UrlResolver$BirddogsoftwareFrontend.useResolved(href);
  return React.createElement(Core.ListItem, MaterialUi_ListItem.makeProps(undefined, undefined, true, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function ($$event) {
                    return Link$BirddogsoftwareFrontend.handleClick($$event, resolved.isExternal, resolved.url, undefined, undefined);
                  }), text, undefined, undefined, undefined, undefined), React.createElement(Core.ListItemIcon, MaterialUi_ListItemIcon.makeProps(Caml_option.some(icon), undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.ListItemText, MaterialUi_ListItemText.makeProps(undefined, undefined, undefined, undefined, Caml_option.some(text), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make = ListItemLink;

exports.make = make;
/* react Not a pure module */
