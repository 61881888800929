'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_ThemeOptions = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ThemeOptions.bs.js");
var Styles = require("@material-ui/core/styles");
var MaterialUi_ThemeProvider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ThemeProvider.bs.js");
var ModuleUtils$BirddogsoftwareFrontend = require("../ModuleUtils.bs.js");
var CartQuickAdd$BirddogsoftwareFrontend = require("./Cart/CartQuickAdd/CartQuickAdd.bs.js");
var EcommerceHome$BirddogsoftwareFrontend = require("./EcommerceHome.bs.js");
var EcommerceReset$BirddogsoftwareFrontend = require("./signin/EcommerceReset.bs.js");
var EcommerceForgot$BirddogsoftwareFrontend = require("./signin/EcommerceForgot.bs.js");
var EcommerceSearch$BirddogsoftwareFrontend = require("./Search/EcommerceSearch.bs.js");
var EcommerceSignIn$BirddogsoftwareFrontend = require("./signin/EcommerceSignIn.bs.js");
var EcommerceSignUp$BirddogsoftwareFrontend = require("./signin/EcommerceSignUp.bs.js");

function route(url, store) {
  return ModuleUtils$BirddogsoftwareFrontend.generateModuleRoutes((function (store) {
                return React.createElement(EcommerceSignIn$BirddogsoftwareFrontend.make, {
                            signUpLink: {
                              NAME: "Ecommerce",
                              VAL: "/signup"
                            },
                            forgotLink: {
                              NAME: "Ecommerce",
                              VAL: "/forgot"
                            },
                            store: store
                          });
              }), (function (store) {
                return React.createElement(EcommerceSignUp$BirddogsoftwareFrontend.make, {
                            signInLink: {
                              NAME: "Ecommerce",
                              VAL: "/signin"
                            },
                            forgotLink: {
                              NAME: "Ecommerce",
                              VAL: "/forgot"
                            },
                            store: store
                          });
              }), (function (store) {
                return React.createElement(EcommerceForgot$BirddogsoftwareFrontend.make, {
                            signInLink: {
                              NAME: "Ecommerce",
                              VAL: "/signin"
                            },
                            store: store
                          });
              }), (function (store, tokenId) {
                return React.createElement(EcommerceReset$BirddogsoftwareFrontend.make, {
                            tokenId: tokenId,
                            store: store
                          });
              }), (function (url, store) {
                var match = url.path;
                if (!match) {
                  return React.createElement(EcommerceHome$BirddogsoftwareFrontend.make, {});
                }
                switch (match.hd) {
                  case "cart" :
                      var match$1 = match.tl;
                      if (match$1 && match$1.hd === "quick-add" && !match$1.tl) {
                        return React.createElement(CartQuickAdd$BirddogsoftwareFrontend.make, {});
                      } else {
                        return "404 not found ecommerce";
                      }
                  case "search" :
                      var match$2 = match.tl;
                      if (match$2) {
                        if (match$2.tl) {
                          return "404 not found ecommerce";
                        } else {
                          return React.createElement(EcommerceSearch$BirddogsoftwareFrontend.Id.make, {
                                      rootWebCategoryId: match$2.hd
                                    });
                        }
                      } else {
                        return React.createElement(EcommerceSearch$BirddogsoftwareFrontend.NoId.make, {});
                      }
                  default:
                    return "404 not found ecommerce";
                }
              }), url, store);
}

var theme = Styles.createMuiTheme(MaterialUi_ThemeOptions.make(undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.PaletteOptions.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.Primary.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#000000", undefined)), undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.Typography.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined));

function EcommerceModule(Props) {
  var url = Props.url;
  var store = Props.store;
  return React.createElement(MaterialUi_ThemeProvider.make, {
              children: route(url, store),
              theme: theme
            });
}

var make = EcommerceModule;

exports.route = route;
exports.theme = theme;
exports.make = make;
/* theme Not a pure module */
