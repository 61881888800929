'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var BarcodeListener$BirddogsoftwareFrontend = require("../../../../Utils/BarcodeListener.bs.js");

function EnterpriseOrderBarcodeHandler$AddLine(Props) {
  var useLineAdd = Props.useLineAdd;
  var line = Props.line;
  var onFinish = Props.onFinish;
  var match = React.useReducer((function (state, action) {
          return action;
        }), false);
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var success = SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  var lineAdd = Curry._2(useLineAdd, line.itemNo, {
        quantityOrdered: line.qty,
        comments: undefined,
        discountPercent: undefined,
        locationCode: undefined,
        promiseDate: undefined,
        quantityToShip: undefined,
        requestDate: undefined,
        price: undefined
      });
  if (match[0]) {
    
  } else {
    Curry._1(match[1], true);
    console.log("starting add");
    Curry._1(lineAdd, undefined).then(function (response) {
          if (response.TAG) {
            var data = response._0;
            if (data.NAME === "Success") {
              Curry._1(success, "Added " + line.itemNo);
            } else if (data.VAL === "INVALID_ITEM") {
              Curry._1(error, "Invalid item " + line.itemNo);
            } else {
              Curry._1(error, "unexpected response");
            }
          } else {
            Curry._1(error, response._0);
          }
          Curry._1(onFinish, undefined);
          return Promise.resolve(undefined);
        });
  }
  return React.createElement(React.Fragment, undefined);
}

var AddLine = {
  make: EnterpriseOrderBarcodeHandler$AddLine
};

function reducer(state, action) {
  if (!action) {
    if (state) {
      return state.tl;
    } else {
      return /* [] */0;
    }
  }
  var line = action._0;
  if (state) {
    return Pervasives.$at(state, {
                hd: line,
                tl: /* [] */0
              });
  } else {
    return {
            hd: line,
            tl: /* [] */0
          };
  }
}

function EnterpriseOrderBarcodeHandler(Props) {
  var useLineAdd = Props.useLineAdd;
  var refetch = Props.refetch;
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var match = React.useReducer(reducer, /* [] */0);
  var dispatch = match[1];
  var state = match[0];
  BarcodeListener$BirddogsoftwareFrontend.useBarcodeListener(";", (function (itemNo) {
          return Curry._1(dispatch, /* Push */{
                      _0: {
                        itemNo: itemNo,
                        qty: 1.0
                      }
                    });
        }), (function (param) {
          return Curry._1(error, "Barcode reader timeout. Please check your barcode reader setup.");
        }));
  if (state) {
    return React.createElement(EnterpriseOrderBarcodeHandler$AddLine, {
                useLineAdd: useLineAdd,
                line: state.hd,
                onFinish: (function (param) {
                    Curry._1(refetch, undefined);
                    return Curry._1(dispatch, /* Pop */0);
                  })
              });
  } else {
    return React.createElement(React.Fragment, undefined);
  }
}

var make = EnterpriseOrderBarcodeHandler;

exports.AddLine = AddLine;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
