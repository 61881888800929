'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_MenuItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_MenuItem.bs.js");
var QuerySelect$BirddogsoftwareFrontend = require("../QuerySelect.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");

var ppx_printed_query = "query Q($itemNo: String!)  {\nitem(itemNo: $itemNo)  {\nlocations  {\nlocation  {\nlocationCode  \ndescriptionCalculated  \n}\n\n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "item");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "locations");
      tmp = {
        locations: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map(function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "location");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var match = Js_json.decodeNull(value$3);
                  if (match !== undefined) {
                    tmp = undefined;
                  } else {
                    var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
                    var value$5 = Js_dict.get(value$4, "locationCode");
                    var tmp$1;
                    if (value$5 !== undefined) {
                      var value$6 = Caml_option.valFromOption(value$5);
                      var value$7 = Js_json.decodeString(value$6);
                      tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                    } else {
                      tmp$1 = Js_exn.raiseError("graphql_ppx: Field locationCode on type Location is missing");
                    }
                    var value$8 = Js_dict.get(value$4, "descriptionCalculated");
                    var tmp$2;
                    if (value$8 !== undefined) {
                      var value$9 = Caml_option.valFromOption(value$8);
                      var value$10 = Js_json.decodeString(value$9);
                      tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                    } else {
                      tmp$2 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Location is missing");
                    }
                    tmp = {
                      locationCode: tmp$1,
                      descriptionCalculated: tmp$2
                    };
                  }
                } else {
                  tmp = undefined;
                }
                return {
                        location: tmp
                      };
              }) : Js_exn.raiseError("graphql_ppx: Field locations on type Item is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          item: tmp
        };
}

function make(itemNo, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "itemNo",
                    itemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var itemNo = variables.itemNo;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "itemNo",
                    itemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(itemNo, param) {
  return Js_dict.fromArray([[
                  "itemNo",
                  itemNo
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, itemNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "itemNo",
                      itemNo
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ItemLocationQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function ItemLocationSelect(Props) {
  var itemNo = Props.itemNo;
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var value = Props.value;
  var onChange = Props.onChange;
  var queryResponse = GraphQLHooks$BirddogsoftwareFrontend.responseMap(GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(itemNo, undefined), undefined, undefined).result, (function (x) {
          var item = x.item;
          if (item !== undefined) {
            return Caml_option.valFromOption(item).locations;
          } else {
            return [];
          }
        }));
  var tmp = {
    queryResponse: queryResponse,
    value: value,
    onChange: onChange,
    children: (function (items) {
        return Belt_Array.map(items, (function (itemLocation) {
                      var $$location = itemLocation.location;
                      if ($$location === undefined) {
                        return React.createElement(React.Fragment, undefined);
                      }
                      var $$location$1 = Caml_option.valFromOption($$location);
                      return React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some($$location$1.descriptionCalculated), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      NAME: "String",
                                      VAL: $$location$1.locationCode
                                    }, undefined, undefined, $$location$1.locationCode, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                    }));
      })
  };
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (fullWidth !== undefined) {
    tmp.fullWidth = Caml_option.valFromOption(fullWidth);
  }
  if (margin !== undefined) {
    tmp.margin = Caml_option.valFromOption(margin);
  }
  return React.createElement(QuerySelect$BirddogsoftwareFrontend.make, tmp);
}

var make$1 = ItemLocationSelect;

exports.ItemLocationQuery = ItemLocationQuery;
exports.make = make$1;
/* react Not a pure module */
