'use strict';


function ProjectPath(Props) {
  return "Path " + Props.projectId;
}

var make = ProjectPath;

exports.make = make;
/* No side effect */
