'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var ItemCard$BirddogsoftwareFrontend = require("../../../../Components/Ecommerce/Item/ItemCard.bs.js");
var QrReader$BirddogsoftwareFrontend = require("../../../../Components/QrReader/QrReader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../../Utils/GraphQLHooks.bs.js");
var OrderQueries$BirddogsoftwareFrontend = require("../../../../Utils/Queries/OrderQueries.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");

var ppx_printed_query = "query Q($upcOrItemNo: String!)  {\nitemByUpcOrItemNo(upcOrItemNo: $upcOrItemNo)  {\nitemNo  \ndescriptionCalculated  \nlongDescription  \nlowResImage  \nmediumResImage  \nhighResImage  \noriginalImage  \npricing  {\ncurrentUserAndDraftOrder  {\nresult  {\ncanBuy  \ncalculatedPrice  \n}\n\n}\n\n}\n\nuserAmount  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "itemByUpcOrItemNo");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "itemNo");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field itemNo on type Item is missing");
      }
      var value$8 = Js_dict.get(value$4, "descriptionCalculated");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Item is missing");
      }
      var value$11 = Js_dict.get(value$4, "longDescription");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field longDescription on type Item is missing");
      }
      var value$14 = Js_dict.get(value$4, "lowResImage");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field lowResImage on type Item is missing");
      }
      var value$17 = Js_dict.get(value$4, "mediumResImage");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var value$19 = Js_json.decodeString(value$18);
        tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$18));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field mediumResImage on type Item is missing");
      }
      var value$20 = Js_dict.get(value$4, "highResImage");
      var tmp$6;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var value$22 = Js_json.decodeString(value$21);
        tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field highResImage on type Item is missing");
      }
      var value$23 = Js_dict.get(value$4, "originalImage");
      var tmp$7;
      if (value$23 !== undefined) {
        var value$24 = Caml_option.valFromOption(value$23);
        var value$25 = Js_json.decodeString(value$24);
        tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
      } else {
        tmp$7 = Js_exn.raiseError("graphql_ppx: Field originalImage on type Item is missing");
      }
      var value$26 = Js_dict.get(value$4, "pricing");
      var tmp$8;
      if (value$26 !== undefined) {
        var value$27 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$26)));
        var value$28 = Js_dict.get(value$27, "currentUserAndDraftOrder");
        var tmp$9;
        if (value$28 !== undefined) {
          var value$29 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$28)));
          var value$30 = Js_dict.get(value$29, "result");
          var tmp$10;
          if (value$30 !== undefined) {
            var value$31 = Caml_option.valFromOption(value$30);
            var match$1 = Js_json.decodeNull(value$31);
            if (match$1 !== undefined) {
              tmp$10 = undefined;
            } else {
              var value$32 = Js_option.getExn(Js_json.decodeObject(value$31));
              var value$33 = Js_dict.get(value$32, "canBuy");
              var tmp$11;
              if (value$33 !== undefined) {
                var value$34 = Caml_option.valFromOption(value$33);
                var value$35 = Js_json.decodeBoolean(value$34);
                tmp$11 = value$35 !== undefined ? value$35 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$34));
              } else {
                tmp$11 = Js_exn.raiseError("graphql_ppx: Field canBuy on type PricingResult is missing");
              }
              var value$36 = Js_dict.get(value$32, "calculatedPrice");
              var tmp$12;
              if (value$36 !== undefined) {
                var value$37 = Caml_option.valFromOption(value$36);
                var match$2 = Js_json.decodeNull(value$37);
                tmp$12 = match$2 !== undefined ? undefined : Caml_option.some(value$37);
              } else {
                tmp$12 = undefined;
              }
              tmp$10 = {
                canBuy: tmp$11,
                calculatedPrice: tmp$12
              };
            }
          } else {
            tmp$10 = undefined;
          }
          tmp$9 = {
            result: tmp$10
          };
        } else {
          tmp$9 = Js_exn.raiseError("graphql_ppx: Field currentUserAndDraftOrder on type PricingManager is missing");
        }
        tmp$8 = {
          currentUserAndDraftOrder: tmp$9
        };
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Field pricing on type Item is missing");
      }
      var value$38 = Js_dict.get(value$4, "userAmount");
      var tmp$13;
      if (value$38 !== undefined) {
        var value$39 = Caml_option.valFromOption(value$38);
        var value$40 = Js_json.decodeNumber(value$39);
        tmp$13 = value$40 !== undefined ? value$40 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$39));
      } else {
        tmp$13 = Js_exn.raiseError("graphql_ppx: Field userAmount on type Item is missing");
      }
      tmp = {
        itemNo: tmp$1,
        descriptionCalculated: tmp$2,
        longDescription: tmp$3,
        lowResImage: tmp$4,
        mediumResImage: tmp$5,
        highResImage: tmp$6,
        originalImage: tmp$7,
        pricing: tmp$8,
        userAmount: tmp$13
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          itemByUpcOrItemNo: tmp
        };
}

function make(upcOrItemNo, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "upcOrItemNo",
                    upcOrItemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var upcOrItemNo = variables.upcOrItemNo;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "upcOrItemNo",
                    upcOrItemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(upcOrItemNo, param) {
  return Js_dict.fromArray([[
                  "upcOrItemNo",
                  upcOrItemNo
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, upcOrItemNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "upcOrItemNo",
                      upcOrItemNo
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var ItemQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function CartQuickAddScanner$ScanAdder(Props) {
  var orderDraftId = Props.orderDraftId;
  var item = Props.item;
  var qty = Props.qty;
  var onAdd = Props.onAdd;
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var lineAdd = OrderQueries$BirddogsoftwareFrontend.LineAdd.use(orderDraftId, item.itemNo, OrderQueries$BirddogsoftwareFrontend.LineAdd.lineAddData(qty, undefined), onAdd, Curry.__1(error));
  return React.createElement(ItemCard$BirddogsoftwareFrontend.Card.make, {
              item: item,
              pricing: item.pricing.currentUserAndDraftOrder,
              qty: lineAdd.quantityOrdered,
              onAdd: (function (param) {
                  return Curry._1(lineAdd.add, undefined);
                }),
              onQtyChange: (function (value) {
                  return Curry._1(lineAdd.setQuantityOrdered, value);
                }),
              addState: lineAdd.addState
            });
}

var ScanAdder = {
  make: CartQuickAddScanner$ScanAdder
};

function CartQuickAddScanner$ScanItemLoader(Props) {
  var orderDraftId = Props.orderDraftId;
  var qrData = Props.qrData;
  var onBack = Props.onBack;
  var onReload = Props.onReload;
  var itemQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(qrData.itemNo, undefined), undefined, undefined);
  var result = itemQuery.result;
  if (typeof result === "number") {
    return "Loading...";
  }
  if (!result.TAG) {
    return React.createElement(React.Fragment, undefined, "An error occured looking up your item.", React.createElement(Core.Button, MaterialUi_Button.makeProps("Back", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          return Curry._1(onBack, undefined);
                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
  var item = result._0.itemByUpcOrItemNo;
  if (item === undefined) {
    return React.createElement(React.Fragment, undefined, "This item is invalid, or no longer exists. Please scan a different code.", React.createElement(Core.Button, MaterialUi_Button.makeProps("Back", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          return Curry._1(onBack, undefined);
                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
  var item$1 = Caml_option.valFromOption(item);
  var match = qrData.qty;
  var match$1 = item$1.userAmount;
  return React.createElement(CartQuickAddScanner$ScanAdder, {
              orderDraftId: orderDraftId,
              item: item$1,
              qty: match !== undefined ? match : (
                  match$1 !== 0.0 ? match$1 : 1.0
                ),
              onAdd: onReload
            });
}

var ScanItemLoader = {
  make: CartQuickAddScanner$ScanItemLoader
};

function CartQuickAddScanner$ScanParser(Props) {
  var orderDraftId = Props.orderDraftId;
  var raw = Props.raw;
  var onBack = Props.onBack;
  var onReload = Props.onReload;
  var match = React.useReducer((function (x, y) {
          return y;
        }), /* Init */0);
  var state = match[0];
  if (typeof state !== "number") {
    return React.createElement(CartQuickAddScanner$ScanItemLoader, {
                orderDraftId: orderDraftId,
                qrData: state._0,
                onBack: onBack,
                onReload: onReload
              });
  }
  if (state !== 0) {
    return React.createElement(React.Fragment, undefined, "The code you just scanned is not valid. Please try again with a different code.", React.createElement(Core.Button, MaterialUi_Button.makeProps("Back", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          return Curry._1(onBack, undefined);
                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
  var maybeJson;
  try {
    maybeJson = Caml_option.some(JSON.parse(raw));
  }
  catch (exn){
    maybeJson = undefined;
  }
  var parsed;
  if (maybeJson !== undefined) {
    var json = Caml_option.valFromOption(maybeJson);
    var parsed$1 = Js_json.decodeObject(json);
    if (parsed$1 !== undefined) {
      var parsed$2 = Caml_option.valFromOption(parsed$1);
      var itemNo = Belt_Option.flatMap(Js_dict.get(parsed$2, "itemNo"), Js_json.decodeString);
      var qty = Js_dict.get(parsed$2, "qty");
      var qtyAsNumber = Belt_Option.flatMap(qty, Js_json.decodeNumber);
      var qtyAsString = Belt_Option.flatMap(Belt_Option.flatMap(qty, Js_json.decodeString), (function (qty) {
              try {
                return Caml_format.caml_float_of_string(qty);
              }
              catch (exn){
                return ;
              }
            }));
      parsed = itemNo !== undefined ? (
          qtyAsNumber !== undefined ? ({
                itemNo: itemNo,
                qty: qtyAsNumber
              }) : (
              qtyAsString !== undefined ? ({
                    itemNo: itemNo,
                    qty: qtyAsString
                  }) : undefined
            )
        ) : undefined;
    } else {
      parsed = Belt_Option.flatMap(Js_json.decodeNumber(json), (function (parsed) {
              return {
                      itemNo: parsed.toString(),
                      qty: undefined
                    };
            }));
    }
  } else {
    parsed = {
      itemNo: raw,
      qty: undefined
    };
  }
  Curry._1(match[1], parsed !== undefined ? /* Valid */({
            _0: parsed
          }) : /* Invalid */1);
  return React.createElement(React.Fragment, undefined);
}

var ScanParser = {
  make: CartQuickAddScanner$ScanParser
};

function CartQuickAddScanner$ScanManager(Props) {
  var orderDraftId = Props.orderDraftId;
  var onReload = Props.onReload;
  var match = React.useReducer((function (x, y) {
          return y;
        }), /* ShowScanner */0);
  var dispatch = match[1];
  var state = match[0];
  if (typeof state === "number") {
    if (state !== 0) {
      return "An error occured scanning. Please make sure that you have granted permission to use the camera, and try refreshing the page.";
    } else {
      return React.createElement(QrReader$BirddogsoftwareFrontend.make, {
                  delay: 300,
                  onError: (function (err) {
                      Curry._1(dispatch, /* ScanError */1);
                      console.log(err);
                      
                    }),
                  onScan: (function (value) {
                      if (value == null) {
                        return ;
                      } else {
                        return Curry._1(dispatch, /* Scanned */{
                                    _0: value
                                  });
                      }
                    })
                });
    }
  } else {
    return React.createElement(CartQuickAddScanner$ScanParser, {
                orderDraftId: orderDraftId,
                raw: state._0,
                onBack: (function (param) {
                    return Curry._1(dispatch, /* ShowScanner */0);
                  }),
                onReload: onReload
              });
  }
}

var ScanManager = {
  make: CartQuickAddScanner$ScanManager
};

exports.ItemQuery = ItemQuery;
exports.ScanAdder = ScanAdder;
exports.ScanItemLoader = ScanItemLoader;
exports.ScanParser = ScanParser;
exports.ScanManager = ScanManager;
/* react Not a pure module */
