'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var AppProvider$BirddogsoftwareFrontend = require("./AppProvider.bs.js");
var ModuleRoutes$BirddogsoftwareFrontend = require("./Modules/ModuleRoutes.bs.js");

function parsePath(path) {
  switch (path) {
    case "" :
    case "/" :
        return /* [] */0;
    default:
      var raw = path.startsWith("/") ? path.slice(1) : path;
      var match = raw[raw.length - 1 | 0];
      var raw$1 = match === "/" ? raw.slice(0, -1) : raw;
      return $$Array.to_list(raw$1.split("/"));
  }
}

function parseSearch(search) {
  switch (search) {
    case "" :
    case "?" :
        return "";
    default:
      if ("?".startsWith(search)) {
        return search.slice(1);
      } else {
        return search;
      }
  }
}

function classRecordToJs(param) {
  return {
          app: param.app
        };
}

var classes = {
  TAG: /* Record */0,
  _0: {
    app: {
      fontFamily: "Roboto"
    }
  }
};

function classRecordStringsFromJs(arg) {
  return {
          app: arg.app
        };
}

var AppStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var AppStyles = MaterialUi_WithStyles.WithStylesSafe(AppStylesDefs);

function App(Props) {
  var gqlClient = Props.gqlClient;
  var hostUrl = Props.hostUrl;
  var serverUrl = Props.serverUrl;
  var defaultModule = Props.defaultModule;
  var hostMode = Props.hostMode;
  var enterpriseDashboardUrl = Props.enterpriseDashboardUrl;
  var url = serverUrl !== undefined ? ({
        path: parsePath(serverUrl[0]),
        hash: "",
        search: parseSearch(serverUrl[1])
      }) : ReasonReactRouter.useUrl(undefined, undefined);
  var enterpriseDashboardUrl$1 = enterpriseDashboardUrl !== undefined ? ({
        NAME: "Raw",
        VAL: enterpriseDashboardUrl
      }) : ({
        NAME: "Enterprise",
        VAL: "/"
      });
  return React.createElement(AppProvider$BirddogsoftwareFrontend.make, {
              gqlClient: gqlClient,
              hostUrl: hostUrl,
              serverUrl: serverUrl,
              defaultModule: defaultModule,
              hostMode: hostMode,
              children: (function (store, defaultModule, basename) {
                  var url$1;
                  if (basename !== undefined) {
                    var match = Belt_List.splitAt(url.path, Belt_List.length(basename));
                    if (match !== undefined) {
                      url$1 = {
                        path: match[1],
                        hash: url.hash,
                        search: url.search
                      };
                    } else {
                      console.log("this is weird");
                      console.log(url);
                      console.log(basename);
                      url$1 = url;
                    }
                  } else {
                    url$1 = url;
                  }
                  var url_path = Belt_List.map(url$1.path, (function (x) {
                          return decodeURIComponent(x);
                        }));
                  var url_hash = url$1.hash;
                  var url_search = url$1.search;
                  var url$2 = {
                    path: url_path,
                    hash: url_hash,
                    search: url_search
                  };
                  return ModuleRoutes$BirddogsoftwareFrontend.route(defaultModule, url$2, store, enterpriseDashboardUrl$1);
                })
            });
}

var make = App;

var $$default = App;

exports.parsePath = parsePath;
exports.parseSearch = parseSearch;
exports.AppStylesDefs = AppStylesDefs;
exports.AppStyles = AppStyles;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* AppStyles Not a pure module */
