'use strict';


function parse(raw) {
  var match = raw.toLowerCase();
  switch (match) {
    case "ecommerce" :
        return /* Ecommerce */1;
    case "enterprise" :
        return /* Enterprise */0;
    default:
      return ;
  }
}

exports.parse = parse;
/* No side effect */
