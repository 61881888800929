'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Autocomplete$BirddogsoftwareFrontend = require("../Autocomplete.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");

var ppx_printed_query = "query Q($text: String!)  {\nitemSearch(text: $text)  {\nitemNo  \ndescriptionCalculated  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "itemSearch");
  return {
          itemSearch: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "itemNo");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field itemNo on type Item is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "descriptionCalculated");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Item is missing");
                  }
                  return {
                          itemNo: tmp,
                          descriptionCalculated: tmp$1
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field itemSearch on type Query is missing")
        };
}

function make(text, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "text",
                    text
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var text = variables.text;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "text",
                    text
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(text, param) {
  return Js_dict.fromArray([[
                  "text",
                  text
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, text, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "text",
                      text
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var CustomerQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function useQuery(text) {
  return GraphQLHooks$BirddogsoftwareFrontend.responseMap(GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(text, undefined), undefined, undefined).result, (function (x) {
                return Belt_Array.map(x.itemSearch, (function (x) {
                              return {
                                      label: x.itemNo,
                                      data: x.itemNo,
                                      key: x.itemNo
                                    };
                            }));
              }));
}

function ItemAutocomplete(Props) {
  var className = Props.className;
  var label = Props.label;
  var onChange = Props.onChange;
  var isTypeahead = Props.isTypeahead;
  var helperText = Props.helperText;
  var error = Props.error;
  var tmp = {
    useQuery: useQuery,
    onChange: onChange,
    itemToString: (function (x) {
        return Belt_Option.getWithDefault(x, "");
      })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (isTypeahead !== undefined) {
    tmp.isTypeahead = Caml_option.valFromOption(isTypeahead);
  }
  if (helperText !== undefined) {
    tmp.helperText = Caml_option.valFromOption(helperText);
  }
  if (error !== undefined) {
    tmp.error = Caml_option.valFromOption(error);
  }
  return React.createElement(Autocomplete$BirddogsoftwareFrontend.GraphQL.make, tmp);
}

var make$1 = ItemAutocomplete;

exports.CustomerQuery = CustomerQuery;
exports.useQuery = useQuery;
exports.make = make$1;
/* react Not a pure module */
