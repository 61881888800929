'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var Date$BirddogsoftwareFrontend = require("../../../Utils/Date.bs.js");
var Link$BirddogsoftwareFrontend = require("../../../Components/Link.bs.js");
var UserSelect$BirddogsoftwareFrontend = require("./Components/UserSelect.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../../../Components/PaperSpacer.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../../Components/Forms/ActionHeader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var MaterialTable$BirddogsoftwareFrontend = require("../../../Components/MaterialTable.bs.js");

var ppx_printed_query = "query TaskSearch($userID: Guid, $complete: Boolean, $acknowledged: Boolean)  {\ntaskSearch(userID: $userID, complete: $complete, acknowledged: $acknowledged)  {\ntaskId  \npriority  \ntitle  \ndescription  \ncreated  \ncreatedById  \ncreatedBy  {\nuserId  \nname  \n}\n\ncontactId  \ncontact  {\nname  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "taskSearch");
  return {
          taskSearch: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "taskId");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field taskId on type Task is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "priority");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field priority on type Task is missing");
                  }
                  var value$8 = Js_dict.get(value$1, "title");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field title on type Task is missing");
                  }
                  var value$11 = Js_dict.get(value$1, "description");
                  var tmp$3;
                  if (value$11 !== undefined) {
                    var value$12 = Caml_option.valFromOption(value$11);
                    var value$13 = Js_json.decodeString(value$12);
                    tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field description on type Task is missing");
                  }
                  var value$14 = Js_dict.get(value$1, "created");
                  var tmp$4;
                  if (value$14 !== undefined) {
                    var value$15 = Caml_option.valFromOption(value$14);
                    var match = Js_json.decodeNull(value$15);
                    tmp$4 = match !== undefined ? undefined : Caml_option.some(value$15);
                  } else {
                    tmp$4 = undefined;
                  }
                  var value$16 = Js_dict.get(value$1, "createdById");
                  var tmp$5;
                  if (value$16 !== undefined) {
                    var value$17 = Caml_option.valFromOption(value$16);
                    var value$18 = Js_json.decodeString(value$17);
                    tmp$5 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
                  } else {
                    tmp$5 = Js_exn.raiseError("graphql_ppx: Field createdById on type Task is missing");
                  }
                  var value$19 = Js_dict.get(value$1, "createdBy");
                  var tmp$6;
                  if (value$19 !== undefined) {
                    var value$20 = Caml_option.valFromOption(value$19);
                    var match$1 = Js_json.decodeNull(value$20);
                    if (match$1 !== undefined) {
                      tmp$6 = undefined;
                    } else {
                      var value$21 = Js_option.getExn(Js_json.decodeObject(value$20));
                      var value$22 = Js_dict.get(value$21, "userId");
                      var value$23 = Js_dict.get(value$21, "name");
                      var tmp$7;
                      if (value$23 !== undefined) {
                        var value$24 = Caml_option.valFromOption(value$23);
                        var value$25 = Js_json.decodeString(value$24);
                        tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$24));
                      } else {
                        tmp$7 = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
                      }
                      tmp$6 = {
                        userId: value$22 !== undefined ? Caml_option.valFromOption(value$22) : Js_exn.raiseError("graphql_ppx: Field userId on type User is missing"),
                        name: tmp$7
                      };
                    }
                  } else {
                    tmp$6 = undefined;
                  }
                  var value$26 = Js_dict.get(value$1, "contactId");
                  var tmp$8;
                  if (value$26 !== undefined) {
                    var value$27 = Caml_option.valFromOption(value$26);
                    var value$28 = Js_json.decodeString(value$27);
                    tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
                  } else {
                    tmp$8 = Js_exn.raiseError("graphql_ppx: Field contactId on type Task is missing");
                  }
                  var value$29 = Js_dict.get(value$1, "contact");
                  var tmp$9;
                  if (value$29 !== undefined) {
                    var value$30 = Caml_option.valFromOption(value$29);
                    var match$2 = Js_json.decodeNull(value$30);
                    if (match$2 !== undefined) {
                      tmp$9 = undefined;
                    } else {
                      var value$31 = Js_option.getExn(Js_json.decodeObject(value$30));
                      var value$32 = Js_dict.get(value$31, "name");
                      var tmp$10;
                      if (value$32 !== undefined) {
                        var value$33 = Caml_option.valFromOption(value$32);
                        var value$34 = Js_json.decodeString(value$33);
                        tmp$10 = value$34 !== undefined ? value$34 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$33));
                      } else {
                        tmp$10 = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
                      }
                      tmp$9 = {
                        name: tmp$10
                      };
                    }
                  } else {
                    tmp$9 = undefined;
                  }
                  return {
                          taskId: tmp,
                          priority: tmp$1,
                          title: tmp$2,
                          description: tmp$3,
                          created: tmp$4,
                          createdById: tmp$5,
                          createdBy: tmp$6,
                          contactId: tmp$8,
                          contact: tmp$9
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field taskSearch on type Query is missing")
        };
}

function make(userID, complete, acknowledged, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "userID",
                    userID !== undefined ? Caml_option.valFromOption(userID) : null
                  ],
                  [
                    "complete",
                    complete !== undefined ? complete : null
                  ],
                  [
                    "acknowledged",
                    acknowledged !== undefined ? acknowledged : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var userID = variables.userID;
  var complete = variables.complete;
  var acknowledged = variables.acknowledged;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "userID",
                    userID !== undefined ? Caml_option.valFromOption(userID) : null
                  ],
                  [
                    "complete",
                    complete !== undefined ? complete : null
                  ],
                  [
                    "acknowledged",
                    acknowledged !== undefined ? acknowledged : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(userID, complete, acknowledged, param) {
  return Js_dict.fromArray([
                [
                  "userID",
                  userID !== undefined ? Caml_option.valFromOption(userID) : null
                ],
                [
                  "complete",
                  complete !== undefined ? complete : null
                ],
                [
                  "acknowledged",
                  acknowledged !== undefined ? acknowledged : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, userID, complete, acknowledged, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "userID",
                      userID !== undefined ? Caml_option.valFromOption(userID) : null
                    ],
                    [
                      "complete",
                      complete !== undefined ? complete : null
                    ],
                    [
                      "acknowledged",
                      acknowledged !== undefined ? acknowledged : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var TaskSearchQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function handleDate(d) {
  if (d === "") {
    return ;
  } else {
    return Caml_option.some(new Date(d));
  }
}

function reducer(state, action) {
  return {
          currentUserId: action._0,
          startDate: state.startDate,
          endDate: state.endDate,
          eventMessage: state.eventMessage,
          openLog: state.openLog
        };
}

var singleLineRegex = /(.*)$((.|[\r\n])*)/m;

function EnterpriseInbox(Props) {
  var defaultUserId = Props.defaultUserId;
  var match = React.useReducer(reducer, {
        currentUserId: defaultUserId,
        startDate: Caml_option.some(Date$BirddogsoftwareFrontend.monthAgo(undefined)),
        endDate: Caml_option.some(new Date(Date.now())),
        eventMessage: undefined,
        openLog: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var taskSearchQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(Caml_option.some(state.currentUserId), false, false, undefined), undefined, undefined);
  var message = taskSearchQuery.result;
  var match$1;
  match$1 = typeof message === "number" ? [
      true,
      undefined,
      []
    ] : (
      message.TAG ? [
          false,
          undefined,
          message._0.taskSearch
        ] : [
          false,
          message._0,
          []
        ]
    );
  return React.createElement(React.Fragment, undefined, React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                  breadcrumbs: [
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/brain"
                      },
                      content: "BRAIN"
                    },
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/brain/inbox"
                      },
                      content: "Inbox"
                    }
                  ],
                  mainActions: [{
                      key: "new",
                      content: "New",
                      action: {
                        NAME: "Link",
                        VAL: {
                          NAME: "Enterprise",
                          VAL: "/brain/tasks/new"
                        }
                      },
                      color: "Primary"
                    }]
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(UserSelect$BirddogsoftwareFrontend.make, {
                                    defaultUserId: state.currentUserId,
                                    onChange: (function (value) {
                                        return Curry._1(dispatch, /* SetCurrentUser */{
                                                    _0: value
                                                  });
                                      })
                                  })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(MaterialTable$BirddogsoftwareFrontend.Table.make, {
                                    columns: [
                                      {
                                        title: "Priority",
                                        field: "priority"
                                      },
                                      {
                                        title: "From",
                                        render: (function (row) {
                                            var x = row.createdBy;
                                            return React.createElement(Link$BirddogsoftwareFrontend.make, {
                                                        href: {
                                                          NAME: "Enterprise",
                                                          VAL: "/brain/contacts/" + (row.createdById + "/")
                                                        },
                                                        children: x !== undefined ? Caml_option.valFromOption(x).name : "Not found"
                                                      });
                                          })
                                      },
                                      {
                                        title: "Received",
                                        field: "created",
                                        type: "Datetime"
                                      },
                                      {
                                        title: "Reference",
                                        render: (function (row) {
                                            return React.createElement(Link$BirddogsoftwareFrontend.make, {
                                                        href: {
                                                          NAME: "Enterprise",
                                                          VAL: "/brain/tasks/" + (row.taskId + "/")
                                                        },
                                                        children: row.title
                                                      });
                                          })
                                      },
                                      {
                                        title: "Contact",
                                        render: (function (row) {
                                            var x = row.contact;
                                            return React.createElement(Link$BirddogsoftwareFrontend.make, {
                                                        href: {
                                                          NAME: "Enterprise",
                                                          VAL: "/brain/contacts/" + (row.contactId + "/")
                                                        },
                                                        children: x !== undefined ? Caml_option.valFromOption(x).name : "Not found"
                                                      });
                                          })
                                      }
                                    ],
                                    data: match$1[2],
                                    isLoading: match$1[0],
                                    options: {
                                      grouping: false,
                                      filtering: false,
                                      toolbar: false
                                    },
                                    components: {
                                      Container: MaterialTable$BirddogsoftwareFrontend.divContainer
                                    }
                                  })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }));
}

var Paper;

var Theme;

var Mixins;

var ZIndex;

var Breakpoints;

var PaletteColor;

var Palette;

var make$1 = EnterpriseInbox;

exports.Paper = Paper;
exports.Theme = Theme;
exports.Mixins = Mixins;
exports.ZIndex = ZIndex;
exports.Breakpoints = Breakpoints;
exports.PaletteColor = PaletteColor;
exports.Palette = Palette;
exports.TaskSearchQuery = TaskSearchQuery;
exports.handleDate = handleDate;
exports.reducer = reducer;
exports.singleLineRegex = singleLineRegex;
exports.make = make$1;
/* react Not a pure module */
