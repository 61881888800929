'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function compileSimple(filters) {
  var c_values = function (filterGroup, filterGroupDetail, values) {
    if (!values) {
      return ;
    }
    var rest = values.tl;
    var value = values.hd;
    if (!rest) {
      return {
              TAG: /* FilterGroupDetail */3,
              _0: {
                filterGroup: filterGroup,
                filterGroupDetail: filterGroupDetail,
                value: value
              }
            };
    }
    var x = {
      TAG: /* FilterGroupDetail */3,
      _0: {
        filterGroup: filterGroup,
        filterGroupDetail: filterGroupDetail,
        value: value
      }
    };
    var y = c_values(filterGroup, filterGroupDetail, rest);
    if (y !== undefined) {
      return {
              TAG: /* Or */1,
              _0: x,
              _1: y
            };
    } else {
      return x;
    }
  };
  var c = function (_filters) {
    while(true) {
      var filters = _filters;
      if (!filters) {
        return ;
      }
      var rest = filters.tl;
      var match = filters.hd;
      var values = match[2];
      var filterGroupDetail = match[1];
      var filterGroup = match[0];
      if (!rest) {
        if (values) {
          return c_values(filterGroup, filterGroupDetail, values);
        } else {
          return ;
        }
      }
      if (values) {
        var match$1 = c_values(filterGroup, filterGroupDetail, values);
        var match$2 = c(rest);
        if (match$1 !== undefined) {
          if (match$2 !== undefined) {
            return {
                    TAG: /* And */0,
                    _0: match$1,
                    _1: match$2
                  };
          } else {
            return match$1;
          }
        } else if (match$2 !== undefined) {
          return match$2;
        } else {
          return ;
        }
      }
      _filters = rest;
      continue ;
    };
  };
  return c(filters);
}

var FilterGroupQuery = {
  compileSimple: compileSimple
};

function compile(query) {
  switch (query.TAG | 0) {
    case /* And */0 :
        return {
                itemField_ComparisonMethod: undefined,
                itemField_ComparisonValue: undefined,
                itemField_FieldName: undefined,
                itemField_ItemFieldSource: undefined,
                type: "AND",
                v1: Caml_option.some(compile(query._0)),
                v2: Caml_option.some(compile(query._1))
              };
    case /* Or */1 :
        return {
                itemField_ComparisonMethod: undefined,
                itemField_ComparisonValue: undefined,
                itemField_FieldName: undefined,
                itemField_ItemFieldSource: undefined,
                type: "OR",
                v1: Caml_option.some(compile(query._0)),
                v2: Caml_option.some(compile(query._1))
              };
    case /* Not */2 :
        return {
                itemField_ComparisonMethod: undefined,
                itemField_ComparisonValue: undefined,
                itemField_FieldName: undefined,
                itemField_ItemFieldSource: undefined,
                type: "NOT",
                v1: Caml_option.some(compile(query._0)),
                v2: undefined
              };
    case /* FilterGroupDetail */3 :
        var filterGroupDetailQuery = query._0;
        var match = filterGroupDetailQuery.filterGroupDetail.values;
        return {
                itemField_ComparisonMethod: match.length !== 0 ? "EQUALS" : "CONTAINS",
                itemField_ComparisonValue: filterGroupDetailQuery.value,
                itemField_FieldName: filterGroupDetailQuery.filterGroupDetail.name,
                itemField_ItemFieldSource: "USER_DEFINED_FIELD",
                type: "ITEM_FIELD",
                v1: undefined,
                v2: undefined
              };
    
  }
}

var SiteSearchInput = {
  compile: compile
};

exports.FilterGroupQuery = FilterGroupQuery;
exports.SiteSearchInput = SiteSearchInput;
/* No side effect */
