'use strict';


function stringify(ty) {
  if (ty === "SHOP_FLOOR_ORDER") {
    return "Shop_Floor_Order";
  } else if (ty === "QUOTE") {
    return "Quote";
  } else if (ty === "PRODUCTION_ORDER") {
    return "Production_Order";
  } else if (ty === "EMPTY") {
    return "Empty";
  } else if (ty === "MASTER_ORDER") {
    return "Master_Order";
  } else if (ty === "T") {
    return "T";
  } else if (ty === "DEBIT_MEMO") {
    return "Debit_Memo";
  } else if (ty === "L_CLOSED") {
    return "L_Closed";
  } else if (ty === "ORDER") {
    return "Order";
  } else if (ty === "CREDIT_MEMO") {
    return "Credit_Memo";
  } else if (ty === "BLANKET") {
    return "Blanket";
  } else {
    return "Invoice";
  }
}

function ofString(value) {
  var match = value.toLowerCase();
  switch (match) {
    case "blanket" :
        return "BLANKET";
    case "credit_memo" :
        return "CREDIT_MEMO";
    case "debit_memo" :
        return "DEBIT_MEMO";
    case "empty" :
        return "EMPTY";
    case "invoice" :
        return "INVOICE";
    case "l_closed" :
        return "L_CLOSED";
    case "master_order" :
        return "MASTER_ORDER";
    case "order" :
        return "ORDER";
    case "production_order" :
        return "PRODUCTION_ORDER";
    case "quote" :
        return "QUOTE";
    case "shop_floor_order" :
        return "SHOP_FLOOR_ORDER";
    case "t" :
        return "T";
    default:
      return ;
  }
}

var OrderType = {
  stringify: stringify,
  ofString: ofString
};

function stringifyOrderStatus(consolidatedType, orderType) {
  if (consolidatedType === "OPEN") {
    if (orderType === "ORDER" || orderType === "INVOICE") {
      return "Accepted";
    } else if (orderType === "QUOTE") {
      return "Received";
    } else if (orderType === "DEBIT_MEMO") {
      return "Debit Memo";
    } else if (orderType === "CREDIT_MEMO") {
      return "Credit Memo";
    } else if (orderType === "BLANKET") {
      return "Blanket";
    } else {
      return "Call for details.";
    }
  } else {
    return "Shipped";
  }
}

function stringifyOrderStatusViewPoint(consolidatedType, orderType) {
  if (consolidatedType === "OPEN") {
    if (orderType === "ORDER" || orderType === "INVOICE") {
      return "Open";
    } else if (orderType === "QUOTE") {
      return "Received";
    } else if (orderType === "DEBIT_MEMO") {
      return "Debit Memo";
    } else if (orderType === "CREDIT_MEMO") {
      return "Credit Memo";
    } else if (orderType === "BLANKET") {
      return "Blanket";
    } else {
      return "Call for details.";
    }
  } else {
    return "Shipped";
  }
}

exports.OrderType = OrderType;
exports.stringifyOrderStatus = stringifyOrderStatus;
exports.stringifyOrderStatusViewPoint = stringifyOrderStatusViewPoint;
/* No side effect */
