'use strict';


function EcommerceHome(Props) {
  return "ecommerce empty";
}

var make = EcommerceHome;

exports.make = make;
/* No side effect */
