'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var floatRegex = /^(\s*)([0-9]+)(\s*)(\.?|\.(\s*)([0-9]+))(\s*)$/;

function $$parseFloat(x) {
  var result = floatRegex.exec(x);
  if (result === null) {
    return ;
  }
  var characteristic = Belt_Option.getExn(Caml_option.nullable_to_opt(Caml_array.caml_array_get(result, 2)));
  var mantissa = Caml_array.caml_array_get(result, 6);
  return Caml_format.caml_float_of_string((mantissa == null) ? characteristic : characteristic + ("." + mantissa));
}

exports.floatRegex = floatRegex;
exports.$$parseFloat = $$parseFloat;
/* No side effect */
