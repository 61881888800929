'use strict';

var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Location$BirddogsoftwareFrontend = require("../Utils/Location.bs.js");

var context = React.createContext(undefined);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function resolveSelf(defaultModule, route) {
  var variant = route.NAME;
  if (variant === "Ecommerce") {
    var path = route.VAL;
    if (defaultModule) {
      return path;
    } else {
      return "/ecommerce" + path;
    }
  }
  if (variant !== "Enterprise") {
    return route.VAL;
  }
  var path$1 = route.VAL;
  if (defaultModule) {
    return "/e" + path$1;
  } else {
    return path$1;
  }
}

function resolveClassicOrSelf_self(route) {
  return "/item/" + route.VAL;
}

function resolveClassicOrSelf_classic(route) {
  return "/itemform.aspx?ItemNo=" + route.VAL;
}

function resolve(base, route) {
  var match = base !== undefined ? base : ({
        defaultModule: /* Ecommerce */1,
        hostMode: "SelfHost",
        basename: undefined
      });
  var basename = match.basename;
  var hostMode = match.hostMode;
  var match$1 = route.NAME === "Item" ? (
      hostMode === "EmbedInClassic" || hostMode === "HostInClassic" ? [
          resolveClassicOrSelf_classic(route),
          true
        ] : [
          resolveClassicOrSelf_self(route),
          false
        ]
    ) : [
      resolveSelf(match.defaultModule, route),
      false
    ];
  var url = match$1[0];
  var isExternal = hostMode === "EmbedInClassic" ? true : match$1[1];
  var match$2 = url.startsWith("~");
  var match$3 = isExternal || !(basename !== undefined && basename) ? [
      isExternal,
      url
    ] : (
      match$2 ? [
          true,
          url.substring(1)
        ] : [
          isExternal,
          "/" + (Belt_List.toArray(basename).join("/") + url)
        ]
    );
  return {
          url: match$3[1],
          isExternal: match$3[0]
        };
}

function useResolved(route) {
  var base = React.useContext(context);
  return resolve(base, route);
}

function useResolver(param) {
  var base = React.useContext(context);
  return function (route) {
    return resolve(base, route);
  };
}

function useLocationSet(param) {
  var base = React.useContext(context);
  return function (moduleRoute) {
    var resolved = resolve(base, moduleRoute);
    return Location$BirddogsoftwareFrontend.unsafe_set(resolved.isExternal, resolved.url);
  };
}

exports.Context = Context;
exports.resolveSelf = resolveSelf;
exports.resolveClassicOrSelf_self = resolveClassicOrSelf_self;
exports.resolveClassicOrSelf_classic = resolveClassicOrSelf_classic;
exports.resolve = resolve;
exports.useResolved = useResolved;
exports.useResolver = useResolver;
exports.useLocationSet = useLocationSet;
/* context Not a pure module */
