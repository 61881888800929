'use strict';

var React = require("react");

var context = React.createContext(true);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function ClientOnly(Props) {
  var children = Props.children;
  var isClient = React.useContext(context);
  if (isClient) {
    return children;
  } else {
    return React.createElement(React.Fragment, undefined);
  }
}

var make$1 = ClientOnly;

exports.Context = Context;
exports.make = make$1;
/* context Not a pure module */
