'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var Order$BirddogsoftwareFrontend = require("../../../../Types/Order.bs.js");
var Location$BirddogsoftwareFrontend = require("../../../../Utils/Location.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../../UrlResolver.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../../Utils/GraphQLHooks.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var ButtonProgress$BirddogsoftwareFrontend = require("../../../../Components/ButtonProgress.bs.js");

function reducer(state, action) {
  return action;
}

var ppx_printed_query = "mutation Q($orderDraftId: Guid!)  {\norderDraftFinalize(orderDraftId: $orderDraftId)  {\norderType  \norderNo  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftFinalize");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderType");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        if (value$7 !== undefined) {
          switch (value$7) {
            case "BLANKET" :
                tmp$1 = "BLANKET";
                break;
            case "CREDIT_MEMO" :
                tmp$1 = "CREDIT_MEMO";
                break;
            case "DEBIT_MEMO" :
                tmp$1 = "DEBIT_MEMO";
                break;
            case "EMPTY" :
                tmp$1 = "EMPTY";
                break;
            case "INVOICE" :
                tmp$1 = "INVOICE";
                break;
            case "L_CLOSED" :
                tmp$1 = "L_CLOSED";
                break;
            case "MASTER_ORDER" :
                tmp$1 = "MASTER_ORDER";
                break;
            case "ORDER" :
                tmp$1 = "ORDER";
                break;
            case "PRODUCTION_ORDER" :
                tmp$1 = "PRODUCTION_ORDER";
                break;
            case "QUOTE" :
                tmp$1 = "QUOTE";
                break;
            case "SHOP_FLOOR_ORDER" :
                tmp$1 = "SHOP_FLOOR_ORDER";
                break;
            case "T" :
                tmp$1 = "T";
                break;
            default:
              tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderType: " + value$7);
          }
        } else {
          tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderType, got " + JSON.stringify(value$6));
        }
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field orderType on type OrderOpen is missing");
      }
      var value$8 = Js_dict.get(value$4, "orderNo");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
      }
      tmp = {
        orderType: tmp$1,
        orderNo: tmp$2
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderDraftFinalize: tmp
        };
}

function make(orderDraftId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "orderDraftId",
                    orderDraftId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var orderDraftId = variables.orderDraftId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "orderDraftId",
                    orderDraftId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(orderDraftId, param) {
  return Js_dict.fromArray([[
                  "orderDraftId",
                  orderDraftId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, orderDraftId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "orderDraftId",
                      orderDraftId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftFinalize = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function EnterpriseOrderDraftFooter(Props) {
  var orderDraftId = Props.orderDraftId;
  var match = React.useReducer(reducer, /* NotStarted */0);
  var dispatch = match[1];
  var state = match[0];
  var finalizeMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(orderDraftId, undefined));
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var resolver = UrlResolver$BirddogsoftwareFrontend.useResolver(undefined);
  return React.createElement(ButtonProgress$BirddogsoftwareFrontend.make, {
              showProgress: state === /* FinalizeStarted */1,
              children: React.createElement(Core.Button, MaterialUi_Button.makeProps("Finalize", undefined, undefined, undefined, state === /* FinalizeStarted */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Contained", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(dispatch, /* FinalizeStarted */1);
                          Curry._1(finalizeMutation.runMutation, undefined).then(function (response) {
                                if (response.TAG) {
                                  var o = response._0.orderDraftFinalize;
                                  if (o !== undefined) {
                                    var o$1 = Caml_option.valFromOption(o);
                                    Curry._1(dispatch, /* Finalized */2);
                                    var resolved = Curry._1(resolver, {
                                          NAME: "Enterprise",
                                          VAL: "/orders/open/" + (Order$BirddogsoftwareFrontend.OrderType.stringify(o$1.orderType) + ("/" + o$1.orderNo))
                                        });
                                    Location$BirddogsoftwareFrontend.unsafe_set(resolved.isExternal, resolved.url);
                                  } else {
                                    Curry._1(dispatch, /* NotStarted */0);
                                    Curry._1(error, "Draft not found");
                                  }
                                } else {
                                  Curry._1(dispatch, /* NotStarted */0);
                                  Curry._1(error, response._0);
                                }
                                return Promise.resolve(undefined);
                              });
                          
                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
            });
}

var make$1 = EnterpriseOrderDraftFooter;

exports.reducer = reducer;
exports.OrderDraftFinalize = OrderDraftFinalize;
exports.make = make$1;
/* react Not a pure module */
