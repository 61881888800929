'use strict';

var React = require("react");
var Address$BirddogsoftwareFrontend = require("./Address.bs.js");
var LoadingPlaceholder$BirddogsoftwareFrontend = require("./LoadingPlaceholder.bs.js");

function NameAndAddress(Props) {
  var nameAndAddress = Props.nameAndAddress;
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, nameAndAddress.name), React.createElement(Address$BirddogsoftwareFrontend.make, {
                  address: nameAndAddress
                }));
}

function NameAndAddress$Placeholder(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {}), React.createElement(Address$BirddogsoftwareFrontend.Placeholder.make, {}));
}

var Placeholder = {
  make: NameAndAddress$Placeholder
};

var make = NameAndAddress;

exports.make = make;
exports.Placeholder = Placeholder;
/* react Not a pure module */
