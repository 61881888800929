'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_IconButton = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_IconButton.bs.js");
var Delete = require("@material-ui/icons/Delete");
var Intl$BirddogsoftwareFrontend = require("../../../../Utils/Intl.bs.js");
var Link$BirddogsoftwareFrontend = require("../../../../Components/Link.bs.js");
var MaterialTable$BirddogsoftwareFrontend = require("../../../../Components/MaterialTable.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");

function EnterpriseOrderLineTable$DeleteButton(Props) {
  var onLineDelete = Props.onLineDelete;
  var useLineDelete = Props.useLineDelete;
  var orderLine = Props.orderLine;
  var $$delete = Curry._1(useLineDelete, orderLine);
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var success = SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  return React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(React.createElement(Delete.default, {})), undefined, undefined, undefined, undefined, undefined, undefined, "Small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                    Curry._1($$delete, undefined).then(function (response) {
                          if (response.TAG) {
                            if (response._0.NAME === "Success") {
                              Curry._1(onLineDelete, undefined);
                              Curry._1(success, "Line deleted.");
                            } else {
                              Curry._1(error, "There was an error deleting the line");
                            }
                          } else {
                            Curry._1(error, response._0);
                          }
                          return Promise.resolve(undefined);
                        });
                    
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var DeleteButton = {
  make: EnterpriseOrderLineTable$DeleteButton
};

function EnterpriseOrderLineTable(Props) {
  var lines = Props.lines;
  var isLoading = Props.isLoading;
  var onEdit = Props.onEdit;
  var onLineDelete = Props.onLineDelete;
  var useLineDelete = Props.useLineDelete;
  return React.createElement(MaterialTable$BirddogsoftwareFrontend.Table.make, {
              columns: [
                {
                  title: "Item",
                  render: (function (row) {
                      var item = row.item;
                      return React.createElement(Core.Link, Link$BirddogsoftwareFrontend.BD_MaterialUi_Link.makeProps(Caml_option.some(item !== undefined ? Caml_option.valFromOption(item).descriptionCalculated : row.itemNo), undefined, "#", undefined, undefined, undefined, (function (e) {
                                        e.preventDefault();
                                        return Curry._1(onEdit, row);
                                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                    })
                },
                {
                  title: "Quantity Ordered",
                  render: (function (row) {
                      return row.quantityOrdered.toString();
                    })
                },
                {
                  title: "Quantity Shipped",
                  render: (function (row) {
                      return row.quantityToShip.toString();
                    })
                },
                {
                  title: "Location",
                  render: (function (row) {
                      return row.locationCode;
                    })
                },
                {
                  title: "Unit Price",
                  render: (function (row) {
                      return Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, row.price);
                    })
                },
                {
                  title: "Discount %",
                  render: (function (row) {
                      return row.discountPercent.toString();
                    })
                },
                {
                  title: "Line Total",
                  render: (function (row) {
                      return Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, row.total);
                    })
                },
                {
                  title: "Comments",
                  render: (function (row) {
                      return row.comments;
                    })
                },
                {
                  render: (function (orderLine) {
                      return React.createElement(EnterpriseOrderLineTable$DeleteButton, {
                                  onLineDelete: onLineDelete,
                                  useLineDelete: useLineDelete,
                                  orderLine: orderLine
                                });
                    })
                }
              ],
              data: lines,
              isLoading: isLoading,
              options: {
                grouping: false,
                filtering: false,
                toolbar: false
              },
              components: {
                Container: MaterialTable$BirddogsoftwareFrontend.divContainer
              }
            });
}

var make = EnterpriseOrderLineTable;

exports.DeleteButton = DeleteButton;
exports.make = make;
/* react Not a pure module */
