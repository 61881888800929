'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var Location$BirddogsoftwareFrontend = require("../../Utils/Location.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../Modules/UrlResolver.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../SnackbarHooks.bs.js");

var ppx_printed_query = "mutation Logout  {\nlogout  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "logout");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var value$4 = Js_json.decodeBoolean(value$3);
    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field logout on type Mutation is missing");
  }
  return {
          logout: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var SignOutQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function useSignOut(store) {
  var signOutQuery = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(undefined));
  var snackbarSendError = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var rootResolved = UrlResolver$BirddogsoftwareFrontend.useResolved({
        NAME: "Raw",
        VAL: "/"
      });
  return function (param) {
    Curry._1(signOutQuery.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            if (response._0.logout) {
              Curry._1(store.dispatch, /* SetUser */{
                    _0: /* Anon */0
                  });
              Location$BirddogsoftwareFrontend.unsafe_set(rootResolved.isExternal, rootResolved.url);
            } else {
              Curry._1(snackbarSendError, "Sign Out failed.");
            }
          } else {
            Curry._1(snackbarSendError, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
}

function SignOutButton(Props) {
  var store = Props.store;
  var className = Props.className;
  var signOut = useSignOut(store);
  return React.createElement(Core.Button, MaterialUi_Button.makeProps("Sign Out", className, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (_event) {
                    return Curry._1(signOut, undefined);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make$1 = SignOutButton;

exports.SignOutQuery = SignOutQuery;
exports.useSignOut = useSignOut;
exports.make = make$1;
/* react Not a pure module */
