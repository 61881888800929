'use strict';

var Date$BirddogsoftwareFrontend = require("../../Utils/Date.bs.js");
var FloatExt$BirddogsoftwareFrontend = require("../../Utils/FloatExt.bs.js");
var StrongField$BirddogsoftwareFrontend = require("./StrongField.bs.js");

function stringify(prim) {
  return prim.toString();
}

var Float = StrongField$BirddogsoftwareFrontend.Make({
      name: "number",
      fieldType: undefined,
      stringify: stringify,
      parse: FloatExt$BirddogsoftwareFrontend.$$parseFloat
    });

var $$Date = StrongField$BirddogsoftwareFrontend.Make({
      name: "date",
      fieldType: "date",
      stringify: Date$BirddogsoftwareFrontend.toHtmlDateString,
      parse: Date$BirddogsoftwareFrontend.parseHtmlDateString
    });

exports.Float = Float;
exports.$$Date = $$Date;
/* Float Not a pure module */
