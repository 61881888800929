'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var GraphqlHooks = require("graphql-hooks");
var GraphqlHooksMemcache = require("graphql-hooks-memcache");

var MemCache = {};

function createMemCache(initialState) {
  return GraphqlHooksMemcache.default({
              initialState: initialState
            });
}

var Client = {};

function createClient(url, cache) {
  return new GraphqlHooks.GraphQLClient({
              url: url,
              cache: cache
            });
}

var provider = GraphqlHooks.ClientContext.Provider;

var Provider = {
  provider: provider
};

function responseMap(response, func) {
  if (typeof response === "number") {
    return /* Loading */0;
  } else if (response.TAG) {
    return {
            TAG: /* Data */1,
            _0: Curry._1(func, response._0)
          };
  } else {
    return {
            TAG: /* Error */0,
            _0: response._0
          };
  }
}

function useQuery(query, useCacheOpt, param) {
  var useCache = useCacheOpt !== undefined ? useCacheOpt : false;
  var queryOpts = {
    variables: query.variables,
    useCache: useCache
  };
  var result = GraphqlHooks.useQuery(query.query, queryOpts);
  var refetch = result.refetch;
  var match = result.loading;
  var match$1 = result.error;
  var match$2 = result.data;
  var match$3 = result.fetchError;
  var match$4 = result.httpError;
  var result$1 = match ? /* Loading */0 : (
      match$1 ? (
          match$2 !== undefined ? ({
                TAG: /* Error */0,
                _0: "Something went wrong"
              }) : (
              match$3 !== undefined ? (
                  match$4 !== undefined ? ({
                        TAG: /* Error */0,
                        _0: "Something went wrong"
                      }) : ({
                        TAG: /* Error */0,
                        _0: Caml_option.valFromOption(match$3).message
                      })
                ) : (
                  match$4 !== undefined ? ({
                        TAG: /* Error */0,
                        _0: Caml_option.valFromOption(match$4).body
                      }) : ({
                        TAG: /* Error */0,
                        _0: "Something went wrong"
                      })
                )
            )
        ) : (
          match$2 !== undefined ? ({
                TAG: /* Data */1,
                _0: Curry._1(query.parse, Caml_option.valFromOption(match$2))
              }) : ({
                TAG: /* Error */0,
                _0: "Something went wrong"
              })
        )
    );
  return {
          result: result$1,
          refetch: refetch
        };
}

function useMutation(query) {
  var useMutationResultRaw = GraphqlHooks.useMutation(query.query);
  var fn = Caml_array.caml_array_get(useMutationResultRaw, 0);
  var state = Caml_array.caml_array_get(useMutationResultRaw, 1);
  var runMutation = function (param) {
    return Curry._1(fn, {
                  variables: query.variables
                }).then(function (result) {
                var match = result.error;
                var match$1 = result.data;
                var match$2 = result.fetchError;
                var match$3 = result.httpError;
                return Promise.resolve(match ? (
                              match$1 !== undefined ? ({
                                    TAG: /* MutationError */0,
                                    _0: "Something went wrong"
                                  }) : (
                                  match$2 !== undefined ? (
                                      match$3 !== undefined ? ({
                                            TAG: /* MutationError */0,
                                            _0: "Something went wrong"
                                          }) : ({
                                            TAG: /* MutationError */0,
                                            _0: Caml_option.valFromOption(match$2).message
                                          })
                                    ) : (
                                      match$3 !== undefined ? ({
                                            TAG: /* MutationError */0,
                                            _0: Caml_option.valFromOption(match$3).body
                                          }) : ({
                                            TAG: /* MutationError */0,
                                            _0: "Something went wrong"
                                          })
                                    )
                                )
                            ) : (
                              match$1 !== undefined ? ({
                                    TAG: /* MutationData */1,
                                    _0: Curry._1(query.parse, Caml_option.valFromOption(match$1))
                                  }) : ({
                                    TAG: /* MutationError */0,
                                    _0: "Something went wrong"
                                  })
                            ));
              });
  };
  return {
          runMutation: runMutation,
          loading: state.loading,
          cacheHit: state.cacheHit,
          error: state.error
        };
}

function mutationMap(mutation, func) {
  return mutation.then(function (response) {
              var tmp;
              tmp = response.TAG ? ({
                    TAG: /* MutationData */1,
                    _0: Curry._1(func, response._0)
                  }) : ({
                    TAG: /* MutationError */0,
                    _0: response._0
                  });
              return Promise.resolve(tmp);
            });
}

function runMutationMap(runMutation, func, param) {
  return mutationMap(Curry._1(runMutation, undefined), func);
}

exports.MemCache = MemCache;
exports.createMemCache = createMemCache;
exports.Client = Client;
exports.createClient = createClient;
exports.Provider = Provider;
exports.responseMap = responseMap;
exports.useQuery = useQuery;
exports.useMutation = useMutation;
exports.mutationMap = mutationMap;
exports.runMutationMap = runMutationMap;
/* provider Not a pure module */
