'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function startRead(leaderChar, e) {
  if (e.ctrlKey && e.altKey && e.key === leaderChar) {
    e.preventDefault();
    return /* Reading */{
            _0: "",
            _1: Date.now()
          };
  } else {
    return /* Waiting */0;
  }
}

function isReadValid(lastReadTimeStamp, currentTimeStamp, maxMsBetween) {
  var diff = currentTimeStamp - lastReadTimeStamp;
  return diff < maxMsBetween;
}

function continueRead(maxMsBetween, onRead, e, read, lastReadTimeStamp) {
  var currentTimeStamp = Date.now();
  if (isReadValid(lastReadTimeStamp, currentTimeStamp, maxMsBetween)) {
    e.preventDefault();
    if (e.key === "Enter") {
      Curry._1(onRead, read);
      return /* Waiting */0;
    } else {
      return /* Reading */{
              _0: read + e.key,
              _1: currentTimeStamp
            };
    }
  } else {
    return /* Waiting */0;
  }
}

function reducer(leaderChar, maxMsBetween, onRead, onTimeout, state, action) {
  if (action) {
    var e = action._0;
    var match = state.readState;
    return {
            readState: match ? continueRead(maxMsBetween, onRead, e, match._0, match._1) : startRead(leaderChar, e)
          };
  }
  var match$1 = state.readState;
  if (!match$1) {
    return state;
  }
  var currentTimeStamp = Date.now();
  if (isReadValid(match$1._1, currentTimeStamp, maxMsBetween)) {
    return state;
  } else {
    Curry._1(onTimeout, match$1._0);
    return {
            readState: /* Waiting */0
          };
  }
}

function useBarcodeListener(leaderChar, onRead, onTimeout) {
  var match = React.useReducer((function (param, param$1) {
          return reducer(leaderChar, 2000.0, onRead, onTimeout, param, param$1);
        }), {
        readState: /* Waiting */0
      });
  var dispatch = match[1];
  React.useEffect(function () {
        var d = document;
        var intervalId = setInterval((function (param) {
                return Curry._1(dispatch, /* CheckTimeout */0);
              }), 2000);
        var keyDown = function (e) {
          return Curry._1(dispatch, /* Read */{
                      _0: e
                    });
        };
        d.addEventListener("keydown", keyDown);
        return (function (param) {
                  d.removeEventListener("keydown", keyDown);
                  clearInterval(intervalId);
                  
                });
      });
  
}

exports.startRead = startRead;
exports.isReadValid = isReadValid;
exports.continueRead = continueRead;
exports.reducer = reducer;
exports.useBarcodeListener = useBarcodeListener;
/* react Not a pure module */
