'use strict';

var React = require("react");
var GraphiQL$BirddogsoftwareFrontend = require("../GraphiQL.bs.js");
var EcommerceModule$BirddogsoftwareFrontend = require("./Ecommerce/EcommerceModule.bs.js");
var EnterpriseModule$BirddogsoftwareFrontend = require("./Enterprise/EnterpriseModule.bs.js");

function route(defaultModule, url, store, enterpriseDashboardUrl) {
  var match = url.path;
  if (match) {
    var exit = 0;
    switch (match.hd) {
      case "ecommerce" :
          return React.createElement(EcommerceModule$BirddogsoftwareFrontend.make, {
                      url: {
                        path: match.tl,
                        hash: url.hash,
                        search: url.search
                      },
                      store: store
                    });
      case "e" :
      case "enterprise" :
          exit = 2;
          break;
      case "graphiql" :
          if (!match.tl) {
            return React.createElement(GraphiQL$BirddogsoftwareFrontend.make, GraphiQL$BirddogsoftwareFrontend.makeProps(undefined));
          }
          break;
      default:
        
    }
    if (exit === 2) {
      return React.createElement(EnterpriseModule$BirddogsoftwareFrontend.make, {
                  url: {
                    path: match.tl,
                    hash: url.hash,
                    search: url.search
                  },
                  store: store,
                  enterpriseDashboardUrl: enterpriseDashboardUrl
                });
    }
    
  }
  if (defaultModule) {
    return React.createElement(EcommerceModule$BirddogsoftwareFrontend.make, {
                url: url,
                store: store
              });
  } else {
    return React.createElement(EnterpriseModule$BirddogsoftwareFrontend.make, {
                url: url,
                store: store,
                enterpriseDashboardUrl: enterpriseDashboardUrl
              });
  }
}

exports.route = route;
/* react Not a pure module */
