'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var Cart$BirddogsoftwareFrontend = require("../../Components/Cart.bs.js");
var Page$BirddogsoftwareFrontend = require("../../../../Components/Page.bs.js");
var Tabs$BirddogsoftwareFrontend = require("../../../../Components/Tabs.bs.js");
var PaperPadded$BirddogsoftwareFrontend = require("../../../../Components/PaperPadded.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../../Utils/GraphQLHooks.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../../../../Components/QueryResponse.bs.js");
var CartQuickAddItemNo$BirddogsoftwareFrontend = require("./CartQuickAddItemNo.bs.js");
var CartQuickAddScanner$BirddogsoftwareFrontend = require("./CartQuickAddScanner.bs.js");

var ppx_printed_query = "query Q  {\norderDraftInProgressOrNew  {\norderDraftId  \nlines  {\norderDraftLineId  \nitem  {\nlowResImage  \ndescriptionCalculated  \nlongDescription  \n}\n\nquantityOrdered  \ntotal  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftInProgressOrNew");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "orderDraftId");
    var value$5 = Js_dict.get(value$3, "lines");
    tmp = {
      orderDraftId: value$4 !== undefined ? Caml_option.valFromOption(value$4) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing"),
      lines: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map(function (value) {
              var value$1 = Js_option.getExn(Js_json.decodeObject(value));
              var value$2 = Js_dict.get(value$1, "orderDraftLineId");
              var tmp;
              if (value$2 !== undefined) {
                var value$3 = Caml_option.valFromOption(value$2);
                var value$4 = Js_json.decodeString(value$3);
                tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
              } else {
                tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftLineId on type OrderDraftLine is missing");
              }
              var value$5 = Js_dict.get(value$1, "item");
              var tmp$1;
              if (value$5 !== undefined) {
                var value$6 = Caml_option.valFromOption(value$5);
                var match = Js_json.decodeNull(value$6);
                if (match !== undefined) {
                  tmp$1 = undefined;
                } else {
                  var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
                  var value$8 = Js_dict.get(value$7, "lowResImage");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field lowResImage on type Item is missing");
                  }
                  var value$11 = Js_dict.get(value$7, "descriptionCalculated");
                  var tmp$3;
                  if (value$11 !== undefined) {
                    var value$12 = Caml_option.valFromOption(value$11);
                    var value$13 = Js_json.decodeString(value$12);
                    tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Item is missing");
                  }
                  var value$14 = Js_dict.get(value$7, "longDescription");
                  var tmp$4;
                  if (value$14 !== undefined) {
                    var value$15 = Caml_option.valFromOption(value$14);
                    var value$16 = Js_json.decodeString(value$15);
                    tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                  } else {
                    tmp$4 = Js_exn.raiseError("graphql_ppx: Field longDescription on type Item is missing");
                  }
                  tmp$1 = {
                    lowResImage: tmp$2,
                    descriptionCalculated: tmp$3,
                    longDescription: tmp$4
                  };
                }
              } else {
                tmp$1 = undefined;
              }
              var value$17 = Js_dict.get(value$1, "quantityOrdered");
              var tmp$5;
              if (value$17 !== undefined) {
                var value$18 = Caml_option.valFromOption(value$17);
                var value$19 = Js_json.decodeNumber(value$18);
                tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$18));
              } else {
                tmp$5 = Js_exn.raiseError("graphql_ppx: Field quantityOrdered on type OrderDraftLine is missing");
              }
              var value$20 = Js_dict.get(value$1, "total");
              return {
                      orderDraftLineId: tmp,
                      item: tmp$1,
                      quantityOrdered: tmp$5,
                      total: value$20 !== undefined ? Caml_option.valFromOption(value$20) : Js_exn.raiseError("graphql_ppx: Field total on type OrderDraftLine is missing")
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field lines on type OrderDraft is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftInProgressOrNew on type Query is missing");
  }
  return {
          orderDraftInProgressOrNew: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftInProgressOrNewQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "query Q  {\norderDraftInProgressOrNew  {\nlines  {\norderDraftLineId  \nitem  {\nlowResImage  \ndescriptionCalculated  \nlongDescription  \n}\n\nquantityOrdered  \ntotal  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftInProgressOrNew");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "lines");
    tmp = {
      lines: value$4 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$4))).map(function (value) {
              var value$1 = Js_option.getExn(Js_json.decodeObject(value));
              var value$2 = Js_dict.get(value$1, "orderDraftLineId");
              var tmp;
              if (value$2 !== undefined) {
                var value$3 = Caml_option.valFromOption(value$2);
                var value$4 = Js_json.decodeString(value$3);
                tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
              } else {
                tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftLineId on type OrderDraftLine is missing");
              }
              var value$5 = Js_dict.get(value$1, "item");
              var tmp$1;
              if (value$5 !== undefined) {
                var value$6 = Caml_option.valFromOption(value$5);
                var match = Js_json.decodeNull(value$6);
                if (match !== undefined) {
                  tmp$1 = undefined;
                } else {
                  var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
                  var value$8 = Js_dict.get(value$7, "lowResImage");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field lowResImage on type Item is missing");
                  }
                  var value$11 = Js_dict.get(value$7, "descriptionCalculated");
                  var tmp$3;
                  if (value$11 !== undefined) {
                    var value$12 = Caml_option.valFromOption(value$11);
                    var value$13 = Js_json.decodeString(value$12);
                    tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Item is missing");
                  }
                  var value$14 = Js_dict.get(value$7, "longDescription");
                  var tmp$4;
                  if (value$14 !== undefined) {
                    var value$15 = Caml_option.valFromOption(value$14);
                    var value$16 = Js_json.decodeString(value$15);
                    tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
                  } else {
                    tmp$4 = Js_exn.raiseError("graphql_ppx: Field longDescription on type Item is missing");
                  }
                  tmp$1 = {
                    lowResImage: tmp$2,
                    descriptionCalculated: tmp$3,
                    longDescription: tmp$4
                  };
                }
              } else {
                tmp$1 = undefined;
              }
              var value$17 = Js_dict.get(value$1, "quantityOrdered");
              var tmp$5;
              if (value$17 !== undefined) {
                var value$18 = Caml_option.valFromOption(value$17);
                var value$19 = Js_json.decodeNumber(value$18);
                tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$18));
              } else {
                tmp$5 = Js_exn.raiseError("graphql_ppx: Field quantityOrdered on type OrderDraftLine is missing");
              }
              var value$20 = Js_dict.get(value$1, "total");
              return {
                      orderDraftLineId: tmp,
                      item: tmp$1,
                      quantityOrdered: tmp$5,
                      total: value$20 !== undefined ? Caml_option.valFromOption(value$20) : Js_exn.raiseError("graphql_ppx: Field total on type OrderDraftLine is missing")
                    };
            }) : Js_exn.raiseError("graphql_ppx: Field lines on type OrderDraft is missing")
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftInProgressOrNew on type Query is missing");
  }
  return {
          orderDraftInProgressOrNew: tmp
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var OrderDraftLines = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "mutation Q($orderDraftId: Guid!, $itemNo: String!, $data: OrderDraftLineInput!)  {\norderDraftAddLine(orderDraftId: $orderDraftId, itemNo: $itemNo, data: $data)  {\nsuccess  {\norderDraftId  \n}\n\nfailure  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftAddLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "INVALID_FEATURE_OPTION_CONFIG" :
                      tmp$1 = "INVALID_FEATURE_OPTION_CONFIG";
                      break;
                  case "INVALID_ITEM" :
                      tmp$1 = "INVALID_ITEM";
                      break;
                  case "INVALID_ORDER" :
                      tmp$1 = "INVALID_ORDER";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftAddLineFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftAddLineFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderDraftId");
          tmp = {
            NAME: "Success",
            VAL: {
              orderDraftId: value$7 !== undefined ? Caml_option.valFromOption(value$7) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftAddLine on type Mutation is missing");
  }
  return {
          orderDraftAddLine: tmp
        };
}

function json_of_OrderDraftLineInput(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$2(orderDraftId, itemNo, data, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var orderDraftId = variables.orderDraftId;
  var itemNo = variables.itemNo;
  var data = variables.data;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(orderDraftId, itemNo, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "itemNo",
                  itemNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, orderDraftId, itemNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "itemNo",
                      itemNo
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var OrderDraftAddLine = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function useLineAdd(orderDraftId, itemNo, data) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$2(orderDraftId, itemNo, data, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderDraftAddLine;
                }), param);
  };
}

function reducer(state, action) {
  return {
          currentTab: action._0
        };
}

function CartQuickAdd(Props) {
  var orderDraftIdQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(undefined), undefined, undefined);
  var orderDraftLinesQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(undefined), undefined, undefined);
  React.useReducer(reducer, {
        currentTab: 0
      });
  return React.createElement(Page$BirddogsoftwareFrontend.make, {
              maxWidth: "Sm",
              children: React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, "Row", undefined, undefined, undefined, undefined, undefined, /* V1 */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
                                    queryResponse: orderDraftIdQuery.result,
                                    children: (function (result) {
                                        return React.createElement(Tabs$BirddogsoftwareFrontend.make, {
                                                    children: [
                                                      {
                                                        label: "Part # Entry",
                                                        content: React.createElement(CartQuickAddItemNo$BirddogsoftwareFrontend.make, {
                                                              orderDraftId: Belt_Option.getExn(Js_json.decodeString(result.orderDraftInProgressOrNew.orderDraftId)),
                                                              onLineAdd: (function (param) {
                                                                  return Curry._1(orderDraftLinesQuery.refetch, undefined);
                                                                })
                                                            })
                                                      },
                                                      {
                                                        label: "QR Scan",
                                                        content: React.createElement(CartQuickAddScanner$BirddogsoftwareFrontend.ScanManager.make, {
                                                              orderDraftId: Belt_Option.getExn(Js_json.decodeString(result.orderDraftInProgressOrNew.orderDraftId)),
                                                              onReload: (function (param) {
                                                                  return Curry._1(orderDraftLinesQuery.refetch, undefined);
                                                                })
                                                            })
                                                      }
                                                    ]
                                                  });
                                      })
                                  })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(PaperPadded$BirddogsoftwareFrontend.make, {
                                    children: null
                                  }, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "SHOPPING CART", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
                                        queryResponse: orderDraftLinesQuery.result,
                                        children: (function (result) {
                                            return React.createElement(Cart$BirddogsoftwareFrontend.make, {
                                                        orderDraft: result.orderDraftInProgressOrNew
                                                      });
                                          })
                                      }))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))
            });
}

var make$3 = CartQuickAdd;

exports.OrderDraftInProgressOrNewQuery = OrderDraftInProgressOrNewQuery;
exports.OrderDraftLines = OrderDraftLines;
exports.OrderDraftAddLine = OrderDraftAddLine;
exports.useLineAdd = useLineAdd;
exports.reducer = reducer;
exports.make = make$3;
/* react Not a pure module */
