'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../GraphQLHooks.bs.js");

var ppx_printed_query = "mutation Q($orderDraftId: Guid!, $itemNo: String!, $data: OrderDraftLineInput!)  {\norderDraftAddLine(orderDraftId: $orderDraftId, itemNo: $itemNo, data: $data)  {\nsuccess  {\norderDraftId  \n}\n\nfailure  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftAddLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "INVALID_FEATURE_OPTION_CONFIG" :
                      tmp$1 = "INVALID_FEATURE_OPTION_CONFIG";
                      break;
                  case "INVALID_ITEM" :
                      tmp$1 = "INVALID_ITEM";
                      break;
                  case "INVALID_ORDER" :
                      tmp$1 = "INVALID_ORDER";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftAddLineFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftAddLineFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderDraftId");
          tmp = {
            NAME: "Success",
            VAL: {
              orderDraftId: value$7 !== undefined ? Caml_option.valFromOption(value$7) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftAddLine on type Mutation is missing");
  }
  return {
          orderDraftAddLine: tmp
        };
}

function json_of_OrderDraftLineInput(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(orderDraftId, itemNo, data, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var orderDraftId = variables.orderDraftId;
  var itemNo = variables.itemNo;
  var data = variables.data;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(orderDraftId, itemNo, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "itemNo",
                  itemNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, orderDraftId, itemNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "itemNo",
                      itemNo
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftAddLine = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function lineAddData(quantityOrdered, param) {
  return {
          comments: undefined,
          discountPercent: undefined,
          locationCode: undefined,
          price: undefined,
          promiseDate: undefined,
          quantityOrdered: quantityOrdered,
          quantityToShip: undefined,
          requestDate: undefined
        };
}

function reducer(state, action) {
  if (action.TAG) {
    return {
            quantityOrdered: state.quantityOrdered,
            addState: action._0
          };
  } else {
    return {
            quantityOrdered: action._0,
            addState: state.addState
          };
  }
}

function use(orderDraftId, itemNo, lineData, onSuccess, onError) {
  var match = React.useReducer(reducer, {
        quantityOrdered: lineData.quantityOrdered,
        addState: "NotStarted"
      });
  var dispatch = match[1];
  var state = match[0];
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(orderDraftId, itemNo, {
            comments: lineData.comments,
            discountPercent: lineData.discountPercent,
            locationCode: lineData.locationCode,
            price: lineData.price,
            promiseDate: lineData.promiseDate,
            quantityOrdered: state.quantityOrdered,
            quantityToShip: lineData.quantityToShip,
            requestDate: lineData.requestDate
          }, undefined));
  var add = function (param) {
    var match = lineData.quantityOrdered;
    if (match !== undefined) {
      Curry._1(dispatch, {
            TAG: /* SetAddState */1,
            _0: "Started"
          });
      Curry._1(m.runMutation, undefined).then(function (response) {
            if (response.TAG) {
              var match = response._0.orderDraftAddLine;
              if (match.NAME === "Success") {
                Curry._1(dispatch, {
                      TAG: /* SetAddState */1,
                      _0: "Added"
                    });
                Curry._1(onSuccess, undefined);
              } else {
                var er = "There was an error adding the item to cart.";
                Curry._1(dispatch, {
                      TAG: /* SetAddState */1,
                      _0: {
                        NAME: "Error",
                        VAL: er
                      }
                    });
                Curry._1(onError, er);
              }
            } else {
              var x = response._0;
              Curry._1(dispatch, {
                    TAG: /* SetAddState */1,
                    _0: {
                      NAME: "Error",
                      VAL: x
                    }
                  });
              Curry._1(onError, x);
            }
            return Promise.resolve(undefined);
          });
      return ;
    }
    
  };
  return {
          setQuantityOrdered: (function (x) {
              return Curry._1(dispatch, {
                          TAG: /* SetQuantityOrdered */0,
                          _0: x
                        });
            }),
          quantityOrdered: state.quantityOrdered,
          add: add,
          addState: state.addState
        };
}

var LineAdd = {
  OrderDraftAddLine: OrderDraftAddLine,
  lineAddData: lineAddData,
  reducer: reducer,
  use: use
};

exports.LineAdd = LineAdd;
/* react Not a pure module */
