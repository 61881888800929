'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_ThemeOptions = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ThemeOptions.bs.js");
var Styles = require("@material-ui/core/styles");
var MaterialUi_ThemeProvider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_ThemeProvider.bs.js");
var Order$BirddogsoftwareFrontend = require("../../Types/Order.bs.js");
var Location$BirddogsoftwareFrontend = require("../../Utils/Location.bs.js");
var ModuleUtils$BirddogsoftwareFrontend = require("../ModuleUtils.bs.js");
var RequireRole$BirddogsoftwareFrontend = require("../../Components/RequireRole.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../UrlResolver.bs.js");
var SignOutButton$BirddogsoftwareFrontend = require("../../Components/SignIn/SignOutButton.bs.js");
var EcommerceReset$BirddogsoftwareFrontend = require("../Ecommerce/signin/EcommerceReset.bs.js");
var EnterpriseItem$BirddogsoftwareFrontend = require("./Item/EnterpriseItem.bs.js");
var EcommerceForgot$BirddogsoftwareFrontend = require("../Ecommerce/signin/EcommerceForgot.bs.js");
var EcommerceSignIn$BirddogsoftwareFrontend = require("../Ecommerce/signin/EcommerceSignIn.bs.js");
var EcommerceSignUp$BirddogsoftwareFrontend = require("../Ecommerce/signin/EcommerceSignUp.bs.js");
var EnterpriseBRAIN$BirddogsoftwareFrontend = require("./BRAIN/EnterpriseBRAIN.bs.js");
var EnterpriseInbox$BirddogsoftwareFrontend = require("./BRAIN/EnterpriseInbox.bs.js");
var EnterpriseSidebar$BirddogsoftwareFrontend = require("./EnterpriseSidebar.bs.js");
var EnterpriseCalendar$BirddogsoftwareFrontend = require("./BRAIN/EnterpriseCalendar.bs.js");
var EnterpriseLogEntry$BirddogsoftwareFrontend = require("./EnterpriseLogEntry.bs.js");
var EnterpriseTaskEdit$BirddogsoftwareFrontend = require("./BRAIN/EnterpriseTaskEdit.bs.js");
var EnterpriseUserEdit$BirddogsoftwareFrontend = require("./EnterpriseUserEdit.bs.js");
var EnterpriseDashboard$BirddogsoftwareFrontend = require("./EnterpriseDashboard.bs.js");
var EnterpriseLogSearch$BirddogsoftwareFrontend = require("./EnterpriseLogSearch.bs.js");
var EnterpriseOrderList$BirddogsoftwareFrontend = require("./Order/EnterpriseOrderList.bs.js");
var EnterpriseOrderOpen$BirddogsoftwareFrontend = require("./Order/EnterpriseOrderOpen.bs.js");
var SidebarHeaderLayout$BirddogsoftwareFrontend = require("../../Components/SidebarHeaderLayout.bs.js");
var EnterpriseOrderDraft$BirddogsoftwareFrontend = require("./Order/EnterpriseOrderDraft.bs.js");
var EnterpriseUserSearch$BirddogsoftwareFrontend = require("./EnterpriseUserSearch.bs.js");
var EnterpriseContactEdit$BirddogsoftwareFrontend = require("./BRAIN/EnterpriseContactEdit.bs.js");
var EnterpriseFilterGroup$BirddogsoftwareFrontend = require("./FilterGroup/EnterpriseFilterGroup.bs.js");
var EnterpriseSettingsEdit$BirddogsoftwareFrontend = require("./EnterpriseSettingsEdit.bs.js");
var EnterpriseSettingsList$BirddogsoftwareFrontend = require("./EnterpriseSettingsList.bs.js");
var EnterpriseContactSearch$BirddogsoftwareFrontend = require("./BRAIN/EnterpriseContactSearch.bs.js");
var EnterpriseConnectorAccount$BirddogsoftwareFrontend = require("./EnterpriseConnectorAccount.bs.js");
var EnterpriseEmailTemplateEdit$BirddogsoftwareFrontend = require("./EnterpriseEmailTemplateEdit.bs.js");
var EnterpriseEmailTemplateList$BirddogsoftwareFrontend = require("./EnterpriseEmailTemplateList.bs.js");
var EnterpriseFilterGroupDetail$BirddogsoftwareFrontend = require("./FilterGroup/EnterpriseFilterGroupDetail.bs.js");
var EnterpriseWarehouseStations$BirddogsoftwareFrontend = require("./Warehouse/EnterpriseWarehouseStations.bs.js");

function EnterpriseModule$IfAnon(Props) {
  var signinResolved = UrlResolver$BirddogsoftwareFrontend.useResolved({
        NAME: "Enterprise",
        VAL: "/signin"
      });
  Location$BirddogsoftwareFrontend.unsafe_setWithReturnUrl(signinResolved.isExternal, signinResolved.url, "CurrentPage");
  return React.createElement(React.Fragment, undefined);
}

var IfAnon = {
  make: EnterpriseModule$IfAnon
};

function route(param, param$1) {
  return ModuleUtils$BirddogsoftwareFrontend.generateModuleRoutes((function (store) {
                return React.createElement(EcommerceSignIn$BirddogsoftwareFrontend.make, {
                            signUpLink: {
                              NAME: "Enterprise",
                              VAL: "/signup"
                            },
                            forgotLink: {
                              NAME: "Enterprise",
                              VAL: "/forgot"
                            },
                            store: store
                          });
              }), (function (store) {
                return React.createElement(EcommerceSignUp$BirddogsoftwareFrontend.make, {
                            signInLink: {
                              NAME: "Enterprise",
                              VAL: "/signin"
                            },
                            forgotLink: {
                              NAME: "Enterprise",
                              VAL: "/forgot"
                            },
                            store: store
                          });
              }), (function (store) {
                return React.createElement(EcommerceForgot$BirddogsoftwareFrontend.make, {
                            signInLink: {
                              NAME: "Enterprise",
                              VAL: "/signin"
                            },
                            store: store
                          });
              }), (function (store, tokenId) {
                return React.createElement(EcommerceReset$BirddogsoftwareFrontend.make, {
                            tokenId: tokenId,
                            store: store
                          });
              }), (function (url, store) {
                var user = store.state.user;
                var tmp;
                if (user) {
                  var match = url.path;
                  if (match) {
                    switch (match.hd) {
                      case "brain" :
                          var match$1 = match.tl;
                          if (match$1) {
                            switch (match$1.hd) {
                              case "calendar" :
                                  tmp = match$1.tl ? "404 not found" : React.createElement(EnterpriseCalendar$BirddogsoftwareFrontend.make, {});
                                  break;
                              case "contacts" :
                                  var match$2 = match$1.tl;
                                  if (match$2) {
                                    var id = match$2.hd;
                                    tmp = id === "new" ? (
                                        match$2.tl ? "404 not found" : React.createElement(EnterpriseContactEdit$BirddogsoftwareFrontend.New.make, {})
                                      ) : (
                                        match$2.tl ? "404 not found" : React.createElement(EnterpriseContactEdit$BirddogsoftwareFrontend.Edit.make, {
                                                store: store,
                                                id: id
                                              })
                                      );
                                  } else {
                                    tmp = React.createElement(EnterpriseContactSearch$BirddogsoftwareFrontend.make, {});
                                  }
                                  break;
                              case "inbox" :
                                  tmp = match$1.tl ? "404 not found" : React.createElement(EnterpriseInbox$BirddogsoftwareFrontend.make, {
                                          defaultUserId: user._0.userId
                                        });
                                  break;
                              case "tasks" :
                                  var match$3 = match$1.tl;
                                  if (match$3) {
                                    var id$1 = match$3.hd;
                                    tmp = id$1 === "new" ? (
                                        match$3.tl ? "404 not found" : React.createElement(EnterpriseTaskEdit$BirddogsoftwareFrontend.New.make, {})
                                      ) : (
                                        match$3.tl ? "404 not found" : React.createElement(EnterpriseTaskEdit$BirddogsoftwareFrontend.Edit.make, {
                                                store: store,
                                                id: id$1
                                              })
                                      );
                                  } else {
                                    tmp = "404 not found";
                                  }
                                  break;
                              default:
                                tmp = "404 not found";
                            }
                          } else {
                            tmp = React.createElement(EnterpriseBRAIN$BirddogsoftwareFrontend.make, {});
                          }
                          break;
                      case "connector-accounts" :
                          var match$4 = match.tl;
                          if (match$4) {
                            var id$2 = match$4.hd;
                            tmp = id$2 === "new" ? (
                                match$4.tl ? "404 not found" : React.createElement(EnterpriseConnectorAccount$BirddogsoftwareFrontend.Form.New.make, {
                                        context: undefined
                                      })
                              ) : (
                                match$4.tl ? "404 not found" : React.createElement(EnterpriseConnectorAccount$BirddogsoftwareFrontend.Form.Edit.make, {
                                        context: undefined,
                                        id: id$2
                                      })
                              );
                          } else {
                            tmp = React.createElement(EnterpriseConnectorAccount$BirddogsoftwareFrontend.List.WithContainer.make, {
                                  context: undefined
                                });
                          }
                          break;
                      case "email-templates" :
                          var match$5 = match.tl;
                          if (match$5) {
                            var emailTemplateId = match$5.hd;
                            tmp = emailTemplateId === "new" ? (
                                match$5.tl ? "404 not found" : React.createElement(EnterpriseEmailTemplateEdit$BirddogsoftwareFrontend.New.make, {})
                              ) : (
                                match$5.tl ? "404 not found" : React.createElement(EnterpriseEmailTemplateEdit$BirddogsoftwareFrontend.Edit.make, {
                                        emailTemplateId: emailTemplateId
                                      })
                              );
                          } else {
                            tmp = React.createElement(EnterpriseEmailTemplateList$BirddogsoftwareFrontend.make, {});
                          }
                          break;
                      case "filter-groups" :
                          var match$6 = match.tl;
                          if (match$6) {
                            var id$3 = match$6.hd;
                            var exit = 0;
                            if (id$3 === "new" && !match$6.tl) {
                              tmp = React.createElement(EnterpriseFilterGroup$BirddogsoftwareFrontend.Form.New.make, {
                                    context: undefined
                                  });
                            } else {
                              exit = 1;
                            }
                            if (exit === 1) {
                              var match$7 = match$6.tl;
                              if (match$7) {
                                if (match$7.hd === "details") {
                                  var match$8 = match$7.tl;
                                  if (match$8) {
                                    var id$4 = match$8.hd;
                                    tmp = id$4 === "new" ? (
                                        match$8.tl ? "404 not found" : React.createElement(EnterpriseFilterGroupDetail$BirddogsoftwareFrontend.Form.New.make, {
                                                context: {
                                                  filterGroupId: id$3
                                                }
                                              })
                                      ) : (
                                        match$8.tl ? "404 not found" : React.createElement(EnterpriseFilterGroupDetail$BirddogsoftwareFrontend.Form.Edit.make, {
                                                context: {
                                                  filterGroupId: id$3
                                                },
                                                id: id$4
                                              })
                                      );
                                  } else {
                                    tmp = "404 not found";
                                  }
                                } else {
                                  tmp = "404 not found";
                                }
                              } else {
                                tmp = React.createElement(EnterpriseFilterGroup$BirddogsoftwareFrontend.Form.Edit.make, {
                                      context: undefined,
                                      id: id$3
                                    });
                              }
                            }
                            
                          } else {
                            tmp = React.createElement(EnterpriseFilterGroup$BirddogsoftwareFrontend.List.WithContainer.make, {
                                  context: undefined
                                });
                          }
                          break;
                      case "items" :
                          var match$9 = match.tl;
                          if (match$9) {
                            var id$5 = match$9.hd;
                            tmp = id$5 === "new" ? (
                                match$9.tl ? "404 not found" : React.createElement(EnterpriseItem$BirddogsoftwareFrontend.Form.New.make, {
                                        context: undefined
                                      })
                              ) : (
                                match$9.tl ? "404 not found" : React.createElement(EnterpriseItem$BirddogsoftwareFrontend.Form.Edit.make, {
                                        context: undefined,
                                        id: id$5
                                      })
                              );
                          } else {
                            tmp = React.createElement(EnterpriseItem$BirddogsoftwareFrontend.List.WithContainer.make, {
                                  context: undefined
                                });
                          }
                          break;
                      case "log" :
                          var match$10 = match.tl;
                          tmp = match$10 ? (
                              match$10.tl ? "404 not found" : React.createElement(EnterpriseLogEntry$BirddogsoftwareFrontend.make, {
                                      id: match$10.hd
                                    })
                            ) : React.createElement(EnterpriseLogSearch$BirddogsoftwareFrontend.make, {});
                          break;
                      case "orders" :
                          var match$11 = match.tl;
                          if (match$11) {
                            switch (match$11.hd) {
                              case "draft" :
                                  var match$12 = match$11.tl;
                                  tmp = match$12 ? (
                                      match$12.tl ? "404 not found" : React.createElement(EnterpriseOrderDraft$BirddogsoftwareFrontend.Draft.make, {
                                              orderDraftId: match$12.hd
                                            })
                                    ) : React.createElement(EnterpriseOrderDraft$BirddogsoftwareFrontend.Current.make, {});
                                  break;
                              case "open" :
                                  var match$13 = match$11.tl;
                                  if (match$13) {
                                    var match$14 = match$13.tl;
                                    if (match$14 && !match$14.tl) {
                                      var orderType = match$13.hd;
                                      var orderType$1 = Order$BirddogsoftwareFrontend.OrderType.ofString(orderType);
                                      tmp = orderType$1 !== undefined ? React.createElement(EnterpriseOrderOpen$BirddogsoftwareFrontend.make, {
                                              orderType: orderType$1,
                                              orderNo: match$14.hd
                                            }) : "invalid orderType: " + orderType;
                                    } else {
                                      tmp = "404 not found";
                                    }
                                  } else {
                                    tmp = "404 not found";
                                  }
                                  break;
                              default:
                                tmp = "404 not found";
                            }
                          } else {
                            tmp = React.createElement(EnterpriseOrderList$BirddogsoftwareFrontend.make, {});
                          }
                          break;
                      case "settings" :
                          var match$15 = match.tl;
                          if (match$15) {
                            var instanceSettingsId = match$15.hd;
                            tmp = instanceSettingsId === "new" ? (
                                match$15.tl ? "404 not found" : React.createElement(EnterpriseSettingsEdit$BirddogsoftwareFrontend.New.make, {})
                              ) : (
                                match$15.tl ? "404 not found" : React.createElement(EnterpriseSettingsEdit$BirddogsoftwareFrontend.Edit.make, {
                                        instanceSettingsId: instanceSettingsId
                                      })
                              );
                          } else {
                            tmp = React.createElement(EnterpriseSettingsList$BirddogsoftwareFrontend.make, {});
                          }
                          break;
                      case "users" :
                          var match$16 = match.tl;
                          if (match$16) {
                            var id$6 = match$16.hd;
                            tmp = id$6 === "new" ? (
                                match$16.tl ? "404 not found" : React.createElement(EnterpriseUserEdit$BirddogsoftwareFrontend.New.make, {})
                              ) : (
                                match$16.tl ? "404 not found" : React.createElement(EnterpriseUserEdit$BirddogsoftwareFrontend.Edit.make, {
                                        store: store,
                                        id: id$6
                                      })
                              );
                          } else {
                            tmp = React.createElement(EnterpriseUserSearch$BirddogsoftwareFrontend.make, {});
                          }
                          break;
                      case "warehouse-stations" :
                          var match$17 = match.tl;
                          if (match$17) {
                            var id$7 = match$17.hd;
                            tmp = id$7 === "new" ? (
                                match$17.tl ? "404 not found" : React.createElement(EnterpriseWarehouseStations$BirddogsoftwareFrontend.Form.New.make, {
                                        context: undefined
                                      })
                              ) : (
                                match$17.tl ? "404 not found" : React.createElement(EnterpriseWarehouseStations$BirddogsoftwareFrontend.Form.Edit.make, {
                                        context: undefined,
                                        id: id$7
                                      })
                              );
                          } else {
                            tmp = React.createElement(EnterpriseWarehouseStations$BirddogsoftwareFrontend.List.WithContainer.make, {
                                  context: undefined
                                });
                          }
                          break;
                      default:
                        tmp = "404 not found";
                    }
                  } else {
                    tmp = React.createElement(EnterpriseDashboard$BirddogsoftwareFrontend.make, {});
                  }
                } else {
                  tmp = React.createElement(EnterpriseDashboard$BirddogsoftwareFrontend.make, {});
                }
                return React.createElement(RequireRole$BirddogsoftwareFrontend.make, {
                            store: store,
                            roles: [
                              /* Employee */3,
                              /* Admin */4
                            ],
                            allOrAny: /* Any */1,
                            ifAnon: React.createElement(EnterpriseModule$IfAnon, {}),
                            children: tmp
                          });
              }), param, param$1);
}

var theme = Styles.createMuiTheme(MaterialUi_ThemeOptions.make(undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.PaletteOptions.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.Primary.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f95602", undefined)), Caml_option.some(MaterialUi_ThemeOptions.Secondary.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#666666", undefined)), undefined, undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUi_ThemeOptions.Typography.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined));

function EnterpriseModule(Props) {
  var url = Props.url;
  var store = Props.store;
  var enterpriseDashboardUrl = Props.enterpriseDashboardUrl;
  var user = store.state.user;
  var tmp = {
    headerTitle: "Dashboard",
    sidebar: React.createElement(EnterpriseSidebar$BirddogsoftwareFrontend.make, {
          enterpriseDashboardUrl: enterpriseDashboardUrl
        }),
    children: route(url, store)
  };
  var tmp$1 = user ? Caml_option.some(React.createElement(SignOutButton$BirddogsoftwareFrontend.make, {
              store: store
            })) : undefined;
  if (tmp$1 !== undefined) {
    tmp.headerButtons = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(MaterialUi_ThemeProvider.make, {
              children: React.createElement(SidebarHeaderLayout$BirddogsoftwareFrontend.make, tmp),
              theme: theme
            });
}

var make = EnterpriseModule;

exports.IfAnon = IfAnon;
exports.route = route;
exports.theme = theme;
exports.make = make;
/* theme Not a pure module */
