'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var CamlinternalOO = require("bs-platform/lib/js/camlinternalOO.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../SnackbarHooks.bs.js");
var Autocomplete = require("../../../../../../src/client/Components/Forms/Autocomplete");

function makeProps(label, placeholder, className, disabled, fullWidth, margin, initialSelectedKey, useGetSuggestions, useGetEmptySuggestions, onChange, itemToString, isTypeahead, helperText, error, param) {
  var tmp = {
    useGetSuggestions: useGetSuggestions,
    onChange: (function (x) {
        return Curry._1(onChange, (x == null) ? undefined : Caml_option.some(x));
      }),
    itemToString: (function (x) {
        return Curry._1(itemToString, (x == null) ? undefined : Caml_option.some(x));
      })
  };
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (fullWidth !== undefined) {
    tmp.fullWidth = Caml_option.valFromOption(fullWidth);
  }
  var tmp$1 = Belt_Option.map(margin, MaterialUi_TextField.marginToJs);
  if (tmp$1 !== undefined) {
    tmp.margin = Caml_option.valFromOption(tmp$1);
  }
  if (initialSelectedKey !== undefined) {
    tmp.initialSelectedKey = Caml_option.valFromOption(initialSelectedKey);
  }
  if (useGetEmptySuggestions !== undefined) {
    tmp.useGetEmptySuggestions = Caml_option.valFromOption(useGetEmptySuggestions);
  }
  if (isTypeahead !== undefined) {
    tmp.isTypeahead = Caml_option.valFromOption(isTypeahead);
  }
  if (helperText !== undefined) {
    tmp.helperText = Caml_option.valFromOption(helperText);
  }
  if (error !== undefined) {
    tmp.error = Caml_option.valFromOption(error);
  }
  return tmp;
}

var make = Autocomplete.default;

var Base = {
  makeProps: makeProps,
  make: make
};

var class_tables = /* Cons */{
  key: undefined,
  data: undefined,
  next: undefined
};

function Autocomplete$GraphQL$HookWrapper(Props) {
  if (!class_tables.key) {
    var $$class = CamlinternalOO.create_table(0);
    var env = CamlinternalOO.new_variable($$class, "");
    var env_init = function (env$1) {
      var self = CamlinternalOO.create_object_opt(undefined, $$class);
      self[env] = env$1;
      return self;
    };
    CamlinternalOO.init_class($$class);
    class_tables.key = env_init;
  }
  return Curry._1(class_tables.key, undefined);
}

var HookWrapper = {
  make: Autocomplete$GraphQL$HookWrapper
};

function mapResult(error, result) {
  if (typeof result === "number") {
    return {
            suggestions: [],
            isLoading: true
          };
  }
  if (result.TAG) {
    return {
            suggestions: result._0,
            isLoading: false
          };
  }
  Curry._1(error, result._0);
  return {
          suggestions: [],
          isLoading: false
        };
}

function Autocomplete$GraphQL(Props) {
  var className = Props.className;
  var label = Props.label;
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var fullWidth = Props.fullWidth;
  var margin = Props.margin;
  var initialSelectedKey = Props.initialSelectedKey;
  var useQuery = Props.useQuery;
  var useEmptyQuery = Props.useEmptyQuery;
  var onChange = Props.onChange;
  var itemToString = Props.itemToString;
  var isTypeahead = Props.isTypeahead;
  var helperText = Props.helperText;
  var error = Props.error;
  var snackbarError = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var useGetSuggestions = function (input) {
    return mapResult(snackbarError, Curry._1(useQuery, input));
  };
  var useGetEmptySuggestions = useEmptyQuery !== undefined ? (function (param) {
        return mapResult(snackbarError, Curry._1(useEmptyQuery, undefined));
      }) : undefined;
  return React.createElement(make, makeProps(label, placeholder, className, disabled, fullWidth, margin, initialSelectedKey, useGetSuggestions, useGetEmptySuggestions, onChange, itemToString, isTypeahead, helperText, error, undefined));
}

var GraphQL = {
  HookWrapper: HookWrapper,
  mapResult: mapResult,
  make: Autocomplete$GraphQL
};

exports.Base = Base;
exports.GraphQL = GraphQL;
/* make Not a pure module */
