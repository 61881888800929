'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Select = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Select.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../../Utils/GraphQLHooks.bs.js");

var ppx_printed_query = "query UserEmployees  {\nuserEmployees  {\nuserId  \nname  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "userEmployees");
  return {
          userEmployees: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "userId");
                  var value$3 = Js_dict.get(value$1, "name");
                  var tmp;
                  if (value$3 !== undefined) {
                    var value$4 = Caml_option.valFromOption(value$3);
                    var value$5 = Js_json.decodeString(value$4);
                    tmp = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
                  }
                  return {
                          userId: value$2 !== undefined ? Caml_option.valFromOption(value$2) : Js_exn.raiseError("graphql_ppx: Field userId on type User is missing"),
                          name: tmp
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field userEmployees on type Query is missing")
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var UserEmployeesQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function UserSelect(Props) {
  var defaultUserId = Props.defaultUserId;
  var onChange = Props.onChange;
  var userEmployeesQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(undefined), undefined, undefined);
  var message = userEmployeesQuery.result;
  var match;
  match = typeof message === "number" ? [
      true,
      undefined,
      []
    ] : (
      message.TAG ? [
          false,
          undefined,
          message._0.userEmployees
        ] : [
          false,
          message._0,
          []
        ]
    );
  var data = match[2];
  return React.createElement(Core.Select, MaterialUi_Select.makeProps(undefined, Caml_option.some(Belt_Array.map(data, (function (value) {
                            return React.createElement("option", {
                                        value: Belt_Option.getExn(Js_json.decodeString(value.userId))
                                      }, value.name);
                          }))), undefined, undefined, undefined, undefined, undefined, undefined, true, (function ($$event, x) {
                    return Curry._1(onChange, $$event.target.value);
                  }), undefined, undefined, undefined, undefined, undefined, {
                  NAME: "String",
                  VAL: defaultUserId
                }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, data.length === 0, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined));
}

var make$1 = UserSelect;

exports.UserEmployeesQuery = UserEmployeesQuery;
exports.make = make$1;
/* react Not a pure module */
