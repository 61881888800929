'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var intRegex = /^(\s*)([0-9]+)(\s*)$/;

function $$parseInt(x) {
  var result = intRegex.exec(x);
  if (result !== null) {
    return Caml_format.caml_int_of_string(Belt_Option.getExn(Caml_option.nullable_to_opt(Caml_array.caml_array_get(result, 2))));
  }
  
}

exports.intRegex = intRegex;
exports.$$parseInt = $$parseInt;
/* No side effect */
