'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Divider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Divider.bs.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var Style$BirddogsoftwareFrontend = require("../../../Utils/Style.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../../Components/Forms/ActionHeader.bs.js");
var OrderLineAdd$BirddogsoftwareFrontend = require("../../../Components/Orders/OrderLineAdd.bs.js");
var PaperPadding$BirddogsoftwareFrontend = require("../../../Components/PaperPadding.bs.js");
var SnackbarContent$BirddogsoftwareFrontend = require("../../../Components/SnackbarContent.bs.js");
var EnterpriseOrderTotals$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderTotals.bs.js");
var EnterpriseOrderCustomer$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderCustomer.bs.js");
var EnterpriseOrderLineEdit$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderLineEdit.bs.js");
var EnterpriseOrderLineTable$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderLineTable.bs.js");
var EnterpriseOrderBarcodeHandler$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderBarcodeHandler.bs.js");
var EnterpriseOrderNameAndAddress$BirddogsoftwareFrontend = require("./Components/EnterpriseOrderNameAndAddress.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            lastOrder: state.lastOrder,
            editingLine: undefined
          };
  } else if (action.TAG) {
    return {
            lastOrder: state.lastOrder,
            editingLine: Caml_option.some(action._0)
          };
  } else {
    return {
            lastOrder: Caml_option.some(action._0),
            editingLine: state.editingLine
          };
  }
}

function classRecordToJs(param) {
  return {
          lineAdd: param.lineAdd
        };
}

var classes = {
  TAG: /* ThemeFunc */1,
  _0: (function (theme) {
      return {
              lineAdd: {
                marginBottom: Style$BirddogsoftwareFrontend.getSpacing(theme, 1)
              }
            };
    })
};

function classRecordStringsFromJs(arg) {
  return {
          lineAdd: arg.lineAdd
        };
}

var EnterpriseOrderStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var EnterpriseOrderStyles = MaterialUi_WithStyles.WithStylesSafe(EnterpriseOrderStylesDefs);

function EnterpriseOrder(Props) {
  var breadcrumbHref = Props.breadcrumbHref;
  var breadcrumbContent = Props.breadcrumbContent;
  var orderQueryResult = Props.orderQueryResult;
  var refetch = Props.refetch;
  var mainActions = Props.mainActions;
  var footer = Props.footer;
  var sideContent = Props.sideContent;
  var useUpdate = Props.useUpdate;
  var useLineAdd = Props.useLineAdd;
  var useLineEdit = Props.useLineEdit;
  var useLineDelete = Props.useLineDelete;
  var useSelectCustomer = Props.useSelectCustomer;
  var match = React.useReducer(reducer, {
        lastOrder: undefined,
        editingLine: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  if (typeof orderQueryResult !== "number" && orderQueryResult.TAG) {
    var order = orderQueryResult._0;
    if (order !== undefined) {
      var order$1 = Caml_option.valFromOption(order);
      var lastOrder = state.lastOrder;
      if (lastOrder !== undefined && !Caml_obj.caml_notequal(Caml_option.valFromOption(lastOrder), order$1)) {
        
      } else {
        Curry._1(dispatch, {
              TAG: /* Loaded */0,
              _0: order$1
            });
      }
    }
    
  }
  var match$1 = state.lastOrder;
  var isLoading = match$1 !== undefined || typeof orderQueryResult !== "number" ? false : true;
  var match$2 = state.lastOrder;
  var lines;
  if (typeof orderQueryResult === "number") {
    lines = match$2 !== undefined ? Caml_option.valFromOption(match$2).lines : [];
  } else if (orderQueryResult.TAG) {
    var order$2 = orderQueryResult._0;
    lines = order$2 !== undefined ? Caml_option.valFromOption(order$2).lines : [];
  } else {
    lines = [];
  }
  var match$3 = state.lastOrder;
  var order$3;
  if (typeof orderQueryResult === "number") {
    order$3 = match$3;
  } else {
    if (!orderQueryResult.TAG) {
      return React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
                  variant: "Error",
                  children: orderQueryResult._0
                });
    }
    var order$4 = orderQueryResult._0;
    if (order$4 === undefined) {
      return React.createElement(SnackbarContent$BirddogsoftwareFrontend.make, {
                  variant: "Error",
                  children: "Order not found"
                });
    }
    order$3 = order$4;
  }
  return React.createElement(EnterpriseOrderStyles.make, {
              children: (function (classes) {
                  var tmp = {
                    breadcrumbs: [
                      {
                        href: {
                          NAME: "Enterprise",
                          VAL: "/orders"
                        },
                        content: "Orders"
                      },
                      {
                        href: {
                          NAME: "Enterprise",
                          VAL: breadcrumbHref
                        },
                        content: breadcrumbContent
                      }
                    ]
                  };
                  if (mainActions !== undefined) {
                    tmp.mainActions = Caml_option.valFromOption(mainActions);
                  }
                  var orderLine = state.editingLine;
                  return React.createElement(React.Fragment, undefined, React.createElement(ActionHeader$BirddogsoftwareFrontend.make, tmp), orderLine !== undefined ? React.createElement(EnterpriseOrderLineEdit$BirddogsoftwareFrontend.make, {
                                    orderLine: Caml_option.valFromOption(orderLine),
                                    useLineEdit: useLineEdit,
                                    onSave: (function (param) {
                                        return Curry._1(refetch, undefined);
                                      }),
                                    onClose: (function (param) {
                                        return Curry._1(dispatch, /* CloseEditLine */0);
                                      })
                                  }) : React.createElement(React.Fragment, undefined), React.createElement(EnterpriseOrderBarcodeHandler$BirddogsoftwareFrontend.make, {
                                  useLineAdd: useLineAdd,
                                  refetch: refetch
                                }), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V1 */1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                                      children: React.createElement(OrderLineAdd$BirddogsoftwareFrontend.make, {
                                                            useLineAdd: useLineAdd,
                                                            onLineAdd: (function (param) {
                                                                return Curry._1(refetch, undefined);
                                                              })
                                                          })
                                                    }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(EnterpriseOrderLineTable$BirddogsoftwareFrontend.make, {
                                                      lines: lines,
                                                      isLoading: isLoading,
                                                      onEdit: (function (row) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* EditLine */1,
                                                                      _0: row
                                                                    });
                                                        }),
                                                      onLineDelete: (function (param) {
                                                          return Curry._1(refetch, undefined);
                                                        }),
                                                      useLineDelete: useLineDelete
                                                    }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                                      children: React.createElement(EnterpriseOrderTotals$BirddogsoftwareFrontend.make, {
                                                            order: order$3,
                                                            useUpdate: useUpdate,
                                                            refetch: refetch
                                                          })
                                                    }), footer !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                                            children: Caml_option.valFromOption(footer)
                                                          })) : React.createElement(React.Fragment, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, /* V9 */8, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, true, undefined, undefined, /* V3 */2, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                              children: React.createElement(EnterpriseOrderCustomer$BirddogsoftwareFrontend.make, {
                                                    order: order$3,
                                                    refetch: refetch,
                                                    useSelectCustomer: useSelectCustomer
                                                  })
                                            }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                              children: React.createElement(EnterpriseOrderNameAndAddress$BirddogsoftwareFrontend.make, {
                                                    title: "Billing Address",
                                                    nameAndAddress: Belt_Option.map(order$3, (function (order) {
                                                            return order.billTo;
                                                          })),
                                                    useUpdate: useUpdate,
                                                    buildInput: (function (nameAndAddress) {
                                                        return {
                                                                billTo: Caml_option.some(nameAndAddress),
                                                                shipTo: undefined,
                                                                calculateFreight: undefined,
                                                                discountPercent: undefined,
                                                                freight: undefined,
                                                                miscellaneous: undefined,
                                                                orderDate: undefined,
                                                                userId: undefined
                                                              };
                                                      }),
                                                    refetch: refetch
                                                  })
                                            }), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                              children: React.createElement(EnterpriseOrderNameAndAddress$BirddogsoftwareFrontend.make, {
                                                    title: "Shipping Address",
                                                    nameAndAddress: Belt_Option.map(order$3, (function (order) {
                                                            return order.shipTo;
                                                          })),
                                                    useUpdate: useUpdate,
                                                    buildInput: (function (nameAndAddress) {
                                                        return {
                                                                billTo: undefined,
                                                                shipTo: Caml_option.some(nameAndAddress),
                                                                calculateFreight: undefined,
                                                                discountPercent: undefined,
                                                                freight: undefined,
                                                                miscellaneous: undefined,
                                                                orderDate: undefined,
                                                                userId: undefined
                                                              };
                                                      }),
                                                    refetch: refetch
                                                  })
                                            })), sideContent !== undefined ? Caml_option.valFromOption(sideContent) : React.createElement(React.Fragment, undefined))));
                })
            });
}

var make = EnterpriseOrder;

exports.reducer = reducer;
exports.EnterpriseOrderStylesDefs = EnterpriseOrderStylesDefs;
exports.EnterpriseOrderStyles = EnterpriseOrderStyles;
exports.make = make;
/* EnterpriseOrderStyles Not a pure module */
