'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_MenuItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_MenuItem.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");

function TrueFalseUnsetSelect(Props) {
  var itemLabelsOpt = Props.itemLabels;
  var label = Props.label;
  var margin = Props.margin;
  var fullWidth = Props.fullWidth;
  var value = Props.value;
  var onChange = Props.onChange;
  var itemLabels = itemLabelsOpt !== undefined ? itemLabelsOpt : "TrueFalse";
  var value$1 = value !== undefined ? (
      value ? "true" : "false"
    ) : "unset";
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, label, margin, undefined, undefined, undefined, (function ($$event) {
                    var match = $$event.target.value;
                    var tmp;
                    switch (match) {
                      case "false" :
                          tmp = false;
                          break;
                      case "true" :
                          tmp = true;
                          break;
                      case "unset" :
                          tmp = undefined;
                          break;
                      default:
                        tmp = Js_exn.raiseError($$event.target.value);
                    }
                    return Curry._1(onChange, tmp);
                  }), undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, value$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(itemLabels === "TrueFalse" ? "True" : "Yes"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                      NAME: "String",
                      VAL: "true"
                    }, undefined, undefined, "true", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(itemLabels === "TrueFalse" ? "False" : "No"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                      NAME: "String",
                      VAL: "false"
                    }, undefined, undefined, "false", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps("Unset", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                      NAME: "String",
                      VAL: "unset"
                    }, undefined, undefined, "unset", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make = TrueFalseUnsetSelect;

exports.make = make;
/* react Not a pure module */
