'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var Page$BirddogsoftwareFrontend = require("./Page.bs.js");

function PagePaper(Props) {
  var maxWidth = Props.maxWidth;
  var children = Props.children;
  return React.createElement(Page$BirddogsoftwareFrontend.make, {
              maxWidth: maxWidth,
              children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(children), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
            });
}

var Paper;

var make = PagePaper;

exports.Paper = Paper;
exports.make = make;
/* react Not a pure module */
