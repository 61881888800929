'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var MaterialUi_Checkbox = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Checkbox.bs.js");
var MaterialUi_FormControlLabel = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_FormControlLabel.bs.js");
var Intl$BirddogsoftwareFrontend = require("../../../Utils/Intl.bs.js");
var Stricture$BirddogsoftwareFrontend = require("../../../Components/Forms/Stricture.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../../Components/Forms/SimpleForm.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var ServiceProTypeSelect$BirddogsoftwareFrontend = require("../../../Components/Forms/Pickers/ServiceProTypeSelect.bs.js");

var ppx_printed_query = "mutation q($itemNo: String!)  {\nitemDelete(itemNo: $itemNo)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "itemDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          itemDelete: tmp
        };
}

function make(itemNo, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "itemNo",
                    itemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var itemNo = variables.itemNo;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "itemNo",
                    itemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(itemNo, param) {
  return Js_dict.fromArray([[
                  "itemNo",
                  itemNo
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, itemNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "itemNo",
                      itemNo
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($itemNo: String, $data: ItemInput!)  {\nitem(itemNo: $itemNo, data: $data)  {\nsuccess  {\nitemNo  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "item");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "success");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "itemNo");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field itemNo on type Item is missing");
          }
          tmp$1 = {
            itemNo: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      tmp = {
        success: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          item: tmp
        };
}

function json_of_ServiceProTypes(value) {
  if (value === "UNASSIGNED") {
    return "UNASSIGNED";
  } else if (value === "INSPECTION") {
    return "INSPECTION";
  } else if (value === "SERVICE_TECH") {
    return "SERVICE_TECH";
  } else if (value === "TASK") {
    return "TASK";
  } else {
    return "INVENTORY";
  }
}

function json_of_ItemInput(value) {
  var v = value.itemNo;
  var v$1 = value.serviceProType;
  var v$2 = value.shortDescription;
  return Js_dict.fromArray([
                [
                  "itemNo",
                  v !== undefined ? v : null
                ],
                [
                  "serviceProType",
                  v$1 !== undefined ? json_of_ServiceProTypes(v$1) : null
                ],
                [
                  "shortDescription",
                  v$2 !== undefined ? v$2 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(itemNo, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "itemNo",
                    itemNo !== undefined ? itemNo : null
                  ],
                  [
                    "data",
                    json_of_ItemInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var itemNo = variables.itemNo;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "itemNo",
                    itemNo !== undefined ? itemNo : null
                  ],
                  [
                    "data",
                    json_of_ItemInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(itemNo, data, param) {
  return Js_dict.fromArray([
                [
                  "itemNo",
                  itemNo !== undefined ? itemNo : null
                ],
                [
                  "data",
                  json_of_ItemInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, itemNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "itemNo",
                      itemNo !== undefined ? itemNo : null
                    ],
                    [
                      "data",
                      json_of_ItemInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Mutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_ServiceProTypes: json_of_ServiceProTypes,
  json_of_ItemInput: json_of_ItemInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "query Q($itemNo: String!)  {\nitem(itemNo: $itemNo)  {\nitemNo  \nshortDescription  \nserviceProType  \nuserField5  \nlistPrice  \npricing  {\ncurrentUserAndDraftOrder  {\nresult  {\ncalculatedPrice  \n}\n\nquantityBreaksAvailable  \n}\n\n}\n\nquantityOnHand  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "item");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "itemNo");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field itemNo on type Item is missing");
      }
      var value$8 = Js_dict.get(value$4, "shortDescription");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field shortDescription on type Item is missing");
      }
      var value$11 = Js_dict.get(value$4, "serviceProType");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        if (value$13 !== undefined) {
          switch (value$13) {
            case "INSPECTION" :
                tmp$3 = "INSPECTION";
                break;
            case "INVENTORY" :
                tmp$3 = "INVENTORY";
                break;
            case "SERVICE_TECH" :
                tmp$3 = "SERVICE_TECH";
                break;
            case "TASK" :
                tmp$3 = "TASK";
                break;
            case "UNASSIGNED" :
                tmp$3 = "UNASSIGNED";
                break;
            default:
              tmp$3 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for ServiceProTypes: " + value$13);
          }
        } else {
          tmp$3 = Js_exn.raiseError("graphql_ppx: Expected enum value for ServiceProTypes, got " + JSON.stringify(value$12));
        }
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field serviceProType on type Item is missing");
      }
      var value$14 = Js_dict.get(value$4, "userField5");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field userField5 on type Item is missing");
      }
      var value$17 = Js_dict.get(value$4, "listPrice");
      var value$18 = Js_dict.get(value$4, "pricing");
      var tmp$5;
      if (value$18 !== undefined) {
        var value$19 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$18)));
        var value$20 = Js_dict.get(value$19, "currentUserAndDraftOrder");
        var tmp$6;
        if (value$20 !== undefined) {
          var value$21 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$20)));
          var value$22 = Js_dict.get(value$21, "result");
          var tmp$7;
          if (value$22 !== undefined) {
            var value$23 = Caml_option.valFromOption(value$22);
            var match$1 = Js_json.decodeNull(value$23);
            if (match$1 !== undefined) {
              tmp$7 = undefined;
            } else {
              var value$24 = Js_option.getExn(Js_json.decodeObject(value$23));
              var value$25 = Js_dict.get(value$24, "calculatedPrice");
              var tmp$8;
              if (value$25 !== undefined) {
                var value$26 = Caml_option.valFromOption(value$25);
                var match$2 = Js_json.decodeNull(value$26);
                tmp$8 = match$2 !== undefined ? undefined : Caml_option.some(value$26);
              } else {
                tmp$8 = undefined;
              }
              tmp$7 = {
                calculatedPrice: tmp$8
              };
            }
          } else {
            tmp$7 = undefined;
          }
          var value$27 = Js_dict.get(value$21, "quantityBreaksAvailable");
          var tmp$9;
          if (value$27 !== undefined) {
            var value$28 = Caml_option.valFromOption(value$27);
            var value$29 = Js_json.decodeBoolean(value$28);
            tmp$9 = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$28));
          } else {
            tmp$9 = Js_exn.raiseError("graphql_ppx: Field quantityBreaksAvailable on type Pricing is missing");
          }
          tmp$6 = {
            result: tmp$7,
            quantityBreaksAvailable: tmp$9
          };
        } else {
          tmp$6 = Js_exn.raiseError("graphql_ppx: Field currentUserAndDraftOrder on type PricingManager is missing");
        }
        tmp$5 = {
          currentUserAndDraftOrder: tmp$6
        };
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field pricing on type Item is missing");
      }
      var value$30 = Js_dict.get(value$4, "quantityOnHand");
      var tmp$10;
      if (value$30 !== undefined) {
        var value$31 = Caml_option.valFromOption(value$30);
        var value$32 = Js_json.decodeNumber(value$31);
        tmp$10 = value$32 !== undefined ? value$32 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$31));
      } else {
        tmp$10 = Js_exn.raiseError("graphql_ppx: Field quantityOnHand on type Item is missing");
      }
      tmp = {
        itemNo: tmp$1,
        shortDescription: tmp$2,
        serviceProType: tmp$3,
        userField5: tmp$4,
        listPrice: value$17 !== undefined ? Caml_option.valFromOption(value$17) : Js_exn.raiseError("graphql_ppx: Field listPrice on type Item is missing"),
        pricing: tmp$5,
        quantityOnHand: tmp$10
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          item: tmp
        };
}

function make$2(itemNo, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "itemNo",
                    itemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var itemNo = variables.itemNo;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "itemNo",
                    itemNo
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(itemNo, param) {
  return Js_dict.fromArray([[
                  "itemNo",
                  itemNo
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, itemNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "itemNo",
                      itemNo
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var Query = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function doneRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/items"
        };
}

function breadcrumbs(context) {
  return [{
            href: {
              NAME: "Enterprise",
              VAL: "/items"
            },
            content: "Items"
          }];
}

function newRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/items/new"
        };
}

var RouteArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute
};

var ppx_printed_query$3 = "mutation Q($itemNo: String!, $data: OrderDraftLineInput!)  {\norderDraftAddLine(itemNo: $itemNo, data: $data)  {\nsuccess  {\norderDraftId  \n}\n\nfailure  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftAddLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "INVALID_FEATURE_OPTION_CONFIG" :
                      tmp$1 = "INVALID_FEATURE_OPTION_CONFIG";
                      break;
                  case "INVALID_ITEM" :
                      tmp$1 = "INVALID_ITEM";
                      break;
                  case "INVALID_ORDER" :
                      tmp$1 = "INVALID_ORDER";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftAddLineFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftAddLineFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderDraftId");
          tmp = {
            NAME: "Success",
            VAL: {
              orderDraftId: value$7 !== undefined ? Caml_option.valFromOption(value$7) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderPreOrder, OrderDraftAddLineFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftAddLine on type Mutation is missing");
  }
  return {
          orderDraftAddLine: tmp
        };
}

function json_of_OrderDraftLineInput(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$3(itemNo, data, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var itemNo = variables.itemNo;
  var data = variables.data;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$3(itemNo, data, param) {
  return Js_dict.fromArray([
                [
                  "itemNo",
                  itemNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, itemNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "itemNo",
                      itemNo
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var OrderDraftAddLine = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

var newState = {
  itemNo: "",
  shortDescription: "",
  serviceProType: "UNASSIGNED",
  listPrice: undefined,
  currentCustomerPrice: undefined,
  quantityBreaksAvailable: undefined,
  quantityOnHand: undefined,
  userField5: undefined,
  addState: "NotStarted"
};

function reducer(context, state, action) {
  if (typeof action === "number") {
    if (action === /* StartAddToOrder */0) {
      return {
              itemNo: state.itemNo,
              shortDescription: state.shortDescription,
              serviceProType: state.serviceProType,
              listPrice: state.listPrice,
              currentCustomerPrice: state.currentCustomerPrice,
              quantityBreaksAvailable: state.quantityBreaksAvailable,
              quantityOnHand: state.quantityOnHand,
              userField5: state.userField5,
              addState: "Started"
            };
    } else {
      return {
              itemNo: state.itemNo,
              shortDescription: state.shortDescription,
              serviceProType: state.serviceProType,
              listPrice: state.listPrice,
              currentCustomerPrice: state.currentCustomerPrice,
              quantityBreaksAvailable: state.quantityBreaksAvailable,
              quantityOnHand: state.quantityOnHand,
              userField5: state.userField5,
              addState: "Added"
            };
    }
  }
  switch (action.TAG | 0) {
    case /* SetItemNo */0 :
        return {
                itemNo: action._0,
                shortDescription: state.shortDescription,
                serviceProType: state.serviceProType,
                listPrice: state.listPrice,
                currentCustomerPrice: state.currentCustomerPrice,
                quantityBreaksAvailable: state.quantityBreaksAvailable,
                quantityOnHand: state.quantityOnHand,
                userField5: state.userField5,
                addState: state.addState
              };
    case /* SetShortDescription */1 :
        return {
                itemNo: state.itemNo,
                shortDescription: action._0,
                serviceProType: state.serviceProType,
                listPrice: state.listPrice,
                currentCustomerPrice: state.currentCustomerPrice,
                quantityBreaksAvailable: state.quantityBreaksAvailable,
                quantityOnHand: state.quantityOnHand,
                userField5: state.userField5,
                addState: state.addState
              };
    case /* SetServiceProType */2 :
        return {
                itemNo: state.itemNo,
                shortDescription: state.shortDescription,
                serviceProType: action._0,
                listPrice: state.listPrice,
                currentCustomerPrice: state.currentCustomerPrice,
                quantityBreaksAvailable: state.quantityBreaksAvailable,
                quantityOnHand: state.quantityOnHand,
                userField5: state.userField5,
                addState: state.addState
              };
    
  }
}

function getName(context, state) {
  return state.shortDescription;
}

function selfRoute(context, id) {
  return {
          NAME: "Enterprise",
          VAL: "/items/" + id
        };
}

function makeMutation(context, itemNo, state) {
  return make$1(itemNo, {
              itemNo: state.itemNo,
              shortDescription: state.shortDescription,
              serviceProType: state.serviceProType
            }, undefined);
}

function mapMutation(context, result) {
  return Belt_Option.map(Belt_Option.flatMap(result.item, (function (item) {
                    return item.success;
                  })), (function (success) {
                return success.itemNo;
              }));
}

function makeQuery(context, itemNo) {
  return make$2(itemNo, undefined);
}

function mapQuery(context, result) {
  return Belt_Option.map(result.item, (function (item) {
                return {
                        itemNo: item.itemNo,
                        shortDescription: item.shortDescription,
                        serviceProType: item.serviceProType,
                        listPrice: Belt_Option.getExn(Js_json.decodeNumber(item.listPrice)),
                        currentCustomerPrice: Belt_Option.flatMap(item.pricing.currentUserAndDraftOrder.result, (function (x) {
                                return Belt_Option.map(x.calculatedPrice, (function (x) {
                                              return Belt_Option.getExn(Js_json.decodeNumber(x));
                                            }));
                              })),
                        quantityBreaksAvailable: item.pricing.currentUserAndDraftOrder.quantityBreaksAvailable,
                        quantityOnHand: item.quantityOnHand,
                        userField5: item.userField5,
                        addState: "NotStarted"
                      };
              }));
}

function makeDelete(context, itemNo) {
  return make(itemNo, undefined);
}

function saveValidate(context, state) {
  return state.shortDescription.trim() !== "";
}

function mainActions(context, id, state, dispatch) {
  var mutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$3(state.itemNo, {
            comments: undefined,
            discountPercent: undefined,
            locationCode: undefined,
            price: undefined,
            promiseDate: undefined,
            quantityOrdered: undefined,
            quantityToShip: undefined,
            requestDate: undefined
          }, undefined));
  var match = state.addState;
  var tmp;
  var exit = 0;
  if (typeof match === "string" && (match === "Added" || match === "Started")) {
    tmp = "Disabled";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = {
      NAME: "OnClick",
      VAL: (function (param) {
          Curry._1(dispatch, /* StartAddToOrder */0);
          Curry._1(mutation.runMutation, undefined).then(function (response) {
                if (response.TAG) {
                  Curry._1(dispatch, /* FinishAddToOrder */1);
                }
                return Promise.resolve(undefined);
              });
          
        })
    };
  }
  return [{
            key: "addtoorder",
            content: "Add to order",
            action: tmp,
            color: "Primary"
          }];
}

function mainContent(context, id, state, dispatch) {
  var listPrice = state.listPrice;
  var currentCustomerPrice = state.currentCustomerPrice;
  var quantityBreaksAvailable = state.quantityBreaksAvailable;
  var quantityOnHand = state.quantityOnHand;
  var userField5 = state.userField5;
  return React.createElement(React.Fragment, undefined, React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Item #",
                  value: state.itemNo,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetItemNo */0,
                                  _0: value
                                });
                    })
                }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Short Description",
                  value: state.shortDescription,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetShortDescription */1,
                                  _0: value
                                });
                    })
                }), React.createElement(ServiceProTypeSelect$BirddogsoftwareFrontend.Required.make, {
                  label: "Service Pro Type",
                  fullWidth: true,
                  value: state.serviceProType,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetServiceProType */2,
                                  _0: value
                                });
                    })
                }), listPrice !== undefined ? React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                    label: "List Price",
                    value: Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, listPrice),
                    disabled: true
                  }) : React.createElement(React.Fragment, undefined), currentCustomerPrice !== undefined ? React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                    label: "Current Customer Price",
                    value: Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, currentCustomerPrice),
                    disabled: true
                  }) : React.createElement(React.Fragment, undefined), quantityBreaksAvailable !== undefined ? React.createElement(Core.FormControlLabel, MaterialUi_FormControlLabel.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(quantityBreaksAvailable, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), true, undefined, "Quantity Breaks Available", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : React.createElement(React.Fragment, undefined), quantityOnHand !== undefined ? React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                    label: "Quantity On Hand",
                    value: quantityOnHand.toString(),
                    disabled: true
                  }) : React.createElement(React.Fragment, undefined), userField5 !== undefined ? React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                    label: "User Field 5",
                    value: userField5,
                    disabled: true
                  }) : React.createElement(React.Fragment, undefined));
}

var FormArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  newState: newState,
  reducer: reducer,
  doneRoute: doneRoute,
  getName: getName,
  selfRoute: selfRoute,
  makeMutation: makeMutation,
  mapMutation: mapMutation,
  stayAfterSaveNew: false,
  makeQuery: makeQuery,
  mapQuery: mapQuery,
  makeDelete: makeDelete,
  saveValidate: saveValidate,
  mainActions: mainActions,
  mainContent: mainContent,
  secondaryContent: undefined
};

var Form = Stricture$BirddogsoftwareFrontend.Form.Make({
      breadcrumbs: breadcrumbs,
      newRoute: newRoute,
      newState: newState,
      reducer: reducer,
      doneRoute: doneRoute,
      getName: getName,
      selfRoute: selfRoute,
      makeMutation: makeMutation,
      mapMutation: mapMutation,
      makeQuery: makeQuery,
      mapQuery: mapQuery,
      makeDelete: makeDelete,
      saveValidate: saveValidate,
      mainContent: mainContent,
      mainActions: mainActions,
      secondaryContent: undefined,
      stayAfterSaveNew: false
    });

function reducer$1(state, action) {
  return action;
}

function EnterpriseItem$ListAddToCart(Props) {
  var itemNo = Props.itemNo;
  var match = React.useReducer(reducer$1, "NotStarted");
  var dispatch = match[1];
  var state = match[0];
  var mutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$3(itemNo, {
            comments: undefined,
            discountPercent: undefined,
            locationCode: undefined,
            price: undefined,
            promiseDate: undefined,
            quantityOrdered: undefined,
            quantityToShip: undefined,
            requestDate: undefined
          }, undefined));
  return React.createElement(Core.Button, MaterialUi_Button.makeProps(Caml_option.some(typeof state === "string" ? (
                        state === "Started" ? "Adding..." : (
                            state === "Added" ? "Added" : "Add"
                          )
                      ) : "error"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                    Curry._1(dispatch, "Started");
                    Curry._1(mutation.runMutation, undefined).then(function (response) {
                          if (response.TAG) {
                            Curry._1(dispatch, "Added");
                          }
                          return Promise.resolve(undefined);
                        });
                    
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var ListAddToCart = {
  reducer: reducer$1,
  make: EnterpriseItem$ListAddToCart
};

var ppx_printed_query$4 = "query Q($text: String!)  {\nitemSearch(text: $text)  {\nitemNo  \nshortDescription  \nuserField5  \nlistPrice  \npricing  {\ncurrentUserAndDraftOrder  {\nresult  {\ncalculatedPrice  \n}\n\nquantityBreaksAvailable  \n}\n\n}\n\nquantityOnHand  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "itemSearch");
  return {
          itemSearch: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "itemNo");
                  var tmp;
                  if (value$2 !== undefined) {
                    var value$3 = Caml_option.valFromOption(value$2);
                    var value$4 = Js_json.decodeString(value$3);
                    tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field itemNo on type Item is missing");
                  }
                  var value$5 = Js_dict.get(value$1, "shortDescription");
                  var tmp$1;
                  if (value$5 !== undefined) {
                    var value$6 = Caml_option.valFromOption(value$5);
                    var value$7 = Js_json.decodeString(value$6);
                    tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field shortDescription on type Item is missing");
                  }
                  var value$8 = Js_dict.get(value$1, "userField5");
                  var tmp$2;
                  if (value$8 !== undefined) {
                    var value$9 = Caml_option.valFromOption(value$8);
                    var value$10 = Js_json.decodeString(value$9);
                    tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                  } else {
                    tmp$2 = Js_exn.raiseError("graphql_ppx: Field userField5 on type Item is missing");
                  }
                  var value$11 = Js_dict.get(value$1, "listPrice");
                  var value$12 = Js_dict.get(value$1, "pricing");
                  var tmp$3;
                  if (value$12 !== undefined) {
                    var value$13 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$12)));
                    var value$14 = Js_dict.get(value$13, "currentUserAndDraftOrder");
                    var tmp$4;
                    if (value$14 !== undefined) {
                      var value$15 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$14)));
                      var value$16 = Js_dict.get(value$15, "result");
                      var tmp$5;
                      if (value$16 !== undefined) {
                        var value$17 = Caml_option.valFromOption(value$16);
                        var match = Js_json.decodeNull(value$17);
                        if (match !== undefined) {
                          tmp$5 = undefined;
                        } else {
                          var value$18 = Js_option.getExn(Js_json.decodeObject(value$17));
                          var value$19 = Js_dict.get(value$18, "calculatedPrice");
                          var tmp$6;
                          if (value$19 !== undefined) {
                            var value$20 = Caml_option.valFromOption(value$19);
                            var match$1 = Js_json.decodeNull(value$20);
                            tmp$6 = match$1 !== undefined ? undefined : Caml_option.some(value$20);
                          } else {
                            tmp$6 = undefined;
                          }
                          tmp$5 = {
                            calculatedPrice: tmp$6
                          };
                        }
                      } else {
                        tmp$5 = undefined;
                      }
                      var value$21 = Js_dict.get(value$15, "quantityBreaksAvailable");
                      var tmp$7;
                      if (value$21 !== undefined) {
                        var value$22 = Caml_option.valFromOption(value$21);
                        var value$23 = Js_json.decodeBoolean(value$22);
                        tmp$7 = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$22));
                      } else {
                        tmp$7 = Js_exn.raiseError("graphql_ppx: Field quantityBreaksAvailable on type Pricing is missing");
                      }
                      tmp$4 = {
                        result: tmp$5,
                        quantityBreaksAvailable: tmp$7
                      };
                    } else {
                      tmp$4 = Js_exn.raiseError("graphql_ppx: Field currentUserAndDraftOrder on type PricingManager is missing");
                    }
                    tmp$3 = {
                      currentUserAndDraftOrder: tmp$4
                    };
                  } else {
                    tmp$3 = Js_exn.raiseError("graphql_ppx: Field pricing on type Item is missing");
                  }
                  var value$24 = Js_dict.get(value$1, "quantityOnHand");
                  var tmp$8;
                  if (value$24 !== undefined) {
                    var value$25 = Caml_option.valFromOption(value$24);
                    var value$26 = Js_json.decodeNumber(value$25);
                    tmp$8 = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$25));
                  } else {
                    tmp$8 = Js_exn.raiseError("graphql_ppx: Field quantityOnHand on type Item is missing");
                  }
                  return {
                          itemNo: tmp,
                          shortDescription: tmp$1,
                          userField5: tmp$2,
                          listPrice: value$11 !== undefined ? Caml_option.valFromOption(value$11) : Js_exn.raiseError("graphql_ppx: Field listPrice on type Item is missing"),
                          pricing: tmp$3,
                          quantityOnHand: tmp$8
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field itemSearch on type Query is missing")
        };
}

function make$4(text, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([[
                    "text",
                    text
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var text = variables.text;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([[
                    "text",
                    text
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$4(text, param) {
  return Js_dict.fromArray([[
                  "text",
                  text
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, text, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "text",
                      text
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$4
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var ListQuery = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

function primaryColumnTitle(context) {
  return "Item No";
}

function getPrimaryColumnContent(context, x) {
  return x.itemNo;
}

function getPrimaryColumnRoute(context, x) {
  return {
          NAME: "Enterprise",
          VAL: "/items/" + x.itemNo
        };
}

function queryArgsReducer(state, action) {
  return {
          text: action._0
        };
}

function queryArgsElement(state, dispatch) {
  return React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
              label: "Search",
              value: state.text,
              onChange: (function (value) {
                  return Curry._1(dispatch, /* SetText */{
                              _0: value
                            });
                })
            });
}

function makeQuery$1(context, queryArgs) {
  return make$4(queryArgs.text, undefined);
}

function mapQuery$1(context, result) {
  return Belt_Array.map(result.itemSearch, (function (item) {
                return {
                        shortDescription: item.shortDescription,
                        itemNo: item.itemNo,
                        listPrice: Belt_Option.getExn(Js_json.decodeNumber(item.listPrice)),
                        currentCustomerPrice: Belt_Option.flatMap(item.pricing.currentUserAndDraftOrder.result, (function (x) {
                                return Belt_Option.map(x.calculatedPrice, (function (x) {
                                              return Belt_Option.getExn(Js_json.decodeNumber(x));
                                            }));
                              })),
                        quantityBreaksAvailable: item.pricing.currentUserAndDraftOrder.quantityBreaksAvailable,
                        quantityOnHand: item.quantityOnHand,
                        userField5: item.userField5
                      };
              }));
}

var columns = [
  {
    title: "Description",
    render: (function (row) {
        return row.shortDescription;
      })
  },
  {
    title: "List Price",
    render: (function (row) {
        return Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, row.listPrice);
      })
  },
  {
    title: "Customer Price",
    render: (function (row) {
        var x = row.currentCustomerPrice;
        if (x !== undefined) {
          return Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, x);
        } else {
          return React.createElement(React.Fragment, undefined);
        }
      })
  },
  {
    title: "Qty Breaks?",
    render: (function (row) {
        return React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(row.quantityBreaksAvailable, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      })
  },
  {
    title: "Qty On Hand",
    render: (function (row) {
        return row.quantityOnHand.toString();
      })
  },
  {
    title: "Style Number",
    render: (function (row) {
        return row.userField5;
      })
  },
  {
    title: "Add",
    render: (function (row) {
        return React.createElement(EnterpriseItem$ListAddToCart, {
                    itemNo: row.itemNo
                  });
      })
  }
];

var ListArg_queryArgsDefault = {
  text: ""
};

var ListArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  primaryColumnTitle: primaryColumnTitle,
  getPrimaryColumnContent: getPrimaryColumnContent,
  getPrimaryColumnRoute: getPrimaryColumnRoute,
  queryArgsDefault: ListArg_queryArgsDefault,
  queryArgsReducer: queryArgsReducer,
  queryArgsElement: queryArgsElement,
  makeQuery: makeQuery$1,
  mapQuery: mapQuery$1,
  columns: columns
};

var List = Stricture$BirddogsoftwareFrontend.List.Make(ListArg);

exports.DeleteMutation = DeleteMutation;
exports.Mutation = Mutation;
exports.Query = Query;
exports.doneRoute = doneRoute;
exports.RouteArg = RouteArg;
exports.OrderDraftAddLine = OrderDraftAddLine;
exports.FormArg = FormArg;
exports.Form = Form;
exports.ListAddToCart = ListAddToCart;
exports.ListQuery = ListQuery;
exports.ListArg = ListArg;
exports.List = List;
/* Form Not a pure module */
