'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var IntExt$BirddogsoftwareFrontend = require("../../Utils/IntExt.bs.js");

function IntField(Props) {
  var label = Props.label;
  var fullWidth = Props.fullWidth;
  var value = Props.value;
  var onChange = Props.onChange;
  var match = React.useReducer((function (state, action) {
          return action;
        }), true);
  var dispatch = match[1];
  var helperText = match[0] ? undefined : "Please enter an integer";
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, value !== undefined ? (
                    value.NAME === "Int" ? String(value.VAL) : value.VAL
                  ) : "", undefined, helperText !== undefined, undefined, fullWidth, helperText, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(label), undefined, undefined, undefined, undefined, (function ($$event) {
                    var x = $$event.target.value;
                    var tmp;
                    if (x === "") {
                      Curry._1(dispatch, true);
                      tmp = undefined;
                    } else {
                      var x$1 = IntExt$BirddogsoftwareFrontend.$$parseInt(x);
                      if (x$1 !== undefined) {
                        Curry._1(dispatch, true);
                        tmp = {
                          NAME: "Int",
                          VAL: x$1
                        };
                      } else {
                        Curry._1(dispatch, false);
                        tmp = {
                          NAME: "NonInt",
                          VAL: x
                        };
                      }
                    }
                    return Curry._1(onChange, tmp);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = IntField;

exports.make = make;
/* react Not a pure module */
