'use strict';

var Jss = require("jss");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Styles = require("@material-ui/styles");

function makeProps(children, jss, param) {
  return {
          children: children,
          jss: jss
        };
}

var MaterialUi_StylesProvider = {
  makeProps: makeProps
};

var create = (function(jssPreset, jssCreate, insertionPoint){ return jssCreate({...jssPreset, insertionPoint: insertionPoint});});

function reducer(state, action) {
  return action;
}

function StylesProvider(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, /* Init */0);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect(function () {
        if (typeof state !== "number" && !state.TAG) {
          var jss = create(Styles.jssPreset(), Jss.create, state._0);
          Curry._1(dispatch, {
                TAG: /* Created */1,
                _0: jss
              });
        }
        
      });
  var tmp;
  tmp = typeof state === "number" || !state.TAG ? React.createElement(React.Fragment, undefined) : React.createElement(Styles.StylesProvider, {
          children: children,
          jss: state._0
        });
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: (function (x) {
                      if (typeof state === "number" && !(x == null)) {
                        return Curry._1(dispatch, {
                                    TAG: /* HaveRef */0,
                                    _0: x
                                  });
                      }
                      
                    })
                }), tmp);
}

var make = StylesProvider;

exports.MaterialUi_StylesProvider = MaterialUi_StylesProvider;
exports.create = create;
exports.reducer = reducer;
exports.make = make;
/* jss Not a pure module */
