'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var Link$BirddogsoftwareFrontend = require("../Link.bs.js");
var Style$BirddogsoftwareFrontend = require("../../Utils/Style.bs.js");
var Location$BirddogsoftwareFrontend = require("../../Utils/Location.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("./SimpleForm.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../PaperSpacer.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../Modules/UrlResolver.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("./ActionHeader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");
var MaterialTable$BirddogsoftwareFrontend = require("../MaterialTable.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../QueryResponse.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../SnackbarHooks.bs.js");

function Make(Args) {
  var reducer = function (state, action) {
    return {
            id: Caml_option.some(action._0)
          };
  };
  var Stricture$Form$Make$Form = function (Props) {
    var context = Props.context;
    var id = Props.id;
    var state = Props.state;
    var runDelete = Props.runDelete;
    var match = React.useReducer(reducer, {
          id: id
        });
    var dispatch = match[1];
    var id$1 = match[0].id;
    var useSaveMutation = function (data) {
      return GraphQLHooks$BirddogsoftwareFrontend.useMutation(Curry._3(Args.makeMutation, context, id$1, data));
    };
    var breadcrumbs = Curry._1(Args.breadcrumbs, context).concat([id$1 !== undefined ? ({
                href: Curry._2(Args.selfRoute, context, Caml_option.valFromOption(id$1)),
                content: Curry._2(Args.getName, context, state)
              }) : ({
                href: Curry._1(Args.newRoute, context),
                content: "New"
              })]);
    var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
    var doneRoute = Curry._1(Args.doneRoute, context);
    var doneResolved = UrlResolver$BirddogsoftwareFrontend.useResolved(doneRoute);
    var onSave = function (saveResp) {
      var newId = Curry._2(Args.mapMutation, context, saveResp);
      var match = Args.stayAfterSaveNew;
      if (newId !== undefined) {
        if (id$1 === undefined && match) {
          return Curry._1(dispatch, /* SetId */{
                      _0: Caml_option.valFromOption(newId)
                    });
        }
        
      } else if (!match) {
        return Curry._1(error, "Something went wrong.");
      }
      return Location$BirddogsoftwareFrontend.unsafe_setUseReturnUrl(doneResolved.isExternal, doneResolved.url);
    };
    var secondaryContent = Args.secondaryContent;
    var tmp = {
      breadcrumbs: breadcrumbs,
      reducer: Curry._1(Args.reducer, context),
      state: state,
      doneRoute: doneRoute,
      onSave: onSave,
      useSaveMutation: useSaveMutation,
      saveValidate: Curry._1(Args.saveValidate, context),
      mainContent: Curry._2(Args.mainContent, context, id$1),
      mainActions: Curry._2(Args.mainActions, context, id$1)
    };
    if (runDelete !== undefined) {
      tmp.runDelete = Caml_option.valFromOption(runDelete);
    }
    var tmp$1 = secondaryContent !== undefined ? Curry._2(secondaryContent, context, id$1) : undefined;
    if (tmp$1 !== undefined) {
      tmp.secondaryContent = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement(SimpleForm$BirddogsoftwareFrontend.Form.make, tmp);
  };
  var Form = {
    reducer: reducer,
    make: Stricture$Form$Make$Form
  };
  var Stricture$Form$Make$New = function (Props) {
    var context = Props.context;
    return React.createElement(Stricture$Form$Make$Form, {
                context: context,
                id: undefined,
                state: Args.newState
              });
  };
  var New = {
    make: Stricture$Form$Make$New
  };
  var Stricture$Form$Make$Edit = function (Props) {
    var context = Props.context;
    var id = Props.id;
    var getQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(Curry._2(Args.makeQuery, context, id), undefined, undefined);
    var deleteMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(Curry._2(Args.makeDelete, context, id));
    return React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
                queryResponse: getQuery.result,
                children: (function (result) {
                    var state = Curry._2(Args.mapQuery, context, result);
                    if (state !== undefined) {
                      return React.createElement(Stricture$Form$Make$Form, {
                                  context: context,
                                  id: Caml_option.some(id),
                                  state: Caml_option.valFromOption(state),
                                  runDelete: (function (param) {
                                      return Curry._1(deleteMutation.runMutation, undefined);
                                    })
                                });
                    } else {
                      return "Not found";
                    }
                  })
              });
  };
  var Edit = {
    make: Stricture$Form$Make$Edit
  };
  return {
          Form: Form,
          New: New,
          Edit: Edit
        };
}

var Form = {
  Make: Make
};

function classRecordToJs(param) {
  return {
          queryArg: param.queryArg
        };
}

var classes = {
  TAG: /* ThemeFunc */1,
  _0: (function (theme) {
      return {
              queryArg: {
                margin: Style$BirddogsoftwareFrontend.getSpacing(theme, 2)
              }
            };
    })
};

function classRecordStringsFromJs(arg) {
  return {
          queryArg: arg.queryArg
        };
}

var ListStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var ListStyles = MaterialUi_WithStyles.WithStylesSafe(ListStylesDefs);

function Make$1(Args) {
  var Stricture$List$Make$NoContainer = function (Props) {
    var context = Props.context;
    var match = React.useReducer((function (param, param$1) {
            return Curry._2(Args.queryArgsReducer, param, param$1);
          }), Args.queryArgsDefault);
    var queryArgsDispatch = match[1];
    var queryArgs = match[0];
    var emailTemplatesQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(Curry._2(Args.makeQuery, context, queryArgs), undefined, undefined);
    var primaryColumn = {
      title: Curry._1(Args.primaryColumnTitle, context),
      render: (function (row) {
          return React.createElement(Link$BirddogsoftwareFrontend.make, {
                      href: Curry._2(Args.getPrimaryColumnRoute, context, row),
                      underline: "None",
                      children: Curry._2(Args.getPrimaryColumnContent, context, row)
                    });
        })
    };
    var columns = [primaryColumn].concat(Args.columns);
    var message = emailTemplatesQuery.result;
    var match$1;
    match$1 = typeof message === "number" ? [
        true,
        undefined,
        []
      ] : (
        message.TAG ? [
            false,
            undefined,
            Curry._2(Args.mapQuery, context, message._0)
          ] : [
            false,
            message._0,
            []
          ]
      );
    var data = match$1[2];
    var isLoading = match$1[0];
    return React.createElement(ListStyles.make, {
                children: (function (styles) {
                    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                    className: styles.queryArg
                                  }, Curry._2(Args.queryArgsElement, queryArgs, queryArgsDispatch)), React.createElement(MaterialTable$BirddogsoftwareFrontend.Table.make, {
                                    columns: columns,
                                    data: data,
                                    isLoading: isLoading,
                                    options: {
                                      grouping: false,
                                      filtering: false,
                                      toolbar: false
                                    },
                                    components: {
                                      Container: MaterialTable$BirddogsoftwareFrontend.divContainer
                                    }
                                  }));
                  })
              });
  };
  var NoContainer = {
    make: Stricture$List$Make$NoContainer
  };
  var Stricture$List$Make$NoContainerWithNew = function (Props) {
    var context = Props.context;
    return React.createElement(React.Fragment, undefined, React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                    breadcrumbs: [],
                    mainActions: [{
                        key: "new",
                        content: "New",
                        action: {
                          NAME: "Link",
                          VAL: Curry._1(Args.newRoute, context)
                        },
                        color: "Primary"
                      }]
                  }), React.createElement(Stricture$List$Make$NoContainer, {
                    context: context
                  }));
  };
  var NoContainerWithNew = {
    make: Stricture$List$Make$NoContainerWithNew
  };
  var Stricture$List$Make$WithContainer = function (Props) {
    var context = Props.context;
    return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(null), undefined, undefined, undefined, "Md", undefined, undefined, undefined, undefined, undefined), React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                    breadcrumbs: Curry._1(Args.breadcrumbs, context),
                    mainActions: [{
                        key: "new",
                        content: "New",
                        action: {
                          NAME: "Link",
                          VAL: Curry._1(Args.newRoute, context)
                        },
                        color: "Primary"
                      }]
                  }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                    children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(Stricture$List$Make$NoContainer, {
                                      context: context
                                    })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                  }));
  };
  var WithContainer = {
    make: Stricture$List$Make$WithContainer
  };
  return {
          NoContainer: NoContainer,
          NoContainerWithNew: NoContainerWithNew,
          WithContainer: WithContainer
        };
}

var List = {
  Make: Make$1
};

exports.Form = Form;
exports.ListStylesDefs = ListStylesDefs;
exports.ListStyles = ListStyles;
exports.List = List;
/* ListStyles Not a pure module */
