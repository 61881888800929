'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function StoreWithPreload$LoadedStore(Props) {
  var reducer = Props.reducer;
  var state = Props.state;
  var children = Props.children;
  var match = React.useReducer(Curry.__2(reducer), state);
  return Curry._1(children, {
              state: match[0],
              dispatch: match[1]
            });
}

var LoadedStore = {
  make: StoreWithPreload$LoadedStore
};

function StoreWithPreload$UnloadedStore(Props) {
  var getInitialData = Props.getInitialData;
  var setState = Props.setState;
  Curry._1(getInitialData, (function (state) {
          return Curry._1(setState, (function (_state) {
                        return /* LoadedOrError */{
                                _0: state
                              };
                      }));
        }));
  return React.createElement("div", undefined, "Waiting on initial load...");
}

var UnloadedStore = {
  make: StoreWithPreload$UnloadedStore
};

function StoreWithPreload(Props) {
  var getInitialData = Props.getInitialData;
  var reducer = Props.reducer;
  var children = Props.children;
  var match = React.useState(function () {
        return /* NotLoaded */0;
      });
  var state = match[0];
  if (!state) {
    return React.createElement(StoreWithPreload$UnloadedStore, {
                getInitialData: getInitialData,
                setState: match[1]
              });
  }
  var x = state._0;
  if (x.TAG) {
    return React.createElement("div", undefined, x._0);
  } else {
    return React.createElement(StoreWithPreload$LoadedStore, {
                reducer: reducer,
                state: x._0,
                children: children
              });
  }
}

var make = StoreWithPreload;

exports.LoadedStore = LoadedStore;
exports.UnloadedStore = UnloadedStore;
exports.make = make;
/* react Not a pure module */
