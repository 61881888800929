'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_MenuItem = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_MenuItem.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var Order$BirddogsoftwareFrontend = require("../../../Types/Order.bs.js");

function item(x) {
  var s = Order$BirddogsoftwareFrontend.OrderType.stringify(x);
  return React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(s), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                  NAME: "String",
                  VAL: s
                }, undefined, undefined, s, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

function OrderTypeSelect$Required(Props) {
  var orderTypes = Props.orderTypes;
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var value = Props.value;
  var onChange = Props.onChange;
  var value$1 = Order$BirddogsoftwareFrontend.OrderType.stringify(value);
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, Caml_option.some(Belt_Array.map(orderTypes, item)), undefined, undefined, undefined, undefined, undefined, fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, label, margin, undefined, undefined, undefined, (function ($$event) {
                    return Curry._1(onChange, Belt_Option.getExn(Order$BirddogsoftwareFrontend.OrderType.ofString($$event.target.value)));
                  }), undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, value$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var Required = {
  make: OrderTypeSelect$Required
};

function OrderTypeSelect$Option(Props) {
  var orderTypes = Props.orderTypes;
  var label = Props.label;
  Props.className;
  var fullWidth = Props.fullWidth;
  Props.disabled;
  var margin = Props.margin;
  var value = Props.value;
  var onChange = Props.onChange;
  var value$1 = value !== undefined ? Order$BirddogsoftwareFrontend.OrderType.stringify(value) : "";
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, label, margin, undefined, undefined, undefined, (function ($$event) {
                    var value = $$event.target.value;
                    var tmp = value === "" ? undefined : Belt_Option.getExn(Order$BirddogsoftwareFrontend.OrderType.ofString(value));
                    return Curry._1(onChange, tmp);
                  }), undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, value$1, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.MenuItem, MaterialUi_MenuItem.makeProps(Caml_option.some(React.createElement("em", undefined, "None")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                      NAME: "String",
                      VAL: ""
                    }, undefined, undefined, "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), Belt_Array.map(orderTypes, item));
}

var $$Option = {
  make: OrderTypeSelect$Option
};

exports.item = item;
exports.Required = Required;
exports.$$Option = $$Option;
/* react Not a pure module */
