'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var ArrayExt$BirddogsoftwareFrontend = require("../../Utils/ArrayExt.bs.js");
var Location$BirddogsoftwareFrontend = require("../../Utils/Location.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../PaperSpacer.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../Modules/UrlResolver.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("./ActionHeader.bs.js");
var PaperPadding$BirddogsoftwareFrontend = require("../PaperPadding.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../SnackbarHooks.bs.js");

function SimpleForm$Form(Props) {
  var breadcrumbs = Props.breadcrumbs;
  var reducer = Props.reducer;
  var state = Props.state;
  var doneRoute = Props.doneRoute;
  var onSave = Props.onSave;
  var onDelete = Props.onDelete;
  var useSaveMutation = Props.useSaveMutation;
  var saveValidate = Props.saveValidate;
  var runDelete = Props.runDelete;
  var mainContent = Props.mainContent;
  var secondaryContent = Props.secondaryContent;
  var mainActions = Props.mainActions;
  var match = React.useReducer(Curry.__2(reducer), state);
  var dispatch = match[1];
  var state$1 = match[0];
  var doneResolved = UrlResolver$BirddogsoftwareFrontend.useResolved(doneRoute);
  var onSave$1 = onSave !== undefined ? onSave : (function (param) {
        return Location$BirddogsoftwareFrontend.unsafe_setUseReturnUrl(doneResolved.isExternal, doneResolved.url);
      });
  var onDelete$1 = onDelete !== undefined ? onDelete : (function (param) {
        return Location$BirddogsoftwareFrontend.unsafe_setUseReturnUrl(doneResolved.isExternal, doneResolved.url);
      });
  var saveMutation = Curry._1(useSaveMutation, state$1);
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var save = function (param) {
    if (saveValidate !== undefined && !Curry._1(saveValidate, state$1)) {
      return Curry._1(error, "Form invalid");
    } else {
      Curry._1(saveMutation.runMutation, undefined).then(function (response) {
            if (response.TAG) {
              Curry._1(onSave$1, response._0);
            } else {
              Curry._1(error, response._0);
            }
            return Promise.resolve(undefined);
          });
      return ;
    }
  };
  var $$delete = runDelete !== undefined ? (function (param) {
        Curry._1(runDelete, undefined).then(function (response) {
              if (response.TAG) {
                Curry._1(onDelete$1, undefined);
              } else {
                Curry._1(error, response._0);
              }
              return Promise.resolve(undefined);
            });
        
      }) : undefined;
  return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(null), undefined, undefined, undefined, "Md", undefined, undefined, undefined, undefined, undefined), React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                  breadcrumbs: breadcrumbs,
                  mainActions: $$Array.append(mainActions !== undefined ? Curry._2(mainActions, state$1, dispatch) : [], ArrayExt$BirddogsoftwareFrontend.keepMapDefault([
                            {
                              key: "save",
                              content: "Save",
                              action: {
                                NAME: "OnClick",
                                VAL: save
                              },
                              color: "Primary"
                            },
                            $$delete !== undefined ? ({
                                  key: "delete",
                                  content: "Delete",
                                  action: {
                                    NAME: "OnClick",
                                    VAL: $$delete
                                  },
                                  color: "Secondary"
                                }) : undefined,
                            {
                              key: "cancel",
                              content: "Cancel",
                              action: {
                                NAME: "Link",
                                VAL: doneRoute
                              },
                              color: "Secondary"
                            }
                          ]))
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                    children: Curry._2(mainContent, state$1, dispatch)
                                  })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }), secondaryContent !== undefined ? Belt_Array.map(Curry._2(secondaryContent, state$1, dispatch), (function (x) {
                      return React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                                    children: null
                                                  }, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(x.title), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined)), x.content)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                                  key: x.title
                                });
                    })) : React.createElement(React.Fragment, undefined));
}

var Form = {
  make: SimpleForm$Form
};

function SimpleForm$TextField(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var multiline = Props.multiline;
  var disabled = Props.disabled;
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, undefined, disabled, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(label), undefined, multiline, undefined, undefined, onChange !== undefined ? (function ($$event) {
                      return Curry._1(onChange, $$event.target.value);
                    }) : (function (x) {
                      
                    }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var TextField = {
  make: SimpleForm$TextField
};

function noneToEmptyString(x) {
  return Belt_Option.getWithDefault(x, "");
}

function SimpleForm$TextFieldOption(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var multiline = Props.multiline;
  return React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(label), undefined, multiline, undefined, undefined, (function ($$event) {
                    var x = $$event.target.value;
                    var tmp = x === "" ? undefined : x;
                    return Curry._1(onChange, tmp);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Belt_Option.getWithDefault(value, ""), undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var TextFieldOption = {
  make: SimpleForm$TextFieldOption
};

exports.Form = Form;
exports.TextField = TextField;
exports.noneToEmptyString = noneToEmptyString;
exports.TextFieldOption = TextFieldOption;
/* react Not a pure module */
