'use strict';

var React = require("react");
var Core = require("@material-ui/core");
var MaterialUi_Avatar = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Avatar.bs.js");

function UserAvatar(Props) {
  Props.userId;
  return React.createElement(Core.Avatar, MaterialUi_Avatar.makeProps(undefined, "HI", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = UserAvatar;

exports.make = make;
/* react Not a pure module */
