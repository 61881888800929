'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Checkbox = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Checkbox.bs.js");
var MaterialUi_FormControlLabel = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_FormControlLabel.bs.js");
var Fields$BirddogsoftwareFrontend = require("../../../Components/Forms/Fields.bs.js");
var Stricture$BirddogsoftwareFrontend = require("../../../Components/Forms/Stricture.bs.js");
var SimpleForm$BirddogsoftwareFrontend = require("../../../Components/Forms/SimpleForm.bs.js");
var UserDefinedFieldSelect$BirddogsoftwareFrontend = require("../../../Components/Forms/Pickers/UserDefinedFieldSelect.bs.js");

var ppx_printed_query = "mutation q($filterGroupDetailId: Guid!)  {\nfilterGroupDetailDelete(filterGroupDetailId: $filterGroupDetailId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroupDetailDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroupDetailDelete: tmp
        };
}

function make(filterGroupDetailId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "filterGroupDetailId",
                    filterGroupDetailId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var filterGroupDetailId = variables.filterGroupDetailId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "filterGroupDetailId",
                    filterGroupDetailId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(filterGroupDetailId, param) {
  return Js_dict.fromArray([[
                  "filterGroupDetailId",
                  filterGroupDetailId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, filterGroupDetailId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "filterGroupDetailId",
                      filterGroupDetailId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($filterGroupDetailId: Guid, $data: FilterGroupDetailInput!)  {\nfilterGroupDetail(filterGroupDetailId: $filterGroupDetailId, data: $data)  {\nsuccess  {\nfilterGroupDetailId  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroupDetail");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "success");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "filterGroupDetailId");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field filterGroupDetailId on type FilterGroupDetail is missing");
          }
          tmp$1 = {
            filterGroupDetailId: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      tmp = {
        success: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroupDetail: tmp
        };
}

function json_of_FilterGroupDetailInput(value) {
  var v = value.name;
  var v$1 = value.filterGroupId;
  var v$2 = value.label;
  var v$3 = value.description;
  var v$4 = value.sortOrder;
  var v$5 = value.autoPopulateWithAvailableValues;
  return Js_dict.fromArray([
                [
                  "name",
                  v !== undefined ? v : null
                ],
                [
                  "filterGroupId",
                  v$1 !== undefined ? Caml_option.valFromOption(v$1) : null
                ],
                [
                  "label",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "description",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "sortOrder",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "autoPopulateWithAvailableValues",
                  v$5 !== undefined ? v$5 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(filterGroupDetailId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "filterGroupDetailId",
                    filterGroupDetailId !== undefined ? Caml_option.valFromOption(filterGroupDetailId) : null
                  ],
                  [
                    "data",
                    json_of_FilterGroupDetailInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var filterGroupDetailId = variables.filterGroupDetailId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "filterGroupDetailId",
                    filterGroupDetailId !== undefined ? Caml_option.valFromOption(filterGroupDetailId) : null
                  ],
                  [
                    "data",
                    json_of_FilterGroupDetailInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(filterGroupDetailId, data, param) {
  return Js_dict.fromArray([
                [
                  "filterGroupDetailId",
                  filterGroupDetailId !== undefined ? Caml_option.valFromOption(filterGroupDetailId) : null
                ],
                [
                  "data",
                  json_of_FilterGroupDetailInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, filterGroupDetailId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "filterGroupDetailId",
                      filterGroupDetailId !== undefined ? Caml_option.valFromOption(filterGroupDetailId) : null
                    ],
                    [
                      "data",
                      json_of_FilterGroupDetailInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Mutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_FilterGroupDetailInput: json_of_FilterGroupDetailInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "query Q($filterGroupDetailId: Guid!)  {\nfilterGroupDetail(filterGroupDetailId: $filterGroupDetailId)  {\nfilterGroupDetailId  \nname  \nlabel  \ndescription  \nsortOrder  \nautoPopulateWithAvailableValues  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroupDetail");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "filterGroupDetailId");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field filterGroupDetailId on type FilterGroupDetail is missing");
      }
      var value$8 = Js_dict.get(value$4, "name");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field name on type FilterGroupDetail is missing");
      }
      var value$11 = Js_dict.get(value$4, "label");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field label on type FilterGroupDetail is missing");
      }
      var value$14 = Js_dict.get(value$4, "description");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field description on type FilterGroupDetail is missing");
      }
      var value$17 = Js_dict.get(value$4, "sortOrder");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var value$19 = Js_json.decodeNumber(value$18);
        tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$18));
      } else {
        tmp$5 = Js_exn.raiseError("graphql_ppx: Field sortOrder on type FilterGroupDetail is missing");
      }
      var value$20 = Js_dict.get(value$4, "autoPopulateWithAvailableValues");
      var tmp$6;
      if (value$20 !== undefined) {
        var value$21 = Caml_option.valFromOption(value$20);
        var value$22 = Js_json.decodeBoolean(value$21);
        tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$21));
      } else {
        tmp$6 = Js_exn.raiseError("graphql_ppx: Field autoPopulateWithAvailableValues on type FilterGroupDetail is missing");
      }
      tmp = {
        filterGroupDetailId: tmp$1,
        name: tmp$2,
        label: tmp$3,
        description: tmp$4,
        sortOrder: tmp$5,
        autoPopulateWithAvailableValues: tmp$6
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroupDetail: tmp
        };
}

function make$2(filterGroupDetailId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "filterGroupDetailId",
                    filterGroupDetailId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var filterGroupDetailId = variables.filterGroupDetailId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "filterGroupDetailId",
                    filterGroupDetailId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(filterGroupDetailId, param) {
  return Js_dict.fromArray([[
                  "filterGroupDetailId",
                  filterGroupDetailId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, filterGroupDetailId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "filterGroupDetailId",
                      filterGroupDetailId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var Query = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function doneRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/" + context.filterGroupId
        };
}

function breadcrumbs(context) {
  return [{
            href: {
              NAME: "Enterprise",
              VAL: "/filter-groups"
            },
            content: "Filter Groups"
          }];
}

function newRoute(context) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/" + (context.filterGroupId + "/details/new")
        };
}

var RouteArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute
};

var newState = {
  name: "",
  label: "",
  description: "",
  sortOrder: 0,
  autoPopulateWithAvailableValues: false
};

function reducer(context, state, action) {
  switch (action.TAG | 0) {
    case /* SetName */0 :
        return {
                name: action._0,
                label: state.label,
                description: state.description,
                sortOrder: state.sortOrder,
                autoPopulateWithAvailableValues: state.autoPopulateWithAvailableValues
              };
    case /* SetLabel */1 :
        return {
                name: state.name,
                label: action._0,
                description: state.description,
                sortOrder: state.sortOrder,
                autoPopulateWithAvailableValues: state.autoPopulateWithAvailableValues
              };
    case /* SetDescription */2 :
        return {
                name: state.name,
                label: state.label,
                description: action._0,
                sortOrder: state.sortOrder,
                autoPopulateWithAvailableValues: state.autoPopulateWithAvailableValues
              };
    case /* SetSortOrder */3 :
        return {
                name: state.name,
                label: state.label,
                description: state.description,
                sortOrder: action._0,
                autoPopulateWithAvailableValues: state.autoPopulateWithAvailableValues
              };
    case /* SetAutoPopulateWithAvailableValues */4 :
        return {
                name: state.name,
                label: state.label,
                description: state.description,
                sortOrder: state.sortOrder,
                autoPopulateWithAvailableValues: action._0
              };
    
  }
}

function getName(context, state) {
  return state.name;
}

function selfRoute(context, id) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/" + (context.filterGroupId + ("/details/" + id))
        };
}

function makeMutation(context, filterGroupDetailId, state) {
  return make$1(Belt_Option.map(filterGroupDetailId, (function (prim) {
                    return prim;
                  })), {
              filterGroupId: Caml_option.some(context.filterGroupId),
              name: state.name,
              label: state.label,
              description: state.description,
              sortOrder: state.sortOrder,
              autoPopulateWithAvailableValues: state.autoPopulateWithAvailableValues
            }, undefined);
}

function mapMutation(context, result) {
  return Belt_Option.map(Belt_Option.flatMap(result.filterGroupDetail, (function (filterGroupDetail) {
                    return filterGroupDetail.success;
                  })), (function (success) {
                return success.filterGroupDetailId;
              }));
}

function makeQuery(context, filterGroupDetailId) {
  return make$2(filterGroupDetailId, undefined);
}

function mapQuery(context, result) {
  return Belt_Option.map(result.filterGroupDetail, (function (filterGroupDetail) {
                return {
                        name: filterGroupDetail.name,
                        label: filterGroupDetail.label,
                        description: filterGroupDetail.description,
                        sortOrder: filterGroupDetail.sortOrder,
                        autoPopulateWithAvailableValues: filterGroupDetail.autoPopulateWithAvailableValues
                      };
              }));
}

function makeDelete(context, filterGroupDetailId) {
  return make(filterGroupDetailId, undefined);
}

function saveValidate(context, state) {
  if (state.name.trim() !== "") {
    return state.sortOrder !== 0;
  } else {
    return false;
  }
}

function mainActions(context, id, state, dispatch) {
  return [];
}

function mainContent(context, id, state, dispatch) {
  return React.createElement(React.Fragment, undefined, React.createElement(UserDefinedFieldSelect$BirddogsoftwareFrontend.make, {
                  objectType: "Item",
                  label: "Name",
                  value: state.name,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetName */0,
                                  _0: value
                                });
                    })
                }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Label",
                  value: state.label,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetLabel */1,
                                  _0: value
                                });
                    })
                }), React.createElement(SimpleForm$BirddogsoftwareFrontend.TextField.make, {
                  label: "Description",
                  value: state.description,
                  onChange: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetDescription */2,
                                  _0: value
                                });
                    }),
                  multiline: true
                }), React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                  label: "SortOrder",
                  fullWidth: true,
                  margin: "Dense",
                  value: state.sortOrder,
                  onChangeValid: (function (value) {
                      return Curry._1(dispatch, {
                                  TAG: /* SetSortOrder */3,
                                  _0: value
                                });
                    })
                }), React.createElement(Core.FormControlLabel, MaterialUi_FormControlLabel.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(state.autoPopulateWithAvailableValues, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetAutoPopulateWithAvailableValues */4,
                                                _0: !state.autoPopulateWithAvailableValues
                                              });
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, "Auto populate with available values", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var FormArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  newState: newState,
  reducer: reducer,
  doneRoute: doneRoute,
  getName: getName,
  selfRoute: selfRoute,
  makeMutation: makeMutation,
  mapMutation: mapMutation,
  stayAfterSaveNew: true,
  makeQuery: makeQuery,
  mapQuery: mapQuery,
  makeDelete: makeDelete,
  saveValidate: saveValidate,
  mainActions: mainActions,
  mainContent: mainContent,
  secondaryContent: undefined
};

var Form = Stricture$BirddogsoftwareFrontend.Form.Make({
      breadcrumbs: breadcrumbs,
      newRoute: newRoute,
      newState: newState,
      reducer: reducer,
      doneRoute: doneRoute,
      getName: getName,
      selfRoute: selfRoute,
      makeMutation: makeMutation,
      mapMutation: mapMutation,
      makeQuery: makeQuery,
      mapQuery: mapQuery,
      makeDelete: makeDelete,
      saveValidate: saveValidate,
      mainContent: mainContent,
      mainActions: mainActions,
      secondaryContent: undefined,
      stayAfterSaveNew: true
    });

var ppx_printed_query$3 = "query Q($filterGroupId: Guid!)  {\nfilterGroup(filterGroupId: $filterGroupId)  {\ndetails  {\nfilterGroupDetailId  \nname  \nlabel  \nsortOrder  \n}\n\n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "filterGroup");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "details");
      tmp = {
        details: value$5 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$5))).map(function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "filterGroupDetailId");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeString(value$3);
                  tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field filterGroupDetailId on type FilterGroupDetail is missing");
                }
                var value$5 = Js_dict.get(value$1, "name");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type FilterGroupDetail is missing");
                }
                var value$8 = Js_dict.get(value$1, "label");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var value$10 = Js_json.decodeString(value$9);
                  tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
                } else {
                  tmp$2 = Js_exn.raiseError("graphql_ppx: Field label on type FilterGroupDetail is missing");
                }
                var value$11 = Js_dict.get(value$1, "sortOrder");
                var tmp$3;
                if (value$11 !== undefined) {
                  var value$12 = Caml_option.valFromOption(value$11);
                  var value$13 = Js_json.decodeNumber(value$12);
                  tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$12));
                } else {
                  tmp$3 = Js_exn.raiseError("graphql_ppx: Field sortOrder on type FilterGroupDetail is missing");
                }
                return {
                        filterGroupDetailId: tmp,
                        name: tmp$1,
                        label: tmp$2,
                        sortOrder: tmp$3
                      };
              }) : Js_exn.raiseError("graphql_ppx: Field details on type FilterGroup is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          filterGroup: tmp
        };
}

function make$3(filterGroupId, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "filterGroupId",
                    filterGroupId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var filterGroupId = variables.filterGroupId;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([[
                    "filterGroupId",
                    filterGroupId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$3(filterGroupId, param) {
  return Js_dict.fromArray([[
                  "filterGroupId",
                  filterGroupId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, filterGroupId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "filterGroupId",
                      filterGroupId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var ListQuery = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function primaryColumnTitle(context) {
  return "Description";
}

function getPrimaryColumnContent(context, x) {
  return x.name;
}

function getPrimaryColumnRoute(context, x) {
  return {
          NAME: "Enterprise",
          VAL: "/filter-groups/" + (context.filterGroupId + ("/details/" + x.filterGroupDetailId))
        };
}

function queryArgsReducer(a, b) {
  
}

function queryArgsElement(state, dispatch) {
  return React.createElement(React.Fragment, undefined);
}

function makeQuery$1(context, queryArgs) {
  return make$3(context.filterGroupId, undefined);
}

function mapQuery$1(context, result) {
  var x = result.filterGroup;
  return Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(Belt_Array.map(x !== undefined ? Caml_option.valFromOption(x).details : [], (function (filterGroupDetail) {
                            return {
                                    filterGroupDetailId: filterGroupDetail.filterGroupDetailId,
                                    name: filterGroupDetail.name,
                                    label: filterGroupDetail.label,
                                    sortOrder: filterGroupDetail.sortOrder
                                  };
                          }))), (function (a, b) {
                    return a.sortOrder - b.sortOrder | 0;
                  })));
}

var columns = [
  {
    title: "Label",
    render: (function (row) {
        return row.label;
      })
  },
  {
    title: "Sort Order",
    render: (function (row) {
        return row.sortOrder.toString();
      })
  }
];

var ListArg = {
  breadcrumbs: breadcrumbs,
  newRoute: newRoute,
  primaryColumnTitle: primaryColumnTitle,
  getPrimaryColumnContent: getPrimaryColumnContent,
  getPrimaryColumnRoute: getPrimaryColumnRoute,
  queryArgsDefault: undefined,
  queryArgsReducer: queryArgsReducer,
  queryArgsElement: queryArgsElement,
  makeQuery: makeQuery$1,
  mapQuery: mapQuery$1,
  columns: columns
};

var List = Stricture$BirddogsoftwareFrontend.List.Make(ListArg);

exports.DeleteMutation = DeleteMutation;
exports.Mutation = Mutation;
exports.Query = Query;
exports.doneRoute = doneRoute;
exports.RouteArg = RouteArg;
exports.FormArg = FormArg;
exports.Form = Form;
exports.ListQuery = ListQuery;
exports.ListArg = ListArg;
exports.List = List;
/* Form Not a pure module */
