'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Checkbox = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Checkbox.bs.js");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var MaterialUi_FormControlLabel = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_FormControlLabel.bs.js");
var Fields$BirddogsoftwareFrontend = require("../../../Components/Forms/Fields.bs.js");
var Location$BirddogsoftwareFrontend = require("../../../Utils/Location.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../../../Components/PaperSpacer.bs.js");
var ProjectPath$BirddogsoftwareFrontend = require("./Components/ProjectPath.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../../UrlResolver.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../../Components/Forms/ActionHeader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var PaperPadding$BirddogsoftwareFrontend = require("../../../Components/PaperPadding.bs.js");
var QueryResponse$BirddogsoftwareFrontend = require("../../../Components/QueryResponse.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../Components/SnackbarHooks.bs.js");

var ppx_printed_query = "mutation q($warehouseStationId: Guid!)  {\nwarehouseStationDelete(warehouseStationId: $warehouseStationId)  \n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "warehouseStationDelete");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_json.decodeBoolean(value$3);
      tmp = value$4 !== undefined ? value$4 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$3));
    }
  } else {
    tmp = undefined;
  }
  return {
          warehouseStationDelete: tmp
        };
}

function make(warehouseStationId, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "warehouseStationId",
                    warehouseStationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var warehouseStationId = variables.warehouseStationId;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "warehouseStationId",
                    warehouseStationId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(warehouseStationId, param) {
  return Js_dict.fromArray([[
                  "warehouseStationId",
                  warehouseStationId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, warehouseStationId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "warehouseStationId",
                      warehouseStationId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DeleteMutation = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation q($taskId: Guid, $data: TaskInput!)  {\ntask(taskId: $taskId, data: $data)  {\nsuccess  {\ntaskId  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "task");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "success");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "taskId");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field taskId on type Task is missing");
          }
          tmp$1 = {
            taskId: tmp$2
          };
        }
      } else {
        tmp$1 = undefined;
      }
      tmp = {
        success: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          task: tmp
        };
}

function json_of_TaskInput(value) {
  var v = value.title;
  var v$1 = value.description;
  var v$2 = value.priority;
  var v$3 = value.code;
  var v$4 = value.userId;
  var v$5 = value.contactId;
  var v$6 = value.acknowledged;
  var v$7 = value.complete;
  var v$8 = value.date;
  var v$9 = value.dueDate;
  var v$10 = value.projectId;
  var v$11 = value.projectReferenceNo;
  var v$12 = value.startTimeSlip;
  var v$13 = value.estimatedDurationToCompleteHours;
  var v$14 = value.estimatedDurationToCompleteText;
  var v$15 = value.taskCategoryID;
  return Js_dict.fromArray([
                [
                  "title",
                  v !== undefined ? v : null
                ],
                [
                  "description",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "priority",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "code",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "userId",
                  v$4 !== undefined ? Caml_option.valFromOption(v$4) : null
                ],
                [
                  "contactId",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "acknowledged",
                  v$6 !== undefined ? v$6 : null
                ],
                [
                  "complete",
                  v$7 !== undefined ? v$7 : null
                ],
                [
                  "date",
                  v$8 !== undefined ? Caml_option.valFromOption(v$8) : null
                ],
                [
                  "dueDate",
                  v$9 !== undefined ? Caml_option.valFromOption(v$9) : null
                ],
                [
                  "projectId",
                  v$10 !== undefined ? Caml_option.valFromOption(v$10) : null
                ],
                [
                  "projectReferenceNo",
                  v$11 !== undefined ? v$11 : null
                ],
                [
                  "startTimeSlip",
                  v$12 !== undefined ? v$12 : null
                ],
                [
                  "estimatedDurationToCompleteHours",
                  v$13 !== undefined ? v$13 : null
                ],
                [
                  "estimatedDurationToCompleteText",
                  v$14 !== undefined ? v$14 : null
                ],
                [
                  "taskCategoryID",
                  v$15 !== undefined ? Caml_option.valFromOption(v$15) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(taskId, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "taskId",
                    taskId !== undefined ? Caml_option.valFromOption(taskId) : null
                  ],
                  [
                    "data",
                    json_of_TaskInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var taskId = variables.taskId;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "taskId",
                    taskId !== undefined ? Caml_option.valFromOption(taskId) : null
                  ],
                  [
                    "data",
                    json_of_TaskInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(taskId, data, param) {
  return Js_dict.fromArray([
                [
                  "taskId",
                  taskId !== undefined ? Caml_option.valFromOption(taskId) : null
                ],
                [
                  "data",
                  json_of_TaskInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, taskId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "taskId",
                      taskId !== undefined ? Caml_option.valFromOption(taskId) : null
                    ],
                    [
                      "data",
                      json_of_TaskInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Mutation = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_TaskInput: json_of_TaskInput,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetTitle */0 :
        var init = state.taskData;
        return {
                taskData: {
                  title: action._0,
                  description: init.description,
                  estimatedDurationToCompleteHours: init.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init.estimatedDurationToCompleteText,
                  priority: init.priority,
                  code: init.code,
                  acknowledged: init.acknowledged,
                  complete: init.complete,
                  date: init.date,
                  dueDate: init.dueDate,
                  projectId: init.projectId,
                  projectReferenceNo: init.projectReferenceNo,
                  startTimeSlip: init.startTimeSlip,
                  taskCategoryID: init.taskCategoryID
                },
                error: state.error
              };
    case /* SetDescription */1 :
        var init$1 = state.taskData;
        return {
                taskData: {
                  title: init$1.title,
                  description: action._0,
                  estimatedDurationToCompleteHours: init$1.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$1.estimatedDurationToCompleteText,
                  priority: init$1.priority,
                  code: init$1.code,
                  acknowledged: init$1.acknowledged,
                  complete: init$1.complete,
                  date: init$1.date,
                  dueDate: init$1.dueDate,
                  projectId: init$1.projectId,
                  projectReferenceNo: init$1.projectReferenceNo,
                  startTimeSlip: init$1.startTimeSlip,
                  taskCategoryID: init$1.taskCategoryID
                },
                error: state.error
              };
    case /* SetEstimatedDurationToCompleteHours */2 :
        var init$2 = state.taskData;
        return {
                taskData: {
                  title: init$2.title,
                  description: init$2.description,
                  estimatedDurationToCompleteHours: action._0,
                  estimatedDurationToCompleteText: init$2.estimatedDurationToCompleteText,
                  priority: init$2.priority,
                  code: init$2.code,
                  acknowledged: init$2.acknowledged,
                  complete: init$2.complete,
                  date: init$2.date,
                  dueDate: init$2.dueDate,
                  projectId: init$2.projectId,
                  projectReferenceNo: init$2.projectReferenceNo,
                  startTimeSlip: init$2.startTimeSlip,
                  taskCategoryID: init$2.taskCategoryID
                },
                error: state.error
              };
    case /* SetEstimatedDurationToCompleteText */3 :
        var init$3 = state.taskData;
        return {
                taskData: {
                  title: init$3.title,
                  description: init$3.description,
                  estimatedDurationToCompleteHours: init$3.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: action._0,
                  priority: init$3.priority,
                  code: init$3.code,
                  acknowledged: init$3.acknowledged,
                  complete: init$3.complete,
                  date: init$3.date,
                  dueDate: init$3.dueDate,
                  projectId: init$3.projectId,
                  projectReferenceNo: init$3.projectReferenceNo,
                  startTimeSlip: init$3.startTimeSlip,
                  taskCategoryID: init$3.taskCategoryID
                },
                error: state.error
              };
    case /* SetPriority */4 :
        var init$4 = state.taskData;
        return {
                taskData: {
                  title: init$4.title,
                  description: init$4.description,
                  estimatedDurationToCompleteHours: init$4.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$4.estimatedDurationToCompleteText,
                  priority: action._0,
                  code: init$4.code,
                  acknowledged: init$4.acknowledged,
                  complete: init$4.complete,
                  date: init$4.date,
                  dueDate: init$4.dueDate,
                  projectId: init$4.projectId,
                  projectReferenceNo: init$4.projectReferenceNo,
                  startTimeSlip: init$4.startTimeSlip,
                  taskCategoryID: init$4.taskCategoryID
                },
                error: state.error
              };
    case /* SetCode */5 :
        var init$5 = state.taskData;
        return {
                taskData: {
                  title: init$5.title,
                  description: init$5.description,
                  estimatedDurationToCompleteHours: init$5.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$5.estimatedDurationToCompleteText,
                  priority: init$5.priority,
                  code: action._0,
                  acknowledged: init$5.acknowledged,
                  complete: init$5.complete,
                  date: init$5.date,
                  dueDate: init$5.dueDate,
                  projectId: init$5.projectId,
                  projectReferenceNo: init$5.projectReferenceNo,
                  startTimeSlip: init$5.startTimeSlip,
                  taskCategoryID: init$5.taskCategoryID
                },
                error: state.error
              };
    case /* SetDate */6 :
        var init$6 = state.taskData;
        return {
                taskData: {
                  title: init$6.title,
                  description: init$6.description,
                  estimatedDurationToCompleteHours: init$6.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$6.estimatedDurationToCompleteText,
                  priority: init$6.priority,
                  code: init$6.code,
                  acknowledged: init$6.acknowledged,
                  complete: init$6.complete,
                  date: action._0,
                  dueDate: init$6.dueDate,
                  projectId: init$6.projectId,
                  projectReferenceNo: init$6.projectReferenceNo,
                  startTimeSlip: init$6.startTimeSlip,
                  taskCategoryID: init$6.taskCategoryID
                },
                error: state.error
              };
    case /* SetDueDate */7 :
        var init$7 = state.taskData;
        return {
                taskData: {
                  title: init$7.title,
                  description: init$7.description,
                  estimatedDurationToCompleteHours: init$7.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$7.estimatedDurationToCompleteText,
                  priority: init$7.priority,
                  code: init$7.code,
                  acknowledged: init$7.acknowledged,
                  complete: init$7.complete,
                  date: init$7.date,
                  dueDate: action._0,
                  projectId: init$7.projectId,
                  projectReferenceNo: init$7.projectReferenceNo,
                  startTimeSlip: init$7.startTimeSlip,
                  taskCategoryID: init$7.taskCategoryID
                },
                error: state.error
              };
    case /* SetAcknowledged */8 :
        var init$8 = state.taskData;
        return {
                taskData: {
                  title: init$8.title,
                  description: init$8.description,
                  estimatedDurationToCompleteHours: init$8.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$8.estimatedDurationToCompleteText,
                  priority: init$8.priority,
                  code: init$8.code,
                  acknowledged: action._0,
                  complete: init$8.complete,
                  date: init$8.date,
                  dueDate: init$8.dueDate,
                  projectId: init$8.projectId,
                  projectReferenceNo: init$8.projectReferenceNo,
                  startTimeSlip: init$8.startTimeSlip,
                  taskCategoryID: init$8.taskCategoryID
                },
                error: state.error
              };
    case /* SetComplete */9 :
        var init$9 = state.taskData;
        return {
                taskData: {
                  title: init$9.title,
                  description: init$9.description,
                  estimatedDurationToCompleteHours: init$9.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$9.estimatedDurationToCompleteText,
                  priority: init$9.priority,
                  code: init$9.code,
                  acknowledged: init$9.acknowledged,
                  complete: action._0,
                  date: init$9.date,
                  dueDate: init$9.dueDate,
                  projectId: init$9.projectId,
                  projectReferenceNo: init$9.projectReferenceNo,
                  startTimeSlip: init$9.startTimeSlip,
                  taskCategoryID: init$9.taskCategoryID
                },
                error: state.error
              };
    case /* SetProjectReferenceNo */10 :
        var init$10 = state.taskData;
        return {
                taskData: {
                  title: init$10.title,
                  description: init$10.description,
                  estimatedDurationToCompleteHours: init$10.estimatedDurationToCompleteHours,
                  estimatedDurationToCompleteText: init$10.estimatedDurationToCompleteText,
                  priority: init$10.priority,
                  code: init$10.code,
                  acknowledged: init$10.acknowledged,
                  complete: init$10.complete,
                  date: init$10.date,
                  dueDate: init$10.dueDate,
                  projectId: init$10.projectId,
                  projectReferenceNo: action._0,
                  startTimeSlip: init$10.startTimeSlip,
                  taskCategoryID: init$10.taskCategoryID
                },
                error: state.error
              };
    case /* SetError */11 :
        return {
                taskData: state.taskData,
                error: action._0
              };
    
  }
}

function EnterpriseTaskEdit$Form(Props) {
  var breadcrumbLink = Props.breadcrumbLink;
  var breadcrumbText = Props.breadcrumbText;
  var taskId = Props.taskId;
  var taskData = Props.taskData;
  var match = React.useReducer(reducer, {
        taskData: taskData,
        error: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var date = state.taskData.date;
  var dueDate = state.taskData.dueDate;
  var saveMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$1(Belt_Option.map(taskId, (function (prim) {
                  return prim;
                })), {
            title: state.taskData.title,
            description: state.taskData.description,
            estimatedDurationToCompleteHours: state.taskData.estimatedDurationToCompleteHours,
            estimatedDurationToCompleteText: state.taskData.estimatedDurationToCompleteText,
            priority: state.taskData.priority,
            code: state.taskData.code,
            userId: undefined,
            contactId: undefined,
            projectId: undefined,
            projectReferenceNo: state.taskData.projectReferenceNo,
            acknowledged: state.taskData.acknowledged,
            complete: state.taskData.complete,
            date: date !== undefined ? Caml_option.some(Caml_option.valFromOption(date).toISOString()) : undefined,
            dueDate: dueDate !== undefined ? Caml_option.some(Caml_option.valFromOption(dueDate).toISOString()) : undefined,
            startTimeSlip: undefined,
            taskCategoryID: undefined
          }, undefined));
  var saveResolved = UrlResolver$BirddogsoftwareFrontend.useResolved({
        NAME: "Enterprise",
        VAL: "/brain/inbox"
      });
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var save = function (param) {
    Curry._1(saveMutation.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            Location$BirddogsoftwareFrontend.unsafe_setUseReturnUrl(saveResolved.isExternal, saveResolved.url);
          } else {
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  var apply = function (param) {
    Curry._1(saveMutation.runMutation, undefined).then(function (response) {
          if (response.TAG) {
            Curry._1(error, "Changes saved.");
          } else {
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(null), undefined, undefined, undefined, "Md", undefined, undefined, undefined, undefined, undefined), React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                  breadcrumbs: [
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/brain"
                      },
                      content: "BRAIN"
                    },
                    {
                      href: {
                        NAME: "Enterprise",
                        VAL: "/brain/tasks/" + breadcrumbLink
                      },
                      content: breadcrumbText
                    }
                  ],
                  mainActions: [
                    {
                      key: "apply",
                      content: "Apply",
                      action: {
                        NAME: "OnClick",
                        VAL: apply
                      },
                      color: "Primary"
                    },
                    {
                      key: "save",
                      content: "Save",
                      action: {
                        NAME: "OnClick",
                        VAL: save
                      },
                      color: "Primary"
                    },
                    {
                      key: "cancel",
                      content: "Cancel",
                      action: {
                        NAME: "Link",
                        VAL: {
                          NAME: "Enterprise",
                          VAL: "/brain/inbox"
                        }
                      },
                      color: "Secondary"
                    }
                  ]
                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                                    children: null
                                  }, React.createElement(ProjectPath$BirddogsoftwareFrontend.make, {
                                        projectId: state.taskData.projectId
                                      }), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.taskData.title, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Title", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetTitle */0,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.taskData.priority, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Priority", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetPriority */4,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.taskData.projectReferenceNo, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Project Reference No", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetProjectReferenceNo */10,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.taskData.code, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Code", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetCode */5,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, undefined, state.taskData.description, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Description", undefined, undefined, undefined, undefined, (function ($$event) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetDescription */1,
                                                          _0: $$event.target.value
                                                        });
                                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.FormControlLabel, MaterialUi_FormControlLabel.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(state.taskData.acknowledged, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* SetAcknowledged */8,
                                                                      _0: !state.taskData.acknowledged
                                                                    });
                                                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, "Acknowledged", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.FormControlLabel, MaterialUi_FormControlLabel.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Checkbox, MaterialUi_Checkbox.makeProps(state.taskData.complete, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* SetComplete */9,
                                                                      _0: !state.taskData.complete
                                                                    });
                                                        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, "Complete", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Fields$BirddogsoftwareFrontend.$$Date.Required.make, {
                                        label: "Date",
                                        fullWidth: true,
                                        margin: "Dense",
                                        defaultValue: state.taskData.date,
                                        onChange: (function (value) {
                                            return Curry._1(dispatch, {
                                                        TAG: /* SetDate */6,
                                                        _0: value
                                                      });
                                          })
                                      }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }));
}

var Form = {
  reducer: reducer,
  make: EnterpriseTaskEdit$Form
};

function EnterpriseTaskEdit$New(Props) {
  return React.createElement(EnterpriseTaskEdit$Form, {
              breadcrumbLink: "new",
              breadcrumbText: "New",
              taskId: undefined,
              taskData: {
                title: "",
                description: "",
                estimatedDurationToCompleteHours: 0,
                estimatedDurationToCompleteText: "",
                priority: "",
                code: "",
                acknowledged: false,
                complete: false,
                date: undefined,
                dueDate: undefined,
                projectId: "",
                projectReferenceNo: 0,
                startTimeSlip: false,
                taskCategoryID: ""
              }
            });
}

var New = {
  make: EnterpriseTaskEdit$New
};

var ppx_printed_query$2 = "query TaskQuery($taskId: Guid!)  {\ntask(taskId: $taskId)  {\ntitle  \npriority  \ncode  \ndescription  \ndate  \ndueDate  \nacknowledged  \ncomplete  \nprojectId  \nprojectReferenceNo  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "task");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "title");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field title on type Task is missing");
      }
      var value$8 = Js_dict.get(value$4, "priority");
      var tmp$2;
      if (value$8 !== undefined) {
        var value$9 = Caml_option.valFromOption(value$8);
        var value$10 = Js_json.decodeString(value$9);
        tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field priority on type Task is missing");
      }
      var value$11 = Js_dict.get(value$4, "code");
      var tmp$3;
      if (value$11 !== undefined) {
        var value$12 = Caml_option.valFromOption(value$11);
        var value$13 = Js_json.decodeString(value$12);
        tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
      } else {
        tmp$3 = Js_exn.raiseError("graphql_ppx: Field code on type Task is missing");
      }
      var value$14 = Js_dict.get(value$4, "description");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Caml_option.valFromOption(value$14);
        var value$16 = Js_json.decodeString(value$15);
        tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$15));
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field description on type Task is missing");
      }
      var value$17 = Js_dict.get(value$4, "date");
      var tmp$5;
      if (value$17 !== undefined) {
        var value$18 = Caml_option.valFromOption(value$17);
        var match$1 = Js_json.decodeNull(value$18);
        tmp$5 = match$1 !== undefined ? undefined : Caml_option.some(value$18);
      } else {
        tmp$5 = undefined;
      }
      var value$19 = Js_dict.get(value$4, "dueDate");
      var tmp$6;
      if (value$19 !== undefined) {
        var value$20 = Caml_option.valFromOption(value$19);
        var match$2 = Js_json.decodeNull(value$20);
        tmp$6 = match$2 !== undefined ? undefined : Caml_option.some(value$20);
      } else {
        tmp$6 = undefined;
      }
      var value$21 = Js_dict.get(value$4, "acknowledged");
      var tmp$7;
      if (value$21 !== undefined) {
        var value$22 = Caml_option.valFromOption(value$21);
        var value$23 = Js_json.decodeBoolean(value$22);
        tmp$7 = value$23 !== undefined ? value$23 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$22));
      } else {
        tmp$7 = Js_exn.raiseError("graphql_ppx: Field acknowledged on type Task is missing");
      }
      var value$24 = Js_dict.get(value$4, "complete");
      var tmp$8;
      if (value$24 !== undefined) {
        var value$25 = Caml_option.valFromOption(value$24);
        var value$26 = Js_json.decodeBoolean(value$25);
        tmp$8 = value$26 !== undefined ? value$26 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$25));
      } else {
        tmp$8 = Js_exn.raiseError("graphql_ppx: Field complete on type Task is missing");
      }
      var value$27 = Js_dict.get(value$4, "projectId");
      var tmp$9;
      if (value$27 !== undefined) {
        var value$28 = Caml_option.valFromOption(value$27);
        var value$29 = Js_json.decodeString(value$28);
        tmp$9 = value$29 !== undefined ? value$29 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$28));
      } else {
        tmp$9 = Js_exn.raiseError("graphql_ppx: Field projectId on type Task is missing");
      }
      var value$30 = Js_dict.get(value$4, "projectReferenceNo");
      var tmp$10;
      if (value$30 !== undefined) {
        var value$31 = Caml_option.valFromOption(value$30);
        var value$32 = Js_json.decodeNumber(value$31);
        tmp$10 = value$32 !== undefined ? value$32 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$31));
      } else {
        tmp$10 = Js_exn.raiseError("graphql_ppx: Field projectReferenceNo on type Task is missing");
      }
      tmp = {
        title: tmp$1,
        priority: tmp$2,
        code: tmp$3,
        description: tmp$4,
        date: tmp$5,
        dueDate: tmp$6,
        acknowledged: tmp$7,
        complete: tmp$8,
        projectId: tmp$9,
        projectReferenceNo: tmp$10
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          task: tmp
        };
}

function make$2(taskId, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "taskId",
                    taskId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var taskId = variables.taskId;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([[
                    "taskId",
                    taskId
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(taskId, param) {
  return Js_dict.fromArray([[
                  "taskId",
                  taskId
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, taskId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "taskId",
                      taskId
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var TaskQuery = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function EnterpriseTaskEdit$Edit(Props) {
  Props.store;
  var id = Props.id;
  var getQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$2(id, undefined), undefined, undefined);
  SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  return React.createElement(QueryResponse$BirddogsoftwareFrontend.make, {
              queryResponse: getQuery.result,
              children: (function (result) {
                  var task = result.task;
                  if (task === undefined) {
                    return "Task not found";
                  }
                  var task$1 = Caml_option.valFromOption(task);
                  var date = task$1.date;
                  var tmp;
                  if (date !== undefined) {
                    var value = Js_json.decodeString(Caml_option.valFromOption(date));
                    tmp = value !== undefined ? Caml_option.some(new Date(value)) : undefined;
                  } else {
                    tmp = undefined;
                  }
                  var date$1 = task$1.dueDate;
                  var tmp$1;
                  if (date$1 !== undefined) {
                    var value$1 = Js_json.decodeString(Caml_option.valFromOption(date$1));
                    tmp$1 = value$1 !== undefined ? Caml_option.some(new Date(value$1)) : undefined;
                  } else {
                    tmp$1 = undefined;
                  }
                  return React.createElement(EnterpriseTaskEdit$Form, {
                              breadcrumbLink: id,
                              breadcrumbText: task$1.title,
                              taskId: id,
                              taskData: {
                                title: task$1.title,
                                description: task$1.description,
                                estimatedDurationToCompleteHours: 0,
                                estimatedDurationToCompleteText: "",
                                priority: task$1.priority,
                                code: task$1.code,
                                acknowledged: task$1.acknowledged,
                                complete: task$1.complete,
                                date: tmp,
                                dueDate: tmp$1,
                                projectId: task$1.projectId,
                                projectReferenceNo: task$1.projectReferenceNo,
                                startTimeSlip: false,
                                taskCategoryID: ""
                              }
                            });
                })
            });
}

var Edit = {
  make: EnterpriseTaskEdit$Edit
};

exports.DeleteMutation = DeleteMutation;
exports.Mutation = Mutation;
exports.Form = Form;
exports.New = New;
exports.TaskQuery = TaskQuery;
exports.Edit = Edit;
/* react Not a pure module */
