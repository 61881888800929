'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Order$BirddogsoftwareFrontend = require("../../../Types/Order.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../Utils/GraphQLHooks.bs.js");
var EnterpriseOrder$BirddogsoftwareFrontend = require("./EnterpriseOrder.bs.js");

var ppx_printed_query = "mutation Q($orderNo: String!, $orderType: OrderType!, $itemNo: String!, $data: OrderDraftLineInput!)  {\norderOpenAddLine(orderNo: $orderNo, orderType: $orderType, itemNo: $itemNo, data: $data)  {\nsuccess  {\norderNo  \n}\n\nfailure  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderOpenAddLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineAddFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "INVALID_ITEM" :
                      tmp$1 = "INVALID_ITEM";
                      break;
                  case "ORDER_IS_CLOSED" :
                      tmp$1 = "ORDER_IS_CLOSED";
                      break;
                  case "ORDER_NOT_FOUND" :
                      tmp$1 = "ORDER_NOT_FOUND";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderOpenLineAddFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderOpenLineAddFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineAddFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderNo");
          var tmp$2;
          if (value$7 !== undefined) {
            var value$8 = Caml_option.valFromOption(value$7);
            var value$9 = Js_json.decodeString(value$8);
            tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
          }
          tmp = {
            NAME: "Success",
            VAL: {
              orderNo: tmp$2
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineAddFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineAddFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderOpenAddLine on type Mutation is missing");
  }
  return {
          orderOpenAddLine: tmp
        };
}

function json_of_OrderType(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function json_of_OrderDraftLineInput(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(orderNo, orderType, itemNo, data, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType(orderType)
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var orderNo = variables.orderNo;
  var orderType = variables.orderType;
  var itemNo = variables.itemNo;
  var data = variables.data;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType(orderType)
                  ],
                  [
                    "itemNo",
                    itemNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(orderNo, orderType, itemNo, data, param) {
  return Js_dict.fromArray([
                [
                  "orderNo",
                  orderNo
                ],
                [
                  "orderType",
                  json_of_OrderType(orderType)
                ],
                [
                  "itemNo",
                  itemNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, orderNo, orderType, itemNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderNo",
                      orderNo
                    ],
                    [
                      "orderType",
                      json_of_OrderType(orderType)
                    ],
                    [
                      "itemNo",
                      itemNo
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftAddLine = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_OrderType: json_of_OrderType,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "mutation Q($orderNo: String!, $orderType: OrderType!, $lineNo: Int!, $data: OrderDraftLineInput!)  {\norderOpenUpdateLine(orderNo: $orderNo, orderType: $orderType, lineNo: $lineNo, data: $data)  {\nsuccess  {\norderNo  \n}\n\nfailure  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderOpenUpdateLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "success");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var match = Js_json.decodeNull(value$5);
      if (match !== undefined) {
        tmp$1 = undefined;
      } else {
        var value$6 = Js_option.getExn(Js_json.decodeObject(value$5));
        var value$7 = Js_dict.get(value$6, "orderNo");
        var tmp$2;
        if (value$7 !== undefined) {
          var value$8 = Caml_option.valFromOption(value$7);
          var value$9 = Js_json.decodeString(value$8);
          tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
        } else {
          tmp$2 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
        }
        tmp$1 = {
          orderNo: tmp$2
        };
      }
    } else {
      tmp$1 = undefined;
    }
    var value$10 = Js_dict.get(value$3, "failure");
    var tmp$3;
    if (value$10 !== undefined) {
      var value$11 = Caml_option.valFromOption(value$10);
      var match$1 = Js_json.decodeNull(value$11);
      if (match$1 !== undefined) {
        tmp$3 = undefined;
      } else {
        var value$12 = Js_json.decodeString(value$11);
        var tmp$4;
        if (value$12 !== undefined) {
          switch (value$12) {
            case "LINE_NOT_FOUND" :
                tmp$4 = "LINE_NOT_FOUND";
                break;
            case "ORDER_IS_CLOSED" :
                tmp$4 = "ORDER_IS_CLOSED";
                break;
            case "ORDER_NOT_FOUND" :
                tmp$4 = "ORDER_NOT_FOUND";
                break;
            default:
              tmp$4 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderOpenLineUpdateFailures: " + value$12);
          }
        } else {
          tmp$4 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderOpenLineUpdateFailures, got " + JSON.stringify(value$11));
        }
        tmp$3 = tmp$4;
      }
    } else {
      tmp$3 = undefined;
    }
    tmp = {
      success: tmp$1,
      failure: tmp$3
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderOpenUpdateLine on type Mutation is missing");
  }
  return {
          orderOpenUpdateLine: tmp
        };
}

function json_of_OrderType$1(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function json_of_OrderDraftLineInput$1(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$1(orderNo, orderType, lineNo, data, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$1(orderType)
                  ],
                  [
                    "lineNo",
                    lineNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput$1(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var orderNo = variables.orderNo;
  var orderType = variables.orderType;
  var lineNo = variables.lineNo;
  var data = variables.data;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$1(orderType)
                  ],
                  [
                    "lineNo",
                    lineNo
                  ],
                  [
                    "data",
                    json_of_OrderDraftLineInput$1(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$1
        };
}

function makeVariables$1(orderNo, orderType, lineNo, data, param) {
  return Js_dict.fromArray([
                [
                  "orderNo",
                  orderNo
                ],
                [
                  "orderType",
                  json_of_OrderType$1(orderType)
                ],
                [
                  "lineNo",
                  lineNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput$1(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, orderNo, orderType, lineNo, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderNo",
                      orderNo
                    ],
                    [
                      "orderType",
                      json_of_OrderType$1(orderType)
                    ],
                    [
                      "lineNo",
                      lineNo
                    ],
                    [
                      "data",
                      json_of_OrderDraftLineInput$1(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var OrderDraftUpdateLine = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  json_of_OrderType: json_of_OrderType$1,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "mutation Q($orderNo: String!, $orderType: OrderType!, $lineNo: Int!)  {\norderOpenDeleteLine(orderNo: $orderNo, orderType: $orderType, lineNo: $lineNo)  {\nsuccess  {\norderNo  \n}\n\nfailure  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderOpenDeleteLine");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineDeleteFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              if (value$5 !== undefined) {
                switch (value$5) {
                  case "LINE_NOT_FOUND" :
                      tmp$1 = "LINE_NOT_FOUND";
                      break;
                  case "ORDER_IS_CLOSED" :
                      tmp$1 = "ORDER_IS_CLOSED";
                      break;
                  case "ORDER_NOT_FOUND" :
                      tmp$1 = "ORDER_NOT_FOUND";
                      break;
                  default:
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderOpenLineDeleteFailures: " + value$5);
                }
              } else {
                tmp$1 = Js_exn.raiseError("graphql_ppx: Expected enum value for OrderOpenLineDeleteFailures, got " + JSON.stringify(temp$3));
              }
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineDeleteFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "orderNo");
          var tmp$2;
          if (value$7 !== undefined) {
            var value$8 = Caml_option.valFromOption(value$7);
            var value$9 = Js_json.decodeString(value$8);
            tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
          }
          tmp = {
            NAME: "Success",
            VAL: {
              orderNo: tmp$2
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineDeleteFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<IOrderOpen, OrderOpenLineDeleteFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderOpenDeleteLine on type Mutation is missing");
  }
  return {
          orderOpenDeleteLine: tmp
        };
}

function json_of_OrderType$2(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function make$2(orderNo, orderType, lineNo, param) {
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$2(orderType)
                  ],
                  [
                    "lineNo",
                    lineNo
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeWithVariables$2(variables) {
  var orderNo = variables.orderNo;
  var orderType = variables.orderType;
  var lineNo = variables.lineNo;
  return {
          query: ppx_printed_query$2,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$2(orderType)
                  ],
                  [
                    "lineNo",
                    lineNo
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$2
        };
}

function makeVariables$2(orderNo, orderType, lineNo, param) {
  return Js_dict.fromArray([
                [
                  "orderNo",
                  orderNo
                ],
                [
                  "orderType",
                  json_of_OrderType$2(orderType)
                ],
                [
                  "lineNo",
                  lineNo
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$2(graphql_ppx_use_json_variables_fn, orderNo, orderType, lineNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderNo",
                      orderNo
                    ],
                    [
                      "orderType",
                      json_of_OrderType$2(orderType)
                    ],
                    [
                      "lineNo",
                      lineNo
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$2 = [
  parse$2,
  ppx_printed_query$2,
  definition_2$2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var OrderDraftDeleteLine = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  json_of_OrderType: json_of_OrderType$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

function useLineAdd(orderType, orderNo, itemNo, data) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(orderNo, orderType, itemNo, data, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderOpenAddLine;
                }), param);
  };
}

var ppx_printed_query$3 = "mutation Q($orderNo: String!, $orderType: OrderType!, $data: OrderOpenInput!)  {\norderOpen(orderNo: $orderNo, orderType: $orderType, data: $data)  {\norderNo  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderOpen");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderNo");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
      }
      tmp = {
        orderNo: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderOpen: tmp
        };
}

function json_of_OrderType$3(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function json_of_NameAndAddressInput(value) {
  var v = value.name;
  var v$1 = value.line1;
  var v$2 = value.line2;
  var v$3 = value.city;
  var v$4 = value.region;
  var v$5 = value.postalCode;
  var v$6 = value.country;
  return Js_dict.fromArray([
                [
                  "name",
                  v !== undefined ? v : null
                ],
                [
                  "line1",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "line2",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "city",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "region",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "postalCode",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "country",
                  v$6 !== undefined ? v$6 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_OrderOpenInput(value) {
  var v = value.shipTo;
  var v$1 = value.billTo;
  var v$2 = value.discountPercent;
  var v$3 = value.freight;
  var v$4 = value.miscellaneous;
  var v$5 = value.calculateFreight;
  var v$6 = value.orderDate;
  var v$7 = value.customerNo;
  return Js_dict.fromArray([
                [
                  "shipTo",
                  v !== undefined ? json_of_NameAndAddressInput(Caml_option.valFromOption(v)) : null
                ],
                [
                  "billTo",
                  v$1 !== undefined ? json_of_NameAndAddressInput(Caml_option.valFromOption(v$1)) : null
                ],
                [
                  "discountPercent",
                  v$2 !== undefined ? Caml_option.valFromOption(v$2) : null
                ],
                [
                  "freight",
                  v$3 !== undefined ? Caml_option.valFromOption(v$3) : null
                ],
                [
                  "miscellaneous",
                  v$4 !== undefined ? Caml_option.valFromOption(v$4) : null
                ],
                [
                  "calculateFreight",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "orderDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "customerNo",
                  v$7 !== undefined ? v$7 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make$3(orderNo, orderType, data, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$3(orderType)
                  ],
                  [
                    "data",
                    json_of_OrderOpenInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var orderNo = variables.orderNo;
  var orderType = variables.orderType;
  var data = variables.data;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$3(orderType)
                  ],
                  [
                    "data",
                    json_of_OrderOpenInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$3
        };
}

function makeVariables$3(orderNo, orderType, data, param) {
  return Js_dict.fromArray([
                [
                  "orderNo",
                  orderNo
                ],
                [
                  "orderType",
                  json_of_OrderType$3(orderType)
                ],
                [
                  "data",
                  json_of_OrderOpenInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$3(graphql_ppx_use_json_variables_fn, orderNo, orderType, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderNo",
                      orderNo
                    ],
                    [
                      "orderType",
                      json_of_OrderType$3(orderType)
                    ],
                    [
                      "data",
                      json_of_OrderOpenInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$3 = [
  parse$3,
  ppx_printed_query$3,
  definition_2$3
];

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var OrderDraftUpdate = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  json_of_OrderType: json_of_OrderType$3,
  json_of_NameAndAddressInput: json_of_NameAndAddressInput,
  json_of_OrderOpenInput: json_of_OrderOpenInput,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

function useUpdate(orderType, orderNo, data) {
  return GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$3(orderNo, orderType, {
                  billTo: data.billTo,
                  shipTo: data.shipTo,
                  calculateFreight: data.calculateFreight,
                  discountPercent: Belt_Option.map(data.discountPercent, (function (x) {
                          return x;
                        })),
                  freight: Belt_Option.map(data.freight, (function (x) {
                          return x;
                        })),
                  miscellaneous: Belt_Option.map(data.miscellaneous, (function (x) {
                          return x;
                        })),
                  userId: Belt_Option.map(data.userId, (function (x) {
                          return x;
                        })),
                  orderDate: Belt_Option.map(data.orderDate, (function (x) {
                          return x.toISOString();
                        })),
                  customerNo: undefined
                }, undefined));
}

function useLineEdit(orderType, orderNo, orderLine, data) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$1(orderNo, orderType, orderLine.lineNo, {
            quantityOrdered: data.quantityOrdered,
            quantityToShip: data.quantityToShip,
            comments: data.comments,
            discountPercent: data.discountPercent,
            locationCode: data.locationCode,
            promiseDate: Belt_Option.map(data.promiseDate, (function (x) {
                    return x.toISOString();
                  })),
            requestDate: Belt_Option.map(data.requestDate, (function (x) {
                    return x.toISOString();
                  })),
            price: Belt_Option.map(data.price, (function (x) {
                    return x;
                  }))
          }, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderOpenUpdateLine;
                }), param);
  };
}

function useLineDelete(orderType, orderNo, orderLine) {
  var m = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$2(orderNo, orderType, orderLine.lineNo, undefined));
  var partial_arg = m.runMutation;
  return function (param) {
    return GraphQLHooks$BirddogsoftwareFrontend.runMutationMap(partial_arg, (function (x) {
                  return x.orderOpenDeleteLine;
                }), param);
  };
}

var ppx_printed_query$4 = "mutation Q($orderNo: String!, $orderType: OrderType!, $customerNo: String)  {\norderOpenSelectCustomer(orderNo: $orderNo, orderType: $orderType, customerNo: $customerNo)  {\norderNo  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderOpenSelectCustomer");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderNo");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var value$7 = Js_json.decodeString(value$6);
        tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field orderNo on type OrderOpen is missing");
      }
      tmp = {
        orderNo: tmp$1
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderOpenSelectCustomer: tmp
        };
}

function json_of_OrderType$4(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function make$4(orderNo, orderType, customerNo, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$4(orderType)
                  ],
                  [
                    "customerNo",
                    customerNo !== undefined ? customerNo : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var orderNo = variables.orderNo;
  var orderType = variables.orderType;
  var customerNo = variables.customerNo;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$4(orderType)
                  ],
                  [
                    "customerNo",
                    customerNo !== undefined ? customerNo : null
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$4
        };
}

function makeVariables$4(orderNo, orderType, customerNo, param) {
  return Js_dict.fromArray([
                [
                  "orderNo",
                  orderNo
                ],
                [
                  "orderType",
                  json_of_OrderType$4(orderType)
                ],
                [
                  "customerNo",
                  customerNo !== undefined ? customerNo : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$4(graphql_ppx_use_json_variables_fn, orderNo, orderType, customerNo, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderNo",
                      orderNo
                    ],
                    [
                      "orderType",
                      json_of_OrderType$4(orderType)
                    ],
                    [
                      "customerNo",
                      customerNo !== undefined ? customerNo : null
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$4 = [
  parse$4,
  ppx_printed_query$4,
  definition_2$4
];

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var SelectCustomerMutation = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  json_of_OrderType: json_of_OrderType$4,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

function useSelectCustomer(orderType, orderNo, customerNo) {
  return GraphQLHooks$BirddogsoftwareFrontend.useMutation(make$4(orderNo, orderType, customerNo, undefined));
}

var ppx_printed_query$5 = "query Q($orderNo: String!, $orderType: OrderType!)  {\norderOpen(orderNo: $orderNo, orderType: $orderType)  {\ncustomer  {\ncustomerNo  \nname  \n}\n\nbillTo  {\nname  \nline1  \nline2  \ncity  \nregion  \npostalCode  \ncountry  \n}\n\nshipTo  {\nname  \nline1  \nline2  \ncity  \nregion  \npostalCode  \ncountry  \n}\n\nlines  {\nlineNo  \nitemNo  \nitem  {\ndescriptionCalculated  \n}\n\nquantityOrdered  \nquantityToShip  \nlocationCode  \ndiscountPercent  \ncomments  \nprice  \ntotal  \npromiseDate  \nrequestDate  \n}\n\ntax  \nfreight  \nmiscellaneous  \nsubTotalByOrdered  \ntotalByOrdered  \ndiscountPercent  \n}\n\n}\n";

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderOpen");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "customer");
      var tmp$1;
      if (value$5 !== undefined) {
        var value$6 = Caml_option.valFromOption(value$5);
        var match$1 = Js_json.decodeNull(value$6);
        if (match$1 !== undefined) {
          tmp$1 = undefined;
        } else {
          var value$7 = Js_option.getExn(Js_json.decodeObject(value$6));
          var value$8 = Js_dict.get(value$7, "customerNo");
          var tmp$2;
          if (value$8 !== undefined) {
            var value$9 = Caml_option.valFromOption(value$8);
            var value$10 = Js_json.decodeString(value$9);
            tmp$2 = value$10 !== undefined ? value$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$9));
          } else {
            tmp$2 = Js_exn.raiseError("graphql_ppx: Field customerNo on type Customer is missing");
          }
          var value$11 = Js_dict.get(value$7, "name");
          var tmp$3;
          if (value$11 !== undefined) {
            var value$12 = Caml_option.valFromOption(value$11);
            var value$13 = Js_json.decodeString(value$12);
            tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
          } else {
            tmp$3 = Js_exn.raiseError("graphql_ppx: Field name on type Customer is missing");
          }
          tmp$1 = {
            customerNo: tmp$2,
            name: tmp$3
          };
        }
      } else {
        tmp$1 = undefined;
      }
      var value$14 = Js_dict.get(value$4, "billTo");
      var tmp$4;
      if (value$14 !== undefined) {
        var value$15 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$14)));
        var value$16 = Js_dict.get(value$15, "name");
        var tmp$5;
        if (value$16 !== undefined) {
          var value$17 = Caml_option.valFromOption(value$16);
          var value$18 = Js_json.decodeString(value$17);
          tmp$5 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$17));
        } else {
          tmp$5 = Js_exn.raiseError("graphql_ppx: Field name on type NameAndAddress is missing");
        }
        var value$19 = Js_dict.get(value$15, "line1");
        var tmp$6;
        if (value$19 !== undefined) {
          var value$20 = Caml_option.valFromOption(value$19);
          var value$21 = Js_json.decodeString(value$20);
          tmp$6 = value$21 !== undefined ? value$21 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$20));
        } else {
          tmp$6 = Js_exn.raiseError("graphql_ppx: Field line1 on type NameAndAddress is missing");
        }
        var value$22 = Js_dict.get(value$15, "line2");
        var tmp$7;
        if (value$22 !== undefined) {
          var value$23 = Caml_option.valFromOption(value$22);
          var value$24 = Js_json.decodeString(value$23);
          tmp$7 = value$24 !== undefined ? value$24 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$23));
        } else {
          tmp$7 = Js_exn.raiseError("graphql_ppx: Field line2 on type NameAndAddress is missing");
        }
        var value$25 = Js_dict.get(value$15, "city");
        var tmp$8;
        if (value$25 !== undefined) {
          var value$26 = Caml_option.valFromOption(value$25);
          var value$27 = Js_json.decodeString(value$26);
          tmp$8 = value$27 !== undefined ? value$27 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$26));
        } else {
          tmp$8 = Js_exn.raiseError("graphql_ppx: Field city on type NameAndAddress is missing");
        }
        var value$28 = Js_dict.get(value$15, "region");
        var tmp$9;
        if (value$28 !== undefined) {
          var value$29 = Caml_option.valFromOption(value$28);
          var value$30 = Js_json.decodeString(value$29);
          tmp$9 = value$30 !== undefined ? value$30 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$29));
        } else {
          tmp$9 = Js_exn.raiseError("graphql_ppx: Field region on type NameAndAddress is missing");
        }
        var value$31 = Js_dict.get(value$15, "postalCode");
        var tmp$10;
        if (value$31 !== undefined) {
          var value$32 = Caml_option.valFromOption(value$31);
          var value$33 = Js_json.decodeString(value$32);
          tmp$10 = value$33 !== undefined ? value$33 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$32));
        } else {
          tmp$10 = Js_exn.raiseError("graphql_ppx: Field postalCode on type NameAndAddress is missing");
        }
        var value$34 = Js_dict.get(value$15, "country");
        var tmp$11;
        if (value$34 !== undefined) {
          var value$35 = Caml_option.valFromOption(value$34);
          var value$36 = Js_json.decodeString(value$35);
          tmp$11 = value$36 !== undefined ? value$36 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$35));
        } else {
          tmp$11 = Js_exn.raiseError("graphql_ppx: Field country on type NameAndAddress is missing");
        }
        tmp$4 = {
          name: tmp$5,
          line1: tmp$6,
          line2: tmp$7,
          city: tmp$8,
          region: tmp$9,
          postalCode: tmp$10,
          country: tmp$11
        };
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field billTo on type OrderOpen is missing");
      }
      var value$37 = Js_dict.get(value$4, "shipTo");
      var tmp$12;
      if (value$37 !== undefined) {
        var value$38 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$37)));
        var value$39 = Js_dict.get(value$38, "name");
        var tmp$13;
        if (value$39 !== undefined) {
          var value$40 = Caml_option.valFromOption(value$39);
          var value$41 = Js_json.decodeString(value$40);
          tmp$13 = value$41 !== undefined ? value$41 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$40));
        } else {
          tmp$13 = Js_exn.raiseError("graphql_ppx: Field name on type NameAndAddress is missing");
        }
        var value$42 = Js_dict.get(value$38, "line1");
        var tmp$14;
        if (value$42 !== undefined) {
          var value$43 = Caml_option.valFromOption(value$42);
          var value$44 = Js_json.decodeString(value$43);
          tmp$14 = value$44 !== undefined ? value$44 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$43));
        } else {
          tmp$14 = Js_exn.raiseError("graphql_ppx: Field line1 on type NameAndAddress is missing");
        }
        var value$45 = Js_dict.get(value$38, "line2");
        var tmp$15;
        if (value$45 !== undefined) {
          var value$46 = Caml_option.valFromOption(value$45);
          var value$47 = Js_json.decodeString(value$46);
          tmp$15 = value$47 !== undefined ? value$47 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$46));
        } else {
          tmp$15 = Js_exn.raiseError("graphql_ppx: Field line2 on type NameAndAddress is missing");
        }
        var value$48 = Js_dict.get(value$38, "city");
        var tmp$16;
        if (value$48 !== undefined) {
          var value$49 = Caml_option.valFromOption(value$48);
          var value$50 = Js_json.decodeString(value$49);
          tmp$16 = value$50 !== undefined ? value$50 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$49));
        } else {
          tmp$16 = Js_exn.raiseError("graphql_ppx: Field city on type NameAndAddress is missing");
        }
        var value$51 = Js_dict.get(value$38, "region");
        var tmp$17;
        if (value$51 !== undefined) {
          var value$52 = Caml_option.valFromOption(value$51);
          var value$53 = Js_json.decodeString(value$52);
          tmp$17 = value$53 !== undefined ? value$53 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$52));
        } else {
          tmp$17 = Js_exn.raiseError("graphql_ppx: Field region on type NameAndAddress is missing");
        }
        var value$54 = Js_dict.get(value$38, "postalCode");
        var tmp$18;
        if (value$54 !== undefined) {
          var value$55 = Caml_option.valFromOption(value$54);
          var value$56 = Js_json.decodeString(value$55);
          tmp$18 = value$56 !== undefined ? value$56 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$55));
        } else {
          tmp$18 = Js_exn.raiseError("graphql_ppx: Field postalCode on type NameAndAddress is missing");
        }
        var value$57 = Js_dict.get(value$38, "country");
        var tmp$19;
        if (value$57 !== undefined) {
          var value$58 = Caml_option.valFromOption(value$57);
          var value$59 = Js_json.decodeString(value$58);
          tmp$19 = value$59 !== undefined ? value$59 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$58));
        } else {
          tmp$19 = Js_exn.raiseError("graphql_ppx: Field country on type NameAndAddress is missing");
        }
        tmp$12 = {
          name: tmp$13,
          line1: tmp$14,
          line2: tmp$15,
          city: tmp$16,
          region: tmp$17,
          postalCode: tmp$18,
          country: tmp$19
        };
      } else {
        tmp$12 = Js_exn.raiseError("graphql_ppx: Field shipTo on type OrderOpen is missing");
      }
      var value$60 = Js_dict.get(value$4, "lines");
      var value$61 = Js_dict.get(value$4, "tax");
      var value$62 = Js_dict.get(value$4, "freight");
      var value$63 = Js_dict.get(value$4, "miscellaneous");
      var value$64 = Js_dict.get(value$4, "subTotalByOrdered");
      var value$65 = Js_dict.get(value$4, "totalByOrdered");
      var value$66 = Js_dict.get(value$4, "discountPercent");
      tmp = {
        customer: tmp$1,
        billTo: tmp$4,
        shipTo: tmp$12,
        lines: value$60 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$60))).map(function (value) {
                var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                var value$2 = Js_dict.get(value$1, "lineNo");
                var tmp;
                if (value$2 !== undefined) {
                  var value$3 = Caml_option.valFromOption(value$2);
                  var value$4 = Js_json.decodeNumber(value$3);
                  tmp = value$4 !== undefined ? value$4 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$3));
                } else {
                  tmp = Js_exn.raiseError("graphql_ppx: Field lineNo on type OrderOpenLine is missing");
                }
                var value$5 = Js_dict.get(value$1, "itemNo");
                var tmp$1;
                if (value$5 !== undefined) {
                  var value$6 = Caml_option.valFromOption(value$5);
                  var value$7 = Js_json.decodeString(value$6);
                  tmp$1 = value$7 !== undefined ? value$7 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                } else {
                  tmp$1 = Js_exn.raiseError("graphql_ppx: Field itemNo on type OrderOpenLine is missing");
                }
                var value$8 = Js_dict.get(value$1, "item");
                var tmp$2;
                if (value$8 !== undefined) {
                  var value$9 = Caml_option.valFromOption(value$8);
                  var match = Js_json.decodeNull(value$9);
                  if (match !== undefined) {
                    tmp$2 = undefined;
                  } else {
                    var value$10 = Js_option.getExn(Js_json.decodeObject(value$9));
                    var value$11 = Js_dict.get(value$10, "descriptionCalculated");
                    var tmp$3;
                    if (value$11 !== undefined) {
                      var value$12 = Caml_option.valFromOption(value$11);
                      var value$13 = Js_json.decodeString(value$12);
                      tmp$3 = value$13 !== undefined ? value$13 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$12));
                    } else {
                      tmp$3 = Js_exn.raiseError("graphql_ppx: Field descriptionCalculated on type Item is missing");
                    }
                    tmp$2 = {
                      descriptionCalculated: tmp$3
                    };
                  }
                } else {
                  tmp$2 = undefined;
                }
                var value$14 = Js_dict.get(value$1, "quantityOrdered");
                var tmp$4;
                if (value$14 !== undefined) {
                  var value$15 = Caml_option.valFromOption(value$14);
                  var value$16 = Js_json.decodeNumber(value$15);
                  tmp$4 = value$16 !== undefined ? value$16 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$15));
                } else {
                  tmp$4 = Js_exn.raiseError("graphql_ppx: Field quantityOrdered on type OrderOpenLine is missing");
                }
                var value$17 = Js_dict.get(value$1, "quantityToShip");
                var tmp$5;
                if (value$17 !== undefined) {
                  var value$18 = Caml_option.valFromOption(value$17);
                  var value$19 = Js_json.decodeNumber(value$18);
                  tmp$5 = value$19 !== undefined ? value$19 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$18));
                } else {
                  tmp$5 = Js_exn.raiseError("graphql_ppx: Field quantityToShip on type OrderOpenLine is missing");
                }
                var value$20 = Js_dict.get(value$1, "locationCode");
                var tmp$6;
                if (value$20 !== undefined) {
                  var value$21 = Caml_option.valFromOption(value$20);
                  var value$22 = Js_json.decodeString(value$21);
                  tmp$6 = value$22 !== undefined ? value$22 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$21));
                } else {
                  tmp$6 = Js_exn.raiseError("graphql_ppx: Field locationCode on type OrderOpenLine is missing");
                }
                var value$23 = Js_dict.get(value$1, "discountPercent");
                var tmp$7;
                if (value$23 !== undefined) {
                  var value$24 = Caml_option.valFromOption(value$23);
                  var value$25 = Js_json.decodeNumber(value$24);
                  tmp$7 = value$25 !== undefined ? value$25 : Js_exn.raiseError("graphql_ppx: Expected float, got " + JSON.stringify(value$24));
                } else {
                  tmp$7 = Js_exn.raiseError("graphql_ppx: Field discountPercent on type OrderOpenLine is missing");
                }
                var value$26 = Js_dict.get(value$1, "comments");
                var tmp$8;
                if (value$26 !== undefined) {
                  var value$27 = Caml_option.valFromOption(value$26);
                  var value$28 = Js_json.decodeString(value$27);
                  tmp$8 = value$28 !== undefined ? value$28 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$27));
                } else {
                  tmp$8 = Js_exn.raiseError("graphql_ppx: Field comments on type OrderOpenLine is missing");
                }
                var value$29 = Js_dict.get(value$1, "price");
                var value$30 = Js_dict.get(value$1, "total");
                var value$31 = Js_dict.get(value$1, "promiseDate");
                var value$32 = Js_dict.get(value$1, "requestDate");
                return {
                        lineNo: tmp,
                        itemNo: tmp$1,
                        item: tmp$2,
                        quantityOrdered: tmp$4,
                        quantityToShip: tmp$5,
                        locationCode: tmp$6,
                        discountPercent: tmp$7,
                        comments: tmp$8,
                        price: value$29 !== undefined ? Caml_option.valFromOption(value$29) : Js_exn.raiseError("graphql_ppx: Field price on type OrderOpenLine is missing"),
                        total: value$30 !== undefined ? Caml_option.valFromOption(value$30) : Js_exn.raiseError("graphql_ppx: Field total on type OrderOpenLine is missing"),
                        promiseDate: value$31 !== undefined ? Caml_option.valFromOption(value$31) : Js_exn.raiseError("graphql_ppx: Field promiseDate on type OrderOpenLine is missing"),
                        requestDate: value$32 !== undefined ? Caml_option.valFromOption(value$32) : Js_exn.raiseError("graphql_ppx: Field requestDate on type OrderOpenLine is missing")
                      };
              }) : Js_exn.raiseError("graphql_ppx: Field lines on type OrderOpen is missing"),
        tax: value$61 !== undefined ? Caml_option.valFromOption(value$61) : Js_exn.raiseError("graphql_ppx: Field tax on type OrderOpen is missing"),
        freight: value$62 !== undefined ? Caml_option.valFromOption(value$62) : Js_exn.raiseError("graphql_ppx: Field freight on type OrderOpen is missing"),
        miscellaneous: value$63 !== undefined ? Caml_option.valFromOption(value$63) : Js_exn.raiseError("graphql_ppx: Field miscellaneous on type OrderOpen is missing"),
        subTotalByOrdered: value$64 !== undefined ? Caml_option.valFromOption(value$64) : Js_exn.raiseError("graphql_ppx: Field subTotalByOrdered on type OrderOpen is missing"),
        totalByOrdered: value$65 !== undefined ? Caml_option.valFromOption(value$65) : Js_exn.raiseError("graphql_ppx: Field totalByOrdered on type OrderOpen is missing"),
        discountPercent: value$66 !== undefined ? Caml_option.valFromOption(value$66) : Js_exn.raiseError("graphql_ppx: Field discountPercent on type OrderOpen is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderOpen: tmp
        };
}

function json_of_OrderType$5(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function make$5(orderNo, orderType, param) {
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$5(orderType)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeWithVariables$5(variables) {
  var orderNo = variables.orderNo;
  var orderType = variables.orderType;
  return {
          query: ppx_printed_query$5,
          variables: Js_dict.fromArray([
                  [
                    "orderNo",
                    orderNo
                  ],
                  [
                    "orderType",
                    json_of_OrderType$5(orderType)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse$5
        };
}

function makeVariables$5(orderNo, orderType, param) {
  return Js_dict.fromArray([
                [
                  "orderNo",
                  orderNo
                ],
                [
                  "orderType",
                  json_of_OrderType$5(orderType)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2$5(graphql_ppx_use_json_variables_fn, orderNo, orderType, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderNo",
                      orderNo
                    ],
                    [
                      "orderType",
                      json_of_OrderType$5(orderType)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition$5 = [
  parse$5,
  ppx_printed_query$5,
  definition_2$5
];

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

var OrderOpenQuery = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  json_of_OrderType: json_of_OrderType$5,
  make: make$5,
  makeWithVariables: makeWithVariables$5,
  makeVariables: makeVariables$5,
  definition: definition$5,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5
};

function EnterpriseOrderOpen(Props) {
  var orderType = Props.orderType;
  var orderNo = Props.orderNo;
  Props.onReset;
  var orderQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$5(orderNo, orderType, undefined), undefined, undefined);
  var orderQueryResult = GraphQLHooks$BirddogsoftwareFrontend.responseMap(orderQuery.result, (function (x) {
          var o = x.orderOpen;
          if (o === undefined) {
            return ;
          }
          var o$1 = Caml_option.valFromOption(o);
          var nameAndAddress = function (x) {
            return {
                    name: x.name,
                    line1: x.line1,
                    line2: x.line2,
                    city: x.city,
                    region: x.region,
                    postalCode: x.postalCode,
                    country: x.country
                  };
          };
          return {
                  customer: Belt_Option.map(o$1.customer, (function (c) {
                          return {
                                  name: c.name,
                                  customerNo: c.customerNo
                                };
                        })),
                  billTo: nameAndAddress(o$1.billTo),
                  shipTo: nameAndAddress(o$1.shipTo),
                  lines: Belt_Array.map(o$1.lines, (function (l) {
                          return {
                                  lineNo: l.lineNo,
                                  itemNo: l.itemNo,
                                  item: l.item,
                                  quantityOrdered: l.quantityOrdered,
                                  quantityToShip: l.quantityToShip,
                                  locationCode: l.locationCode,
                                  discountPercent: l.discountPercent,
                                  comments: l.comments,
                                  price: Belt_Option.getExn(Js_json.decodeNumber(l.price)),
                                  total: Belt_Option.getExn(Js_json.decodeNumber(l.total)),
                                  promiseDate: new Date(Belt_Option.getExn(Js_json.decodeString(l.promiseDate))),
                                  requestDate: new Date(Belt_Option.getExn(Js_json.decodeString(l.requestDate)))
                                };
                        })),
                  tax: Belt_Option.getExn(Js_json.decodeNumber(o$1.tax)),
                  freight: Belt_Option.getExn(Js_json.decodeNumber(o$1.freight)),
                  miscellaneous: Belt_Option.getExn(Js_json.decodeNumber(o$1.miscellaneous)),
                  subTotalByOrdered: Belt_Option.getExn(Js_json.decodeNumber(o$1.subTotalByOrdered)),
                  totalByOrdered: Belt_Option.getExn(Js_json.decodeNumber(o$1.totalByOrdered)),
                  discountPercent: Belt_Option.getExn(Js_json.decodeNumber(o$1.discountPercent))
                };
        }));
  return React.createElement(EnterpriseOrder$BirddogsoftwareFrontend.make, {
              breadcrumbHref: "/orders/open/" + (Order$BirddogsoftwareFrontend.OrderType.stringify(orderType).toLowerCase() + ("/" + (orderNo + "/"))),
              breadcrumbContent: Order$BirddogsoftwareFrontend.OrderType.stringify(orderType) + (" " + orderNo),
              orderQueryResult: orderQueryResult,
              refetch: (function (param) {
                  return Curry._1(orderQuery.refetch, undefined);
                }),
              useUpdate: (function (param) {
                  return useUpdate(orderType, orderNo, param);
                }),
              useLineAdd: (function (param, param$1) {
                  return useLineAdd(orderType, orderNo, param, param$1);
                }),
              useLineEdit: (function (param, param$1) {
                  return useLineEdit(orderType, orderNo, param, param$1);
                }),
              useLineDelete: (function (param) {
                  return useLineDelete(orderType, orderNo, param);
                }),
              useSelectCustomer: (function (param) {
                  return useSelectCustomer(orderType, orderNo, param);
                })
            });
}

var make$6 = EnterpriseOrderOpen;

exports.OrderDraftAddLine = OrderDraftAddLine;
exports.OrderDraftUpdateLine = OrderDraftUpdateLine;
exports.OrderDraftDeleteLine = OrderDraftDeleteLine;
exports.useLineAdd = useLineAdd;
exports.OrderDraftUpdate = OrderDraftUpdate;
exports.useUpdate = useUpdate;
exports.useLineEdit = useLineEdit;
exports.useLineDelete = useLineDelete;
exports.SelectCustomerMutation = SelectCustomerMutation;
exports.useSelectCustomer = useSelectCustomer;
exports.OrderOpenQuery = OrderOpenQuery;
exports.make = make$6;
/* react Not a pure module */
