'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function interpolate(ar, ele) {
  var len = ar.length;
  if (len === 0 || len === 1) {
    return ar;
  }
  var addi = len - 1 | 0;
  var newLen = len + addi | 0;
  return $$Array.init(newLen, (function (i) {
                if (i % 2 === 0) {
                  return Caml_array.caml_array_get(ar, i / 2 | 0);
                } else {
                  return ele;
                }
              }));
}

function toggle(ele, ar) {
  var len = ar.length;
  var filtered = ar.filter(function (x) {
        return Caml_obj.caml_notequal(x, ele);
      });
  var i = len === filtered.length;
  if (i) {
    return [ele].concat(ar);
  } else {
    return filtered;
  }
}

function exists(f, ar) {
  return Belt_Option.isSome(Caml_option.undefined_to_opt(ar.find(Curry.__1(f))));
}

function keepMapDefault(ar) {
  return Belt_Array.keepMap(ar, (function (x) {
                return x;
              }));
}

exports.interpolate = interpolate;
exports.toggle = toggle;
exports.exists = exists;
exports.keepMapDefault = keepMapDefault;
/* No side effect */
