'use strict';

var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function yearAgo(param) {
  return new Date(new Date(Date.now()).getFullYear() - 1, new Date(Date.now()).getMonth(), new Date(Date.now()).getDate());
}

function monthAgo(param) {
  return new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth() - 1, new Date(Date.now()).getDate());
}

function startOfMonth(param) {
  return new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth(), 1);
}

function endOfMonth(param) {
  return new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth() + 1, 0);
}

function toHtmlDateString(d) {
  return Caml_array.caml_array_get(d.toISOString().split("T"), 0);
}

function parseHtmlDateString(x) {
  var x$1 = Date.parse(x);
  if (isNaN(x$1)) {
    return ;
  } else {
    return Caml_option.some(new Date(x$1));
  }
}

exports.yearAgo = yearAgo;
exports.monthAgo = monthAgo;
exports.startOfMonth = startOfMonth;
exports.endOfMonth = endOfMonth;
exports.toHtmlDateString = toHtmlDateString;
exports.parseHtmlDateString = parseHtmlDateString;
/* No side effect */
