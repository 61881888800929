'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ClientOnly$BirddogsoftwareFrontend = require("../ClientOnly.bs.js");
var QrReader = require("../../../../../../src/client/Components/QrReader/QrReader");

var make = QrReader.default;

var Base = {
  make: make
};

function QrReader$1(Props) {
  var delay = Props.delay;
  var onError = Props.onError;
  var onScan = Props.onScan;
  var match = React.useState(function () {
        return false;
      });
  var setHasError = match[1];
  if (match[0]) {
    return React.createElement(React.Fragment, undefined);
  } else {
    return React.createElement(ClientOnly$BirddogsoftwareFrontend.make, {
                children: React.createElement(make, {
                      delay: delay,
                      onError: (function (err) {
                          Curry._1(setHasError, (function (param) {
                                  return true;
                                }));
                          return Curry._1(onError, err);
                        }),
                      onScan: onScan
                    })
              });
  }
}

var make$1 = QrReader$1;

exports.Base = Base;
exports.make = make$1;
/* make Not a pure module */
