'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function QueryResponse(Props) {
  var queryResponse = Props.queryResponse;
  var children = Props.children;
  if (typeof queryResponse === "number") {
    return "Loading...";
  } else if (queryResponse.TAG) {
    return Curry._1(children, queryResponse._0);
  } else {
    return queryResponse._0;
  }
}

var make = QueryResponse;

exports.make = make;
/* No side effect */
