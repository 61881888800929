'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var ArrayExt$BirddogsoftwareFrontend = require("../../../../Utils/ArrayExt.bs.js");
var PaperPadded$BirddogsoftwareFrontend = require("../../../../Components/PaperPadded.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../../Utils/GraphQLHooks.bs.js");
var OrderTypeSelect$BirddogsoftwareFrontend = require("../../../../Components/Forms/Pickers/OrderTypeSelect.bs.js");
var LoadingPlaceholder$BirddogsoftwareFrontend = require("../../../../Components/LoadingPlaceholder.bs.js");

var ppx_printed_query = "mutation Q($orderDraftId: Guid!, $data: OrderDraftInput!)  {\norderDraft(orderDraftId: $orderDraftId, data: $data)  {\norderDraftId  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraft");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Caml_option.valFromOption(value$2);
    var match = Js_json.decodeNull(value$3);
    if (match !== undefined) {
      tmp = undefined;
    } else {
      var value$4 = Js_option.getExn(Js_json.decodeObject(value$3));
      var value$5 = Js_dict.get(value$4, "orderDraftId");
      tmp = {
        orderDraftId: value$5 !== undefined ? Caml_option.valFromOption(value$5) : Js_exn.raiseError("graphql_ppx: Field orderDraftId on type OrderDraft is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          orderDraft: tmp
        };
}

function json_of_NameAndAddressInput(value) {
  var v = value.name;
  var v$1 = value.line1;
  var v$2 = value.line2;
  var v$3 = value.city;
  var v$4 = value.region;
  var v$5 = value.postalCode;
  var v$6 = value.country;
  return Js_dict.fromArray([
                [
                  "name",
                  v !== undefined ? v : null
                ],
                [
                  "line1",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "line2",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "city",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "region",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "postalCode",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "country",
                  v$6 !== undefined ? v$6 : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_OrderType(value) {
  if (value === "SHOP_FLOOR_ORDER") {
    return "SHOP_FLOOR_ORDER";
  } else if (value === "QUOTE") {
    return "QUOTE";
  } else if (value === "PRODUCTION_ORDER") {
    return "PRODUCTION_ORDER";
  } else if (value === "EMPTY") {
    return "EMPTY";
  } else if (value === "MASTER_ORDER") {
    return "MASTER_ORDER";
  } else if (value === "T") {
    return "T";
  } else if (value === "DEBIT_MEMO") {
    return "DEBIT_MEMO";
  } else if (value === "L_CLOSED") {
    return "L_CLOSED";
  } else if (value === "ORDER") {
    return "ORDER";
  } else if (value === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (value === "BLANKET") {
    return "BLANKET";
  } else {
    return "INVOICE";
  }
}

function json_of_OrderDraftInput(value) {
  var v = value.shipTo;
  var v$1 = value.billTo;
  var v$2 = value.discountPercent;
  var v$3 = value.freight;
  var v$4 = value.miscellaneous;
  var v$5 = value.calculateFreight;
  var v$6 = value.orderDate;
  var v$7 = value.customerNo;
  var v$8 = value.userId;
  var v$9 = value.orderType;
  return Js_dict.fromArray([
                [
                  "shipTo",
                  v !== undefined ? json_of_NameAndAddressInput(Caml_option.valFromOption(v)) : null
                ],
                [
                  "billTo",
                  v$1 !== undefined ? json_of_NameAndAddressInput(Caml_option.valFromOption(v$1)) : null
                ],
                [
                  "discountPercent",
                  v$2 !== undefined ? Caml_option.valFromOption(v$2) : null
                ],
                [
                  "freight",
                  v$3 !== undefined ? Caml_option.valFromOption(v$3) : null
                ],
                [
                  "miscellaneous",
                  v$4 !== undefined ? Caml_option.valFromOption(v$4) : null
                ],
                [
                  "calculateFreight",
                  v$5 !== undefined ? v$5 : null
                ],
                [
                  "orderDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "customerNo",
                  v$7 !== undefined ? v$7 : null
                ],
                [
                  "userId",
                  v$8 !== undefined ? Caml_option.valFromOption(v$8) : null
                ],
                [
                  "orderType",
                  v$9 !== undefined ? json_of_OrderType(v$9) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(orderDraftId, data, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "data",
                    json_of_OrderDraftInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var orderDraftId = variables.orderDraftId;
  var data = variables.data;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "data",
                    json_of_OrderDraftInput(data)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(orderDraftId, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "data",
                  json_of_OrderDraftInput(data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, orderDraftId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "data",
                      json_of_OrderDraftInput(data)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftUpdate = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_NameAndAddressInput: json_of_NameAndAddressInput,
  json_of_OrderType: json_of_OrderType,
  json_of_OrderDraftInput: json_of_OrderDraftInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function reducer(state, action) {
  if (action) {
    return {
            orderType: action._0,
            needsSave: true
          };
  } else {
    return {
            orderType: state.orderType,
            needsSave: false
          };
  }
}

function EnterpriseOrderDraftSettings$Edit(Props) {
  var orderDraftId = Props.orderDraftId;
  var orderType = Props.orderType;
  var orderTypes = Props.orderTypes;
  var match = React.useReducer(reducer, {
        orderType: orderType,
        needsSave: false
      });
  var dispatch = match[1];
  var state = match[0];
  var updateMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(orderDraftId, {
            billTo: undefined,
            shipTo: undefined,
            calculateFreight: undefined,
            discountPercent: undefined,
            freight: undefined,
            miscellaneous: undefined,
            userId: undefined,
            orderDate: undefined,
            orderType: state.orderType,
            customerNo: undefined
          }, undefined));
  if (state.needsSave) {
    Curry._1(updateMutation.runMutation, undefined);
    Curry._1(dispatch, /* Saved */0);
  }
  return React.createElement(OrderTypeSelect$BirddogsoftwareFrontend.Required.make, {
              orderTypes: orderTypes,
              value: state.orderType,
              onChange: (function (orderType) {
                  return Curry._1(dispatch, /* SetOrderType */{
                              _0: orderType
                            });
                })
            });
}

var Edit = {
  OrderDraftUpdate: OrderDraftUpdate,
  reducer: reducer,
  make: EnterpriseOrderDraftSettings$Edit
};

var ppx_printed_query$1 = "query Settings  {\ncompiledSettings  {\nallowOrderEntryOrderType_Order  \nallowOrderEntryOrderType_Blanket  \nallowOrderEntryOrderType_CreditMemo  \nallowOrderEntryOrderType_DebitMemo  \nallowOrderEntryOrderType_Invoice  \nallowOrderEntryOrderType_Quote  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "compiledSettings");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(value$2)));
    var value$4 = Js_dict.get(value$3, "allowOrderEntryOrderType_Order");
    var tmp$1;
    if (value$4 !== undefined) {
      var value$5 = Caml_option.valFromOption(value$4);
      var value$6 = Js_json.decodeBoolean(value$5);
      tmp$1 = value$6 !== undefined ? value$6 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$5));
    } else {
      tmp$1 = Js_exn.raiseError("graphql_ppx: Field allowOrderEntryOrderType_Order on type CompiledSettings is missing");
    }
    var value$7 = Js_dict.get(value$3, "allowOrderEntryOrderType_Blanket");
    var tmp$2;
    if (value$7 !== undefined) {
      var value$8 = Caml_option.valFromOption(value$7);
      var value$9 = Js_json.decodeBoolean(value$8);
      tmp$2 = value$9 !== undefined ? value$9 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$8));
    } else {
      tmp$2 = Js_exn.raiseError("graphql_ppx: Field allowOrderEntryOrderType_Blanket on type CompiledSettings is missing");
    }
    var value$10 = Js_dict.get(value$3, "allowOrderEntryOrderType_CreditMemo");
    var tmp$3;
    if (value$10 !== undefined) {
      var value$11 = Caml_option.valFromOption(value$10);
      var value$12 = Js_json.decodeBoolean(value$11);
      tmp$3 = value$12 !== undefined ? value$12 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$11));
    } else {
      tmp$3 = Js_exn.raiseError("graphql_ppx: Field allowOrderEntryOrderType_CreditMemo on type CompiledSettings is missing");
    }
    var value$13 = Js_dict.get(value$3, "allowOrderEntryOrderType_DebitMemo");
    var tmp$4;
    if (value$13 !== undefined) {
      var value$14 = Caml_option.valFromOption(value$13);
      var value$15 = Js_json.decodeBoolean(value$14);
      tmp$4 = value$15 !== undefined ? value$15 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$14));
    } else {
      tmp$4 = Js_exn.raiseError("graphql_ppx: Field allowOrderEntryOrderType_DebitMemo on type CompiledSettings is missing");
    }
    var value$16 = Js_dict.get(value$3, "allowOrderEntryOrderType_Invoice");
    var tmp$5;
    if (value$16 !== undefined) {
      var value$17 = Caml_option.valFromOption(value$16);
      var value$18 = Js_json.decodeBoolean(value$17);
      tmp$5 = value$18 !== undefined ? value$18 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$17));
    } else {
      tmp$5 = Js_exn.raiseError("graphql_ppx: Field allowOrderEntryOrderType_Invoice on type CompiledSettings is missing");
    }
    var value$19 = Js_dict.get(value$3, "allowOrderEntryOrderType_Quote");
    var tmp$6;
    if (value$19 !== undefined) {
      var value$20 = Caml_option.valFromOption(value$19);
      var value$21 = Js_json.decodeBoolean(value$20);
      tmp$6 = value$21 !== undefined ? value$21 : Js_exn.raiseError("graphql_ppx: Expected boolean, got " + JSON.stringify(value$20));
    } else {
      tmp$6 = Js_exn.raiseError("graphql_ppx: Field allowOrderEntryOrderType_Quote on type CompiledSettings is missing");
    }
    tmp = {
      allowOrderEntryOrderType_Order: tmp$1,
      allowOrderEntryOrderType_Blanket: tmp$2,
      allowOrderEntryOrderType_CreditMemo: tmp$3,
      allowOrderEntryOrderType_DebitMemo: tmp$4,
      allowOrderEntryOrderType_Invoice: tmp$5,
      allowOrderEntryOrderType_Quote: tmp$6
    };
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field compiledSettings on type Query is missing");
  }
  return {
          compiledSettings: tmp
        };
}

function make$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeWithVariables$1(param) {
  return {
          query: ppx_printed_query$1,
          variables: null,
          parse: parse$1
        };
}

function makeVariables$1(param) {
  return null;
}

function definition_2$1(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var SettingsQuery = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function EnterpriseOrderDraftSettings(Props) {
  var orderDraftId = Props.orderDraftId;
  var orderQueryResult = Props.orderQueryResult;
  var settingsQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make$1(undefined), undefined, undefined);
  var match = settingsQuery.result;
  var tmp;
  var exit = 0;
  if (typeof orderQueryResult === "number") {
    tmp = React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {});
  } else if (orderQueryResult.TAG) {
    var order = orderQueryResult._0;
    if (typeof match === "number" || !match.TAG) {
      exit = 1;
    } else {
      var s = match._0.compiledSettings;
      var checkType = function (b, v) {
        if (b) {
          return Caml_option.some(v);
        }
        
      };
      var orderTypes = ArrayExt$BirddogsoftwareFrontend.keepMapDefault([
            checkType(s.allowOrderEntryOrderType_Order, "ORDER"),
            checkType(s.allowOrderEntryOrderType_Blanket, "BLANKET"),
            checkType(s.allowOrderEntryOrderType_CreditMemo, "CREDIT_MEMO"),
            checkType(s.allowOrderEntryOrderType_DebitMemo, "DEBIT_MEMO"),
            checkType(s.allowOrderEntryOrderType_Invoice, "INVOICE"),
            checkType(s.allowOrderEntryOrderType_Quote, "QUOTE")
          ]);
      tmp = order !== undefined ? React.createElement(EnterpriseOrderDraftSettings$Edit, {
              orderDraftId: orderDraftId,
              orderType: Caml_option.valFromOption(order).orderType,
              orderTypes: orderTypes
            }) : React.createElement(React.Fragment, undefined);
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = typeof match === "number" ? React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {}) : React.createElement(React.Fragment, undefined);
  }
  return React.createElement(PaperPadded$BirddogsoftwareFrontend.make, {
              children: null
            }, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, "Order Settings", undefined, "Inherit", undefined, undefined, undefined, true, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined)), tmp);
}

var make$2 = EnterpriseOrderDraftSettings;

exports.Edit = Edit;
exports.SettingsQuery = SettingsQuery;
exports.make = make$2;
/* react Not a pure module */
