'use strict';

var React = require("react");
var SignIn$BirddogsoftwareFrontend = require("../../../Components/SignIn/SignIn.bs.js");
var PagePaper$BirddogsoftwareFrontend = require("../../../Components/PagePaper.bs.js");

function EcommerceSignIn(Props) {
  var signUpLink = Props.signUpLink;
  var forgotLink = Props.forgotLink;
  var store = Props.store;
  return React.createElement(PagePaper$BirddogsoftwareFrontend.make, {
              maxWidth: "Sm",
              children: React.createElement(SignIn$BirddogsoftwareFrontend.make, {
                    signUpLink: signUpLink,
                    forgotLink: forgotLink,
                    store: store
                  })
            });
}

var make = EcommerceSignIn;

exports.make = make;
/* react Not a pure module */
