'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var MaterialUi_Divider = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Divider.bs.js");
var MaterialUi_Popover = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Popover.bs.js");
var Intl$BirddogsoftwareFrontend = require("../../../../Utils/Intl.bs.js");
var Link$BirddogsoftwareFrontend = require("../../../../Components/Link.bs.js");
var Fields$BirddogsoftwareFrontend = require("../../../../Components/Forms/Fields.bs.js");
var PaperPadding$BirddogsoftwareFrontend = require("../../../../Components/PaperPadding.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var LoadingPlaceholder$BirddogsoftwareFrontend = require("../../../../Components/LoadingPlaceholder.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    if (action !== 0) {
      return {
              value: state.value,
              saveState: /* NotSaved */0
            };
    } else {
      return {
              value: state.value,
              saveState: /* Saving */1
            };
    }
  } else {
    return {
            value: action._0,
            saveState: state.saveState
          };
  }
}

function EnterpriseOrderTotals$EditPopover(Props) {
  var anchorEl = Props.anchorEl;
  var onClose = Props.onClose;
  var createInput = Props.createInput;
  var useSave = Props.useSave;
  var defaultValue = Props.defaultValue;
  var onSave = Props.onSave;
  var match = React.useReducer(reducer, {
        value: defaultValue,
        saveState: /* NotSaved */0
      });
  var dispatch = match[1];
  var state = match[0];
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var thenMutation = function (x) {
    x.then(function (response) {
          if (response.TAG) {
            Curry._1(dispatch, /* SendSaved */1);
            Curry._1(onSave, undefined);
          } else {
            Curry._1(dispatch, /* SendSaved */1);
            Curry._1(error, response._0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  var remove = Curry._1(useSave, 0.0);
  var value = state.value;
  var save = value !== undefined ? Curry._1(useSave, value) : Curry._1(useSave, 0.0);
  var match$1 = state.saveState;
  var editDisabled = match$1 ? true : false;
  var match$2 = state.saveState;
  var match$3 = state.value;
  var saveDisabled = match$2 ? true : match$3 === undefined;
  return React.createElement(Core.Popover, MaterialUi_Popover.makeProps(undefined, anchorEl !== undefined ? ({
                      NAME: "ObjectGeneric",
                      VAL: Caml_option.valFromOption(anchorEl)
                    }) : undefined, {
                  horizontal: {
                    NAME: "Enum",
                    VAL: "Center"
                  },
                  vertical: {
                    NAME: "Enum",
                    VAL: "Bottom"
                  }
                }, undefined, undefined, Caml_option.some(React.createElement(PaperPadding$BirddogsoftwareFrontend.make, {
                          children: null
                        }, Curry._3(createInput, state.value, editDisabled, (function (value) {
                                return Curry._1(dispatch, /* SetValue */{
                                            _0: value
                                          });
                              })), React.createElement(Core.Divider, MaterialUi_Divider.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Button, MaterialUi_Button.makeProps("Remove", undefined, "Primary", undefined, saveDisabled, undefined, undefined, undefined, undefined, undefined, undefined, "submit", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                    Curry._1(dispatch, /* SendSaving */0);
                                    return thenMutation(Curry._1(remove.runMutation, undefined));
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Button, MaterialUi_Button.makeProps("Apply", undefined, "Primary", undefined, saveDisabled, undefined, undefined, undefined, undefined, undefined, undefined, "submit", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                    Curry._1(dispatch, /* SendSaving */0);
                                    return thenMutation(Curry._1(save.runMutation, undefined));
                                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))), undefined, undefined, undefined, undefined, undefined, (function (param, param$1) {
                    return Curry._1(onClose, undefined);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, anchorEl !== undefined, undefined, {
                  horizontal: {
                    NAME: "Enum",
                    VAL: "Center"
                  },
                  vertical: {
                    NAME: "Enum",
                    VAL: "Top"
                  }
                }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var EditPopover = {
  reducer: reducer,
  make: EnterpriseOrderTotals$EditPopover
};

function lineBase(name, value) {
  return React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, "Row", undefined, "Flex_End", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(name), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(value !== undefined ? value : "—"), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, true, "Row", undefined, "Flex_End", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* V1 */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

function line(name, value) {
  return lineBase(name, Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, value));
}

function reducer$1(state, action) {
  if (action) {
    return {
            editing: action._0
          };
  } else {
    return {
            editing: undefined
          };
  }
}

function EnterpriseOrderTotals(Props) {
  var order = Props.order;
  var useUpdate = Props.useUpdate;
  var refetch = Props.refetch;
  var match = React.useReducer(reducer$1, {
        editing: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  if (order !== undefined) {
    var order$1 = Caml_option.valFromOption(order);
    var x = order$1.discountPercent;
    var x$1 = state.editing;
    var x$2 = order$1.freight;
    var x$3 = state.editing;
    var x$4 = order$1.miscellaneous;
    var x$5 = state.editing;
    tmp = React.createElement(React.Fragment, undefined, lineBase(React.createElement(Core.Link, Link$BirddogsoftwareFrontend.BD_MaterialUi_Link.makeProps("Add discount", undefined, "#", undefined, undefined, undefined, (function (e) {
                        e.preventDefault();
                        return Curry._1(dispatch, /* StartEdit */{
                                    _0: {
                                      editType: /* Discount */1,
                                      domTarget: e.target
                                    }
                                  });
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), x !== 0.0 ? x.toString() + "%" : undefined), React.createElement(EnterpriseOrderTotals$EditPopover, {
              anchorEl: x$1 !== undefined && x$1.editType === 1 ? Caml_option.some(x$1.domTarget) : undefined,
              onClose: (function (param) {
                  return Curry._1(dispatch, /* CloseEdit */0);
                }),
              createInput: (function (value, disabled, onChange) {
                  return React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                              label: "Discount %",
                              disabled: disabled,
                              value: value,
                              onChange: onChange
                            });
                }),
              useSave: (function (value) {
                  return Curry._1(useUpdate, {
                              billTo: undefined,
                              shipTo: undefined,
                              calculateFreight: undefined,
                              discountPercent: value,
                              freight: undefined,
                              miscellaneous: undefined,
                              orderDate: undefined,
                              userId: undefined
                            });
                }),
              defaultValue: order$1.discountPercent,
              onSave: (function (param) {
                  Curry._1(refetch, undefined);
                  return Curry._1(dispatch, /* CloseEdit */0);
                })
            }), lineBase(React.createElement(Core.Link, Link$BirddogsoftwareFrontend.BD_MaterialUi_Link.makeProps("Add shipping", undefined, "#", undefined, undefined, undefined, (function (e) {
                        e.preventDefault();
                        return Curry._1(dispatch, /* StartEdit */{
                                    _0: {
                                      editType: /* Freight */0,
                                      domTarget: e.target
                                    }
                                  });
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), x$2 !== 0.0 ? Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, x$2) : undefined), React.createElement(EnterpriseOrderTotals$EditPopover, {
              anchorEl: x$3 !== undefined && x$3.editType === 0 ? Caml_option.some(x$3.domTarget) : undefined,
              onClose: (function (param) {
                  return Curry._1(dispatch, /* CloseEdit */0);
                }),
              createInput: (function (value, disabled, onChange) {
                  return React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                              label: "Shipping",
                              disabled: disabled,
                              value: value,
                              onChange: onChange
                            });
                }),
              useSave: (function (value) {
                  return Curry._1(useUpdate, {
                              billTo: undefined,
                              shipTo: undefined,
                              calculateFreight: undefined,
                              discountPercent: undefined,
                              freight: value,
                              miscellaneous: undefined,
                              orderDate: undefined,
                              userId: undefined
                            });
                }),
              defaultValue: order$1.freight,
              onSave: (function (param) {
                  Curry._1(refetch, undefined);
                  return Curry._1(dispatch, /* CloseEdit */0);
                })
            }), lineBase(React.createElement(Core.Link, Link$BirddogsoftwareFrontend.BD_MaterialUi_Link.makeProps("Add miscellaneous", undefined, "#", undefined, undefined, undefined, (function (e) {
                        e.preventDefault();
                        return Curry._1(dispatch, /* StartEdit */{
                                    _0: {
                                      editType: /* Misc */2,
                                      domTarget: e.target
                                    }
                                  });
                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), x$4 !== 0.0 ? Curry._1(Intl$BirddogsoftwareFrontend.formatCurrencyUSD, x$4) : undefined), React.createElement(EnterpriseOrderTotals$EditPopover, {
              anchorEl: x$5 !== undefined && x$5.editType >= 2 ? Caml_option.some(x$5.domTarget) : undefined,
              onClose: (function (param) {
                  return Curry._1(dispatch, /* CloseEdit */0);
                }),
              createInput: (function (value, disabled, onChange) {
                  return React.createElement(Fields$BirddogsoftwareFrontend.Float.Required.make, {
                              label: "Miscellaneous",
                              disabled: disabled,
                              value: value,
                              onChange: onChange
                            });
                }),
              useSave: (function (value) {
                  return Curry._1(useUpdate, {
                              billTo: undefined,
                              shipTo: undefined,
                              calculateFreight: undefined,
                              discountPercent: undefined,
                              freight: undefined,
                              miscellaneous: value,
                              orderDate: undefined,
                              userId: undefined
                            });
                }),
              defaultValue: order$1.miscellaneous,
              onSave: (function (param) {
                  Curry._1(refetch, undefined);
                  return Curry._1(dispatch, /* CloseEdit */0);
                })
            }), line("Taxes", order$1.tax), line("Sub Total", order$1.subTotalByOrdered), line("Total", order$1.totalByOrdered));
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {
              lines: 6
            }));
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = EnterpriseOrderTotals;

exports.EditPopover = EditPopover;
exports.lineBase = lineBase;
exports.line = line;
exports.reducer = reducer$1;
exports.make = make;
/* react Not a pure module */
