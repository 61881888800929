import React from 'react';
import { hydrate } from 'react-dom';
import memCache from 'graphql-hooks-memcache'
import { GraphQLClient } from 'graphql-hooks'
import { runtimeConfig } from './config';
import 'typeface-roboto';

import App from '../lib/js/src/client/App.bs'; // BuckleScript output directory

function Main() {
  // have to remove the css blob generated by the server once the client has rendered, 
  // otherwise css is duplicated
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  const apiUrl = `/api/graphql`;
  const cache = memCache({
    initialState: window.__INITIAL_STATE__
  });

  const gqlClient = new GraphQLClient({
    url: apiUrl,
    cache: cache
  })

  // allow setting serverUrl in global variable. allows embeding in web classic
  const serverUrl = window.bdswServerUrl;
  return <App
    serverUrl={serverUrl}
    gqlClient={gqlClient}
    defaultModule={runtimeConfig.defaultModule}
    hostUrl={runtimeConfig.hostUrl}
    instanceId={runtimeConfig.instanceId}
    hostMode={runtimeConfig.hostMode}
    enterpriseDashboardUrl={runtimeConfig.enterpriseDashboardUrl} />;
}

// allow mounting at arbitrary points, allows embeding in web classic
const mountPoint = window.bdswWebRoot ? window.bdswWebRoot : document.getElementById('root');

hydrate(
  <Main />,
  mountPoint
);

if (module.hot) {
  module.hot.accept();
}
