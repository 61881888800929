'use strict';

var React = require("react");

function EnterpriseDashboard(Props) {
  return React.createElement(React.Fragment, undefined);
}

var make = EnterpriseDashboard;

exports.make = make;
/* react Not a pure module */
