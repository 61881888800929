'use strict';

var React = require("react");
var ArrayExt$BirddogsoftwareFrontend = require("../Utils/ArrayExt.bs.js");
var LoadingPlaceholder$BirddogsoftwareFrontend = require("./LoadingPlaceholder.bs.js");

function checkEmpty(x) {
  if (x === "") {
    return ;
  } else {
    return x;
  }
}

function Address(Props) {
  var address = Props.address;
  var cityStateZip = ArrayExt$BirddogsoftwareFrontend.keepMapDefault([
        checkEmpty(address.city),
        checkEmpty(address.region),
        checkEmpty(address.postalCode)
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, address.line1), React.createElement("div", undefined, address.line2), React.createElement("div", undefined, cityStateZip.join(", ")), React.createElement("div", undefined, address.country));
}

function Address$Placeholder(Props) {
  return React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {
              lines: 4
            });
}

var Placeholder = {
  make: Address$Placeholder
};

var make = Address;

exports.checkEmpty = checkEmpty;
exports.make = make;
exports.Placeholder = Placeholder;
/* react Not a pure module */
