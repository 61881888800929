'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var Fields$BirddogsoftwareFrontend = require("../../../../Components/Forms/Fields.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../../../Utils/GraphQLHooks.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../../../../Components/SnackbarHooks.bs.js");
var ItemAutocomplete$BirddogsoftwareFrontend = require("../../../../Components/Forms/Pickers/ItemAutocomplete.bs.js");

function CartQuickAddItemNo$Line(Props) {
  var disabled = Props.disabled;
  var line = Props.line;
  var onChangeItemNo = Props.onChangeItemNo;
  var onChangeQuantity = Props.onChangeQuantity;
  var match = line.lineError;
  var helperText = match !== undefined ? "Invalid item #." : undefined;
  var tmp = {
    label: "Item #",
    onChange: Curry.__1(onChangeItemNo),
    isTypeahead: true,
    error: helperText !== undefined
  };
  if (helperText !== undefined) {
    tmp.helperText = Caml_option.valFromOption(helperText);
  }
  var match$1 = line.quantity;
  var tmp$1;
  if (match$1 !== undefined) {
    var x = Caml_option.valFromOption(match$1);
    tmp$1 = x !== undefined ? x : undefined;
  } else {
    tmp$1 = undefined;
  }
  return React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, "Flex_End", Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V2 */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(ItemAutocomplete$BirddogsoftwareFrontend.make, tmp)), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Fields$BirddogsoftwareFrontend.Float.$$Option.make, {
                              label: "Quantity",
                              disabled: disabled,
                              defaultValue: tmp$1,
                              onChange: Curry.__1(onChangeQuantity)
                            })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Line = {
  make: CartQuickAddItemNo$Line
};

function emptyLine(key) {
  return {
          itemNo: undefined,
          quantity: undefined,
          lineError: undefined,
          key: key
        };
}

function updateLine(state, index, f) {
  var lines = state.lines;
  return {
          lines: Belt_Array.mapWithIndex(lines, (function (i, line) {
                  if (i === index) {
                    return Curry._1(f, line);
                  } else {
                    return line;
                  }
                })),
          nextKey: state.nextKey
        };
}

function ensureEmptyLine(state) {
  var addLine = function (param) {
    return {
            lines: Belt_Array.concat(state.lines, [emptyLine(state.nextKey)]),
            nextKey: state.nextKey + 1 | 0
          };
  };
  var match = state.lines;
  if (match.length === 0) {
    return addLine(undefined);
  }
  var last = Belt_Array.getExn(state.lines, state.lines.length - 1 | 0);
  var match$1 = last.itemNo;
  if (match$1 !== undefined) {
    return addLine(undefined);
  } else {
    return state;
  }
}

function reducer(state, action) {
  var tmp;
  switch (action.TAG | 0) {
    case /* SetItemNo */0 :
        var itemNo = action._1;
        tmp = updateLine(state, action._0, (function (line) {
                return {
                        itemNo: itemNo,
                        quantity: line.quantity,
                        lineError: line.lineError,
                        key: line.key
                      };
              }));
        break;
    case /* SetQuantity */1 :
        var quantity = action._1;
        tmp = updateLine(state, action._0, (function (line) {
                return {
                        itemNo: line.itemNo,
                        quantity: quantity,
                        lineError: line.lineError,
                        key: line.key
                      };
              }));
        break;
    case /* SetLineErrors */2 :
        var errors = action._0;
        tmp = {
          lines: Belt_Array.keepMap(Belt_Array.mapWithIndex(state.lines, (function (x, y) {
                      return [
                              x,
                              y
                            ];
                    })), (function (param) {
                  var line = param[1];
                  var i = param[0];
                  var err = Belt_Array.getBy(errors, (function (param) {
                          return param[0] === i;
                        }));
                  if (err !== undefined) {
                    return {
                            itemNo: line.itemNo,
                            quantity: line.quantity,
                            lineError: err[1],
                            key: line.key
                          };
                  }
                  
                })),
          nextKey: state.nextKey
        };
        break;
    
  }
  return ensureEmptyLine(tmp);
}

var ppx_printed_query = "mutation Q($orderDraftId: Guid!, $data: [OrderDraftLineManyInput!]!)  {\norderDraftAddLineMany(orderDraftId: $orderDraftId, data: $data)  {\nsuccess  {\nlineErrors  {\nindex  \nreason  \n}\n\n}\n\nfailure  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "orderDraftAddLineMany");
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_json.decodeObject(Caml_option.valFromOption(value$2));
    if (value$3 !== undefined) {
      var value$4 = Caml_option.valFromOption(value$3);
      var temp = Js_dict.get(value$4, "success");
      if (temp !== undefined) {
        var temp$1 = Caml_option.valFromOption(temp);
        var match = Js_json.decodeNull(temp$1);
        if (match !== undefined) {
          var temp$2 = Js_dict.get(value$4, "failure");
          if (temp$2 !== undefined) {
            var temp$3 = Caml_option.valFromOption(temp$2);
            var match$1 = Js_json.decodeNull(temp$3);
            if (match$1 !== undefined) {
              tmp = Js_exn.raiseError("graphql_ppx: All fields on variant selection set on type SuccessOrFailureGraphType<OrderDraftAddLineManyResult, OrderDraftAddLineManyFailures> were null");
            } else {
              var value$5 = Js_json.decodeString(temp$3);
              var tmp$1;
              tmp$1 = value$5 !== undefined ? (
                  value$5 === "INVALID_ORDER" ? "INVALID_ORDER" : Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftAddLineManyFailures: " + value$5)
                ) : Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftAddLineManyFailures, got " + JSON.stringify(temp$3));
              tmp = {
                NAME: "Failure",
                VAL: tmp$1
              };
            }
          } else {
            tmp = Js_exn.raiseError("graphql_ppx: Field failure on type SuccessOrFailureGraphType<OrderDraftAddLineManyResult, OrderDraftAddLineManyFailures> is missing");
          }
        } else {
          var value$6 = Js_option.getExn(Js_json.decodeObject(temp$1));
          var value$7 = Js_dict.get(value$6, "lineErrors");
          tmp = {
            NAME: "Success",
            VAL: {
              lineErrors: value$7 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$7))).map(function (value) {
                      var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                      var value$2 = Js_dict.get(value$1, "index");
                      var tmp;
                      if (value$2 !== undefined) {
                        var value$3 = Caml_option.valFromOption(value$2);
                        var value$4 = Js_json.decodeNumber(value$3);
                        tmp = value$4 !== undefined ? value$4 | 0 : Js_exn.raiseError("graphql_ppx: Expected int, got " + JSON.stringify(value$3));
                      } else {
                        tmp = Js_exn.raiseError("graphql_ppx: Field index on type OrderDraftAddLineManyError is missing");
                      }
                      var value$5 = Js_dict.get(value$1, "reason");
                      var tmp$1;
                      if (value$5 !== undefined) {
                        var value$6 = Caml_option.valFromOption(value$5);
                        var value$7 = Js_json.decodeString(value$6);
                        tmp$1 = value$7 !== undefined ? (
                            value$7 === "INVALID_ITEM" ? "INVALID_ITEM" : Js_exn.raiseError("graphql_ppx: Unknown enum variant for OrderDraftAddLineManyLineFailures: " + value$7)
                          ) : Js_exn.raiseError("graphql_ppx: Expected enum value for OrderDraftAddLineManyLineFailures, got " + JSON.stringify(value$6));
                      } else {
                        tmp$1 = Js_exn.raiseError("graphql_ppx: Field reason on type OrderDraftAddLineManyError is missing");
                      }
                      return {
                              index: tmp,
                              reason: tmp$1
                            };
                    }) : Js_exn.raiseError("graphql_ppx: Field lineErrors on type OrderDraftAddLineManyResult is missing")
            }
          };
        }
      } else {
        tmp = Js_exn.raiseError("graphql_ppx: Field success on type SuccessOrFailureGraphType<OrderDraftAddLineManyResult, OrderDraftAddLineManyFailures> is missing");
      }
    } else {
      tmp = Js_exn.raiseError("graphql_ppx: Expected type SuccessOrFailureGraphType<OrderDraftAddLineManyResult, OrderDraftAddLineManyFailures> to be an object");
    }
  } else {
    tmp = Js_exn.raiseError("graphql_ppx: Field orderDraftAddLineMany on type Mutation is missing");
  }
  return {
          orderDraftAddLineMany: tmp
        };
}

function json_of_OrderDraftLineInput(value) {
  var v = value.quantityOrdered;
  var v$1 = value.quantityToShip;
  var v$2 = value.locationCode;
  var v$3 = value.discountPercent;
  var v$4 = value.comments;
  var v$5 = value.price;
  var v$6 = value.requestDate;
  var v$7 = value.promiseDate;
  return Js_dict.fromArray([
                [
                  "quantityOrdered",
                  v !== undefined ? v : null
                ],
                [
                  "quantityToShip",
                  v$1 !== undefined ? v$1 : null
                ],
                [
                  "locationCode",
                  v$2 !== undefined ? v$2 : null
                ],
                [
                  "discountPercent",
                  v$3 !== undefined ? v$3 : null
                ],
                [
                  "comments",
                  v$4 !== undefined ? v$4 : null
                ],
                [
                  "price",
                  v$5 !== undefined ? Caml_option.valFromOption(v$5) : null
                ],
                [
                  "requestDate",
                  v$6 !== undefined ? Caml_option.valFromOption(v$6) : null
                ],
                [
                  "promiseDate",
                  v$7 !== undefined ? Caml_option.valFromOption(v$7) : null
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function json_of_OrderDraftLineManyInput(value) {
  return Js_dict.fromArray([
                [
                  "itemNo",
                  value.itemNo
                ],
                [
                  "data",
                  json_of_OrderDraftLineInput(value.data)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function make(orderDraftId, data, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "data",
                    data.map(json_of_OrderDraftLineManyInput)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var orderDraftId = variables.orderDraftId;
  var data = variables.data;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([
                  [
                    "orderDraftId",
                    orderDraftId
                  ],
                  [
                    "data",
                    data.map(json_of_OrderDraftLineManyInput)
                  ]
                ].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(orderDraftId, data, param) {
  return Js_dict.fromArray([
                [
                  "orderDraftId",
                  orderDraftId
                ],
                [
                  "data",
                  data.map(json_of_OrderDraftLineManyInput)
                ]
              ].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, orderDraftId, data, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([
                    [
                      "orderDraftId",
                      orderDraftId
                    ],
                    [
                      "data",
                      data.map(json_of_OrderDraftLineManyInput)
                    ]
                  ].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var OrderDraftAddLineMany = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_OrderDraftLineInput: json_of_OrderDraftLineInput,
  json_of_OrderDraftLineManyInput: json_of_OrderDraftLineManyInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function CartQuickAddItemNo(Props) {
  var orderDraftId = Props.orderDraftId;
  var onLineAdd = Props.onLineAdd;
  var match = React.useReducer(reducer, {
        lines: [{
            itemNo: undefined,
            quantity: undefined,
            lineError: undefined,
            key: 0
          }],
        nextKey: 1
      });
  var dispatch = match[1];
  var state = match[0];
  console.log(state);
  var addMutation = GraphQLHooks$BirddogsoftwareFrontend.useMutation(make(orderDraftId, Belt_Array.map(Belt_Array.keepMap(state.lines, (function (x) {
                      var itemNo = x.itemNo;
                      if (itemNo !== undefined) {
                        return [
                                itemNo,
                                x.quantity
                              ];
                      }
                      
                    })), (function (param) {
                  var quantity = param[1];
                  return {
                          itemNo: param[0],
                          data: {
                            quantityOrdered: quantity !== undefined ? Caml_option.valFromOption(quantity) : 1.0,
                            comments: undefined,
                            discountPercent: undefined,
                            locationCode: undefined,
                            promiseDate: undefined,
                            quantityToShip: undefined,
                            requestDate: undefined,
                            price: undefined
                          }
                        };
                })), undefined));
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V2 */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(Belt_Array.mapWithIndex(state.lines, (function (i, line) {
                                    return React.createElement(CartQuickAddItemNo$Line, {
                                                disabled: false,
                                                line: line,
                                                onChangeItemNo: (function (x) {
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetItemNo */0,
                                                                _0: i,
                                                                _1: x
                                                              });
                                                  }),
                                                onChangeQuantity: (function (x) {
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetQuantity */1,
                                                                _0: i,
                                                                _1: x
                                                              });
                                                  }),
                                                key: String(line.key)
                                              });
                                  }))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Button, MaterialUi_Button.makeProps("Add", undefined, "Primary", undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, "submit", "Contained", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (e) {
                                        e.preventDefault();
                                        Curry._1(addMutation.runMutation, undefined).then(function (response) {
                                              if (response.TAG) {
                                                var match = response._0.orderDraftAddLineMany;
                                                if (match.NAME === "Success") {
                                                  Curry._1(dispatch, {
                                                        TAG: /* SetLineErrors */2,
                                                        _0: Belt_Array.map(match.VAL.lineErrors, (function (x) {
                                                                return [
                                                                        x.index,
                                                                        x.reason
                                                                      ];
                                                              }))
                                                      });
                                                  Curry._1(onLineAdd, undefined);
                                                } else {
                                                  Curry._1(error, "invalid order");
                                                }
                                              } else {
                                                Curry._1(error, response._0);
                                              }
                                              return Promise.resolve(undefined);
                                            });
                                        
                                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var make$1 = CartQuickAddItemNo;

exports.Line = Line;
exports.emptyLine = emptyLine;
exports.updateLine = updateLine;
exports.ensureEmptyLine = ensureEmptyLine;
exports.reducer = reducer;
exports.OrderDraftAddLineMany = OrderDraftAddLineMany;
exports.make = make$1;
/* react Not a pure module */
