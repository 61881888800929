'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var Link$BirddogsoftwareFrontend = require("./Link.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../Modules/UrlResolver.bs.js");

function LinkButton(Props) {
  var href = Props.href;
  var variant = Props.variant;
  var color = Props.color;
  var type_ = Props.type_;
  var classNameOpt = Props.className;
  var returnUrlOpt = Props.returnUrl;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var returnUrl = returnUrlOpt !== undefined ? returnUrlOpt : "None";
  var resolved = UrlResolver$BirddogsoftwareFrontend.useResolved(href);
  return React.createElement(Core.Button, MaterialUi_Button.makeProps(Caml_option.some(children), className, color, undefined, undefined, undefined, undefined, undefined, undefined, resolved.url, undefined, type_, variant, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function ($$event) {
                    return Link$BirddogsoftwareFrontend.handleClick($$event, resolved.isExternal, resolved.url, returnUrl, undefined);
                  }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = LinkButton;

exports.make = make;
/* react Not a pure module */
