'use strict';

var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var Location$BirddogsoftwareFrontend = require("../Utils/Location.bs.js");
var UrlResolver$BirddogsoftwareFrontend = require("../Modules/UrlResolver.bs.js");

var _map = {"Default":"default","Error":"error","Inherit":"inherit","Primary":"primary","Secondary":"secondary","TextPrimary":"textPrimary","TextSecondary":"textSecondary"};

var _revMap = {"default":"Default","error":"Error","inherit":"Inherit","primary":"Primary","secondary":"Secondary","textPrimary":"TextPrimary","textSecondary":"TextSecondary"};

function colorToJs(param) {
  return _map[param];
}

function colorFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"None":"none","Hover":"hover","Always":"always"};

var _revMap$1 = {"none":"None","hover":"Hover","always":"Always"};

function underlineToJs(param) {
  return _map$1[param];
}

function underlineFromJs(param) {
  return _revMap$1[param];
}

var _map$2 = {"Inherit":"inherit","Left":"left","Center":"center","Right":"right","Justify":"justify"};

var _revMap$2 = {"inherit":"Inherit","left":"Left","center":"Center","right":"Right","justify":"Justify"};

function alignToJs(param) {
  return _map$2[param];
}

function alignFromJs(param) {
  return _revMap$2[param];
}

var _map$3 = {"Initial":"initial","Block":"block","Inline":"inline"};

var _revMap$3 = {"initial":"Initial","block":"Block","inline":"Inline"};

function displayToJs(param) {
  return _map$3[param];
}

function displayFromJs(param) {
  return _revMap$3[param];
}

function to_string(param) {
  switch (param.TAG | 0) {
    case /* Root */0 :
        return "root";
    case /* UnderlineNone */1 :
        return "underlineNone";
    case /* UnderlineHover */2 :
        return "underlineHover";
    case /* UnderlineAlways */3 :
        return "underlineAlways";
    case /* Button */4 :
        return "button";
    case /* FocusVisible */5 :
        return "focusVisible";
    
  }
}

function to_obj(listOfClasses) {
  return Belt_List.reduce(listOfClasses, {}, (function (obj, classType) {
                obj[to_string(classType)] = classType._0;
                return obj;
              }));
}

var Classes = {
  to_string: to_string,
  to_obj: to_obj
};

function makeProps(children, className, href, color, onBlur, onFocus, onClick, _TypographyClasses, underline, variant, key, ref_, align, display, gutterBottom, noWrap, paragraph, theme, variantMapping, classes, style, param) {
  var tmp = {};
  if (children !== undefined) {
    tmp.children = Caml_option.valFromOption(children);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (href !== undefined) {
    tmp.href = Caml_option.valFromOption(href);
  }
  var tmp$1 = Belt_Option.map(color, (function (v) {
          return _map[v];
        }));
  if (tmp$1 !== undefined) {
    tmp.color = Caml_option.valFromOption(tmp$1);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (_TypographyClasses !== undefined) {
    tmp.TypographyClasses = Caml_option.valFromOption(_TypographyClasses);
  }
  var tmp$2 = Belt_Option.map(underline, (function (v) {
          return _map$1[v];
        }));
  if (tmp$2 !== undefined) {
    tmp.underline = Caml_option.valFromOption(tmp$2);
  }
  if (variant !== undefined) {
    tmp.variant = Caml_option.valFromOption(variant);
  }
  if (key !== undefined) {
    tmp.key = Caml_option.valFromOption(key);
  }
  if (ref_ !== undefined) {
    tmp._ref = Caml_option.valFromOption(ref_);
  }
  var tmp$3 = Belt_Option.map(align, (function (v) {
          return _map$2[v];
        }));
  if (tmp$3 !== undefined) {
    tmp.align = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Belt_Option.map(display, (function (v) {
          return _map$3[v];
        }));
  if (tmp$4 !== undefined) {
    tmp.display = Caml_option.valFromOption(tmp$4);
  }
  if (gutterBottom !== undefined) {
    tmp.gutterBottom = Caml_option.valFromOption(gutterBottom);
  }
  if (noWrap !== undefined) {
    tmp.noWrap = Caml_option.valFromOption(noWrap);
  }
  if (paragraph !== undefined) {
    tmp.paragraph = Caml_option.valFromOption(paragraph);
  }
  if (theme !== undefined) {
    tmp.theme = Caml_option.valFromOption(theme);
  }
  if (variantMapping !== undefined) {
    tmp.variantMapping = Caml_option.valFromOption(variantMapping);
  }
  var tmp$5 = Belt_Option.map(classes, to_obj);
  if (tmp$5 !== undefined) {
    tmp.classes = Caml_option.valFromOption(tmp$5);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return tmp;
}

var BD_MaterialUi_Link = {
  colorToJs: colorToJs,
  colorFromJs: colorFromJs,
  underlineToJs: underlineToJs,
  underlineFromJs: underlineFromJs,
  alignToJs: alignToJs,
  alignFromJs: alignFromJs,
  displayToJs: displayToJs,
  displayFromJs: displayFromJs,
  Classes: Classes,
  makeProps: makeProps
};

function handleClick($$event, isExternal, href, returnUrlOpt, param) {
  var returnUrl = returnUrlOpt !== undefined ? returnUrlOpt : "None";
  $$event.preventDefault();
  if (returnUrl === "CurrentReturnUrl") {
    return Location$BirddogsoftwareFrontend.unsafe_setWithReturnUrl(isExternal, href, "CurrentReturnUrl");
  } else if (returnUrl === "None") {
    return Location$BirddogsoftwareFrontend.unsafe_set(isExternal, href);
  } else {
    return Location$BirddogsoftwareFrontend.unsafe_setWithReturnUrl(isExternal, href, "CurrentPage");
  }
}

function Link(Props) {
  var href = Props.href;
  var underline = Props.underline;
  var variant = Props.variant;
  var classNameOpt = Props.className;
  var returnUrlOpt = Props.returnUrl;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var returnUrl = returnUrlOpt !== undefined ? returnUrlOpt : "None";
  var resolved = UrlResolver$BirddogsoftwareFrontend.useResolved(href);
  return React.createElement(Core.Link, makeProps(Caml_option.some(children), className, resolved.url, undefined, undefined, undefined, (function ($$event) {
                    return handleClick($$event, resolved.isExternal, resolved.url, returnUrl, undefined);
                  }), undefined, underline, variant, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = Link;

exports.BD_MaterialUi_Link = BD_MaterialUi_Link;
exports.handleClick = handleClick;
exports.make = make;
/* react Not a pure module */
