'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");

function getReturnUrl(param) {
  var parsedQueryString = new URLSearchParams(window.location.search);
  return Caml_option.nullable_to_opt(parsedQueryString.get("returnUrl"));
}

function buildPath(path, returnUrl) {
  var returnUrl$1 = "returnUrl=" + encodeURIComponent(returnUrl);
  if (path.includes("?")) {
    return path + ("&" + returnUrl$1);
  } else {
    return path + ("?" + returnUrl$1);
  }
}

function unsafe_set(isExternal, path) {
  if (isExternal) {
    window.location = path;
    return ;
  } else {
    return ReasonReactRouter.push(path);
  }
}

function unsafe_setWithReturnUrl(isExternal, path, returnUrl) {
  var path$1;
  if (returnUrl === "CurrentReturnUrl") {
    var returnUrl$1 = getReturnUrl(undefined);
    path$1 = returnUrl$1 !== undefined ? buildPath(path, returnUrl$1) : path;
  } else {
    var $$location = window.location;
    path$1 = buildPath(path, $$location.pathname + $$location.search);
  }
  return unsafe_set(isExternal, path$1);
}

function unsafe_setUseReturnUrl(isExternal, path) {
  var returnUrl = getReturnUrl(undefined);
  var path$1 = returnUrl !== undefined ? returnUrl : path;
  return unsafe_set(isExternal, path$1);
}

exports.getReturnUrl = getReturnUrl;
exports.buildPath = buildPath;
exports.unsafe_set = unsafe_set;
exports.unsafe_setWithReturnUrl = unsafe_setWithReturnUrl;
exports.unsafe_setUseReturnUrl = unsafe_setUseReturnUrl;
/* ReasonReactRouter Not a pure module */
