'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_IconButton = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_IconButton.bs.js");
var MaterialUi_Typography = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Typography.bs.js");
var ArrowDropUp = require("@material-ui/icons/ArrowDropUp");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");
var LoadingPlaceholder$BirddogsoftwareFrontend = require("../LoadingPlaceholder.bs.js");

function handleDate(d) {
  if (d === "") {
    return ;
  } else {
    return Caml_option.some(new Date(d));
  }
}

function reducer(state, action) {
  return {
          show: !state.show
        };
}

function FormDataView(Props) {
  var title = Props.title;
  var value = Props.value;
  var collapseModeOpt = Props.collapseMode;
  var collapseMode = collapseModeOpt !== undefined ? collapseModeOpt : "NoCollapse";
  var match = React.useReducer(reducer, {
        show: collapseMode === "NoCollapse" || collapseMode === "StartOpen"
      });
  var dispatch = match[1];
  var state = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Typography, MaterialUi_Typography.makeProps(undefined, Caml_option.some(null), undefined, "Inherit", undefined, undefined, undefined, true, undefined, undefined, "H6", undefined, undefined, undefined, undefined, undefined, undefined), title, collapseMode === "NoCollapse" ? React.createElement(React.Fragment, undefined) : React.createElement(Core.IconButton, MaterialUi_IconButton.makeProps(Caml_option.some(state.show ? React.createElement(ArrowDropUp.default, {}) : React.createElement(ArrowDropDown.default, {})), undefined, undefined, undefined, undefined, undefined, undefined, "Small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              return Curry._1(dispatch, /* Toggle */0);
                            }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), state.show ? (
                value !== undefined ? Caml_option.valFromOption(value) : React.createElement(LoadingPlaceholder$BirddogsoftwareFrontend.make, {})
              ) : React.createElement(React.Fragment, undefined));
}

var make = FormDataView;

exports.handleDate = handleDate;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
