'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Grid = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Grid.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Button = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Button.bs.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var Style$BirddogsoftwareFrontend = require("../../Utils/Style.bs.js");
var Fields$BirddogsoftwareFrontend = require("../Forms/Fields.bs.js");
var SnackbarHooks$BirddogsoftwareFrontend = require("../SnackbarHooks.bs.js");
var ItemAutocomplete$BirddogsoftwareFrontend = require("../Forms/Pickers/ItemAutocomplete.bs.js");

function reducer(state, action) {
  if (typeof action !== "number") {
    if (action.TAG) {
      return {
              itemNo: state.itemNo,
              itemNoValid: state.itemNoValid,
              quantity: action._0,
              addState: state.addState
            };
    } else {
      return {
              itemNo: action._0,
              itemNoValid: state.itemNoValid,
              quantity: state.quantity,
              addState: state.addState
            };
    }
  }
  switch (action) {
    case /* SendStartedAdd */0 :
        return {
                itemNo: state.itemNo,
                itemNoValid: state.itemNoValid,
                quantity: state.quantity,
                addState: /* StartedAdd */1
              };
    case /* SendFinishedAdd */1 :
        return {
                itemNo: state.itemNo,
                itemNoValid: true,
                quantity: state.quantity,
                addState: /* NotAdding */0
              };
    case /* SendInvalidItem */2 :
        return {
                itemNo: state.itemNo,
                itemNoValid: false,
                quantity: state.quantity,
                addState: /* NotAdding */0
              };
    
  }
}

function classRecordToJs(param) {
  return {
          root: param.root,
          item: param.item
        };
}

var classes = {
  TAG: /* ThemeFunc */1,
  _0: (function (theme) {
      return {
              root: {},
              item: {
                width: Style$BirddogsoftwareFrontend.getSpacing(theme, 30)
              }
            };
    })
};

function classRecordStringsFromJs(arg) {
  return {
          root: arg.root,
          item: arg.item
        };
}

var EnterpriseOrderLineAddStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var EnterpriseOrderLineAddStyles = MaterialUi_WithStyles.WithStylesSafe(EnterpriseOrderLineAddStylesDefs);

function OrderLineAdd(Props) {
  var useLineAdd = Props.useLineAdd;
  var onLineAdd = Props.onLineAdd;
  var match = React.useReducer(reducer, {
        itemNo: undefined,
        itemNoValid: true,
        quantity: Caml_option.some(undefined),
        addState: /* NotAdding */0
      });
  var dispatch = match[1];
  var state = match[0];
  var error = SnackbarHooks$BirddogsoftwareFrontend.useError(undefined);
  var success = SnackbarHooks$BirddogsoftwareFrontend.useSuccess(undefined);
  var itemNo = state.itemNo;
  var x = state.quantity;
  var lineAdd = Curry._2(useLineAdd, itemNo !== undefined ? itemNo : "", {
        quantityOrdered: x !== undefined ? Caml_option.valFromOption(x) : -1.0,
        comments: undefined,
        discountPercent: undefined,
        locationCode: undefined,
        promiseDate: undefined,
        quantityToShip: undefined,
        requestDate: undefined,
        price: undefined
      });
  var match$1 = state.itemNo;
  var match$2 = state.quantity;
  var valid = match$1 !== undefined && match$2 !== undefined ? [
      match$1,
      Caml_option.valFromOption(match$2)
    ] : undefined;
  var match$3 = state.addState;
  var disabled = match$3 ? true : false;
  return React.createElement(EnterpriseOrderLineAddStyles.make, {
              children: (function (classes) {
                  var match = state.quantity;
                  var tmp;
                  if (match !== undefined) {
                    var x = Caml_option.valFromOption(match);
                    tmp = x !== undefined ? x : undefined;
                  } else {
                    tmp = undefined;
                  }
                  return React.createElement("div", {
                              className: classes.root
                            }, React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, "Flex_End", Caml_option.some(null), undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V2 */2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(ItemAutocomplete$BirddogsoftwareFrontend.make, {
                                                  className: classes.item,
                                                  label: "Item #",
                                                  onChange: (function (itemNo) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* SetItemNo */0,
                                                                  _0: itemNo
                                                                });
                                                    }),
                                                  isTypeahead: true
                                                })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Fields$BirddogsoftwareFrontend.Float.$$Option.make, {
                                                  label: "Quantity",
                                                  className: classes.item,
                                                  disabled: disabled,
                                                  defaultValue: tmp,
                                                  onChange: (function (value) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* SetQuantity */1,
                                                                  _0: value
                                                                });
                                                    })
                                                })), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Core.Grid, MaterialUi_Grid.makeProps(undefined, undefined, Caml_option.some(React.createElement(Core.Button, MaterialUi_Button.makeProps("Add", undefined, undefined, undefined, disabled, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Contained", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                        if (valid === undefined) {
                                                          return ;
                                                        }
                                                        var itemNo = valid[0];
                                                        Curry._1(dispatch, /* SendStartedAdd */0);
                                                        Curry._1(lineAdd, undefined).then(function (response) {
                                                              if (response.TAG) {
                                                                var data = response._0;
                                                                if (data.NAME === "Success") {
                                                                  Curry._1(onLineAdd, undefined);
                                                                  Curry._1(success, "Added " + itemNo);
                                                                  Curry._1(dispatch, /* SendFinishedAdd */1);
                                                                } else if (data.VAL === "INVALID_ITEM") {
                                                                  Curry._1(dispatch, /* SendInvalidItem */2);
                                                                } else {
                                                                  Curry._1(error, "unexpected response");
                                                                }
                                                              } else {
                                                                Curry._1(dispatch, /* SendFinishedAdd */1);
                                                                Curry._1(error, response._0);
                                                              }
                                                              return Promise.resolve(undefined);
                                                            });
                                                        
                                                      }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
                })
            });
}

var make = OrderLineAdd;

exports.reducer = reducer;
exports.EnterpriseOrderLineAddStylesDefs = EnterpriseOrderLineAddStylesDefs;
exports.EnterpriseOrderLineAddStyles = EnterpriseOrderLineAddStyles;
exports.make = make;
/* EnterpriseOrderLineAddStyles Not a pure module */
