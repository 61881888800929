'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MaterialUi_Paper = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Paper.bs.js");
var Core = require("@material-ui/core");
var MaterialUi_Container = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_Container.bs.js");
var MaterialUi_TextField = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_TextField.bs.js");
var MaterialUi_WithStyles = require("@jsiebern/bs-material-ui/lib/js/src/MaterialUi_WithStyles.bs.js");
var Link$BirddogsoftwareFrontend = require("../../Components/Link.bs.js");
var Style$BirddogsoftwareFrontend = require("../../Utils/Style.bs.js");
var PaperSpacer$BirddogsoftwareFrontend = require("../../Components/PaperSpacer.bs.js");
var ActionHeader$BirddogsoftwareFrontend = require("../../Components/Forms/ActionHeader.bs.js");
var GraphQLHooks$BirddogsoftwareFrontend = require("../../Utils/GraphQLHooks.bs.js");
var MaterialTable$BirddogsoftwareFrontend = require("../../Components/MaterialTable.bs.js");

function classRecordToJs(param) {
  return {
          textField: param.textField
        };
}

var classes = {
  TAG: /* ThemeFunc */1,
  _0: (function (theme) {
      return {
              textField: {
                marginRight: Style$BirddogsoftwareFrontend.getSpacing(theme, 1),
                marginBottom: Style$BirddogsoftwareFrontend.getSpacing(theme, 1),
                marginLeft: Style$BirddogsoftwareFrontend.getSpacing(theme, 1)
              }
            };
    })
};

function classRecordStringsFromJs(arg) {
  return {
          textField: arg.textField
        };
}

var EnterpriseUserSearchStylesDefs = {
  classRecordToJs: classRecordToJs,
  classRecordStringsFromJs: classRecordStringsFromJs,
  classes: classes
};

var EnterpriseUserSearchStyles = MaterialUi_WithStyles.WithStylesSafe(EnterpriseUserSearchStylesDefs);

var ppx_printed_query = "query UserSearch($text: String!)  {\nuserSearch(text: $text)  {\nuserId  \nloginId  \nname  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var value$2 = Js_dict.get(value$1, "userSearch");
  return {
          userSearch: value$2 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(value$2))).map(function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var value$2 = Js_dict.get(value$1, "userId");
                  var value$3 = Js_dict.get(value$1, "loginId");
                  var tmp;
                  if (value$3 !== undefined) {
                    var value$4 = Caml_option.valFromOption(value$3);
                    var value$5 = Js_json.decodeString(value$4);
                    tmp = value$5 !== undefined ? value$5 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field loginId on type User is missing");
                  }
                  var value$6 = Js_dict.get(value$1, "name");
                  var tmp$1;
                  if (value$6 !== undefined) {
                    var value$7 = Caml_option.valFromOption(value$6);
                    var value$8 = Js_json.decodeString(value$7);
                    tmp$1 = value$8 !== undefined ? value$8 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$7));
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field name on type User is missing");
                  }
                  return {
                          userId: value$2 !== undefined ? Caml_option.valFromOption(value$2) : Js_exn.raiseError("graphql_ppx: Field userId on type User is missing"),
                          loginId: tmp,
                          name: tmp$1
                        };
                }) : Js_exn.raiseError("graphql_ppx: Field userSearch on type Query is missing")
        };
}

function make(text, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "text",
                    text
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var text = variables.text;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray([[
                    "text",
                    text
                  ]].filter(function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })),
          parse: parse
        };
}

function makeVariables(text, param) {
  return Js_dict.fromArray([[
                  "text",
                  text
                ]].filter(function (param) {
                  return !Js_json.test(param[1], /* Null */5);
                }));
}

function definition_2(graphql_ppx_use_json_variables_fn, text, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([[
                      "text",
                      text
                    ]].filter(function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    })));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var UserSearchQuery = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function reducer(state, action) {
  return {
          text: action._0
        };
}

function EnterpriseUserSearch(Props) {
  var match = React.useReducer(reducer, {
        text: ""
      });
  var dispatch = match[1];
  var state = match[0];
  var loggingSearchQuery = GraphQLHooks$BirddogsoftwareFrontend.useQuery(make(state.text, undefined), undefined, undefined);
  var message = loggingSearchQuery.result;
  var match$1;
  match$1 = typeof message === "number" ? [
      true,
      undefined,
      []
    ] : (
      message.TAG ? [
          false,
          undefined,
          message._0.userSearch
        ] : [
          false,
          message._0,
          []
        ]
    );
  var data = match$1[2];
  var isLoading = match$1[0];
  return React.createElement(EnterpriseUserSearchStyles.make, {
              children: (function (classes) {
                  return React.createElement(Core.Container, MaterialUi_Container.makeProps(Caml_option.some(null), undefined, undefined, undefined, "Md", undefined, undefined, undefined, undefined, undefined), React.createElement(ActionHeader$BirddogsoftwareFrontend.make, {
                                  breadcrumbs: [{
                                      href: {
                                        NAME: "Enterprise",
                                        VAL: "/users"
                                      },
                                      content: "Users"
                                    }],
                                  mainActions: [{
                                      key: "new",
                                      content: "New",
                                      action: {
                                        NAME: "Link",
                                        VAL: {
                                          NAME: "Enterprise",
                                          VAL: "/users/new"
                                        }
                                      },
                                      color: "Primary"
                                    }]
                                }), React.createElement(PaperSpacer$BirddogsoftwareFrontend.make, {
                                  children: React.createElement(Core.Paper, MaterialUi_Paper.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Core.TextField, MaterialUi_TextField.makeProps(undefined, undefined, undefined, classes.textField, state.text, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Search", undefined, undefined, undefined, undefined, (function ($$event) {
                                                  return Curry._1(dispatch, /* SetText */{
                                                              _0: $$event.target.value
                                                            });
                                                }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(MaterialTable$BirddogsoftwareFrontend.Table.make, {
                                            columns: [
                                              {
                                                render: (function (row) {
                                                    return React.createElement(Link$BirddogsoftwareFrontend.make, {
                                                                href: {
                                                                  NAME: "Enterprise",
                                                                  VAL: "/users/" + (Belt_Option.getExn(Js_json.decodeString(row.userId)) + "/")
                                                                },
                                                                children: "Edit"
                                                              });
                                                  })
                                              },
                                              {
                                                title: "Name",
                                                field: "name"
                                              },
                                              {
                                                title: "Email",
                                                field: "loginId"
                                              }
                                            ],
                                            data: data,
                                            isLoading: isLoading,
                                            options: {
                                              grouping: false,
                                              filtering: false,
                                              toolbar: false
                                            },
                                            components: {
                                              Container: MaterialTable$BirddogsoftwareFrontend.divContainer
                                            }
                                          }))
                                }));
                })
            });
}

var Paper;

var Theme;

var Mixins;

var ZIndex;

var Breakpoints;

var PaletteColor;

var Palette;

var make$1 = EnterpriseUserSearch;

exports.Paper = Paper;
exports.Theme = Theme;
exports.Mixins = Mixins;
exports.ZIndex = ZIndex;
exports.Breakpoints = Breakpoints;
exports.PaletteColor = PaletteColor;
exports.Palette = Palette;
exports.EnterpriseUserSearchStylesDefs = EnterpriseUserSearchStylesDefs;
exports.EnterpriseUserSearchStyles = EnterpriseUserSearchStyles;
exports.UserSearchQuery = UserSearchQuery;
exports.reducer = reducer;
exports.make = make$1;
/* EnterpriseUserSearchStyles Not a pure module */
