'use strict';

var GraphiQL = require("../../../../src/client/GraphiQL");

function makeProps(param) {
  return {};
}

var make = GraphiQL.default;

exports.makeProps = makeProps;
exports.make = make;
/* make Not a pure module */
